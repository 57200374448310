import _ from 'lodash';
import lib from 'lib';
import Room from 'project-helpers/room';
import Product from 'project-helpers/product';
import DetailsHelper from 'helpers/details-helper';
import HatchHelper from 'helpers/hatch-helper';
import getDependencies from 'helpers/get-dependencies';
import K from 'k';

import updateProductionIds from 'helpers/update-production-ids-helper';
import Container from './container';

var Project = {
  get(dependencyKeys, {project}) {
    return getDependencies({dependencyKeys}, ({state}) => {
      if (state.resources.rooms.byFieldKeyIndex) {
        const rooms = _.values(state.resources.rooms.byFieldKeyIndex.projectId[project.id]);

        return {
          appliances: () => state.resources.appliances.byId,
          products: () => _.flatMap(rooms, room => _.values(Room.get('products', {room}))),
          containers: () => _.flatMap(rooms, room => _.values(Room.get('containers', {room}))),
          elevations: () => _.flatMap(rooms, room => _.values(Room.get('elevations', {room}))),
          rooms: () => rooms,
          floors: () => state.resources.floors.byFieldKeyIndex.projectId[project.id],
          dependencies: () => state.resources,
          parts: () => state.resources.parts.byId
        };
      }
    });
  },

  getMaterialFrequencyGroups({project, activeFillMode}) {
    var products = Project.get('products', {project});

    var materialFrequencyGroups = {wHatches: {}, woVisibleIds: {}, wVisibleIds: {}};

    _.forEach(products, product => {
      var productData = Product.getProductData({product});
      var details = DetailsHelper.getDetailsFor({product});
      var ownedCompatibleDetails = Product.getOwnedCompatibleDetails({product});

      var incrementMaterialCount = ({groupKey, materialId}) => {
        materialFrequencyGroups[groupKey][materialId] = (materialFrequencyGroups[groupKey][materialId] || 0) + 1;
      };

      _.forEach(ownedCompatibleDetails, ({key, type}) => {
        if (type === 'material') {
          var detail = details[key];

          if (detail) {
            if (Project.getShouldShowHatch({detailKey: key, productData, activeFillMode})) {
              var hasAppliedBrass = Product.getHasAppliedBrass({product, productType: productData});

              if (hasAppliedBrass && key === 'frontMaterial') {
                incrementMaterialCount({groupKey: 'wHatches', materialId: `${detail.id}brass`});
              }
              else {
                incrementMaterialCount({groupKey: 'wHatches', materialId: detail.id});
              }
            }
            else {
              incrementMaterialCount({groupKey: productData.categoryId === 54 ? 'woVisibleIds' : 'wVisibleIds', materialId: detail.id});
            }
          }
        }
      });
    });

    materialFrequencyGroups = _.mapValues(materialFrequencyGroups, (materialFrequencies, groupKey) => {
      materialFrequencies = _.orderBy(_.map(materialFrequencies, (count, materialId) => ({count, materialId: parseInt(materialId), hasAppliedBrass: _.includes(materialId, 'brass')})), 'count', 'desc');

      if (groupKey === 'wHatches') {
        _.forEach(materialFrequencies, materialFrequency => {
          materialFrequency.hatchKey = Project.getHatchKeyFor({project, detailValue: materialFrequency.materialId, hasAppliedBrass: materialFrequency.hasAppliedBrass, useMaterialFrequencyGroups: false});
        });
      }

      return materialFrequencies;
    });

    return materialFrequencyGroups;
  },

  getShouldShowHatch: ({detailKey, productData, sideKey, activeFillMode}) => {
    var isSubproductDetail = _.split(detailKey, '-').length > 1;

    //HINT should show hatch is determined based on the core detailKey, IE
    //frontMaterial, not drawer-2-frontMaterial
    if (isSubproductDetail) detailKey = _.split(detailKey, '-')[2];

    var isFront = (detailKey === 'frontMaterial' && !_.includes([53, 54], productData.categoryId));
    var isPanel = (detailKey === 'panelMaterial' && (_.includes([53, 77, 79, 50, 46, 49, 47], productData.categoryId) || _.includes([728], productData.id)));

    var isKick = (detailKey === 'kickMaterial');
    var isScribe = (detailKey === 'scribeMaterial') || _.includes(detailKey, 'ScribeMaterial');
    var isEndPanel = (detailKey === 'panelMaterial');
    var isPull = (activeFillMode === 'materialColors' && detailKey === 'pullMaterial');
    var isSubproductDetail = _.split(detailKey, '-').length > 1;

    var exposedBoxProductIds = [
      37, 691, 85, 699, 120, 694, 119, 174, 175, 183, 1148, 1610,
      262, 404, //valets
      960, 1400, 1486, 611, 612, 176, 987, 992, //open units
      977, 160, //sliding glass
      //assemblies?
      1188, 1712, 1713, //opencase trim cap, not sure why this is using box materialKey,
      986, 1581, 1519, 1587, 901, 938, 960, 961, 985, 986, 1355,//ST bookcases
      1564,//ST shelfbank shelf
      1643, 1663, //ST functional wood boxes
      1407,1524, 1525, 1419, //vbb spice drawer, using box material key
      1149, //HB hood freestanding shroud
      1716, //top storage open hanging
      185 //backsplash w wood shelf
    ];

    var isSideOfBox = _.includes(['left', 'right'], sideKey) && detailKey === 'boxMaterial';

    var isBenchAssemblyMaterial = false;

    var isBenchAssemblyMaterial = productData.id === 512 && _.includes(['endPanelMaterial', 'wallPanelMaterial', 'hookMaterial', 'shoeShelfMaterial', 'steelShelfMaterial', 'benchTopMaterial', 'benchBaseMaterial', 'benchBackMaterial', 'benchBracketMaterial'], detailKey);

    var isVBB = _.includes([64], productData.categoryId);
    var isOpenCategory = _.includes([72], productData.categoryId);
    var isExposedBox = ((detailKey === 'boxMaterial' || detailKey === 'boxBackMaterial') && (isOpenCategory || _.includes(exposedBoxProductIds, productData.id) || isVBB));
    var isFramedGlass = (detailKey === 'glassMaterial'); //TODO filter by products?

    var isShelfbankBoxMaterial = detailKey === 'boxMaterial' && _.includes([162, 163, 164, 165, 166, 165, 167, 1379], productData.id);

    var isOpencaseShelfBoxMaterial = detailKey === 'boxMaterial' && _.includes([1476, 1477, 1478], productData.id);
    var isOpencaseShelfFrontMaterial = detailKey === 'frontMaterial' && _.includes([1476, 1477, 1478], productData.id);

    var isOpencaseComponent = activeFillMode === 'materialColors' && _.includes(['ocRodMaterial', 'ocMetalMaterial', 'ocWoodMaterial', 'ocCapMaterial', 'ocLeatherMaterial', 'ocPaperstoneMaterial', 'ocPocketMaterial', 'ocMetal2Material'], detailKey) && productData.categoryId === 54;
    var isPeg = activeFillMode === 'materialColors' && detailKey === 'pegMaterial';
    var isSTPocketingFlipBox = _.includes([990, 1191, 1673, 1674], productData.id) && detailKey === 'boxMaterial';
    var isMicrowaveUpper = _.includes([762, 64], productData.id)  && detailKey === 'upperBoxMaterial';

    //HINT open unit wood drawers are visible
    var isExposedBoxWoodDrawer = detailKey === 'woodDrawerMaterial' && (isOpenCategory || _.includes(exposedBoxProductIds, productData.id) || isVBB);

    return isFront || isPanel || isKick || isScribe || isEndPanel || isExposedBox || isPeg || isSideOfBox ||
      isFramedGlass || isOpencaseComponent || isPull || isShelfbankBoxMaterial || isMicrowaveUpper || isBenchAssemblyMaterial ||
      isOpencaseShelfBoxMaterial || isOpencaseShelfFrontMaterial || isSTPocketingFlipBox || isExposedBoxWoodDrawer;
  },

  getHatchKeyFor: ({project, detailValue, hasAppliedBrass=false, useMaterialFrequencyGroups = true}) => {
    var key;

    if (!K.usedHatches) K.usedHatches = {};

    _.forEach(K.usedHatches, (value, hatchKey) => {
      if (parseInt(value) === detailValue && _.includes(value, 'brass') === hasAppliedBrass) key = hatchKey;
    });

    if (!key && useMaterialFrequencyGroups) {
      key = _.get(_.find(Project.getMaterialFrequencyGroups({project}).wHatches, hatch => hatch.materialId === detailValue && hasAppliedBrass === hatch.hasAppliedBrass), 'hatchKey');
    }

    if (!key) {
      var unusedKeys = _.filter(HatchHelper.getHatchKeys(), key => !_.includes(_.keys(K.usedHatches), key));

      key = unusedKeys[0] || 'invalid';

      K.usedHatches[key] = `${detailValue}${hasAppliedBrass ? 'brass' : ''}`;
    }

    return key;
  },

  autogenerateElevations({project, roomId, reduxActions, isBatched}) {
    //HINT no longer autogenerating
    //just generating a few elevations and designers position themselves
    return false;

    const {continuousElevationAutogenerationDisabled} = project;

    if (continuousElevationAutogenerationDisabled) return false;

    var rooms = Project.get('rooms', {project});
    var allMatchedElevations = [];
    var elevationPropsSetsToCreate = [], elevationUpdates = [], elevationIdsToDestroy = [];

    if (roomId) rooms = _.filter(rooms, room => room.id === roomId);

    _.forEach(rooms, room => {
      if (_.get(room, 'plan.closed')) {
        var oldElevations = Room.get('elevations', {room});
        var matchedElevations = [], newElevationsData = [];
        var matchDistanceThreshold = 36;
        var computedElevationsData = Room.getElevations({room, onlyFronts: {walls: false, island: false}, includeSections: true});

        _.forEach(computedElevationsData, elevationData => {
          var matchedElevation = _.find(oldElevations, oldElevation => {
            var l1 = oldElevation.lineInRoom, l2 = elevationData.lineInRoom;
            var a1 = lib.trig.alpha({p1: l1.from, p2: l1.to}), a2 = lib.trig.alpha({p1: l2.from, p2: l2.to});
            var theta = lib.trig.normalize({radians: Math.abs(a1 - a2)});

            return theta < Math.PI / 2
              && lib.trig.distance({fromPoint: l1.from, toPoint: l2.from}) < matchDistanceThreshold
              && lib.trig.distance({fromPoint: l1.to, toPoint: l2.to}) < matchDistanceThreshold;
          });

          if (matchedElevation) {
            matchedElevations.push(matchedElevation);
            allMatchedElevations.push(matchedElevation);

            newElevationsData.push({actionKey: 'update', elevationData, matchedElevation});
          }
          else {
            newElevationsData.push({actionKey: 'create', elevationData});
          }
        });

        elevationIdsToDestroy.push(..._.map(_.filter(oldElevations, oldElevation => !_.includes(matchedElevations, oldElevation)), 'id'));

        _.forEach(newElevationsData, ({elevationData, matchedElevation, actionKey}, rank) => {
          var {viewDepth = 50, lineInRoom} = elevationData;

          var generatedId = elevationData.id || _.join(_.concat(_.map(lineInRoom, point => {
            return _.join(_.map(_.values(point), value => _.replace(value, '.', '-')), '-');
          }), room.id), '-');

          lineInRoom = lib.trig.extend({line: lineInRoom, by: 7}); //HINT extending after creating id to avoid breaking old ids

          if (actionKey === 'update') {
            var updatedProps = {rank, generatedId, lineInRoom, viewDepth};
            var oldProps = _.pick(matchedElevation, ['rank', 'generatedId', 'lineInRoom', 'viewDepth']);

            if (!_.isEqual(updatedProps, oldProps)) {
              elevationUpdates.push({where: {id: matchedElevation.id}, props: updatedProps});
            }
          }
          else if (actionKey === 'create') {
            elevationPropsSetsToCreate.push({props: {
              roomId: room.id, projectId: project.id, versionId: project.versionId,
              lineInRoom, generatedId, rank, viewDepth
            }});
          }
        });
      }
    });

    if (_.some([elevationPropsSetsToCreate, elevationUpdates, elevationIdsToDestroy], modificationData => modificationData.length > 0)) {
      if (isBatched) {
        return {
          elevations: {
            creations: elevationPropsSetsToCreate,
            updates: elevationUpdates,
            deletedIds: elevationIdsToDestroy
          }
        };
      }
      else {
        reduxActions.modifyElevations({
          creations: elevationPropsSetsToCreate,
          updates: elevationUpdates,
          destructions: elevationIdsToDestroy
        });
      }
    }

    if (!isBatched) updateProductionIds({project, reduxActions});
  },

  getCompanyName({project}) {
    return project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt';
  },

  updateManagedParts({project, reduxActions}) {
    var updates = [];

    const {companyKey} = project;

    var {parts, rooms, containers} = Project.get(['parts', 'containers', 'rooms'], {project});

    var partInstances = _.sortBy(parts, ['id']);

    if (project.id <= 6777 && project.versionId < 24657) {
      var managePart = ({partId, partQuantity}) => {
        var oldPartInstance = _.find(partInstances, {partId, primaryAssociationKey: 'project'});

        if (oldPartInstance) {
          if (oldPartInstance.quantity !== partQuantity) {
            updates.push({where: {id: oldPartInstance.id}, props: {quantity: partQuantity}});

            oldPartInstance.quantity = partQuantity; //WARNING intentional mutation
          }
        }
      };

      //< cleat and cleat spacer parts
      var totalCleatWidth = 0;

      _.forEach(containers, container => {
        if (container.type === 'wall') totalCleatWidth += container.dimensions.width;
      });

      var cleatCount = _.ceil(totalCleatWidth / 95);

      managePart({partId: 275, partQuantity: cleatCount ? cleatCount + 1 : 0}); //cleat
      //#HINT Seth said unneeded currently
      //managePart({partId: 286, partQuantity: cleatCount ? cleatCount + 3 : 0}); //cleat spacer
      // />

      //< subcounter trim and spacer
      var halfInchSubcounterCount = 0;
      var threeQuarterInchSubcounterCount = 0;
      var quarterInchSubcounterCount = 0;


      _.forEach(containers, container => {
        if (Container.getHasSubcounter({container})) {
          var subcounterHeight = _.get(container, 'customData.customSubcounterHeight') || _.get(container, 'customData.subcounterHeight', companyKey === 'hb' ? 0.75 : 0.5);

          if (subcounterHeight === 0.75) {
            threeQuarterInchSubcounterCount += _.ceil(container.dimensions.width / 95);
          }
          else if (subcounterHeight === 0.25) {
            quarterInchSubcounterCount += _.ceil(container.dimensions.width / 95);
          }
          else {
            halfInchSubcounterCount += _.ceil(container.dimensions.width / 95);
          }
        }
      });

      managePart({partId: 273, partQuantity: quarterInchSubcounterCount ? quarterInchSubcounterCount + 1 : 0}); //1/4" subcounter trim
      managePart({partId: 274, partQuantity: quarterInchSubcounterCount ? quarterInchSubcounterCount + 3 : 0}); //1/4" subcounter spacer

      managePart({partId: 333, partQuantity: halfInchSubcounterCount ? halfInchSubcounterCount + 1 : 0}); //1/2" subcounter trim
      managePart({partId: 334, partQuantity: halfInchSubcounterCount ? halfInchSubcounterCount + 3 : 0}); //1/2" subcounter spacer

      managePart({partId: 271, partQuantity: threeQuarterInchSubcounterCount ? threeQuarterInchSubcounterCount + 1 : 0}); //3/4" subcounter trim
      managePart({partId: 272, partQuantity: threeQuarterInchSubcounterCount ? threeQuarterInchSubcounterCount + 3 : 0}); //3/4" subcounter spacer
      // />
    }
    else {
      var managePart = ({partId, scopeId, partQuantity}) => {
        var oldPartInstance = _.find(partInstances , {partId, scopeId, primaryAssociationKey: 'scope'});

        if (oldPartInstance) {
          if (oldPartInstance.quantity !== partQuantity) {
            updates.push({where: {id: oldPartInstance.id}, props: {quantity: partQuantity}});

            oldPartInstance.quantity = partQuantity; //WARNING intentional mutation
          }
        }
      };

      _.forEach(rooms, room => {
        const scope = Room.get('scope', {room});
        const scopeId = scope.id;
        //< cleat and cleat spacer parts
        var totalCleatWidth = 0;

        const roomContainers = _.filter(containers, {scopeId});

        _.forEach(roomContainers, container => {
          if (container.type === 'wall') totalCleatWidth += container.dimensions.width;
        });

        var cleatCount = _.ceil(totalCleatWidth / 95);

        managePart({partId: 275, partQuantity: cleatCount ? cleatCount + 1 : 0, scopeId}); //cleat
        //#HINT Seth said unneeded currently
        //managePart({partId: 286, partQuantity: cleatCount ? cleatCount + 3 : 0}); //cleat spacer
        // />

        //< subcounter trim and spacer
        var halfInchSubcounterCount = 0;
        var threeQuarterInchSubcounterCount = 0;
        var quarterInchSubcounterCount = 0;

        _.forEach(roomContainers, container => {
          if (Container.getHasSubcounter({container})) {
            var subcounterHeight = _.get(container, 'customData.customSubcounterHeight') || _.get(container, 'customData.subcounterHeight', companyKey === 'hb' ? 0.75 : 0.5);

            if (subcounterHeight === 0.75) {
              threeQuarterInchSubcounterCount += _.ceil(container.dimensions.width / 95);
            }
            else if (subcounterHeight === 0.25) {
              quarterInchSubcounterCount += _.ceil(container.dimensions.width / 95);
            }
            else {
              halfInchSubcounterCount += _.ceil(container.dimensions.width / 95);
            }
          }
        });

        managePart({partId: 273, partQuantity: quarterInchSubcounterCount ? quarterInchSubcounterCount + 1 : 0, scopeId}); //1/4" subcounter trim
        managePart({partId: 274, partQuantity: quarterInchSubcounterCount ? quarterInchSubcounterCount + 3 : 0, scopeId}); //1/4" subcounter spacer

        managePart({partId: 333, partQuantity: halfInchSubcounterCount ? halfInchSubcounterCount + 1 : 0, scopeId}); //1/2" subcounter trim
        managePart({partId: 334, partQuantity: halfInchSubcounterCount ? halfInchSubcounterCount + 3 : 0, scopeId}); //1/2" subcounter spacer

        managePart({partId: 271, partQuantity: threeQuarterInchSubcounterCount ? threeQuarterInchSubcounterCount + 1 : 0, scopeId}); //3/4" subcounter trim
        managePart({partId: 272, partQuantity: threeQuarterInchSubcounterCount ? threeQuarterInchSubcounterCount + 3 : 0, scopeId}); //3/4" subcounter spacer
        // />
      });
    }

    if (updates.length) {
      reduxActions.updateParts({updates});
      // lib.api.request({uri: 'resources', body: {resources: updates}});
    }
  }
};

export default Project;
