import React, { useEffect } from 'react';
import _ from 'lodash';
import K from 'k';

import loadProjectData from 'helpers/load-project-data';
import PriceElement from './header/price-element';
import RoomSelectorElement from './header/room-selector-element';
import ConfiguratorGraphic from './components/configurator-graphic';
import ConfiguratorPropertyField from './components/configurator-property-field';
import ConfiguratorSelectorPropertyField from './components/configurator-selector-property-field';
import ConfiguratorOptionSelector from './components/configurator-option-selector';
import FinalizePopup from './popups/finalize-popup';
import CanvasEntities3D from 'project-components/canvas-entities-3d';
import Konva from 'konva';

import applyArchetypeParameterSelection from 'helpers/apply-archetype-parameter-selection';
import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';
import upArrowIcon from 'assets/up-arrow-black.png';

import { connect } from 'redux/index.js';
import { resourceActions } from 'redux/index.js';
import withUseParams from 'hooks/with-use-params';
import Popup from 'components/popup';
import parameter from 'components/parameter';

class ConfiguratorPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      elevationGraphicContainerSize: {width: 0, height: 0},
      roomGraphicContainerSize: {width: 0, height: 0},
      isShowingBuyPopup: false,
      isReviewing: false,
      hasConfirmed: false,
      activePropertyData: null,
      activePropertyKey: null,
      activePropertyRoomId: null,
      summaryParameterDataByRoomId: {},
      appliedOption: null,
      extraCanvasViewMode: 'room',
      elevationCellSize: {
        width: (window.innerWidth - 500) / 3,
        height: (window.innerHeight - 250) / 2
      }
    };
  }

  styles = {
    button: {
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      borderRadius: 20,
      ...K.fonts.label,
      padding: K.spacing,
      opacity: 0.9,
      cursor: 'pointer'
    }
  };

  async componentDidMount() {
    var {match} = this.props; //TODO convert match to params
    Konva.pixelRatio = 2;

    if (window.location.hash) {
      global.apiToken = _.trimStart(window.location.hash, '#');
    }

    await loadProjectData({match, resourceActionDispatchers: this.props});

    setTimeout(async () => {
      var activeRoomId = _.sortBy(_.get(this.roomsByFloor[0], 'rooms'), 'rank')[0].id;
      this.setState({activeRoomId});

      setTimeout(() => {
        var {project} = this.props;
        var projectTitle = `${project.title || `${project.companyKey === 'vp' ? 'ST' : 'HB'} for ${project.clientName}`}`;

        document.title = `${projectTitle} - ${project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt'} Configurator`;

        if (project.companyKey === 'vp') {
          var link = document.querySelector('link[rel~=\'icon\']');
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
          }
          link.href = 'https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/st-favicon.ico';
        }

        this.setState({isLoaded: true});

        this.updateSummaryParameterData();

        setTimeout(() => {
          // if (this.elevationGraphicRef) {
          //   var containerBoundingRect = this.elevationGraphicRef.getBoundingClientRect();

          //   var elevationGraphicContainerSize = {
          //     width: containerBoundingRect.right - containerBoundingRect.left,
          //     height: containerBoundingRect.bottom - containerBoundingRect.top
          //   };

          //   this.setState({elevationGraphicContainerSize});
          // }

          // if (this.roomGraphicRef) {
          //   var containerBoundingRect = this.roomGraphicRef.getBoundingClientRect();

          //   var roomGraphicContainerSize = {
          //     width: containerBoundingRect.right - containerBoundingRect.left,
          //     height: containerBoundingRect.bottom - containerBoundingRect.top
          //   };

          //   this.setState({roomGraphicContainerSize});
          // }
        });
      });
    });

    window.addEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = _.debounce(() => {
    this.setState({elevationCellSize: {
      width: (window.outerWidth - 500) / 3,
      height: (window.outerHeight - 250) / 2
    }});
  }, 100);

  componentDidUpdate(prevProps, prevState) {
    // if (this.elevationGraphicRef) {
    //   var containerBoundingRect = this.elevationGraphicRef.getBoundingClientRect();

    //   var elevationGraphicContainerSize = {
    //     width: containerBoundingRect.right - containerBoundingRect.left,
    //     height: containerBoundingRect.bottom - containerBoundingRect.top
    //   };

    //   if (!_.isEqual(prevState.elevationGraphicContainerSize, elevationGraphicContainerSize)) {
    //     this.setState({elevationGraphicContainerSize});
    //   }
    // }
    // if (this.roomGraphicRef) {
    //   var containerBoundingRect = this.roomGraphicRef.getBoundingClientRect();

    //   var roomGraphicContainerSize = {
    //     width: containerBoundingRect.right - containerBoundingRect.left,
    //     height: containerBoundingRect.bottom - containerBoundingRect.top
    //   };

    //   if (!_.isEqual(prevState.roomGraphicContainerSize, roomGraphicContainerSize)) {
    //     this.setState({roomGraphicContainerSize});
    //   }
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handlePropertyFieldChange = async ({roomId, id, value, path, activePropertyData, appliedOption}) => {
    var room = _.find(this.props.rooms, {id: roomId || this.state.activeRoomId});

    await applyArchetypeParameterSelection({
      room,
      archetypeId: _.get(room, 'archetypeId'),
      parameter: _.find(this.roomArchetypeParameters(roomId), {id}),
      value, path,
      reduxActions: this.props
    });

    this.setState({activePropertyKey: null, activePropertyData: null, appliedOption: null, activePropertyRoomId: null});

    setTimeout(() => {
      this.updateSummaryParameterData(roomId);
    })
  };

  handleBuyPopupOnClose = () => this.setState({isShowingBuyPopup: false});
  handleBuyButtonClick = () => this.setState({isShowingBuyPopup: true});

  handleSelectorPropertyFieldOnClick = ({roomId, activePropertyKey, activePropertyData, appliedOption}) => {
    if (this.state.activePropertyKey === activePropertyKey) {
      this.setState({activePropertyKey: null, activePropertyData: null, appliedOption: null, activePropertyRoomId: null});
    }
    else {
      this.setState({activePropertyKey, activePropertyData, appliedOption, activePropertyRoomId: roomId});
    }
  };

  handleOptionSelectorOnBackClick = () => {
    this.setState({activePropertyKey: null, activePropertyData: null, appliedOption: null, activePropertyRoomId: null});
  };

  get roomsByFloor() {
    var {floors, rooms} = this.props;

    return _.map(floors, (floor) => {
      return {
        ...floor,
        rooms: _.filter(rooms, {floorId: floor.id})
      };
    });
  }

  roomArchetypeParameters(roomId) {
    if (!roomId) roomId = this.state.activeRoomId;

    var room = _.find(this.props.rooms, {id: roomId});
    var roomParameters = _.flatMap(_.get(room, `parameters.${room.archetypeId}`, []));

    return roomParameters;
  }

  updateSummaryParameterData = (roomId) => {
    if (roomId) {
      this.setState({summaryParameterDataByRoomId: {...this.state.summaryParameterDataByRoomId, [roomId]: this.getSummaryParameterDataForRoomId(roomId)}});
    }
    else {
      this.setState({summaryParameterDataByRoomId: this.summaryParameterDataByRoomId});
    }
  }

  get summaryParameterDataByRoomId() {
    var summaryParameterDataByRoomId = {
      //5: {remainingParameterFields: [parameterField1], totalParameterFieldCount: 5}
    };

    _.forEach(this.props.rooms, (room) => {
      summaryParameterDataByRoomId[room.id] = this.getSummaryParameterDataForRoomId(room.id);
    });

    return summaryParameterDataByRoomId;
  }

  getSummaryParameterDataForRoomId = (roomId) => {
    var room = _.find(this.props.rooms, {id: roomId});
    var roomParameters = this.roomArchetypeParameters(room.id);
    var {containers, products} = Room.get(['containers', 'products'], {room: room});
    var {pulls, materialClasses} = this.props;

    var summaryParameterData = {totalParameterFieldCount: 0, remainingParameterFields: []};

    _.forEach(roomParameters, roomParameter => {
      var {type, instances} = roomParameter;
      //HINT parameter required unless it is being applied to products/containers that are all deleted
      //IE island material selections when island is toggled off
      //OR pull material selection when pull type doesn't require a material selection
      var parameterIsApplicable = true;

      if (type === 'pull' || type === 'material') {
        var containerInstancesPresent = _.some(instances, instance => instance.resourceKey === 'container' && _.find(containers, {persistentId: instance.resourcePersistentId}));
        var productInstancesPresent = _.some(instances, instance => instance.resourceKey === 'product' && _.find(products, {persistentId: instance.resourcePersistentId}));

        if (!containerInstancesPresent && !productInstancesPresent) {
          parameterIsApplicable = false;
        }
      }

      if (parameterIsApplicable) {
        var isPullParameterWithPullMaterialRequired = false;

        if (parameterIsApplicable && type === 'pull') {
          var selectedPullTypeId = _.get(room, `selectedParameters.${room.archetypeId}.${roomParameter.id}.pullType`);// || _.get(pullTypeOptions, '[0].value');

          if (selectedPullTypeId) {
            var selectedPull = _.find(pulls, {id: selectedPullTypeId});
            var compatibleMaterials = _.get(materialClasses, `${selectedPull.materialClassId}.materials`);

            if (parameter.compatibleMaterialIds) { //HINT filter compatible materials to those set manually on the parameter
              compatibleMaterials = _.filter(compatibleMaterials, ({id}) => {
                return _.includes(parameter.compatibleMaterialIds, id);
              });
            }

            if (compatibleMaterials.length > 0) isPullParameterWithPullMaterialRequired = true;
          }
        }

        var parameterFieldCount = isPullParameterWithPullMaterialRequired ? 2 : 1;

        _.times(parameterFieldCount, n => {
          var currentValue = _.get(room, `selectedParameters.${room.archetypeId}.${roomParameter.id}${type === 'pull' ? (n === 0 ? '.pullType' : '.pullMaterial'): ''}`);

          if (!currentValue) {
            summaryParameterData.remainingParameterFields.push({...roomParameter, ...(type === 'pull' ? {path: n === 0 ? 'pullType' : 'pullMaterial'} : {})});
          }

          summaryParameterData.totalParameterFieldCount++;
        });
      }
    });

    return summaryParameterData;
  }

  get hasRemainingDecisions() {
    var summaryParameterData = this.state.summaryParameterDataByRoomId;

    return _.some(_.values(summaryParameterData), ({remainingParameterFields, totalParameterFieldCount}) => remainingParameterFields.length > 0);
  }

  get roomsWithRemainingDecisionsCount() {
    var summaryParameterData = this.state.summaryParameterDataByRoomId;

    return _.filter(_.values(summaryParameterData), ({remainingParameterFields, totalParameterFieldCount}) => remainingParameterFields.length > 0).length;
  }

  archetypeParameterGroups(roomId) {
    var archetypeParameterGroups = [];

    if (!roomId) roomId = this.state.activeRoomId;

    _.forEach(this.roomArchetypeParameters(roomId), (parameter) => {
      var type = parameter.type === 'pull' ? 'pullType' : parameter.type;
      var parameterGroup = _.find(archetypeParameterGroups, {key: type});

      var TypeToTitleMap = {
        material: 'Finishes',
        pullType: 'Pull Types',
        pullMaterial: 'Pull Finishes',
        conditionalResources: 'Appliances & Fixtures'
      };

      if (!parameterGroup) {
        archetypeParameterGroups.push({
          key: type,
          title: TypeToTitleMap[type],
          parameters: [{...parameter, type}]
        });

        if (parameter.type === 'pull') {
          archetypeParameterGroups.push({
            key: 'pullMaterial',
            title: 'Pull Finishes',
            parameters: [{...parameter, type: 'pullMaterial'}]
          });
        }
      }
      else {
        parameterGroup.parameters.push({...parameter, type});

        if (parameter.type === 'pull') {
          _.find(archetypeParameterGroups, {key: 'pullMaterial'}).parameters.push({...parameter, type: 'pullMaterial'});
        }
      }
    });

    //HINT manually sort parameter groups by type
    archetypeParameterGroups = _.sortBy(archetypeParameterGroups, ({key}) => _.indexOf([
      'material', 'pullType', 'pullMaterial', 'conditionalResources'
    ], key));

    return archetypeParameterGroups;
  }

  // getElevationGraphicRef = (ref) => this.elevationGraphicRef = ref;
  // getRoomGraphicRef = (ref) => this.roomGraphicRef = ref;

  render() {
    var {project, rooms, floors, pulls, elevations} = this.props;
    var {isLoaded, isConfirming, hasConfirmed, isReviewing, elevationGraphicContainerSize, roomGraphicContainerSize, extraCanvasViewMode, activePropertyData, activePropertyKey, appliedOption, activePropertyRoomId} = this.state;

    var threeDViewOffset = {x: 0, y: 0};

    if (isLoaded) {
      var room = _.find(rooms, {id: this.state.activeRoomId});
      var floor = _.find(floors, {id: room.floorId});
      var floorRooms = _.filter(rooms, room => room.floorId === floor.id);

      const points = room.plan.points;
      const xs = _.map(points, 'x'), ys = _.map(points, 'y');
      const minX = _.min(xs), minY = _.min(ys);
      const maxX = _.max(xs), maxY = _.max(ys);
      const size = {width: maxX - minX, height: maxY - minY};

      threeDViewOffset = lib.object.difference({x: -minX - size.width * 0.5, y: -minY - size.height * 0.5});

      var archetype = _.find(this.props.archetypes, {id: room.archetypeId});
    }
    var style = {
      header: {
        column: {flex: 1, display: 'flex', flexDirection: 'column'}
      },
      fonts: {
        label: {fontWeight: 500, fontSize: '1em', letterSpacing: '0.1em'}
      },
    };

    var visibilityLayers = {
      wallsAndArchElements: true, elevationIndicators: [{entity: _.find(this.props.elevations, {id: this.state.activeElevationId}), showViewDepth: true}]
    };

    global.visibilityLayers = visibilityLayers;

    return (
      <div style={{height: '100%', width: '100%'}} className='cfg-configurator-page'>
        {!isLoaded ? (
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{...style.fonts.label, textTransform: 'uppercase', marginBottom: '0.5rem'}}>
              Loading
            </div>
            <div style={{width: '2rem', height: '2rem'}} className="show-loader loader-dark"></div>
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
            <div className='content' style={{position: 'relative', display: 'flex', flex: 1, height: '100%', maxWidth: '100%', minWidth: 0, minHeight: 0, flexDirection: 'row'}}>
              {this.state.activePropertyKey && _.size(this.state.activePropertyData) && (
                <ConfiguratorOptionSelector {...{activePropertyKey, activePropertyRoomId, activePropertyData, appliedOption, archetype, project, isConfirming, hasConfirmed, handleBuyButtonClick: this.handleBuyButtonClick, pulls}} onBackClick={this.handleOptionSelectorOnBackClick} onApplyClick={this.handlePropertyFieldChange}/>
              )}
              <div style={{flexGrow: !isReviewing ? 1 : 0, display: 'flex', flexDirection: 'column'}}>
                <div className='header' style={{flexDirection: 'column', display: 'flex', alignItems: 'flex-start', padding: '2rem', paddingTop: '1.5rem'}}>
                  <div style={{fontSize: '1.1em', letterSpacing: '0.04em', marginBottom: '0.4rem'}}>
                    {project.title || `For ${project.clientName}`}
                  </div>
                  <div style={{letterSpacing: '0.15em', fontSize: '0.85em', marginBottom: '1.5rem', fontWeight: 400, textTransform: 'uppercase', opacity: 0.5}}>
                    {project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt'}{archetype && archetype.type === 'atlanticFields' ? ' & Atlantic Fields' : ''}
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div
                      style={{
                        fontWeight: 500, textTransform: 'uppercase', letterSpacing: '0.06em', fontSize: '0.8em',
                        cursor: 'pointer', position: 'relative', left: '-2rem', padding: '0.5rem 1rem', paddingLeft: '2rem',
                        ...(isReviewing ? {backgroundColor: '#f5f5f5', color: 'black'} : {backgroundColor: 'black', color: 'white',})
                      }}
                      onClick={() => this.setState({isReviewing: !isReviewing})}
                    >{isReviewing ? 'BACK' : `REVIEW ALL ROOMS →`}</div>
                    <div
                      style={{
                        fontWeight: 500, textTransform: 'uppercase', letterSpacing: '0.06em', fontSize: '0.8em',
                        padding: '0.5rem 1rem', position: 'relative', left: '-2rem', marginLeft: K.spacing,
                        ...((this.roomsWithRemainingDecisionsCount > 0) ? {opacity: 0.5} : {}),
                        ...(!project.locked && this.roomsWithRemainingDecisionsCount === 0 ? {cursor: 'pointer', backgroundColor: 'black', color: 'white'} : {}),
                        // ...((!project.locked) ? {} : {})
                      }}
                      onClick={() => (!project.locked && this.roomsWithRemainingDecisionsCount === 0) ? this.setState({isShowingBuyPopup: true}) : undefined}
                    >{project.locked ? `FINALIZED - PLEASE CONTACT ATLANTIC FIELDS FOR SUPPORT` : (this.roomsWithRemainingDecisionsCount > 0 ? `${this.roomsWithRemainingDecisionsCount} ROOM${this.roomsWithRemainingDecisionsCount > 1 ? 'S' : ''} REMAINING TO FINALIZE` : `FINALIZE`)}</div>
                  </div>
                  </div>
                {!isReviewing && (<div style={{display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  {_.map(_.chunk(_.sortBy(_.filter(this.props.elevations, elevation => {return elevation.roomId === this.state.activeRoomId && (Elevation.getIsSection({elevation}) || Elevation.getSameAngleContainers({elevation}).length > 0)}), 'rank'), 3), elevations => (
                    <div style={{alignSelf: 'stretch', display: 'flex', justifyContent: 'center'}}>
                      {_.map(elevations, elevation => {
                        return (
                          <div style={{width: this.state.elevationCellSize.width, height: this.state.elevationCellSize.height}}>
                            <ConfiguratorGraphic
                              type={'elevation'}
                              {...{containerSize: {width: this.state.elevationCellSize.width, height: this.state.elevationCellSize.height}, model: elevation, project, visibilityLayers: {wallsAndArchElements: true}, activeDimensionsLayer: 'client', detailLevel: 'rendering', fillMode: 'materialColors'}}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ))}
                  {/* <div style={{flex: 1, display: 'flex', alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{width: 500, height: 500}}>
                      <ConfiguratorGraphic
                        type={'elevation'}
                        {...{containerSize: {width: 500, height: 500}, model: _.sortBy(_.filter(this.props.elevations, {roomId: this.state.activeRoomId}), 'rank')[0], project, visibilityLayers: {wallsAndArchElements: true}, activeDimensionsLayer: 'client', detailLevel: 'rendering', fillMode: 'materialColors'}}
                      />
                    </div>
                  </div> */}
                </div>)}
                {isReviewing && (
                  <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: '2rem', marginRight: '2rem', marginBottom: '2rem'}}>
                  {_.map(rooms, room => (<>
                    <div style={{paddingTop: '1rem', paddingBottom: '1rem', paddingRight: '1rem', borderTop: 'none', width: 220, marginRight: '1rem'}}>
                      <div style={{...K.fonts.label, width: '100%', fontSize: '0.7rem', letterSpacing: '0.09em', fontWeight: 500, opacity: 1, marginBottom: K.spacing}}>{room.title}</div>
                      {_.map(this.archetypeParameterGroups(room.id), ({key, title, parameters}) => {
                        return (
                          <div key={key} style={{display: 'flex', flexDirection: 'column', marginBottom: '1rem'}}>
                            <div style={{...style.fonts.label, letterSpacing: '0.09em', textTransform: 'uppercase', marginBottom: '0.5rem', fontSize: '0.7rem'}}>{title}</div>
                            {_.map(parameters, (parameter, index) =>{
                              return (
                                <ConfiguratorSelectorPropertyField
                                  isEditable={((!project.locked || project.isEmployee) && !this.state.isConfirming && !this.state.hasConfirmed)}
                                  key={`configurator-selector-property-field-${index}-${parameter.id}`}
                                  handlePropertyFieldChange={({id, value, path}) => this.handlePropertyFieldChange({roomId: room.id, id, value, path})}
                                  handleClick={this.handleSelectorPropertyFieldOnClick}
                                  {...{parameter, room, index, isReviewing, activePropertyKey: this.state.activePropertyKey, project, archetypeParameterGroup: {key, title}}}
                                />
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </>))}
                </div>
                )}
              </div>
              {!isReviewing && <div style={{display: 'flex', height: '100%', flexDirection: 'column', ...(!isReviewing ? {alignItems: 'flex-end'} : {}), padding: '2rem', paddingTop: '1rem', overflowY: 'auto'}}>
                <div style={{display: 'flex', ...(!isReviewing ? {alignItems: 'flex-end', flexDirection: 'column'} : {flexDirection: 'row', flexWrap: 'wrap'}), marginBottom: '2rem'}}>
                  {_.map(rooms, room => (<>
                    {!isReviewing && <div
                      onClick={() => this.setState({activeRoomId: room.id})}
                      className='hoverable'
                      style={{width: 220, height: 150, marginTop: '1rem', cursor: 'pointer', position: 'relative', ...((this.state.activeRoomId === room.id || (isReviewing && (this.state.summaryParameterDataByRoomId[room.id].remainingParameterFields.length > 0))) ? {border: '2px solid #626FD0'} : {border: '2px solid #ccc'})}}>
                      <ConfiguratorGraphic
                        type={'room'}
                        isStatic
                        {...{containerSize: {width: 220, height: 150}, model: room, project, visibilityLayers, activeDimensionsLayer: 'client', detailLevel: 'rendering', fillMode: 'materialColors'}}
                      />
                      <div style={{...K.fonts.label, position: 'absolute', top: 14, width: '100%', textAlign: 'center', fontSize: '0.7rem', letterSpacing: '0.09em', fontWeight: 500, ...((this.state.activeRoomId === room.id || isReviewing) ? {opacity: 1} : {})}}>{room.title}</div>
                    </div>}
                    {(isReviewing || this.state.activeRoomId === room.id) && (
                      <div style={{paddingTop: '1rem', marginBottom: '1rem', borderRight: '2px solid #B8BDE1', paddingRight: '1rem', borderTop: 'none', width: 220, ...((isReviewing) ? {marginRight: '1rem'} : {})}}>
                        {isReviewing && <div style={{...K.fonts.label, width: '100%', fontSize: '0.7rem', letterSpacing: '0.09em', fontWeight: 500, opacity: 1, marginBottom: K.spacing}}>{room.title}</div>}
                        {_.map(this.archetypeParameterGroups(room.id), ({key, title, parameters}) => {
                          return (
                            <div key={key} style={{display: 'flex', flexDirection: 'column', ...(isReviewing ? {} : {alignItems: 'flex-end'}), marginBottom: '1rem'}}>
                              <div style={{...style.fonts.label, letterSpacing: '0.09em', textTransform: 'uppercase', marginBottom: '0.5rem', fontSize: '0.7rem'}}>{title}</div>
                              {_.map(parameters, (parameter, index) =>{
                                return (
                                  <ConfiguratorSelectorPropertyField
                                    isEditable={((!project.locked || project.isEmployee) && !this.state.isConfirming && !this.state.hasConfirmed)}
                                    key={`configurator-selector-property-field-${index}-${parameter.id}`}
                                    handlePropertyFieldChange={({id, value, path}) => this.handlePropertyFieldChange({roomId: room.id, id, value, path})}
                                    handleClick={this.handleSelectorPropertyFieldOnClick}
                                    {...{parameter, room, index, isReviewing, activePropertyKey: this.state.activePropertyKey, project, archetypeParameterGroup: {key, title}}}
                                  />
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>))}
                </div>
              </div>}
            </div>
            {!project.locked && this.state.isShowingBuyPopup && (
              <FinalizePopup
                {...{project, onClose: () => this.setState({isShowingBuyPopup: false})}}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect({
  mapState: state => {
    return {
      projects: state.resources.projects.byId,
      floors: state.resources.floors.byId,
      rooms: state.resources.rooms.byId,
      elevations: state.resources.elevations.byId,
      scopes: state.resources.scopes.byId,
      containerTypes: state.resources.containerTypes.byId,
      products: state.resources.products.byId,
      productTypes: state.resources.productTypes.byId,
      project: _.values(state.resources.projects.byId)[0],
      materialTypes: state.resources.materialTypes.byId,
      materials: state.resources.materials.byId,
      materialClasses: state.resources.materialClasses.byId,
      pulls: state.resources.pulls.byId,
      archetypes: state.resources.archetypes.byId,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['trackProjects', 'updateProject']),
    ..._.pick(resourceActions.floors, ['trackFloors']),
    ..._.pick(resourceActions.rooms, ['trackRooms', 'updateRoom', 'modifyRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'updateScope', 'updateScopes']),
    ..._.pick(resourceActions.walls, ['trackWalls', 'updateWall', 'modifyWalls']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'createContainer', 'createContainers', 'updateContainer', 'updateContainers', 'destroyContainer', 'destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.elevations, ['trackElevations', 'updateElevations', 'updateElevation', 'createElevations', 'destroyElevations', 'modifyElevations']),
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'updateArchElement', 'destroyArchElement', 'destroyArchElements']),
    ..._.pick(resourceActions.products, ['trackProducts', 'createProduct', 'createProducts', 'updateProduct', 'updateProducts', 'destroyProduct', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productTypes, ['trackProductTypes']),
    ..._.pick(resourceActions.models, ['trackModels']),
    ..._.pick(resourceActions.appliances, ['trackAppliances']),
    ..._.pick(resourceActions.pulls, ['trackPulls']),
    ..._.pick(resourceActions.materialClasses, ['trackMaterialClasses']),
    ..._.pick(resourceActions.materialTypes, ['trackMaterialTypes']),
    ..._.pick(resourceActions.materials, ['trackMaterials']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.pricingRules, ['trackPricingRules']),
    ..._.pick(resourceActions.productOptionTypes, ['trackProductOptionTypes']),
    ..._.pick(resourceActions.projectGraphics, ['trackProjectGraphics', 'updateProjectGraphic', 'updateProjectGraphics', 'destroyProjectGraphic', 'destroyProjectGraphics']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories']),
    ..._.pick(resourceActions.archetypes, ['trackArchetypes']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'updateVolume', 'updateVolumes', 'destroyVolume', 'destroyVolumes', 'modifyVolumes']),
    ..._.pick(resourceActions.parts, ['trackParts', 'updateParts'])
  }
})(withUseParams(ConfiguratorPage));
