import React, { Component, useContext } from 'react';

import {
  CanvasTexture,
  CanvasScriptObject,
  CanvasDataContext,
  CanvasSelectionContext,
  EditableCanvasLine,
  EditableCanvasPolyline,
  CanvasPath,
  CanvasErrorFallback,
  CanvasLine
} from 'canvas';

import PositionHelper from 'helpers/position-helper';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import Color from 'color';
import { connect, resourceActions, issuesDataActions } from 'redux/index.js';
import ProjectGraphic from 'project-helpers/project-graphic';
import ProjectDataContext from 'contexts/project-data-context';

import { withErrorBoundary } from 'react-error-boundary';

// projectGraphic.data.position is passed to <EditableCanvasPolyline /> and it will be processed like converted to canvas and all and we need the exact coordinates received from onDragMove.

class CanvasProjectGraphic extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isTransforming: false,
      cachedProjectGraphic: undefined
    };
  }

  handleChange = async ({hitApi = true, props}) => {//eslint-ignore-line
    const {cachedProjectGraphic} = this.state;

    if (!hitApi && !this.state.isTransforming) {
      this.setState({cachedProjectGraphic: this.props.projectGraphic, isTransforming: true});
    }
    else if (hitApi) {
      this.props.projectData.pushToUndoQueue({type: 'projectGraphic', eventKey: 'transformEnd', instance: cachedProjectGraphic || this.props.projectGraphic});

      this.setState({cachedProjectGraphic: undefined, isTransforming: false});
    }

    await this.props.updateProjectGraphic({id: this.props.projectGraphic.id, hitApi, props});
  };

  handleClose = ({position, points}) => {//eslint-ignore-error
    const {projectGraphic} = this.props;
    const newProps = _.cloneDeep(projectGraphic);

    newProps.data.position = position;
    newProps.data.points = _.map(points, point => ({x: point.x, y: point.y}));
    newProps.data.closed = true;

    this.handleChange({props: newProps});
  };

  handleSelect = () => {
    var { projectGraphic, selectionData, isSelected } = this.props;
    var { setActiveEntities } = selectionData;

    if (!isSelected) {
      setActiveEntities({entities: [{resourceKey: 'projectGraphic', id: projectGraphic.id}], isMultiSelect: false});
    }
  };

  handleDragMove = (position) => {
    let {projectGraphic} = this.props;

    const newProps = _.cloneDeep(projectGraphic);

    this.props.updateProjectGraphic({id: projectGraphic.id, props: newProps, hitApi: false});
  };

  handleDragEnd = (position) => {
    let {projectGraphic} = this.props;

    const newProps = _.cloneDeep(projectGraphic);

    newProps.data.position = position;

    this.props.updateProjectGraphic({id: projectGraphic.id, props: newProps, hitApi: true});
  };

  handlePointsChange = (points, hitApi = true) => {//eslint-ignore-line
    const props = _.cloneDeep(this.props.projectGraphic);

    props.data.points = points;

    this.handleChange({props, hitApi});
  };

  handleAddOrDeletePoints = ({points}) => {
    this.handlePointsChange(points, true);
  };

  handlePointDragMove = (points) => {
    this.handlePointsChange(points, false);
  };

  handlePointDragEnd = (points) => {//eslint-ignore-error)
    this.handlePointsChange(points);
  };

  handleTransform = (props) => {
    if (!this.state.isTransforming) {
      this.setState({cachedProjectGraphic: this.props.projectGraphic, isTransforming: true});
    }

    let { projectGraphic, viewKey, room, viewOffset } = this.props;
    let newProps = {};

    //HINT projectGraphics using EditableCanvasLine only needs to handle transformEnd
    if (_.includes(['text', 'textPointer', 'rectangle', 'circle'], projectGraphic.type)) {
      const viewKeyDependentOffset = viewKey !== 'front' ? room.plan.position : {x: this.props.firstWallXInset};

      newProps.size = props.size;
      if (projectGraphic.type === 'circle') {
        var circleOffset = {x: -projectGraphic.data.size.radius, y: -projectGraphic.data.size.radius};

        //HINT: subtract circleOffset 2 times because canvas-transformer applies it 2 times.
        //First when transformer is rendered after applying transformerOffset to it.
        //Second in the handleDragMove method
        newProps.position = lib.object.difference(props.position, circleOffset, circleOffset, viewKeyDependentOffset, viewOffset);

      }
      else {
        newProps.position = lib.object.difference(props.position, viewKeyDependentOffset, viewOffset);
      }

      this.props.updateProjectGraphic({id: projectGraphic.id, props: {data: {...projectGraphic.data, ...newProps}}, hitApi: false});
    }
  };

  handleTransformEnd = (props) => {
    let { projectGraphic, projectData, viewKey, room, viewOffset } = this.props;
    let {cachedProjectGraphic, selectedHandleKey} = this.state;

    if (!selectedHandleKey && props) {
      const viewKeyDependentOffset = viewKey !== 'front' ? room.plan.position : {x: this.props.firstWallXInset};

      cachedProjectGraphic = _.cloneDeep(projectGraphic);

      var updatedPosition = lib.object.difference(props.position, viewKeyDependentOffset, viewOffset);

      projectGraphic.data = {
        ...projectGraphic.data,
        position: updatedPosition,
        size: props.size,
        ...(_.includes(['text', 'arrow', 'line'], projectGraphic.type) ? {from: lib.object.difference(props.from, viewOffset, viewKeyDependentOffset)} : {}),
        ...(_.includes(['text', 'arrow', 'line'], projectGraphic.type) ? {to: lib.object.difference(props.to, viewOffset, viewKeyDependentOffset)} : {})
      };
    }

    ProjectGraphic.update({cachedProjectGraphic, projectGraphic, props: projectGraphic, reduxActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});

    this.setState({isTransforming: false, cachedArchElement: undefined});
  };

  handleLinearGradientTransformEnd = (props) => {
    let { projectGraphic, projectData, viewKey, room, viewOffset } = this.props;
    let {cachedProjectGraphic} = this.state;

    if (props) {
      const viewKeyDependentOffset = viewKey !== 'front' ? room.plan.position : {x: this.props.firstWallXInset};

      cachedProjectGraphic = _.cloneDeep(projectGraphic);

      projectGraphic.data = {
        ...projectGraphic.data,
        ...(projectGraphic.data.fillType === 'linearGradient' ? {
          gradientPositions: {
            from: lib.object.difference(props.from, viewOffset, projectGraphic.data.position, viewKeyDependentOffset),
            to: lib.object.difference(props.to, viewOffset, projectGraphic.data.position, viewKeyDependentOffset)
        }} : {}),
      };
    }

    ProjectGraphic.update({cachedProjectGraphic, projectGraphic, props: projectGraphic, reduxActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});

    this.setState({isTransforming: false, cachedArchElement: undefined});
  }

  handleTextPointerArrowTransformEnd = (props) => {
    let { projectGraphic, projectData, viewKey, room, viewOffset } = this.props;
    let {cachedProjectGraphic} = this.state;

    if (props) {
      const viewKeyDependentOffset = viewKey !== 'front' ? room.plan.position : {x: this.props.firstWallXInset};

      cachedProjectGraphic = _.cloneDeep(projectGraphic);

      projectGraphic.data = {
        ...projectGraphic.data,
        ...(_.includes(['textPointer'], projectGraphic.type) ? {to: lib.object.difference(props.to, viewOffset, viewKeyDependentOffset)} : {})
      };
    }

    ProjectGraphic.update({cachedProjectGraphic, projectGraphic, props: projectGraphic, reduxActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});

    this.setState({isTransforming: false, cachedArchElement: undefined});
  }

  handleTextureTransform = (props) => {
    const {projectGraphic, canvasData, viewOffset, viewKey, room, firstWallXInset} = this.props;
    const {data} = projectGraphic;
    const newProps = _.cloneDeep(projectGraphic);

    const viewKeyDependentOffset = viewKey !== 'front' ? room.plan.position : {x: firstWallXInset};

    // const normalizedPosition = lib.object.multiply(lib.object.difference(lib.object.difference(props.position, viewOffset), data.points[0]), 1 / canvasData.scale);
    const normalizedPosition = lib.object.difference(props.position, viewOffset, viewKeyDependentOffset);

    newProps.data.fillTexture.delta = lib.object.difference(normalizedPosition, projectGraphic.data.position);
    newProps.data.fillTexture.rotation = props.rotation;
    newProps.data.fillTexture.size = props.size;

    this.props.updateProjectGraphic({id: projectGraphic.id, props: newProps, hitApi: false});
  };

  handleTextureTransformEnd = () => {
    const {projectGraphic} = this.props;
    this.props.updateProjectGraphic({id: projectGraphic.id, props: projectGraphic, hitApi: true});
  };

  handleDeleteProjectGraphic = () => {
    const {selectionData, projectGraphic, projectData, isSelected} = this.props;

    if (isSelected) {
      selectionData.onDeselect();

      ProjectGraphic.destroy({projectGraphic, reduxActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});
    }
  };

  render() {
    const {
      viewOffset,
      room,
      projectGraphic,
      viewKey,
      selectionData,
      canvasData,
      isSelected,
      renderForDrawings,
      preventEditing,
      isExportingToDXF,
      isExportingSvg
    } = this.props;

    var {firstWallXInset} = this.props;

    if (!projectGraphic) return null;

    const { data, type } = projectGraphic;

    if (_.includes(['textPointer', 'text', 'arrow'], type) && _.get(global.visibilityLayers, 'isExportingDxf')) return null;

    if (isExportingSvg && renderForDrawings && _.get(data, 'fillType') === 'linearGradient') return null;

    if ((renderForDrawings || preventEditing) && type === 'polygon' && !data.closed) return null;

    const viewKeyDependentOffset = viewKey !== 'front' ? room.plan.position : {x: firstWallXInset};

    var textureMaskingPolygons = [];
    var circleProps, circleOffset;

    if (type === 'rectangle') {
      textureMaskingPolygons = [
        _.map([{x: 0, y: 0}, {x: 0, y: data.size.height}, {x: data.size.width, y: data.size.height}, {x: data.size.width, y: 0}], (point) => PositionHelper.toCanvas(lib.object.sum(point, data.position, viewOffset, viewKeyDependentOffset), canvasData))
      ];
    }
    else if (type === 'polygon') {
      textureMaskingPolygons = [
        _.map(data.points, (point) => lib.object.sum(PositionHelper.toCanvas(lib.object.sum(point, data.position, viewOffset, viewKeyDependentOffset), canvasData)))
      ];
    }
    else if (type === 'circle') {
      circleOffset = {x: -data.size.radius, y: -data.size.radius};

      circleProps = {
        position: lib.object.sum(data.position, data.fillTexture.delta, viewOffset, viewKeyDependentOffset),
        positionInCanvas: PositionHelper.toCanvas(lib.object.sum(data.position, data.fillTexture.delta, viewOffset, viewKeyDependentOffset), canvasData),
        radius: data.size.radius
      }
    }


    //HINT match product/container dashed lines
    const strokeDashLength = (data.strokeDashLength || 5) * ((renderForDrawings ? 0.25 : 0.5) * 2);

    const rectStrokeDashLength = (data.strokeDashLength || 5) * (data.strokeWidth * (data.scaleStrokeWidth ? canvasData.scale : 1)) * 2;

    var size = data.size || {width: 50, height: 10};
    var textPointerFromPositions = {};

    if (type === 'textPointer') {
      textPointerFromPositions = {
        xAxis: data.to.x > data.position.x + (data.size.width / 2) ? 'right' : 'left',
        yAxis: data.to.y > data.position.y + (data.size.height / 2) ? 'bottom' : 'top'
      };
    };

    return (
      <>
        {_.includes(['line', 'arrow'], type) && (
          <EditableCanvasLine
            preventOrthoIndication
            showToArrow={type === 'arrow'}
            isSelected={isSelected}
            isDisabled={canvasData.isStatic}
            onClick={this.handleSelect}
            onTransformEnd={this.handleTransformEnd}
            onDelete={this.handleDeleteProjectGraphic}
            //HINT historically we've scaled line/arrow stroke width when entered, but nothing else. This is to be consistent with wall lines
            strokeWidth={(data.strokeWidth && _.get(data, 'scaleStrokeWidth', true)) ? (data.strokeWidth * canvasData.scale) : (data.strokeWidth || undefined)}
            stroke={data.stroke ? data.stroke : (projectGraphic.layer === 'installationNotes' ? 'red' : 'black')}
            strokeDashArray={data.isDashed ? [strokeDashLength, strokeDashLength] : []}
            from={lib.object.sum(data.from, viewOffset, viewKeyDependentOffset)}
            to={lib.object.sum(data.to, viewOffset, viewKeyDependentOffset)}
          />
        )}
        {(type === 'line' && data.isGrainflowIndicator === 1 && data.grainContinuity !== 'both') && (
          <>
            {(!data.grainContinuity || data.grainContinuity === 'none' || data.grainContinuity === 'from') && (
              <CanvasLine
                from={PositionHelper.toCanvas(lib.object.sum(data.from, viewOffset, viewKeyDependentOffset), canvasData)}
                to={PositionHelper.toCanvas(lib.object.sum(data.from, lib.trig.rotate({point: {x: 1.5, y: 1.5}, byRadians: lib.trig.alpha({p1: data.from, p2: data.to})}), viewOffset, viewKeyDependentOffset), canvasData)}
                strokeWidth={(data.strokeWidth && _.get(data, 'scaleStrokeWidth', true)) ? (data.strokeWidth * canvasData.scale) : (data.strokeWidth || undefined)}
                stroke={data.stroke ? data.stroke : (projectGraphic.layer === 'installationNotes' ? 'red' : 'black')}
                strokeDashArray={data.isDashed ? [strokeDashLength, strokeDashLength] : []}
              />
            )}
            {(!data.grainContinuity || data.grainContinuity === 'none' || data.grainContinuity === 'to') && (
              <CanvasLine
                from={PositionHelper.toCanvas(lib.object.sum(data.to, viewOffset, viewKeyDependentOffset), canvasData)}
                to={PositionHelper.toCanvas(lib.object.sum(data.to, lib.trig.rotate({point: {x: -1.5, y: -1.5}, byRadians: lib.trig.alpha({p1: data.from, p2: data.to})}), viewOffset, viewKeyDependentOffset), canvasData)}
                strokeWidth={(data.strokeWidth && _.get(data, 'scaleStrokeWidth', true)) ? (data.strokeWidth * canvasData.scale) : (data.strokeWidth || undefined)}
                stroke={data.stroke ? data.stroke : (projectGraphic.layer === 'installationNotes' ? 'red' : 'black')}
                strokeDashArray={data.isDashed ? [strokeDashLength, strokeDashLength] : []}
              />
            )}
          </>
        )}
        {type === 'text' && (
          <CanvasScriptObject
            script={`text({text: \`${data.text}\`, fontSize: '${_.toNumber(data.fontSize / 3) || 11/3}', fontWeight: '${data.isBold === 1 ? 'bold' : 'normal'}', fill: '${data.fontColor || (projectGraphic.layer === 'installationNotes' ? 'red' : 'black')}', width: ${data.size.width}, fontFamily: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", "Open Sans", Helvetica, Arial, "Lucida Grande", sans-serif'})`}
            size={{ height: data.size.height, width: data.size.width }}
            position={{
              x: lib.object.sum(data.position, viewOffset, viewKeyDependentOffset).x,
              y: lib.object.sum(data.position, viewOffset, viewKeyDependentOffset).y
            }}
            isDisabled={canvasData.isStatic}
            isRotatable={false}
            isSelected={isSelected}
            onSelect={this.handleSelect}
            onTransform={this.handleTransform}
            onTransformEnd={this.handleTransformEnd}
            onDelete={this.handleDeleteProjectGraphic}
            rotation={0}
          />
        )}
        {type === 'rectangle' && (
          <>
            <CanvasScriptObject
              isSelected={isSelected}
              isEnabled
              isRotatable={false}
              onSelect={this.handleSelect}
              onDelete={this.handleDeleteProjectGraphic}
              isDraggable
              size={data.size}
              position={lib.object.sum(data.position, viewOffset, viewKeyDependentOffset)}
              onTransform={this.handleTransform}
              onTransformEnd={this.handleTransformEnd}
              strokeWidth={data.strokeWidth * (data.scaleStrokeWidth ? canvasData.scale : 1)}
              rotation={0}
              alwaysUpdate
              metaProps={{dimensions: data.size, scaledStrokeWidth: _.toNumber(data.strokeWidth || 1) / (data.scaleStrokeWidth ? 1 : canvasData.scale)}}
              script={`
                var nodes = [
                  {x: 0, y: 0},
                  {x: 0, y: _.dimensions.height},
                  {x: _.dimensions.width, y: _.dimensions.height},
                  {x: _.dimensions.width, y: 0}
                ];
                var scaledStrokeWidth = _.scaledStrokeWidth;
                var strokeWidthOffset = scaledStrokeWidth / 2;

                var lines = lodash.map(nodes, (point, index) => {
                  var nextPoint = lib.array.next(nodes, index);
                  var alpha = lib.trig.alpha({p1: point, p2: nextPoint});
                  var previousPoint = lib.array.prev(nodes, index);
                  var previousAlpha = lib.trig.alpha({p1: previousPoint, p2: point});

                  var strokeLine = {from: point, to: nextPoint};

                  if (!${isExportingToDXF}) {
                    var nextAlpha = lib.trig.alpha({p1: point, p2: nextPoint});
                    var lineOffset = lib.trig.translate({point: {x: 0, y: 0}, by: strokeWidthOffset, alpha: nextAlpha + Math.PI / 2});

                    strokeLine = {from: lib.object.sum(point, lineOffset), to: lib.object.sum(nextPoint, lineOffset)};
                  }

                  strokeLine = lib.trig.extend({line: strokeLine, by: ${isExportingToDXF} ? scaledStrokeWidth / 2 - 0.5 : scaledStrokeWidth, rangeKey: 'from'});

                  return line({
                    x1: strokeLine.from.x, y1: strokeLine.from.y, x2: strokeLine.to.x, y2: strokeLine.to.y,
                    stroke: '${data.strokeColor === 'transparent' ? 'transparent' : Color(data.strokeColor || 'black').alpha(data.strokeOpacity || 1).hexa()}',
                    dashed: ${data.isDashed},
                    ...(${data.isDashed} ? {strokeDashArray: [${rectStrokeDashLength}, ${rectStrokeDashLength}]} : {})
                  });
                });

                group({opacity: ${data.opacity || 1}}, [
                  ...lines,
                  rect({
                    stroke: '',
                    fill: '${(data.fill && data.fill !== 'transparent' && data.fillType === 'color') ? Color(data.fill).alpha(data.fillOpacity || 1).hexa() : 'transparent'}',
                    ...${data.fillType === 'linearGradient' ? JSON.stringify({
                      fillPriority: "linear-gradient",
                      fillLinearGradientStartPoint: data.gradientPositions.from,
                      fillLinearGradientEndPoint: data.gradientPositions.to,
                      fillLinearGradientColorStops:  _.flatten(_.map(data.gradientStops, (gradientStop, index) => {
                        return [index, Color(gradientStop.color).alpha(gradientStop.opacity).hexa()]
                      }))}) : "{}"
                    }
                  })
                ])
              `}
            />
            {isSelected && data.fillType === 'linearGradient' && (
              <EditableCanvasLine
                portalSelector={".masking-layer"}
                isSelected={isSelected}
                onClick={this.handleSelect}
                onTransformEnd={this.handleLinearGradientTransformEnd}
                strokeWidth={2}
                stroke={'black'}
                strokeDashArray={data.isDashed ? [strokeDashLength, strokeDashLength] : []}
                from={lib.object.sum(data.gradientPositions.from, viewOffset, data.position, viewKeyDependentOffset)}
                to={lib.object.sum(data.gradientPositions.to, viewOffset, data.position, viewKeyDependentOffset)}
              />
            )}
            {data.fillType === 'texture' && (
              <CanvasTexture
                isSelected={_.get(selectionData, 'activeProjectGraphicData.projectGraphicId') === projectGraphic.id}
                projectGraphic={projectGraphic}
                src={data.fillTexture.url}
                height={data.fillTexture.size.height}
                width={data.fillTexture.size.width}
                rotation={data.fillTexture.rotation}
                position={{...lib.object.sum(data.position, data.fillTexture.delta, viewOffset, viewKeyDependentOffset)}}
                positionInCanvas={{...PositionHelper.toCanvas(lib.object.sum(data.position, data.fillTexture.delta, viewOffset, viewKeyDependentOffset), canvasData)}}
                maskingPolygons={textureMaskingPolygons}
                opacity={data.fillTexture.opacity}
                onTransform={this.handleTextureTransform}
                onTransformEnd={this.handleTextureTransformEnd}
                onSelect={this.handleSelect}
              />
            )}
          </>
        )}
        {type === 'circle' && (
          <>
            <CanvasScriptObject
              isSelected={isSelected}
              isEnabled
              isRotatable={false}
              onSelect={this.handleSelect}
              onDelete={this.handleDeleteProjectGraphic}
              isDraggable
              size={data.size}
              position={lib.object.sum(data.position, viewOffset, viewKeyDependentOffset)}
              onTransform={this.handleTransform}
              onTransformEnd={this.handleTransformEnd}
              transformerOffset={circleOffset}
              strokeWidth={data.strokeWidth * (data.scaleStrokeWidth ? canvasData.scale : 1)}
              rotation={0}
              alwaysUpdate
              metaProps={{dimensions: data.size, scaledStrokeWidth: _.toNumber(data.strokeWidth || 1) / (data.scaleStrokeWidth ? 1 : canvasData.scale)}}
              disabledAnchors={['top-center', 'bottom-center', 'middle-left', 'middle-right']}
              script={`
                group({opacity: ${data.opacity || 1}}, [,
                  circle({
                    radius: ${data.size.radius},
                    stroke: '${data.strokeColor === 'transparent' ? 'transparent' : Color(data.strokeColor || 'black').alpha(data.strokeOpacity || 1).hexa()}',
                    origin: {x: 'left', y: 'left'},
                    strokeWidth: ${data.strokeWidth * (data.scaleStrokeWidth ? canvasData.scale : 1)},
                    fill: '${(data.fill && data.fill !== 'transparent' && data.fillType === 'color') ? Color(data.fill).alpha(data.fillOpacity || 1).hexa() : 'transparent'}',
                    ...${data.fillType === 'linearGradient' ? JSON.stringify({
                      fillPriority: "linear-gradient",
                      fillLinearGradientStartPoint: data.gradientPositions.from,
                      fillLinearGradientEndPoint: data.gradientPositions.to,
                      fillLinearGradientColorStops:  _.flatten(_.map(data.gradientStops, (gradientStop, index) => {
                        return [index, Color(gradientStop.color).alpha(gradientStop.opacity).hexa()]
                      }))}) : "{}"
                    }
                  })
                ])
              `}
            />
          </>
        )}
        {type === 'polygon' && (
          <>
            <EditableCanvasPolyline
              isSelected={isSelected}
              isEnabled
              hideDimensions
              onSelect={this.handleSelect}
              onDelete={this.handleDeleteProjectGraphic}
              isDraggable
              opacity={data.opacity}
              fill={data.fillType === 'color' ? data.fill : ''}
              fillOpacity={data.fillOpacity}
              strokeOpacity={data.strokeOpacity}
              points={data.points}
              position={data.position}
              stroke={data.strokeColor}
              strokeWidth={data.strokeWidth}
              dashed={data.isDashed}
              strokeDashArray={data.isDashed ? [rectStrokeDashLength, rectStrokeDashLength] : []}
              usePathStroke
              offset={lib.object.sum(viewOffset, viewKeyDependentOffset)}
              closed={data.closed}
              graphicType={'polygon'}
              shouldScaleStrokeWidth={data.scaleStrokeWidth}
              onPointsChange={this.handlePointsChange}
              onAddPoint={this.handleAddOrDeletePoints}
              onDeletePoint={(points) => this.handleAddOrDeletePoints({points})}
              onOpen={this.handleOpen}
              onClose={this.handleClose}
              onDragMove={this.handleDragMove}
              onDragEnd={this.handleDragEnd}
              onPointDragMove={this.handlePointDragMove}
              onPointDragEnd={this.handlePointDragEnd}
              {...(data.fillType === 'linearGradient' ? {
                fillProps: {
                  fillPriority: "linear-gradient",
                  /* linear-gradient */
                  fillLinearGradientStartPoint: lib.object.multiply(data.gradientPositions.from, canvasData.scale),
                  fillLinearGradientEndPoint: lib.object.multiply(data.gradientPositions.to, canvasData.scale),
                  fillLinearGradientColorStops:  _.flatten(_.map(data.gradientStops, (gradientStop, index) => {
                    return [index, Color(gradientStop.color).alpha(gradientStop.opacity).hexa()]
                  }))}} : {})
              }
            />
            {isSelected && data.fillType === 'linearGradient' && (
              <EditableCanvasLine
                portalSelector={".masking-layer"}
                isSelected={isSelected}
                onClick={this.handleSelect}
                onTransformEnd={this.handleLinearGradientTransformEnd}
                strokeWidth={2}
                stroke={'black'}
                strokeDashArray={data.isDashed ? [strokeDashLength, strokeDashLength] : []}
                from={lib.object.sum(data.gradientPositions.from, viewOffset, data.position, viewKeyDependentOffset)}
                to={lib.object.sum(data.gradientPositions.to, viewOffset, data.position, viewKeyDependentOffset)}
              />
            )}
            {data.fillType === 'texture' && (
              <CanvasTexture
                isSelected={_.get(selectionData, 'activeProjectGraphicData.projectGraphicId') === projectGraphic.id}
                projectGraphic={projectGraphic}
                src={data.fillTexture.url}
                height={data.fillTexture.size.height}
                width={data.fillTexture.size.width}
                rotation={data.fillTexture.rotation}
                position={{...lib.object.sum(data.position, data.fillTexture.delta, viewOffset, viewKeyDependentOffset)}}
                positionInCanvas={{...PositionHelper.toCanvas(lib.object.sum(data.position, data.fillTexture.delta, viewOffset, viewKeyDependentOffset), canvasData)}}
                maskingPolygons={textureMaskingPolygons}
                opacity={data.fillTexture.opacity}
                onTransform={this.handleTextureTransform}
                onTransformEnd={this.handleTextureTransformEnd}
                onSelect={this.handleSelect}
              />
            )}
          </>
        )}
        {type === 'textPointer' && (<>
          <CanvasScriptObject
            {...{renderForDrawings}}
            script={`text({backgroundColor: 'rgba(255, 255, 255, 0.5)', text: \`${data.text}\`, fontSize: '${_.toNumber(data.fontSize / 3) || 11/3}', fontWeight: '${data.isBold === 1 ? 'bold' : 'normal'}', fill: '${data.fontColor || (projectGraphic.layer === 'installationNotes' ? 'red' : 'black')}', width: ${size.width}, fontFamily: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", "Open Sans", Helvetica, Arial, "Lucida Grande", sans-serif'})`}
            size={size}
            position={{
              x: lib.object.sum(data.position, viewOffset, viewKey !== 'front' ? room.plan.position : {x: firstWallXInset}).x,
              y: lib.object.sum(data.position, viewOffset, viewKey !== 'front' ? room.plan.position : {x: firstWallXInset}).y
            }}
            isDisabled={canvasData.isStatic || preventEditing}
            preventRotate={true}
            isSelected={isSelected}
            onSelect={this.handleSelect}
            onTransform={this.handleTransform}
            onTransformEnd={this.handleTransformEnd}
            onDelete={this.handleDeleteProjectGraphic}
            isRotatable={false}
            rotation={0}
          />
          <EditableCanvasLine
            preventOrthoIndication
            showToArrow={data.showArrow}
            isSelected={isSelected}
            isDisabled={canvasData.isStatic}
            onClick={this.handleSelect}
            onTransformEnd={this.handleTextPointerArrowTransformEnd}
            onSelectedHandleKeyChange={(handleKey) => this.setState({selectedHandleKey: handleKey})}
            onDelete={this.handleDeleteProjectGraphic}
            stroke={projectGraphic.layer === 'installationNotes' ? (data.fontColor || 'red'): 'black'}
            strokeDashArray={data.isDashed ? [strokeDashLength, strokeDashLength] : []}
            from={
              lib.object.sum({
                  x: data.position.x + (textPointerFromPositions.xAxis === 'right' ? data.size.width : 0),
                  y: data.position.y + (textPointerFromPositions.yAxis === 'bottom' ? data.size.height : 0)
                }, viewOffset, viewKey !== 'front'
                ? room.plan.position
                : {x: firstWallXInset})
            }
            to={lib.object.sum(data.to, viewOffset, viewKey !== 'front' ? room.plan.position : {x: firstWallXInset})}
            isTextPointer={true}
          />
        </>)}
      </>
    );
  }
}

function CanvasProjectGraphicWithContext(props) {
  const canvasData = useContext(CanvasDataContext);
  let selectionData = useContext(CanvasSelectionContext);
  const projectData = _.omit(useContext(ProjectDataContext), ['dimensionsData']);

  var isSelected = props.projectGraphic && _.size(selectionData) ? selectionData.getIsActiveEntity({resourceKey: 'projectGraphic', id: props.projectGraphic.id}) : false;
  selectionData = _.omit(selectionData, ['activeEntities', 'activeDimensionData', 'activeDatumData']);

  return <CanvasProjectGraphic {...props} {...{isSelected, canvasData, selectionData, projectData}} />;
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    var {projectGraphic} = ownProps;
    var props = {};

    if (ownProps.id) {
      props.projectGraphic = state.resources.projectGraphics.byId[ownProps.id];
    }

    return props;
  },
  mapDispatch: {
    ..._.pick(resourceActions.projectGraphics, ['destroyProjectGraphic', 'updateProjectGraphic']),
    ..._.pick(issuesDataActions, ['setIssuesData']),
  },
})(CanvasProjectGraphicWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasProjectGraphic'})
});
