import React, {useContext} from 'react';
import _ from 'lodash';
import { connect, resourceActions, issuesDataActions } from 'redux/index.js';
import {CanvasDataContext, CanvasSelectionContext} from 'canvas';

import ProjectDataContext from 'contexts/project-data-context';

import { Html } from 'react-konva-utils';

import PositionHelper from 'helpers/position-helper';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import CanvasProductDetailsRow from './canvas-product-details-row';

function CanvasContainerDetails(props) {
  var {canvasData, position, container, products, projectData, selectionData, ...restProps} = props;

  var detailsPosition = PositionHelper.toCanvas({x: position.x - 18.32, y: position.y + (container.dimensions.height || 0) + 10}, canvasData);

  var detailApplications = Product.getDetailApplications({products});

  var handleDeleteProduct = (product) => () => {

    selectionData.onDeselect();

    Product.destroy({product, reduxActions: restProps, pushToUndoQueue: projectData.pushToUndoQueue});
  };

  var handleSelectProduct = (product) => {
    const {setActiveEntities} = selectionData;

    setActiveEntities({entities: [{resourceKey: 'product', id: product.id}], isMultiSelect: false});
  };

  return (
    <Html divProps={{style: {textTransform: 'uppercase', transformOrigin: 'top left', transform: `translate(${detailsPosition.x}px, ${detailsPosition.y}px) scale(${canvasData.scale / 7.5})`}}}>
      <div style={{zIndex: -0.5}}>
        {
          _.map(_.sortBy(products, [product => product.productionId ? 0 : 1, 'rank']), (product, index) => (
            <CanvasProductDetailsRow
              {...{
                canvasData,
                position,
                product,
                detailApplications,
                ...restProps
              }}
              onTitleClick={handleSelectProduct}
              key={`canvas-product-details-row-${product.id}-${index}`}
              onDelete={handleDeleteProduct(product)}
            />
          ))
        }
      </div>
    </Html>
  );
}

function CanvasContainerDetailsWithContext(props) {
  var canvasData = useContext(CanvasDataContext);
  var projectData = useContext(ProjectDataContext);
  var selectionData = useContext(CanvasSelectionContext);

  return (<CanvasContainerDetails {...props} {...{canvasData, projectData, selectionData}}/>);
}

export default connect({
  mapState: (state, ownProps) => {
    var {container} = ownProps;
    var props = {};

    props = container ? {container, ...Container.get(['products'], {container})} : {};

    return props;
  },
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['updateRoom', 'modifyRooms']),
    ..._.pick(resourceActions.archetypes, ['createArchetype', 'destroyArchetype']),
    ..._.pick(resourceActions.containers, ['updateContainer', 'modifyContainers']),
    ..._.pick(resourceActions.archElements, ['updateArchElement']),
    ..._.pick(resourceActions.products, ['updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.projectGraphics, ['updateProjectGraphic', 'trackProjectGraphics']),
    ..._.pick(resourceActions.elevations, ['updateElevation', 'modifyElevations']),
    ..._.pick(resourceActions.walls, ['updateWalls']),
    ..._.pick(resourceActions.volumes, ['updateVolume']),
    ..._.pick(resourceActions.parts, ['updateParts']),
    ..._.pick(issuesDataActions, ['setIssuesData'])
  }
})(CanvasContainerDetailsWithContext);
