import React from 'react';
import _ from 'lodash';
import K from 'k';

import Dropdown from 'components/dropdown';
import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';

import upArrow from 'assets/up-arrow-black.png';

class RoomSelectorElement extends React.PureComponent {
  get roomOptions() {
    var {floors, roomsByFloor} = this.props;

    var currentRoomCount = 0;

    return _.flatten(_.map(roomsByFloor, (floor) => {
      return _.map(floor.rooms, ({id, title}) => {
        var roomNumber = currentRoomCount += 1;

        return {
          value: id,
          title: `${title}${floors.length > 1 ? ` (${floor.title})` : ''}`,
          roomNumber
        };
      });
    }));
  }

  handleActiveElevationIteration = (actionType) => {
    var newActiveElevation;
    var room = _.find(this.props.rooms, {id: this.props.activeRoomId});
    let sortedElevationsArray = Room.get('sortedElevations', {room});

    sortedElevationsArray = _.filter(sortedElevationsArray, elevation => {
      //HINT created because frontContainers may be more specific than just containers with the same angle and sideKey doesn't get set by default so is sometimes undefined/incorrect until page completes loading
      return Elevation.getIsSection({elevation}) || Elevation.getSameAngleContainers({elevation}).length > 0;
    });

    const index = _.findIndex(sortedElevationsArray, {id: this.props.activeElevationId});

    if (actionType === 'prev') {
      newActiveElevation = lib.array.prev(sortedElevationsArray, index);
    }
    else {
      newActiveElevation = lib.array.next(sortedElevationsArray, index);
    }

    if (newActiveElevation) {
      this.props.setActiveElevationId({elevationId: newActiveElevation.id});
    }
  };

  render() {
    var {rooms, elevations, activeRoomId, setActiveRoomId, setActiveElevationId, setActiveRoomAndElevation, activeElevationId} = this.props;

    var activeRoom = _.find(rooms, {id: activeRoomId});
    var sortedElevations = Room.get('sortedElevations', {room: activeRoom});

    sortedElevations = _.filter(sortedElevations, elevation => {
      //HINT created because frontContainers may be more specific than just containers with the same angle and sideKey doesn't get set by default so is sometimes undefined/incorrect until page completes loading
      return Elevation.getIsSection({elevation}) || Elevation.getSameAngleContainers({elevation}).length > 0;
    });

    var isFirstRoom = _.findIndex(this.roomOptions, {value: activeRoomId}) === 0;
    var isLastRoom = _.findIndex(this.roomOptions, {value: activeRoomId}) + 1 === _.size(this.roomOptions);

    var nextRoomId = _.get(this.roomOptions, `[${_.findIndex(this.roomOptions, {value: activeRoomId}) + 1}].value`);
    var prevRoomId = _.get(this.roomOptions, `[${_.findIndex(this.roomOptions, {value: activeRoomId}) - 1}].value`);

    var arrowStyles = {
      container: {width: '1.5rem', height: '1.5rem', position: 'relative'},
      icon: {width: '100%', height: '100%', cursor: 'pointer', position: 'absolute'}
    };

    var dropdownOptions = _.flatMap(this.roomOptions, (roomOption) => {
      var room = _.find(rooms, {id: roomOption.value});
      var sortedElevations = Room.get('sortedElevations', {room});

      sortedElevations = _.filter(sortedElevations, elevation => {
        //HINT created because frontContainers may be more specific than just containers with the same angle and sideKey doesn't get set by default so is sometimes undefined/incorrect until page completes loading
        return Elevation.getIsSection({elevation}) || Elevation.getSameAngleContainers({elevation}).length > 0;
      });

      return _.map(sortedElevations, (elevation) => {
        return {
          value: `${room.id}-${elevation.id}`,
          title: `${room.title}${sortedElevations.length > 1 ? ` - ${Elevation.getTitle({elevation})}` : ''}`
        }
      });
    });

    var currentIndex = _.findIndex(dropdownOptions, {value: `${activeRoomId}-${activeElevationId}`});

    var arrowSize = '20px';

    return (
      <div>
        <div style={{display: 'flex', marginTop: '0.5rem'}}>
          <div style={{display: 'flex', width: '100%'}}>
            <div style={{...K.defaultIconSize, display: 'flex', alignItems: 'center', width: 'auto', height: 'auto', marginRight: '1.5rem'}}>
              <div
                style={{...arrowStyles.container, width: arrowSize, height: arrowSize, cursor: 'pointer'}}
                onClick={() => {
                  var newIndex = currentIndex - 1;

                  if (newIndex < 0) newIndex = dropdownOptions.length - 1;

                  if (newIndex >= 0) {
                    var roomId = _.toNumber(dropdownOptions[newIndex].value.split('-')[0]);
                    var elevationId = _.toNumber(dropdownOptions[newIndex].value.split('-')[1]);

                    setActiveRoomAndElevation({roomId, elevationId});
                  }
                }}
              >
                <img src={upArrow} style={{transform: 'rotate(-90deg)', ...arrowStyles.icon, width: arrowSize, height: arrowSize, }} />
              </div>
            </div>
            <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
              <div style={{textTransform: 'upperCase', fontSize: '1em', marginBottom: K.spacing / 2, display: 'flex', justifyContent: 'center'}}>
                {_.find(rooms, {id: activeRoomId}).title}
              </div>
              <Dropdown
                options={dropdownOptions}
                value={`${activeRoomId}-${activeElevationId}`}
                onChange={(value) => {
                  var roomId = _.toNumber(value.split('-')[0]);
                  var elevationId = _.toNumber(value.split('-')[1]);
                  setActiveRoomAndElevation({roomId, elevationId});
                }}
                style={{display: 'flex', justifyContent: 'center'}}
                labelStyle={{fontSize: '0.8em', textTransform: 'uppercase', opacity: 0.6, letterSpacing: '0.06em'}}
                contentContainerStyle={{borderRadius: '5px', padding: '10px', marginTop: '15px', display: 'flex', flexDirection: 'column', marginRight: 'auto'}}
                showCaret={false}
                right
                overrideLabel={`Elevation ${_.findIndex(dropdownOptions, {value: `${activeRoomId}-${activeElevationId}`}) + 1} of ${_.size(dropdownOptions)}`}
                hasFixedHeight={true}
              />
            </div>
            <div style={{...K.defaultIconSize, display: 'flex', alignItems: 'center', width: 'auto', height: 'auto', marginLeft: '1.5rem'}}>
              <div
                style={{justifyContent: 'center', ...arrowStyles.container, width: arrowSize, height: arrowSize, cursor: 'pointer'}}
                onClick={() => {
                  var newIndex = currentIndex + 1;
                  if (newIndex >= dropdownOptions.length) newIndex = 0;

                  if (newIndex < dropdownOptions.length) {
                    var roomId = _.toNumber(dropdownOptions[newIndex].value.split('-')[0]);
                    var elevationId = _.toNumber(dropdownOptions[newIndex].value.split('-')[1]);

                    setActiveRoomAndElevation({roomId, elevationId});
                  }
                }}
              >
                <img src={upArrow} style={{transform: 'rotate(90deg)', ...arrowStyles.icon, width: arrowSize, height: arrowSize}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    // return (
    //   <div>
    //     {activeElevationId && (
    //       <div style={{display: 'flex'}}>
    //         {/* <div style={{...K.defaultIconSize, width: '17px', height: '17px', marginRight: '1.5rem'}}>
    //           {_.findIndex(sortedElevations, {id: activeElevationId}) !== 0 && (
    //               <div
    //                 style={{justifyContent: 'center', ...K.defaultIconSize, width: '17px', height: '17px', paddingTop: '1px', cursor: 'pointer', opacity: 0.5}}
    //                 onClick={() => this.handleActiveElevationIteration('prev')}
    //               >
    //                 <img src={upArrow} style={{transform: 'rotate(-90deg)', ...arrowStyles.icon, width: '17px', height: '17px'}} />
    //               </div>
    //           )}
    //         </div>
    //         <div style={{display: 'flex', alignItems: 'center'}}>
    //           <Dropdown
    //             options={_.map(sortedElevations, (elevation) => ({
    //               value: elevation.id,
    //               title: Elevation.getTitle({elevation})
    //             }))}
    //             value={activeElevationId}
    //             onChange={(value) => setActiveElevationId({elevationId: value})}
    //             style={{}}
    //             labelStyle={{letterSpacing: '0.15em', fontSize: '1.25em', fontWeight: 400, textTransform: 'uppercase', opacity: 0.5}}
    //             contentContainerStyle={{borderRadius: '5px', padding: '10px', display: 'flex', flexDirection: 'column', marginLeft: '-14px', marginRight: 'auto'}}
    //             showCaret={false}
    //             hasFixedHeight={true}
    //           />
    //         </div> */}
    //         <div>
    //           Configuring Room {_.findIndex(this.roomOptions, {value: activeRoomId}) + 1} of {_.size(this.roomOptions)}
    //         </div>
    //         {/* <div style={{...K.defaultIconSize, width: '17px', height: '17px', marginLeft: '1.5rem'}}>
    //           {_.findIndex(sortedElevations, {id: activeElevationId}) !== sortedElevations.length - 1 && (
    //             <div
    //               style={{justifyContent: 'center', ...K.defaultIconSize, width: '17px', height: '17px', cursor: 'pointer', opacity: 0.5}}
    //               onClick={() => this.handleActiveElevationIteration('next')}
    //             >
    //               <img src={upArrow} style={{transform: 'rotate(90deg)', ...arrowStyles.icon, width: '17px', height: '17px', }} />
    //             </div>
    //           )}
    //         </div> */}
    //       </div>
    //     )}
    //     <div style={{display: 'flex', marginTop: '0.5rem'}}>
    //       <div style={{display: 'flex', padding: '0.5rem 1rem', backgroundColor: '#F5F5F5', borderRadius: '5px'}}>
    //         {(dropdownOptions.length > 1 && currentIndex !== 0) && (

    //           <div style={{...K.defaultIconSize, width: '17px', height: '17px', marginRight: '1.5rem'}}>
    //             {(dropdownOptions.length > 1 && currentIndex !== 0) && (
    //               <div
    //                 style={{justifyContent: 'center', ...K.defaultIconSize, width: '17px', height: '17px', paddingTop: '3px', cursor: 'pointer'}}
    //                 onClick={() => {
    //                   var newIndex = currentIndex - 1;

    //                   if (newIndex >= 0) {
    //                     var roomId = _.toNumber(dropdownOptions[newIndex].value.split('-')[0]);
    //                     var elevationId = _.toNumber(dropdownOptions[newIndex].value.split('-')[1]);

    //                     setActiveRoomAndElevation({roomId, elevationId});
    //                   }
    //                 }}
    //               >
    //                 <img src={upArrow} style={{transform: 'rotate(-90deg)', ...arrowStyles.icon, width: '17px', height: '17px', }} />
    //               </div>
    //             )}
    //           </div>
    //         )}
    //         <div>
    //           <Dropdown
    //             options={_.flatMap(this.roomOptions, (roomOption) => {
    //               var room = _.find(rooms, {id: roomOption.value});
    //               var sortedElevations = Room.get('sortedElevations', {room});

    //               return _.map(sortedElevations, (elevation) => {
    //                 return {
    //                   value: `${room.id}-${elevation.id}`,
    //                   title: `${room.title}${sortedElevations.length > 1 ? ` - ${Elevation.getTitle({elevation})}` : ''}`
    //                 }
    //               });
    //             })}
    //             value={`${activeRoomId}-${activeElevationId}`}
    //             onChange={(value) => {
    //               var roomId = _.toNumber(value.split('-')[0]);
    //               var elevationId = _.toNumber(value.split('-')[1]);
    //               setActiveRoomAndElevation({roomId, elevationId});
    //             }}
    //             style={{}}
    //             labelStyle={{fontSize: '1.5em', letterSpacing: '0.06em'}}
    //             contentContainerStyle={{borderRadius: '5px', padding: '10px', display: 'flex', flexDirection: 'column', marginLeft: '-14px', marginRight: 'auto'}}
    //             showCaret={false}
    //             hasFixedHeight={true}
    //           />
    //         </div>
    //         <div style={{...K.defaultIconSize, width: '17px', height: '17px', marginLeft: '1.5rem'}}>
    //           {(this.roomOptions.length > 1 && !isLastRoom) && (
    //             <div
    //               style={{justifyContent: 'center', ...K.defaultIconSize, width: '17px', height: '17px', paddingTop: '1px', cursor: 'pointer'}}
    //               onClick={() => setActiveRoomId({roomId: nextRoomId})}
    //             >
    //               <img src={upArrow} style={{transform: 'rotate(90deg)', ...arrowStyles.icon, width: '17px', height: '17px', }} />
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default RoomSelectorElement;
