import Container from 'project-helpers/container';
import Product from 'project-helpers/product';

const getMissingProductOptionsIssues = ({
  products, project
}) => {
  var issues = [];

  _.forEach(products, (product) => {
    var ownedCompatibleDetails = Product.getOwnedCompatibleDetails({product});

    var mandatoryDetails = _.filter(ownedCompatibleDetails, detail => {
      return !detail.isSubproductControl && !_.includes(['frontPanelType', 'grainDirection', 'grainContinuity', 'pullLocation', 'pullOrientation'], detail.key);
    });

    _.forEach(mandatoryDetails, detail => {
      var tbdValues = detail.type === 'material' ? [472, 468, 461, 456, 455, 403, 402, 401, 400, 399, 359, 358] : (project.companyKey === 'vp' ? [] : [25]);
      var valueIsTbd = _.includes(tbdValues, _.get(product, `details.${detail.key}.id`, -1));

      if (valueIsTbd) {
        issues.push({
          id: `product-${product.id}-missing-detail-assignment-${detail.key}`,
          message: `${product.productionId || Product.get('productType', {product}).title} - ${detail.title}`,
          resourceKey: 'product',
          resourceId: product.id,
          type: `missing-detail-assignment`,
          level: 2
        })
      }
    });
  });

  return issues;
};

export default getMissingProductOptionsIssues;
