import Container from 'project-helpers/container';
import Product from 'project-helpers/product';

const getProductDepthInconsistancy = ({
  products, project
}) => {
  var issues = [];

  _.forEach(products, (product) => {
    var productIsManaged = (Product.getIsManaged({product}) && _.get(product, 'managedData.managedKey') !== 'autofilledStorage');
    var productIsPanel = Product.getIsPanel({product});
    var productIsComponent = Product.getIsComponentProduct({product});
    var ignoreDepthInconsistency = productIsManaged || productIsPanel || productIsComponent;

    if (!ignoreDepthInconsistency) {
      var container = Product.get('container', {product});

      if (container) {
        var containerDropzoneSize = Container.getDropzoneSize({container, viewKey: 'top'});
        var containerDropzoneDepth = (containerDropzoneSize.depth || containerDropzoneSize.height);

        if (container.type === 'baseWithChase') containerDropzoneDepth -= container.dimensions.depth - container.customData.unitDepth;
        if (container.type === 'wallPanel') containerDropzoneDepth -= container.customData.cleatDepth || 0;

        if (product.dimensions.depth < containerDropzoneDepth) {
          //HINT wall and corner counter units sometimes have depths that are slightly off due to wall spacing, they shouldn't be flagged
          var wallUnitToIgnoreDueToWallSpacing = _.includes(['wall', 'cornerCounterTransition', 'wallUnitLiner'], container.type) && Math.abs(product.dimensions.depth - containerDropzoneDepth) <= 0.5;

          //HINT hb expressed box vanities are 1.5" off
          var vanityUnitToIgnoreDueToExpressedBox = _.includes(['vanity'], container.type) && project.companyKey === 'hb' && _.get(container, 'customData.hasExpressedBox') && Math.abs(product.dimensions.depth - containerDropzoneDepth) <= 1.5;

          var tallUnitToIgnoreDueToSubzero = project.companyKey === 'vp' && container.type === 'tall' && _.get(container, 'dimensions.depth') >= 26.1875 && Math.abs(product.dimensions.depth - containerDropzoneDepth) <= 2.0625;

          if (!wallUnitToIgnoreDueToWallSpacing && !vanityUnitToIgnoreDueToExpressedBox && !tallUnitToIgnoreDueToSubzero) {
            issues.push({
              id: `product-${product.id}-inconsistant-depth-in-container-${product.dimensions.depth}-${containerDropzoneDepth}`,
              message: `${product.productionId || Product.get('productType', {product}).title} - depth`,
              resourceKey: 'product',
              resourceId: product.id,
              type: 'inconsistant-product-depth-in-container',
              level: 1
            });
          }
        }
      }
    }
  });

  return issues;
};

export default getProductDepthInconsistancy;
