import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Wall from './wall';
import WallSet from './wall-set';
import Container from 'project-helpers/container/index';
import Project from 'project-helpers/project';
import getDependencies from 'helpers/get-dependencies';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import DetailsHelper from 'helpers/details-helper';
import ManagedDataHelper from 'helpers/managed-data-helper';
import updateProductionIds from 'helpers/update-production-ids-helper';
import setIssues from 'helpers/issues/index';
import memoObject from 'helpers/memo-object';
import memo from 'helpers/memo';

import { store } from 'redux/index.js';
import Elevation from './elevation';
import ArchElement from './arch-element';
import Volume from 'project-helpers/volume';


var Room = {
  get(dependencyKeys, {room, roomId, state}) {
    roomId = roomId || _.get(room, 'id');

    if (!roomId) return;

    return getDependencies({dependencyKeys, state}, ({state, useDependency}) => {
      if (useDependency('wallSets') || useDependency('computedWalls') || useDependency('walls')) {
        var wallSets = Room.getWallSets({room, state});
      }

      if (useDependency('containers') || useDependency('products') || useDependency('productOptions') || useDependency('volumes') || useDependency('nonSpacialContainers')) {
        var scopeId = _.get(_.values(state.resources.scopes.byFieldKeyIndex.roomId[roomId]), '[0].id');
        var scopeIds = _.map(_.values(state.resources.scopes.byFieldKeyIndex.roomId[roomId]), 'id');
      }

      var combinedResourcesByScopeIds = (resources, scopeIds) => {
        var resourcesByScopeIds = _.map(scopeIds, scopeId => resources[scopeId]);
        var combinedByScopeIds = {};

        _.forEach(resourcesByScopeIds, resources => {
          _.forEach(resources, resource => combinedByScopeIds[resource.id] = resource);
        });

        return combinedByScopeIds;
      };

      return {
        archElements: () => state.resources.archElements.byFieldKeyIndex.roomId[roomId],
        computedWalls: () => Room.getComputedWalls({wallSets, room}),
        containers: () => combinedResourcesByScopeIds(state.resources.containers.byFieldKeyIndex.scopeId, scopeIds),
        nonSpacialContainers: () => _.filter(combinedResourcesByScopeIds(state.resources.containers.byFieldKeyIndex.scopeId, scopeIds), container => {
          return !container.position || _.isEmpty(container.position);
        }),
        volumes: () => combinedResourcesByScopeIds(state.resources.volumes.byFieldKeyIndex.scopeId, scopeIds),
        elevations: () => state.resources.elevations.byFieldKeyIndex.roomId[roomId],
        floor: () => state.resources.floors.byId[room.floorId],
        floors: () => state.resources.floors.byId,
        products: () => combinedResourcesByScopeIds(state.resources.products.byFieldKeyIndex.scopeId, scopeIds),
        productOptions: () => _.filter(_.flatMap(combinedResourcesByScopeIds(state.resources.products.byFieldKeyIndex.scopeId, scopeIds), product => {
          return _.get(state.resources.productOptions, `byFieldKeyIndex.productInstanceId[${product.id}]`);
        })),
        project: () => _.values(state.resources.projects.byId)[0],
        projectGraphics: () => memoObject(_.filter(state.resources.projectGraphics.byFieldKeyIndex.roomId[roomId], (data) => {
          return data.elevationId === null;
        }), `roomProjectGraphics${roomId}`),
        rooms: () => state.resources.rooms.byId,
        scope: () => _.get(_.values(state.resources.scopes.byFieldKeyIndex.roomId[roomId]), '[0]'),
        scopes: () => state.resources.scopes.byFieldKeyIndex.roomId[roomId],
        sortedElevations: () => _.sortBy(_.values(state.resources.elevations.byFieldKeyIndex.roomId[roomId]), 'rank'),
        allWalls: () => state.resources.walls.byFieldKeyIndex.roomId[roomId],
        walls: () => _.flatMap(wallSets, 'walls'),
        wallSets: () => wallSets,
        dependencies: () => state.resources
      };
    });
  },

  getComputedWalls: memo(({wallSets, room}) => {
    return _.flatMap(wallSets, wallSet => WallSet.getComputedWalls({wallSet, room}));
  }),

  getSize({room}) {
    const {points} = room.plan;
    const min = lib.object.min(...points);
    const max = lib.object.max(...points);

    return {width: max.x - min.x, height: max.y - min.y};
  },

  getContentOutline({room}) {
    // containers, walls, volumes
    const {containers, volumes, projectGraphics} = Room.get(['containers', 'volumes', 'projectGraphics'], {room});
    const outline = room.plan.points;
    const projectGraphicPoints = _.flatMap(_.filter(projectGraphics, projectGraphic => _.includes(['text', 'polygon', 'textPointer', 'rectangle', 'circle'], projectGraphic.type)), projectGraphic => {
      var additionalPoints = [];
      var normalizedPosition = projectGraphic.data.position;

      if (projectGraphic.type === 'polygon') {
        additionalPoints = _.map(projectGraphic.data.points, point => {
          return lib.object.sum(projectGraphic.data.position, point);
        });
      }
      else {
        var size = projectGraphic.data.size || {width: 50, height: 10};

        if (projectGraphic.type === 'circle') {
          size = {width: projectGraphic.data.radius, height: projectGraphic.data.radius};
        }

        additionalPoints = [lib.object.sum(normalizedPosition, {x: size.width, y: size.height})];
      }

      return [
        normalizedPosition,
        ...additionalPoints
      ];
    });

    const containerPoints = _.map(containers, container => Container.getFootprintInRoom({container}));
    const volumePoints = _.map(volumes, volume => Volume.getFootprintInRoom({volume}));
    const points = _.flatten(outline.concat(projectGraphicPoints, containerPoints, volumePoints));
    const xs = _.map(points, 'x');
    const ys = _.map(points, 'y');

    return {
      min: {x: _.min(xs), y: _.min(ys)},
      max: {x: _.max(xs), y: _.max(ys)},
    };
  },

  destroy({room, reduxActions, isBatched = false}) {
    let destructions = {};
    const store = Room.get(['containers', 'volumes', 'archElements', 'products', 'projectGraphics', 'elevations', 'allWalls', 'scopes', 'productOptions', 'rooms', 'project'], {room});

    _.forEach(['archElements', 'volumes', 'productOptions', 'products', 'projectGraphics', 'elevations', 'containers', 'walls'], resourceKey => {
      const resources = store[resourceKey === 'walls' ? 'allWalls' : resourceKey];

      if (isBatched) {
        destructions[resourceKey] = _.map(_.values(resources), 'id');
      }
      else {
        reduxActions[`destroy${_.upperFirst(resourceKey)}`]({ids: _.map(_.values(resources), 'id')});
      }
    });

    if (isBatched) {
      destructions.rooms = [room.id];
      destructions.scopes = _.map(store.scopes, 'id');
    }
    else {
      reduxActions.destroyRoom({id: room.id});
      reduxActions.destroyScopes({ids: _.map(store.scopes, 'id')});
    }

    setTimeout(() => {
      Project.updateManagedParts({project: store.project, reduxActions});
    }, 100);

    return destructions;
  },

  nearbyContainersFor({project, c1, containers, walls, wallsData, wallSets, room, containersDistanceMap}) {
    if (!containersDistanceMap) {
      containersDistanceMap = {};

      _.forEach(containers, (c1, i1) => {
        _.forEach(containers, (c2, i2) => {
          if (i2 > i1) {
            var distance = Container.getDistance({sourceContainer: c1, container: c2});
            containersDistanceMap[`${c1.id}-${c2.id}`] = distance;
            containersDistanceMap[`${c2.id}-${c1.id}`] = distance;
          }
        });
      });
    }

    if (!wallsData) {
      wallsData = _.map(walls, wall => {
        var line = Wall.getLine({wall, wallSets, room});
        var width = Wall.getWidth({wall, line});
        var slightlyExtendedLine = lib.trig.extend({line: line, by: 0.001});
        var extendedLine = lib.trig.extend({line})

        return {wall, line, width, slightlyExtendedLine, extendedLine};
      });
    }

    containers = _.filter(containers, c2 => c1 !== c2);

    var nearbyContainers = _.filter(containers, c2 => containersDistanceMap[`${c1.id}-${c2.id}`] < K.nearbyContainerThreshold);//Container.getDistance({sourceContainer: c1, container: c2}) < K.nearbyContainerThreshold);
    var distantContainers = _.filter(containers, container => !_.includes(nearbyContainers, container));

    var f2 = Container.getFootprintInRoom({container: c1});

    _.forEach(nearbyContainers, container => {
      if (Container.getContainersSeparatedByWall({sourceContainer: c1, sourceContainerFootprint: f2, container, wallsData, project})) {
        nearbyContainers = _.reject(nearbyContainers, container);
      }
    });

    var allNearbyContainers = [...nearbyContainers];

    _.forEach(nearbyContainers, c1 => {
      allNearbyContainers.push(...Room.nearbyContainersFor({c1, containers: distantContainers, walls, wallsData, wallSets, room, containersDistanceMap}));

      distantContainers = _.filter(distantContainers, c2 => !_.includes(allNearbyContainers, c2));
    });

    return _.uniq(allNearbyContainers);
  },

  getContainerGroups: memo(({containers, computedWalls, walls, wallSets, room}) => {
    var containerGroups = [];

    var containersDistanceMap = {};

    _.forEach(containers, (c1, i1) => {
      _.forEach(containers, (c2, i2) => {
        if (i2 > i1) {
          var distance = Container.getDistance({sourceContainer: c1, container: c2});

          containersDistanceMap[`${c1.id}-${c2.id}`] = distance;
          containersDistanceMap[`${c2.id}-${c1.id}`] = distance;
        }
      });
    });

    var wallsData = _.map(walls, wall => {
      var line = Wall.getLine({wall, wallSets, room});
      var width = Wall.getWidth({wall, line});
      var slightlyExtendedLine = lib.trig.extend({line: line, by: 0.001});
      var extendedLine = lib.trig.extend({line})

      return {wall, line, width, slightlyExtendedLine, extendedLine};
    });

    _.forEach(containers, container => {
      var groupedContainerIds = _.flatMap(containerGroups, containerGroup => _.map(containerGroup.containers, 'id'));

      if (!_.includes(groupedContainerIds, container.id)) {
        var containerGroup = {
          id: lib.string.uuid(),
          containers: [container, ...Room.nearbyContainersFor({c1: container, containers: _.filter(containers, c1 => !_.includes(groupedContainerIds, c1.id)), walls, wallSets, room, wallsData, containersDistanceMap})]
        };

        containerGroup.countertopManagingContainerId = _.min(_.map(_.filter(containerGroup.containers, container => Container.getSupportsCountertop({container})), 'id'));

        containerGroups.push(containerGroup);
      }
    });

    _.forEach(containerGroups, containerGroup => {
      var isNearWall = _.some(containerGroup.containers, container => {
        return container.type !== 'countertop' && _.some(computedWalls, computedWall => {
          return Container.getDistance({sourceContainer: container, computedWall, room}) <= K.nearbyWallThreshold;
        });
      });

      containerGroup.type = isNearWall ? 'wall' : 'island';
    });

    return containerGroups;
  }),

  getOverlappingContainerSetsOfVaryingDepth({containers}) {
    var containerSets = [];

    var isCandidate = (container) => (Container.getWall({container}) || Container.getVolume({container})) && container.type !== 'tall' && !_.includes(_.flatten(containerSets), container);

    _.forEach(containers, c1 => {
      if (isCandidate(c1)) {
        var containerSet = [c1, ..._.filter(containers, c2 => {
          return c1 !== c2 && isCandidate(c2) && Container.sharesFootprintWith({sourceContainer: c1, container: c2});
        })];

        var depths = _.uniq(_.map(_.reject(containerSet, {type: 'countertop'}), 'dimensions.depth'));

        if (depths.length > 2) {
          containerSets.push(containerSet);
        }
      }
    });

    return containerSets;
  },

  getElevations({room}, {onlyFronts = {walls: false, island: true}, includeSections = false} = {}) {
    var {
      project, floor, floors, rooms, walls, wallSets, computedWalls, containers: allContainers
    } = Room.get(['project', 'floor', 'floors', 'rooms', 'walls', 'wallSets', 'computedWalls', 'containers'], {room});

    var elevations = [];
    var lastWallId = _.max(_.map(walls, 'id'));

    var frontmostContainerDataFor = ({containers, alpha}) => {
      var frontmostContainerData = lib.waterfall(containers, [
        [_.filter, container => lib.trig.anglesAreEqual({a1: alpha, a2: Container.getAlpha({container})})],
        [_.filter, ({type}) => type !== 'countertop'],
        [_.map, container => ({
          container,
          frontY: lib.trig.rotate({point: Container.getFootprintLines({container}).front.from, byRadians: -alpha}).y
        })],
        [_.maxBy, 'frontY']
      ]);

      return frontmostContainerData;
    };

    var containerIsInFrontOfElevation = ({container, alpha, elevation}) => {
      var normalizedElevationLineInRoom = _.mapValues(elevation.points, point => lib.trig.rotate({point, byRadians: -alpha}));
      var normalizedFootprintInRoom = _.mapValues(Container.getFootprintInRoom({container}), point => lib.trig.rotate({point, byRadians: -alpha}));

      var withinElevationXRange = _.some(_.uniq(_.map(normalizedFootprintInRoom, 'x')), x => x > _.min(_.map([normalizedElevationLineInRoom.from, normalizedElevationLineInRoom.to], 'x')) && x < _.max(_.map([normalizedElevationLineInRoom.from, normalizedElevationLineInRoom.to], 'x')));

      var elevationWallsMinY = _.min(_.map(elevation.computedWall.walls, wall => {
        var normalizedWallLineInRoom = _.mapValues(Wall.getLine({wall, wallSet: elevation.computedWall.wallSet, room}), point => lib.trig.rotate({point, byRadians: -alpha}));

        return normalizedWallLineInRoom.from.y;
      }));

      //container is in front of the elevation walls if its not in the xrange or it is and its y (z) is in front of the min wall y (z)
      return !withinElevationXRange || (withinElevationXRange && _.every(normalizedFootprintInRoom, ({y}) => y >= elevationWallsMinY));
    };

    var visibleContainersFor = ({containers, alpha, isIsland = false, lineInRoom, elevation}) => {
      var frontmostContainer = _.get(frontmostContainerDataFor({containers, alpha}), 'container');

      if (frontmostContainer) {
        //draw square using line in room to that + 7, infinite in other 3 directions
        var elevationContainingPolygon = [];
        var frontmostFootprintLines = Container.getFootprintLines({container: frontmostContainer});

        _.forEach(['front', 'back'], sideKey => {
          var line = frontmostFootprintLines[sideKey];

          line = lib.trig.extend({line, by: 10000});

          line = _.mapValues(line, point => {
            return lib.object.sum(point, lib.trig.rotate({point: {x: 0, y: sideKey === 'front' ? 7 : -10000}, byRadians: alpha}));
          });

          elevationContainingPolygon.push(line.from, line.to);
        });

        if (isIsland) var computedWalls = Room.getComputedWalls({wallSets, room});

        //filter containers to ones that overlap or are in polygon
        containers = _.filter(containers, container => {
          var shouldShow = lib.polygon.polygonsOverlap(Container.getFootprintInRoom({container}), elevationContainingPolygon);

          if (isIsland) {
            //attempt to filter out potential wall or base runs behind the island
            var isNearWall = _.some(computedWalls, computedWall => Container.getDistance({sourceContainer: container, computedWall, room}) <= K.nearbyWallThreshold);
            var isFrontFacing = lib.trig.anglesAreEqual({a1: Container.getAlpha({container}), a2: alpha});
            var isFarFromElevationLine = Container.getDistance({sourceContainer: container, line: lineInRoom, room}) > (_.get(project, 'id') === 5572 ? 36 : 48);//HINT bandaid for a tight kitchen

            shouldShow = shouldShow && !(isNearWall && isFrontFacing && isFarFromElevationLine);
          }
          else {
            shouldShow = shouldShow && containerIsInFrontOfElevation({container, alpha, elevation});
          }

          return shouldShow;
        });

        if (isIsland) {
          var filteredFrontContainers = _.filter(containers, container => lib.trig.anglesAreEqual({a1: Container.getAlpha({container}), a2: alpha}));

          //NOTE leaving in case we choose to use
          //this filters to only countertops and the front containers corner containers

          // var containersCornerToFrontContainers = lib.waterfall(filteredFrontContainers, [
          //   [_.map, container => container.cornerContainersData],
          //   [_.flatMap, cornerData => {
          //     return _.map(_.flatten([...cornerData.from, ...cornerData.to]), data => data.container);
          //   }]
          // ]);

          // containers = _.filter(containers, container => {
          //   return container.type === 'countertop' || _.includes([...filteredFrontContainers, ...containersCornerToFrontContainers], container);
          // });

          containers = _.filter(containers, container => {
            return _.some(filteredFrontContainers, frontContainer => Container.getDistance({sourceContainer: frontContainer, container}) < K.nearbyContainerThreshold);
          });
        }
      }
      else {
        if (!isIsland) {
          containers = _.filter(containers, container => containerIsInFrontOfElevation({container, alpha, elevation}));
        }
      }

      return containers;
    };

    _.forEach(computedWalls, computedWall => {
      var {wallSet} = computedWall;
      var elevation = {points: computedWall.lineInRoom, computedWall};
      var wall = computedWall.walls[0];
      var alpha = Wall.getAlpha({wall, room, wallSet: computedWall.wallSet});
      var containers = [];

      var containersOnWall = _.filter(allContainers, container => {
        var normalizedElevationLineInRoom = _.mapValues(elevation.points, point => lib.trig.rotate({point, byRadians: -alpha}));
        var normalizedFootprintInRoom = _.mapValues(Container.getFootprintInRoom({container}), point => lib.trig.rotate({point, byRadians: -alpha}));

        var withinElevationXRange = _.some(normalizedFootprintInRoom, ({x}) => _.minBy([normalizedElevationLineInRoom.from, normalizedElevationLineInRoom.to], 'x').x <= x && _.maxBy([normalizedElevationLineInRoom.from, normalizedElevationLineInRoom.to], 'x').x >= x);

        //find containers within the elevations normalized x range, that aren't behind the wall
        return containerIsInFrontOfElevation({container, elevation, alpha})
          && withinElevationXRange
          && Container.getDistance({sourceContainer: container, computedWall, room}) <= K.nearbyWallThreshold;
      });

      _.forEach(containersOnWall, c1 => {
        var novelContainers = _.filter(allContainers, container => !_.includes(containers, container));

        if (!_.includes(containers, c1)) {
          containers.push(c1, ...Room.nearbyContainersFor({c1, containers: novelContainers, walls, wallSets, room}));
        }
      });

      if (containers.length) {
        containers = _.filter(_.uniq(containers), container => {
          return Container.getDistance({sourceContainer: container, computedWall, room}) <= (_.get(project, 'id') === 5572 ? 36 : 48);//HINT bandaid for a tight kitchen
        });
      }

      elevation.id = `wall-${wall.id}`;
      elevation.containers = containers;
      elevation.lineInRoom = computedWall.lineInRoom;
      elevation.wall = wall;
      elevation.wallId = wall.id;
      elevation.wallIds = _.map(computedWall.walls, 'id');
      elevation.wallSet = computedWall.wallSet;
      elevation.viewKey = 'front';

      var frontContainers = lib.filterEvery(allContainers, {
        sameAngle: container => lib.trig.anglesAreEqual({a1: alpha, a2: Container.getAlpha({container})}),
        nearby: container => Container.getDistance({sourceContainer: container, computedWall, room}) <= 10,
        notCountertop: container => container.type !== 'countertop'
      });

      var frontmostContainerY = _.get(frontmostContainerDataFor({containers: frontContainers, alpha}), 'frontY');

      if (frontmostContainerY) {
        var normalizedLineInRoom = _.mapValues(elevation.lineInRoom, point => lib.trig.rotate({point, byRadians: -alpha}));

        elevation.lineInRoom = _.mapValues(normalizedLineInRoom, point => {
          return lib.trig.rotate({
            point: {x: point.x, y: frontmostContainerY + 7},
            byRadians: alpha
          });
        });

        var sectionContainers = _.filter(allContainers, container => {
          return lib.polygon.polygonsOverlap(Container.getFootprintInRoom({container}), _.values(elevation.lineInRoom));
        });

        elevation.containers.push(...sectionContainers);
      }

      elevation.containers = _.uniq(visibleContainersFor({containers: elevation.containers, alpha, elevation}));

      var hasFrontContainersIfRequired = !onlyFronts.walls || _.filter(containersOnWall, container => lib.trig.anglesAreEqual({a1: alpha, a2: Container.getAlpha({container})}) && container.type !== 'countertop').length > 0;

      var computedWallWidth = lib.trig.distance({fromPoint: computedWall.lineInRoom.from, toPoint: computedWall.lineInRoom.to});

      if (hasFrontContainersIfRequired && !_.every(computedWall.walls, 'isPseudoWall') && computedWallWidth >= 30) {
        elevation.hasFrontContainer = frontContainers.length > 0;

        elevations.push(elevation);
      }
    });

    _.forEach(Room.getContainerGroups({containers: allContainers, computedWalls, walls, wallSets, room}), ({containers}) => {
      //HINT traverse all sides of the island
      var alphas = _.map([0, Math.PI / 2, Math.PI, 3 * Math.PI / 2], alpha => Container.getAlpha({container: containers[0]}) + alpha);

      _.forEach(alphas, alpha => {
        var elevation = {};

        var frontContainers = _.filter(containers, container => {
          return lib.trig.anglesAreEqual({a1: Container.getAlpha({container}), a2: alpha});
        });

        var frontmostContainer = _.get(frontmostContainerDataFor({containers: frontContainers, alpha}), 'container');

        if (!onlyFronts.island || frontContainers.length > 0) {
          elevation.lineInRoom = lib.waterfall(containers, [
            [_.flatMap, container => _.values(Container.getFootprintLines({container}))], //all footprint lines
            [_.map, line => _.mapValues(line, point => lib.trig.rotate({point, byRadians: -alpha}))], //normalize
            [lines => {
              var xs = _.flatMap(lines, line => _.map(line, 'x'));
              var ys = _.flatMap(lines, line => _.map(line, 'y'));
              var y = _.max(ys), x1 = _.min(xs), x2 = _.max(xs);

              if (frontmostContainer) {
                y = lib.trig.rotate({point: Container.getFootprintLines({container: frontmostContainer}).front.from, byRadians: -alpha}).y;
              }

              return {from: {x: x1, y}, to: {x: x2, y}};
            }], //combine normalized lines into 1 line
            [line => _.mapValues(line, point => lib.trig.rotate({point, byRadians: alpha}))] //denormalize
          ]);

          var visibleContainers = visibleContainersFor({containers, alpha, isIsland: true, lineInRoom: elevation.lineInRoom});

          elevation.containers = visibleContainers;
          elevation.wallId = lastWallId + 1;
          elevation.viewKey = 'front';

          //is duplicate if all front containers are represented in another wall elevation as front
          var isDuplicateWallElevation = _.every(frontContainers, container => {
            var isInExistingWallElevation = _.some(elevations, elevation => {
              var inExistingElevation = false;
              var containerInElevation = _.find(elevation.containers, {id: container.id});
              var isWallElevation = _.includes(elevation.id, 'wall');

              if (isWallElevation && containerInElevation) {
                var isFrontFacingInElevation = lib.trig.anglesAreEqual({a1: Container.getAlpha({container}), a2: Wall.getAlpha({wall: elevation.wall, wallSet: elevation.wallSet, room}), mode: 'radians'});

                inExistingElevation = isFrontFacingInElevation;
              }

              return inExistingElevation;
            });

            return container.type === 'countertop' || _.includes([
              'floatingBase', 'floatingShelves', 'opencase', 'wall', 'wallFreestandingAppliance', 'cornerCounterTransition', 'backsplash'
            ], container.type) || isInExistingWallElevation;
          });

          if (!isDuplicateWallElevation) {
            lastWallId += 1;

            elevations.push(elevation);
          }
        }
      });
    });

    var indexOfFrontContainer = _.findIndex(elevations, 'hasFrontContainer');
    var sortedElevations = [];

    if (indexOfFrontContainer !== -1) {
      for (var i = 0; i < elevations.length; i++) {
        sortedElevations.push(elevations[(indexOfFrontContainer + i) % elevations.length]);
      }

      elevations = sortedElevations;
    }

    if (includeSections) {
      var containerSets = Room.getOverlappingContainerSetsOfVaryingDepth({containers: allContainers});

      _.forEach(containerSets, containers => {
        var floorContainer = _.find(containers, container => container.position.y === 0);
        var {wall} = floorContainer;
        var points = Container.getComputedFootprintLines({container: floorContainer}).front.normal;

        if (wall) {
          _.forEach(['right'], viewKey => {
            var elevation = {points};

            elevation.lineInRoom = Container.getComputedFootprintLines({container: floorContainer})[`${viewKey}`].normal;
            elevation.wall = wall;
            elevation.wallId = wall.id;
            elevation.wallIds = [wall.id];
            elevation.frontViewMode = 'section';
            elevation.viewKey = viewKey;
            elevation.containers = containers;

            elevations.push(elevation);
          });
        }
      });
    }

    elevations = lib.waterfall(elevations, [
      [_.map, elevationData => ({room, project, roomId: room.id, projectId: project.id, versionId: project.versionId, ...elevationData})]
    ]);

    elevations.forEach((elevation, e) => {
      elevation.title = Elevation.getTitle({elevation});
    });

    return elevations;
  },

  xzDatumLineAlreadyExists({room, datum}) {
    return _.find(room.xzDatums, (current) => lib.trig.linesAreEqual({l1: datum, l2: current}));
  },

  //room needs to update walls on change
  //then this function can be called

  async updateManagedWalls({room, reduxActions}) {
    var {project, allWalls: oldWalls} = Room.get(['project', 'allWalls'], {room});
    let wallSets = [];
    let updates = [];
    let creations = [];
    let wallIdsToDestroy = [];

    oldWalls = _.keyBy(oldWalls, 'pointId');

    var addWallSet = ({sourceType, source}) => {
      var wallSet = {sourceType, source};

      wallSet.walls = _.map(source.points, (point, index) => {
        var wall = oldWalls[point.id];

        var props = {
          roomId: room.id,
          pointId: point.id,
          isPseudoWall: point.isPseudoPoint ? 1 : 0,
          projectId: project.id,
          versionId: project.versionId
        };

        var line = _.mapValues({from: point, to: lib.array.next(source.points, index)}, ({x, y}) => ({x, y}));

        var {position} = source;

        if (sourceType === 'plan') position = lib.object.difference(position, room.plan.position);

        var w = lib.number.round(lib.math.trig.distance({fromPoint: line.from, toPoint: line.to}), {toNearest: K.minPrecision});
        var h = room.ceilingHeight || K.defaultWallHeight;

        props.outline = {
          width: w,
          height: h,
          points: [
            {x: 0, y: 0}, {x: 0, y: -h}, {x: w, y: -h}, {x: w, y: 0}
          ]
        };

        if (!wall) {
          oldWalls[point.id] = wall = props;
        }
        else {
          if (wall.outline.width !== w) {
            wall = {...wall, ...props};

            updates.push({where: {id: wall.id}, props});
            oldWalls[point.id] = wall;
          }
          else {
            oldWalls[point.id] = wall;
          }
        }

        return wall;
      });

      wallSets.push(wallSet);
    };

    if (room.plan) addWallSet({sourceType: 'plan', source: room.plan}); //TODO pass obstacles?

    var walls = _.flatMap(wallSets, 'walls');

    //clean cache
    _.forEach(oldWalls, oldWall => {
      if (!_.includes(walls, oldWall) && oldWall.id) {
        wallIdsToDestroy.push(oldWall.id);
      }
    });

    //initialize/update wall lines
    _.forEach(walls, wall => {
      if (!wall.id) {
        creations.push({props: wall});
      }
    });

    if (updates.length > 0 || creations.length > 0 || wallIdsToDestroy.length > 0) {
      if (wallIdsToDestroy.length) {
        var archElementsToDestroy = _.flatMap(wallIdsToDestroy, wallId => _.values(Wall.get('archElements', {wall: {id: wallId}})));

        reduxActions.destroyArchElements({ids: _.map(archElementsToDestroy, 'id')});
      }

      reduxActions.modifyWalls({
        creations,
        updates,
        destructions: _.uniq(wallIdsToDestroy) || []
      });
    }

    return walls;
  },

  getWallSets({room, state}) {
    var wallSets = [];
    if (!state) state = store.getState();

    var wallsByPointId = _.keyBy(state.resources.walls.byFieldKeyIndex.roomId[room.id], 'pointId');
    // var wallObstacles = _.filter(this.obstacles, {type: 'wall'});

    var addWallSet = ({sourceType, source}) => {
      wallSets.push({sourceType, source, walls: _.filter(_.map(source.points, point => wallsByPointId[point.id]))});
    };

    if (room.plan && wallsByPointId) addWallSet({sourceType: 'plan', source: room.plan}); //TODO pass obstacles?

    // _.forEach(wallObstacles, wallObstacle => {
    //   addWallSet({sourceType: 'obstacle', source: wallObstacle.editableProps});
    // });
    return wallSets;
  },

  getWidth({room}) {
    return Room.getSize({room}).width;
  },

  getHeight({room}) {
    return Room.getSize({room}).height;
  },

  combineUpdatesMapWithCountertopUpdates: ({room, excludedContainers, reduxActions, updatesMap}) => {
    let countertopApiData = Room.updateManagedCountertops({room, reduxActions, excludedContainers, isBatched: true});

    _.forEach(['creations', 'updates', 'deletedIds'], key => {
      updatesMap.containers[key].push(...countertopApiData[key]);
    });

    updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, countertopApiData.otherUpdates);

    return updatesMap;
  },

  //HINT changing 1 container requires recalcing for whole room
  //HINT bc 1 container can change groups, IE turn 1 group to 2, or 2 groups to 1
  updateManagedCountertops: ({room, reduxActions, excludedContainers = [], isBatched = false}) => {
    const project = Room.get('project', {room});
    let deletedIds = [];
    let updates = [];
    let creations = [];
    let otherUpdates = {
      products: {creations: [], deletedIds: [], updates: []},
      productOptions: {creations: [], deletedIds: [], updates: []}
    };

    //WARNING don't mess with old projects
    if (project.id > 3414 || _.includes([1246], project.id)) {
      let {project, containers, computedWalls, walls, wallSets, scopes} = Room.get(['project', 'containers', 'computedWalls', 'walls', 'wallSets', 'scopes'], {room});

      if (excludedContainers.length > 0) {
        containers = _.reject(containers, container => _.includes(_.map(excludedContainers, 'id'), container.id));
      }

      containers = _.filter(containers, container => container.position && !_.isEqual(container.position, {}));

      let containerGroups = Room.getContainerGroups({containers, computedWalls, walls, wallSets, room});

      _.forEach(containerGroups, containerGroup => {
        if (containerGroup && containerGroup.countertopManagingContainerId) {
          var actionKey = '';
          var container = _.find(containers, {id: containerGroup.countertopManagingContainerId});
          var existingManagedCountertops = Container.getManagedCountertopContainers({container, room});

          if (container && containerGroup) {
            var manualModeCtops = _.filter(existingManagedCountertops, managedCountertop => _.get(managedCountertop, 'customData.inManualMode'));
            var hasManualModeCtops = manualModeCtops.length > 0;

            containerGroup = {...containerGroup, containers: _.filter(containerGroup.containers, c1 => {
              return !(_.get(c1, 'customData.managedCountertopsEnabled') === 0 || _.some(manualModeCtops, managedCountertop => {
                return _.includes(_.get(managedCountertop, 'customData.containerIds'), c1.id);
              }));
            })};

            var reusedCountertopIds = [];

            var countertopsData = Container.getCountertopDataFor({container, containerGroup, room});

            //HINT countertops for a container group are all managed by the same primary container
            _.forEach(countertopsData, (countertopData, index) => {
              //HINT try to find a countertop with the same data
              //Dont reuse existingManagedCountertops if any ctops are in manual mode
              //HINT was previously just updating the existing ctops info to prevent creating many instances
              //HINT this sometimes messed up ctops in manual mode
              var existingManagedCountertop = _.find(existingManagedCountertops, ctop => {
                var representingSameContainers = _.isEqual(_.map(countertopData.containers, 'id'), _.get(ctop, 'customData.containerIds'));
                var samePositionAndDimensions = _.isEqual(_.pick(countertopData, ['dimensions', 'position']), _.pick(ctop, ['dimensions', 'position']));

                return !_.includes(reusedCountertopIds, ctop.id) && (representingSameContainers || samePositionAndDimensions);
              }) || (!hasManualModeCtops && !_.includes(reusedCountertopIds, _.get(existingManagedCountertops, `${index}.id`)) && _.get(existingManagedCountertops, index));

              //HINT still want to enter this if if there is no existingManagedCountertop
              if (!_.get(existingManagedCountertop, 'customData.inManualMode')) {
                var shouldExist = Container.getSupportsCountertop({container}) && containerGroup;

                if (shouldExist) {
                  actionKey = existingManagedCountertop ? 'update' : 'create';
                }

                if (actionKey === 'create') {
                  var roomCountertops = _.filter(Room.get('containers', {room}), {type: 'countertop'});

                  //HINT default is 0.5, prefer to use a value someone has manually set
                  var defaultHeight = _.find(_.map(roomCountertops, 'dimensions.height'), ctopHeight => {
                    return ctopHeight !== 0.5;
                  }) || 0.5;

                  var defaultByOthers = _.some(roomCountertops, ctop => {
                    return _.get(ctop, 'customData.isByOthers');
                  }) ? 1 : 0;

                  var details = {};

                  var defaultCountertopMaterial = _.get(_.find(roomCountertops, ctop => {
                    return !_.get(ctop, 'customData.isByOthers');
                  }), 'details.countertopMaterial');

                  if (defaultCountertopMaterial) details.countertopMaterial = defaultCountertopMaterial;

                  countertopData.dimensions.height = defaultHeight;
                  let props = {
                    type: 'countertop',
                    position: countertopData.position,
                    rotation: countertopData.rotation,
                    dimensions: countertopData.dimensions,
                    projectId: container.projectId,
                    versionId: container.versionId,
                    details,
                    customData: {
                      wrap: {overhang: 1 / 8, boldStyle: {left: 'bold', right: 'bold'}},
                      managingContainerId: container.id,
                      inManualMode: 0,
                      labelPosition: 'top',
                      isByOthers: defaultByOthers,
                      sinks: {},
                      containerIds: _.map(countertopData.containers, 'id')
                    }
                  };

                  props.scopeId = Container.getScopeId({scopes, container: props});

                  details = DetailsHelper.getCleanedOwnedDetailsFor({container: props});
                  props = {...props, details};

                  creations.push({props});
                }
                else if (actionKey === 'update') {
                  reusedCountertopIds.push(existingManagedCountertop.id);

                  var dataChanged = _.map(countertopData.containers, 'id') !== _.get(existingManagedCountertop, 'customData.containerIds') || !_.isEqual(_.pick(countertopData, ['dimensions', 'position']), _.pick(existingManagedCountertop, ['dimensions', 'position']));
                  var updatedProps = {};

                  if (dataChanged) {
                    countertopData.dimensions.height = _.get(existingManagedCountertop, 'dimensions.height', 0.5);

                    updatedProps = {
                      rotation: countertopData.rotation,
                      position: countertopData.position,
                      dimensions: countertopData.dimensions,
                      customData: {
                        ...existingManagedCountertop.customData,
                        containerIds: _.map(countertopData.containers, 'id')
                      },
                    };

                    updatedProps.scopeId = Container.getScopeId({scopes, container: {...existingManagedCountertop, ...updatedProps}});

                    //WARNING this is to manage countertops created before april 9th, 2020, but after automatic ctops went live
                    if (!existingManagedCountertop.customData.sinks) updatedProps.customData.sinks = {};
                  }

                  if (dataChanged) {
                    //WARNING important that this comes last
                    updates.push({where: {id: existingManagedCountertop.id}, props: updatedProps});
                  }
                }
              }
            });

            _.forEach(existingManagedCountertops, (existingManagedCountertop, index) => {
              if (!_.includes(reusedCountertopIds, existingManagedCountertop.id) && !_.get(existingManagedCountertop, 'customData.inManualMode')) {
                deletedIds.push(existingManagedCountertop.id);
              }
            });
          }
        }
      });

      //HINT delete countertops in groups that no longer exist
      _.forEach(_.filter(containers, {type: 'countertop'}), countertop => {
        if (!countertop.customData.inManualMode && !_.includes(_.map(containerGroups, 'countertopManagingContainerId'), countertop.customData.managingContainerId)) {
          deletedIds.push(countertop.id);
        }
      });

      if (updates.length || creations.length || deletedIds.length) {
        if (deletedIds.length) {
          _.forEach(_.filter(containers, container => _.includes(deletedIds, container.id)), deletedCountertop => {
            let {managedUpdatesMap, containerCacheUpdate} = Container.updateManagedResources({container: deletedCountertop, actionKey: 'destroy', reduxActions, isBatched, containerBatched: true});

            if (containerCacheUpdate) updates.push({where: {id: deletedCountertop.id}, props: {customData: {...deletedCountertop.customData, cachedManagedData: containerCacheUpdate}}});

            if (isBatched) {
              _.forEach(managedUpdatesMap, (updatesData, resourceKey) => {
                _.forEach(updatesData, (updateData = [], updateKey) => {
                  otherUpdates[resourceKey][updateKey] = [...otherUpdates[resourceKey][updateKey], ...updateData];
                });
              });
            }
          });
        }

        if (updates.length) {
          if (isBatched) {
            _.forEach(updates, ({where, props}) => {
              //HINT if there is no scope Id, the ctop is being deleted and we don't need to update scopeId on the products
              if (props.scopeId) {
                var countertop = _.find(containers, container => container.id === where.id);
                var countertopManagedProducts = Container.get('managedProductInstances', {container: countertop});

                _.forEach(countertopManagedProducts, managedProduct => {
                  otherUpdates.products.updates.push({where: {id: managedProduct.id}, props: {scopeId: props.scopeId}});
                });
              }
            });
          }
          else {
            reduxActions.modifyContainers({updates});
          }
        }

        if (!isBatched) {
          reduxActions.modifyContainers({
            creations,
            updates,
            destructions: _.uniq(deletedIds) || []
          });
        }
      }
    }

    return {creations, updates, deletedIds, otherUpdates};
  },

  getAlignmentIndicatorLines({room}) {
    var alignmentLinesX = [];
    var alignmentLinesY = [];
    var alignmentIndicators = [];

    var {containers, archElements} = Room.get(['containers', 'archElements'], {room});
    // in alignmentCandidates dont push walls instead push this.canvasObjects.plan.vertices
    //collection of containers, obstacles, products, and walls
    var alignmentCandidates = [..._.map(_.values(containers), container => ({candidate: container, type: 'container'})), ..._.map(_.values(archElements), archElement => ({candidate: archElement, type: 'archElement'})), {candidate: _.cloneDeep(room.plan), type: 'room'}];

    var alignmentVertices = _.map(alignmentCandidates, ({candidate, type}) => {
      var vertices;
      var rotation = candidate.rotation;
      if (type === 'container') {
        vertices = Container.getFootprintInRoom({container: candidate});
      }
      else if (type === 'archElement') {
        var {wall, wallSets} = ArchElement.get(['wall', 'wallSets'], {archElement: candidate},);
        var positionInRoom = ArchElement.getPositionInRoom({archElement: candidate, wall, wallSets});

        positionInRoom = lib.object.sum(positionInRoom, room.plan.position);
        var size = ArchElement.getSize({archElement: candidate, viewKey: 'top'});

        vertices = [
          positionInRoom,
          lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: size.width, y: 0}, byDegrees: rotation})),
          lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: size.width, y: size.height}, byDegrees: rotation})),
          lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: 0, y: size.height}, byDegrees: rotation})),
        ];
      }
      else if (type === 'room') {
        vertices = candidate.points;
      }

      var xs = _.map(vertices, 'x');
      var ys = _.map(vertices, 'y');

      var centerX = (_.min(xs) + _.max(xs)) / 2;
      var centerY = (_.min(ys) + _.max(ys)) / 2;

      var isRotationIncluded = _.includes([90, 270], rotation);

      if (isRotationIncluded) {
        vertices.push({x: _.minBy(vertices, 'x').x, y: centerY, isCenterY: true},
          {x: _.maxBy(vertices, 'x').x, y: centerY, isCenterY: true});
      } else {
        vertices.push({x: centerX, y: _.minBy(vertices, 'y').y, isCenterX: true},
          {x: centerX, y: _.maxBy(vertices, 'y').y, isCenterX: true});
      }

      return {vertices, candidate};
    });

    //for Xs
    _.forEach(alignmentVertices, (alignmentVertice) => {
      if (alignmentVertice) {
        var { vertices, candidate } = alignmentVertice;
        var isPlan = _.isEqual(candidate, room.plan);

        if (!(isPlan || candidate.type === 'countertop')) {
          _.forEach(vertices, vertex => {
            _.forEach(alignmentVertices, ({vertices: verticesOne, candidate: candidateOne}) => {
              if (!_.isEqual(candidate, candidateOne)) {
                var parentAndChildrenIds = _.map([candidate.container, ...(candidate.overlappedCountertops || []), ...(candidate.allChildren || []), ...(candidate.products || [])], 'id');

                if (!_.includes(parentAndChildrenIds, candidateOne.id)) {
                  _.forEach(verticesOne, vertexOne => {
                    if (!(vertex.isCenterY || vertexOne.isCenterY) && vertex.x === vertexOne.x && vertex.y !== vertexOne.y) {
                      alignmentLinesX.push({
                        from: {...vertex},
                        to: {...vertexOne}
                      });
                    }

                    if (!(vertex.isCenterX || vertexOne.isCenterX) && vertex.y === vertexOne.y && vertex.x !== vertexOne.x) {
                      alignmentLinesY.push({
                        from: {...vertex},
                        to: {...vertexOne}
                      });
                    }
                  });
                }
              }
            });
          });

          _.remove(alignmentVertices, ({candidate: c1}) => _.isEqual(c1, candidate));
        }
      }
    });

    alignmentIndicators = _.map(_.uniqBy(alignmentLinesX, line => JSON.stringify(line)), line => {
      var shouldSwapFromTo = false;

      var lineAlpha = lib.trig.alpha({p1: line.from, p2: line.to});

      if (lineAlpha === -Math.PI / 2) {
        shouldSwapFromTo = true;
      }

      if (shouldSwapFromTo) {
        var from = {...line.from};

        line.from = line.to;
        line.to = from;
      }

      return {
        from: {x: 0, y: 0},
        to: {x: 0, y: line.to.y - line.from.y},
        origin: {x: 'left', y: 'top'},
        offset: line.from,
        stroke: 'red',
        strokeWidth: 1,
      };
    });

    alignmentIndicators = [
      ...alignmentIndicators,
      ..._.map(_.uniqBy(alignmentLinesY, line => JSON.stringify(line)), line => {
        var shouldSwapFromTo = false;

        var lineAlpha = lib.trig.alpha({p1: line.from, p2: line.to});

        if (lineAlpha === Math.PI) {
          shouldSwapFromTo = true;
        }

        if (shouldSwapFromTo) {
          var from = {...line.from};

          line.from = line.to;
          line.to = from;
        }

        return {
          from: {x: 0, y: 0},
          to: {x: line.to.x - line.from.x, y: 0},
          origin: {x: 'left', y: 'top'},
          offset: line.from,
          stroke: 'red',
          strokeWidth: 1,
        };})
    ];

    return alignmentIndicators;
  },
  updateManagedResourcesCalls: [],

  //HINT
  //this function updates managed data for an entire room
  //it recalculates countertops
  //updates container managed resources
  //updates managed resources for the managed container resources
  //updates elevations
  //updates production ids
  //updates project level parts (trim)

  //HINT this function is implemented as an asyncronous queue
  //you can spam call it (though I wouldn't recommend it)
  //and the calls are debounced by 500ms (will only trigger after 500 ms of no calls)
  //and then further calls made while the function is running are pushed to the queue
  //and are called after the current call is finished
  updateManagedResources: ({room, reduxActions, updateIssues = true, updateUnitNumbers = true, updateContainerResources = true}) => {
    //HINT this variable is true as long as we are updating managed resources
    //from beginning the debounce, to finishing the last call
    //important for not rendering countertops while they are being updated (prevents jumping)
    Room.waitingForUpdateManagedResources = true;

    var debouncedUpdateCall = _.debounce(async ({room, reduxActions, updateIssues, updateUnitNumbers, updateContainerResources}) => {
      var makeUpdates = async () => {
        Room.currentlyUpdatingManagedResources = true;

        var state = store.getState().resources;

        var project = state.projects.byId[room.projectId];
        var updatesMap = {
          productOptions: {creations: [], updates: [], deletedIds: [], tracks: []},
          products: {creations: [], updates: [], deletedIds: [], tracks: []},
          containers: {creations: [], updates: [], deletedIds: [], tracks: []},
          elevations: {creations: [], updates: [], deletedIds: [], tracks: []},
        };

        if (updateContainerResources) {
          var {state, updatesMap} = await ManagedDataHelper.syncManagedCountertopContainers({room, state, updatesMap});
          var {state, updatesMap} = await ManagedDataHelper.syncContainerManagedProducts({room, state, updatesMap});
          var {state, updatesMap} = await ManagedDataHelper.syncProductManagedProducts({room, state, updatesMap});

          // <autogenerate elevations
          var elevationUpdates = Project.autogenerateElevations({project, roomId: room.id, isBatched: true});

          //request to autogenerate elevations
          if (elevationUpdates) {
            updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, elevationUpdates || {});

            await ManagedDataHelper.applyBatchUpdates({updatesMap: elevationUpdates, state});
          }
          // autogenerate elevations/>

          // <production ids
          var productionIdUpdates = updateProductionIds({project, isBatched: true});

          //request to update production ids
          if (productionIdUpdates) {
            updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, productionIdUpdates || {});

            await ManagedDataHelper.applyBatchUpdates({updatesMap: productionIdUpdates, state});
          }
          // production ids/>

          UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions, hitApi: false});

          //HINT should happen after all other updates are done, just modifies part quantities, not important for rendering
          Project.updateManagedParts({project, reduxActions});
        }

        //HINT if updating container resources, already updating production ids
        if (updateUnitNumbers && !updateContainerResources) updateProductionIds({project, reduxActions});

        if (updateIssues) {
          setTimeout(setIssues({reduxActions, roomId: room.id, project}));
        }

        Room.currentlyUpdatingManagedResources = false;

        if (Room.updateManagedResourcesCalls.length > 0) {
          var nextCall = Room.updateManagedResourcesCalls.shift();

          await nextCall();
        }
        else {
          Room.waitingForUpdateManagedResources = false;
        }
      }

      if (Room.currentlyUpdatingManagedResources) {
        Room.updateManagedResourcesCalls.push(makeUpdates);
      }
      else {
        await makeUpdates();
      }
    }, 500);

    debouncedUpdateCall({room, reduxActions, updateIssues, updateUnitNumbers, updateContainerResources});
  }
};

export default Room;
