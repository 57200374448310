import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import K from 'k';

const getInvalidConfiguration = ({
  products
}) => {
  var issues = [];

  _.forEach(products, (product) => {
    var isInvalid = false;
    var message = '';
    var resourceId = product.id;

    const {siblings, companyKey, parentProduct} = Product.get(['siblings', 'companyKey', 'parentProduct'], {product});
    const type = Product.getType({product});

    var issueId = '';

    if (Product.getIsOpencasePanel({product})) {
      isInvalid = product.dimensions.width > 47 && product.dimensions.height > 47;

      if (isInvalid) {
        message = `${product.productionId || Product.get('productType', {product}).title} - opencase panel dimension over 47"`;
        issueId = `product-${product.id}-invalid-opencase-dims`
      }
    }
    //HINT st shelfbank frame can't be over 47 in either direction
    if (_.includes([1563], product.productId)) {
      isInvalid = product.dimensions.width > 47 && product.dimensions.height > 47;

      if (isInvalid) {
        message = `${product.productionId || Product.get('productType', {product}).title} - shelfbank frame dimension over 47"`;
        issueId = `product-${product.id}-invalid-shelfbank-dims`
      }
    }
    else if (_.includes([1586], product.productId)) {
      var bayWidths = Product.getBayWidths({product});
      //HINT bays must be 21-42" wide
      var invalidBayWidths = _.some(bayWidths, bayWidth => bayWidth > 42 || bayWidth < 21);

      isInvalid = invalidBayWidths;

      if (isInvalid) {
        message = `${product.productionId || Product.get('productType', {product}).title} - appliance bays must be 21-42" wide`;
        issueId = `product-${product.id}-invalid-bay-widths`
      }
    }

    if (isInvalid) {
      issues.push({
        id: issueId,
        message,
        resourceKey: 'product',
        resourceId,
        type: 'general-issues',
        level: 1
      })
    }
  });

  return issues;
};

export default getInvalidConfiguration;
