import React, { useContext } from 'react';
import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import ArchElement from 'project-helpers/arch-element';
import Wall from 'project-helpers/wall';
import Elevation from 'project-helpers/elevation';

import { CanvasScriptObject, CanvasDataContext, CanvasSelectionContext, CanvasErrorFallback } from 'canvas';

import {withErrorBoundary} from 'react-error-boundary';

import CanvasArchElementHelper from 'project-component-helpers/canvas-arch-element-helper';
import { resourceActions, connect, issuesDataActions } from 'redux/index.js';
import ProjectDataContext from 'contexts/project-data-context';

class CanvasArchElement extends React.PureComponent {
  state = {
    isTransforming: false
  };

  handleSelect = () => {
    const {archElement, selectionData, canvasData, isSelected} = this.props;
    const {setActiveEntities} = selectionData;
    const {isShifting} = canvasData;

    if (isShifting) {
      setActiveEntities({entities: [{resourceKey: 'archElement', id: archElement.id}], isMultiSelect: true});
    }
    else if (!isSelected) {
      setActiveEntities({entities: [{resourceKey: 'archElement', id: archElement.id}], isMultiSelect: false});
    }
  };

  handleDelete = () => {
    const {archElement, projectData, selectionData} = this.props;

    selectionData.onDeselect();

    ArchElement.destroy({archElement, reduxActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});
  };

  getUpdatedPropsForTransformerProps = ({transformerProps, roundToMinPrecision = false}) => {
    var {archElement, viewKey, elevation, wall, wallSets, room, typeData, viewOffset} = this.props;
    let newProps = {};

    //HINT renormalize
    if (viewKey === 'front') {
      let updatedSize = transformerProps.size;

      if (roundToMinPrecision) updatedSize = _.mapValues(updatedSize, value => lib.round(value, {toNearest: K.minPrecision}));

      if (_.includes(['opening', 'window', 'door'], archElement.type)) {
        newProps.customData = _.cloneDeep(archElement.customData);

        newProps.customData.openingWidth = updatedSize.width - (archElement.customData.leftTrimWidth || 0) - (archElement.customData.rightTrimWidth || 0);
        newProps.customData.openingHeight = updatedSize.height - (archElement.customData.topTrimHeight || 0) - (archElement.customData.bottomTrimHeight || 0);
      }
      else if (_.includes(['outlet'], archElement.type)) {
        newProps.customData = {...archElement.customData, ...updatedSize};
      }
      else if (_.includes(['soffit'], archElement.type)) {
        newProps.customData = {
          ...archElement.customData,
          dimensions: {...archElement.customData.dimensions, ...updatedSize}
        };
      }

      const wallPosition = lib.object.sum(viewOffset, {x: Wall.getXOffsetInElevation({wall, elevation})});

      transformerProps.position = lib.object.difference(transformerProps.position, wallPosition, {y: -updatedSize.height});

      if (roundToMinPrecision) transformerProps.position = _.mapValues(transformerProps.position, value => lib.round(value, {toNearest: K.minPrecision}));

      transformerProps.position.y = -transformerProps.position.y;

      //update wall id and canvas position x/parent based on position
      var oldWall = wall;
      var oldWallX = Wall.getXOffsetInElevation({wall: oldWall, elevation});
      var newWall = Elevation.getWallFor({elevation, x: transformerProps.position.x + Wall.getXOffsetInElevation({wall: oldWall, elevation})});

      if (newWall !== oldWall) {
        var newWallX = Wall.getXOffsetInElevation({wall: newWall, elevation});
        var wallXDelta = newWallX - oldWallX;

        newProps.wallId = newWall.id;
        transformerProps.position.x -= wallXDelta;

        wall = newWall;
      }
    }
    else {
      if (typeData.classification === 'hybrid') {
        transformerProps.position = lib.object.difference(transformerProps.position, lib.object.sum(room.plan.position, viewOffset));

        if (transformerProps.mousePosition) transformerProps.mousePosition = lib.object.difference(transformerProps.mousePosition, lib.object.sum(room.plan.position, viewOffset));

        var nearestWallData = ArchElement.getNearestWallData({position2d: transformerProps.mousePosition ? transformerProps.mousePosition : transformerProps.position, archElement, mousePosition: transformerProps.mousePosition});

        newProps.wallId = nearestWallData.nearestWallId;

        var updatedWidth = ArchElement.getSize({archElement, viewKey: 'front'}).width;//lib.round(transformerProps.size.height, {toNearest: 1/8});

        // newProps.customData = {
        //   ...archElement.customData, ...(newProps.customData || {}),
        //   openingWidth: updatedWidth - archElement.customData.leftTrimWidth - archElement.customData.rightTrimWidth,
        // }

        nearestWallData.xOnWall = nearestWallData.xOnWall - updatedWidth;

        transformerProps.position = {x: nearestWallData.xOnWall, y: archElement.position.y || ArchElement.getTypeData({archElement}).defaultY || 0};

        if (archElement.type === 'door') {
          newProps.customData = {...archElement.customData, ...(newProps.customData || {}), opens: nearestWallData.opens};
        }
        //TODO fix bug with sizing
        // else if (_.includes(['soffit'], archElement.type)) {
        //   let roundedSize = _.mapValues(updatedProps.size, value => lib.round(value, {toNearest: 1/32}));

        //   newProps.customData = {
        //     ...archElement.customData,
        //     dimensions: {...archElement.customData.dimensions, width: roundedSize.width, depth: roundedSize.height}
        //   };
        // }
        // else if (_.includes(['pillar'], archElement.type)) {
        //   let roundedSize = _.mapValues(updatedProps.size, value => lib.round(value, {toNearest: 1/32}));

        //   newProps.customData = {
        //     ...archElement.customData,
        //     width: roundedSize.width, depth: roundedSize.height
        //   };
        // }
      }
      else {
        const size = ArchElement.getSize({archElement, viewKey: 'front'});

        transformerProps.position = lib.object.difference(transformerProps.position, room.plan.position, viewOffset, {y: -size.height});
      }

    }

    if (ArchElement.getTypeData({archElement}).snapToFloor && typeData.classification !== 'floor') {
      var y = 0;

      var xRange = {from: transformerProps.position.x, to: transformerProps.position.x + ArchElement.getSize({archElement, viewKey: 'front'}).width};
      var {y} = Wall.getNearestFloorData({xRange, elevation, wall});

      transformerProps.position.y = y;
    }

    newProps = {
      ...newProps,
      position: transformerProps.position
    };

    return newProps;
  };

  handleTransform = (transformerProps) => {
    if (!this.state.isTransforming) {
      this.setState({cachedArchElement: this.props.archElement, isTransforming: true});
    }

    const {archElement} = this.props;

    const updatedProps = this.getUpdatedPropsForTransformerProps({transformerProps});

    this.props.updateArchElement({id: archElement.id, props: updatedProps, hitApi: false});
  };

  handleTransformEnd = (transformerProps) => {
    var cachedArchElement;
    let {archElement, projectData} = this.props;

    if (transformerProps) {
      cachedArchElement = _.cloneDeep(archElement);

      let updatedProps = this.getUpdatedPropsForTransformerProps({transformerProps, roundToMinPrecision: true});

      archElement = {...archElement, ...updatedProps};
    }
    else {
      cachedArchElement = this.state.cachedArchElement;
    }

    ArchElement.update({cachedArchElement, archElement, props: archElement, reduxActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});

    this.setState({isTransforming: false, cachedArchElement: undefined});
  };

  render() {
    const {archElement, elevation, viewKey, realPosition, dragRotation, viewOffset, canvasData, room, wall, wallSets, typeData, activeDetailLevel, isSelected, renderForDrawings, preventEditing} = this.props;
    if (!archElement) return null;
    const snapToLines = _.map(ArchElement.getSnapToLines({archElement, elevation, room, viewKey}), line => _.mapValues(line, value => lib.object.sum(value, viewOffset)));
    const {snapToFloor} = ArchElement.getTypeData({archElement});

    let disabledAnchors = [];

    let position, size, sideKey, transformerOffset, rotation = 0;

    if (realPosition) position = realPosition;

    if (viewKey === 'top') {
      sideKey = 'top';
      size = ArchElement.getSize({archElement, viewKey: 'top'});

      if (realPosition) {
        rotation = dragRotation;
      }
      else {
        position = lib.object.sum(room.plan.position, viewOffset, ArchElement.getPositionInRoom({archElement, wall, wallSets}));

        if (typeData.classification === 'hybrid') {
          rotation = ArchElement.getRotationInRoom({archElement, room, wall, wallSets});

          let offsetAmount = -size.width + _.get(archElement, 'customData.trimDepth', 1.5);
          if (archElement.type === 'door' && archElement.customData.opens === 'in') offsetAmount = 0;

          transformerOffset = lib.trig.rotate({point: {x: offsetAmount, y: 0}, byDegrees: rotation});
        }
      }
    }
    else {
      size = ArchElement.getSize({archElement, viewKey: 'front'});

      //TODO determine sideKey
      sideKey = 'front';

      if (snapToFloor) disabledAnchors = ['bottom-middle'];

      if (!realPosition) {
        var wallPosition = lib.object.sum(viewOffset, {x: Wall.getXOffsetInElevation({wall, elevation})});

        position = lib.object.sum(wallPosition, {x: archElement.position.x, y: -archElement.position.y - size.height});
      }
    }

    const script = ArchElement.getScript({archElement, sideKey});

    return (
      <CanvasScriptObject
        {..._.pick(this.props, ['canvasDeps'])}
        script={this.script}
        locked={archElement.customData.isLocked}
        isDisabled={canvasData.isStatic || renderForDrawings || preventEditing}
        onSelect={this.handleSelect}
        onTransform={this.handleTransform}
        onTransformEnd={this.handleTransformEnd}
        onDelete={this.handleDelete}
        isRotatable={false}
        includeOriginalPosition={true}//viewKey === 'top' && archElement.type === 'door'} //HINT used for chosing opening direction
        metaProps={
          {props: {...archElement}, ...CanvasArchElementHelper.getMetaProps({archElement, elevation, viewKey, sideKey, activeDetailLevel})}
        }
        {...{position, size, rotation, script, isSelected, viewKey, snapToLines, disabledAnchors, transformerOffset, renderForDrawings}}
        {...{...((_.includes(['schematic'], activeDetailLevel) || renderForDrawings) ? {stroke: '#999999', opacity: 0.5} : {})}}
        {...((activeDetailLevel === 'rendering' && !renderForDrawings) ? {stroke: '#BBBBBB'} : {})}
      />
    );
  }
}

function CanvasArchElementWithContext(props) {
  const canvasData = useContext(CanvasDataContext);
  const selectionData = useContext(CanvasSelectionContext);
  const projectData = useContext(ProjectDataContext);

  var isSelected = props.archElement && _.size(selectionData) ? selectionData.getIsActiveEntity({id: props.archElement.id, resourceKey: 'archElement'}) : false;

  return <CanvasArchElement {...props} {...{isSelected, canvasData: _.pick(canvasData, ['isStatic', 'isShifting']), selectionData, projectData}}/>;
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    var {archElement} = ownProps;
    var props = {};

    if (ownProps.id) {
      archElement = props.archElement = state.resources.archElements.byId[ownProps.id];
    }

    if (archElement) props = {...props, ...ArchElement.get(['wall', 'room', 'wallSets', 'typeData'], {archElement})};

    return props;
  },
  mapDispatch: {
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'updateArchElement', 'destroyArchElement']),
    ..._.pick(issuesDataActions, ['setIssuesData']),

  }
})(CanvasArchElementWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasArchElement'})
});
