import { connect, store } from 'redux/index.js';

import Project from 'project-helpers/project';
import Room from 'project-helpers/room';

import getProductDepthInconsistancy from './get-product-depth-inconsistancy-issues';
import getMissingProductOptions from './get-missing-product-options-issues';
import getCustomDimsUnsnapped from './get-custom-dims-unsnapped-issues';
import getOverlappingContainers from './get-overlapping-containers-issues';
import getPullMaterialIncompatibility from './get-pull-material-incompatibility-issues';
import getInvalidProductConfiguration from './get-invalid-configuration';
import getOverlappingProducts from './get-overlapping-products-issues';

const setIssues = ({project, reduxActions, roomId,
}) => {
    if (!reduxActions.setIssuesData) return;

    var resourcesState = store.getState().resources;

    var getRoomIssues = ({roomId}) => {
      var room = resourcesState.rooms.byId[roomId];

      var products = Room.get('products', {room});
      var containers = Room.get('containers', {room});
      // var customDimensions = customDimensions.push(..._.get(project, `dimensionsData.${global.activeDimensionsLayer}.customDimensionsById`, {}));
      products = _.sortBy(products, [instance => instance.productionId ? 0 : 1, instance => _.get(instance, 'productionId[0]'), instance => instance.productionId ? _.toNumber(instance.productionId.substring(1)) : 0]);
      var issues = [
        //level 1 - things that can't be ignored -> pull compatibility issues, overlapping units
        ...getProductDepthInconsistancy({products, project}),
        ...getOverlappingContainers({containers, project}),
        ...getPullMaterialIncompatibility({products, project}),
        ...getOverlappingProducts({products, project}),
        ...getInvalidProductConfiguration({products, project}),

        //level 2 - minor issues like missing a material/pull assignment. things we want to flag, but that are somewhat ignoreable
        ...getMissingProductOptions({products, project}),

        //level 3  suggestions/non-issues, future improvements like tips n tricks or suggesting adding of a trash bin


        // ...getCustomDimsUnsnapped({customDimensions, containers, products}),


        // custom dim isn't snapped
        // perpendicular specific?

        // pull issues
        // leaf door swing
        // pull depth + position (calculate from ground - on door) + pull orientation / location
        // assume 90deg position to check if it's touching another drawer or pull
        // check adjacent 90deg containers pull to drawers
        // check pull standards for depth (i-depth: 1/4" depth)
        // check models for pull logic + locations where position gets overridden

        // liner boxes
        // check if box is visible (if no end panels or trapped by architecture on sides)

        // incorrect grainflow
        // more info***

        // leaf door front sizing
        // doors can't be more than 5" wider than they are tall

        // frame and panel rules
        // prevent them in the first place
        // remove during swap product
        // can't have frame and panel on pull outs or with back of door rack

        // door storage option rules
        // dimensional rules and product type (wardrobe + kitchen)
        // more info***

        // notched woodblock + bent pull rules
        // notched when on leaf door + horizontal direction + on hinge side

        // pull material compatibility
        // *check material options index for all conditions*
        // aluminum on black MDF no darkened brass pull options, only face mounted-options and d-pull only (mortised)
        // wrapped stainless no darkened brass pull options, only face mounted-options
        // backwards enforement of choosing front material after pull material

        // metal and painted panels near architecture
        // 1/4" reveal on ceiling and floor
        // support panel (notched support or hidden end panel) OR cabinet (non appliance unless cabinetted (stacked/ oven)) needed next to all metal or painted panels (1/4" end panel or painted material panel (p2k pigmented options))
      ];

      issues = _.map(issues, issue => {
        return {
          ...issue,
          roomId,
          isResolved: _.includes(room.customData.resolvedIssueIds, issue.id)
        };
      });

      return issues;
    }

    var issuesData = {};
    var roomIssuesData = [];

    if (roomId) {
      roomIssuesData = getRoomIssues({roomId})
    }
    else if (project) {
      _.forEach(Project.get('rooms', {project}), room => {
        issuesData[room.id] = getRoomIssues({roomId: room.id});
      });
    }


    reduxActions.setIssuesData({
      ...(roomId ? {roomIssuesData, roomId} : {issuesData}),
    });
  }

  export default setIssues;