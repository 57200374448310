import React from 'react';
import { resourceActions, connect } from 'redux/index.js';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import getMaterialOptionsForArchetypeParameter from 'helpers/get-material-options-for-archetype-parameter';
import applyArchetypeParameterSelection from 'helpers/apply-archetype-parameter-selection';

import ParameterInstances from './parameter-instances';
import AppliedOptions from './applied-options';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import upArrowIcon from '../../../../assets/up-arrow-black.png';
import xIconBlack from '../../../../assets/x-icon-black.png';
import createIcon from '../../../../assets/create-icon.png';

import { pluralize } from 'inflection';

import TextInput from 'components/text-input';
import {ConnectedPropertyField} from 'components/property-field';

class Parameter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHoveringParameterTitle: false,
      hoveredOptionIndex: -1,
      expandedPropertyFieldType: '',
      defaultExpandedPropertyFieldType: '',
      manuallyConstrainedMaterials: false,
      manuallyConstrainedPulls: false,
    };
  }

  componentDidMount() {
    var compatiblePullIds = _.get(this.props.parameter, 'compatiblePullIds') || [];
    var compatibleMaterialIds = _.get(this.props.parameter, 'compatibleMaterialIds') || [];

    if (compatiblePullIds.length > 0) this.setState({manuallyConstrainedPulls: true});
    if (compatibleMaterialIds.length > 0) this.setState({manuallyConstrainedMaterials: true});
  }

  handleDestroyParameter({parameter}) {
    var parameterIndex = _.indexOf(this.props.archetype.parameters, parameter);
    this.props.handleUpdateExpandedParameter({parameter: undefined})

    var updatedParameters = _.cloneDeep(this.props.archetype.parameters);
    _.remove(updatedParameters, parameter => (parameter === updatedParameters[parameterIndex]));

    this.props.updateArchetype({id: this.props.archetype.id, props: {parameters: updatedParameters}});
    this.props.handleSetAppliedOptionTypeFieldsVisible({value: false})
  }

  handleAddConditionalResourcesOption({title, parameter}) {
    if (title) {
      var parameterIndex = _.indexOf(this.props.archetype.parameters, parameter);
      var updatedParameters = _.cloneDeep(this.props.archetype.parameters);
      var updatedParameterOptions = _.get(updatedParameters, `${parameterIndex}.options`, []);

      updatedParameterOptions.push({title, value: lib.string.uuid(), instances: []});

      _.set(updatedParameters, `${parameterIndex}.options`, updatedParameterOptions);

      this.props.updateArchetype({id: this.props.archetype.id, props: {parameters: updatedParameters}});
    }
  }

  handleUpdateConditionalResourcesOption({optionIndex, props, parameter}) {
    var parameterIndex = _.indexOf(this.props.archetype.parameters, parameter);
    var updatedParameters = _.cloneDeep(this.props.archetype.parameters);
    var updatedOption = _.get(updatedParameters, `${parameterIndex}.options.${optionIndex}`, {});

    _.set(updatedOption, _.keys(props)[0], _.cloneDeep(props[_.keys(props)[0]]));
    _.set(updatedParameters, `${parameterIndex}.options.${optionIndex}`, updatedOption);

    this.props.updateArchetype({id: this.props.archetype.id, props: {parameters: updatedParameters}});
  }

  handleDestroyConditionalResourcesOption({optionIndex, parameter}) {
    var parameterIndex = _.indexOf(this.props.archetype.parameters, parameter);
    var updatedParameters = _.cloneDeep(this.props.archetype.parameters);
    var updatedOptions = _.get(updatedParameters, `${parameterIndex}.options`, []);

    _.remove(updatedOptions, option => (option === updatedOptions[optionIndex]));
    _.set(updatedParameters, `${parameterIndex}.options`, updatedOptions);

    this.props.updateArchetype({id: this.props.archetype.id, props: {parameters: updatedParameters}});
  }

  handleUpdateDefaultMaterialKeys({materialKey, action, parameter}) {
    var parameterIndex = _.indexOf(this.props.archetype.parameters, parameter);
    var updatedParameters = _.cloneDeep(this.props.archetype.parameters);
    var updatedParameterDefaultMaterialKeys = _.get(updatedParameters, `${parameterIndex}.defaultMaterialKeys`, []);

    if (action === 'add') {
      updatedParameterDefaultMaterialKeys.push(materialKey);
    }

    if (action === 'remove') {
      _.remove(updatedParameterDefaultMaterialKeys, defaultMaterialKey => (defaultMaterialKey === materialKey))
    }

    _.set(updatedParameters, `${parameterIndex}.defaultMaterialKeys`, updatedParameterDefaultMaterialKeys);

    this.props.updateArchetype({id: this.props.archetype.id, props: {parameters: updatedParameters}});
  }

  handleAddInstances({optionIndex}) {
    var {activeEntities, activeEntitiesData, parameter} = this.props;
    var {type} = parameter;

    var updatedInstances = [];

    if (_.includes(['material', 'pull'], type)) updatedInstances = _.cloneDeep(_.get(parameter, 'instances', []));
    if (type === 'conditionalResources') updatedInstances = _.cloneDeep(_.get(parameter, `options[${optionIndex}]`, []).instances);

    var updatesMap = {
      containers: {updates: []},
      products: {updates: []},
    };

    _.forEach(activeEntities, (activeEntity, index) => {
      var resourcePersistentId = activeEntity.persistentId;
      var resourceKey = activeEntitiesData[index].resourceKey

      if (_.includes(['product', 'container'], resourceKey)) {
        if (!resourcePersistentId) {
          resourcePersistentId = lib.string.uuid();
          updatesMap[pluralize(resourceKey)].updates.push({
            where: {id: activeEntity.id},
            props: {persistentId: resourcePersistentId}
          })
        }

        if (!_.includes(_.map(updatedInstances, 'resourcePersistentId'), resourcePersistentId)) {
          updatedInstances.push({resourceKey, resourcePersistentId});
        }
      }
    });

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions: this.props});

    if (_.includes(['material', 'pull'], type)) {
      this.props.handleParameterChange({instances: updatedInstances});
    }
    else if (type === 'conditionalResources') {
      var updatedOptions = _.cloneDeep(_.get(parameter, 'options', []));
      _.set(updatedOptions, `${optionIndex}.instances`, updatedInstances);

      this.props.handleParameterChange({options: updatedOptions});
    }
  }

  handleDestroyInstance({instance, type, optionIndex}) {
    var {parameter} = this.props;
    var updatedInstances = [];
    if (_.includes(['material', 'pull'], type)) updatedInstances = _.cloneDeep(parameter.instances);
    if (type === 'conditionalResources') updatedInstances = _.cloneDeep(_.get(parameter, `options[${optionIndex}]`, []).instances);

    _.remove(updatedInstances, updatedInstance => {
      return (updatedInstance.resourcePersistentId === instance.resourcePersistentId)
    });

    if (_.includes(['material', 'pull'], type)) {
      this.props.handleParameterChange({instances: updatedInstances});
    }
    if (type === 'conditionalResources') {
      var updatedOptions = _.cloneDeep(_.get(parameter, `options`, {}));
      _.set(updatedOptions, `${optionIndex}.instances`, updatedInstances);

      this.props.handleParameterChange({options: updatedOptions});
    }
  }

  handleSetExpandedPropertyFieldType({value, isDefault}) {
    if (
      (!isDefault && (value === this.state.expandedPropertyFieldType)) ||
      (isDefault && (value === this.state.defaultExpandedPropertyFieldType))
    ) {
      isDefault
        ? this.setState({defaultExpandedPropertyFieldType: ''})
        : this.setState({expandedPropertyFieldType: ''})
    }
    else {
      isDefault
        ? this.setState({defaultExpandedPropertyFieldType: value})
        : this.setState({expandedPropertyFieldType: value})
    };
  }

  handleUpdateCompatibleDetailIds({id, action, key}) {
    var manuallyConstrainedOptionIds = _.get(this.props.parameter, key) || [];

    if (action === 'add') {
      manuallyConstrainedOptionIds.push(id);
    }
    else {
      _.remove(manuallyConstrainedOptionIds, optionId => (optionId === id));
    }

    this.props.handleParameterChange({[key]: manuallyConstrainedOptionIds});
  }

  handleUpdateDefaultDetails({id, action, key}) {
    var updatedDefaultDetails = _.clone(_.get(this.props.parameter, `defaultDetails`, {}));

    _.set(updatedDefaultDetails, key, action === 'add' ? [id] : []);

    this.props.handleParameterChange({defaultDetails: updatedDefaultDetails});
    applyArchetypeParameterSelection({
      updatingSourceRoom: true,
      isInAdminMode: true,
      room: this.props.room,
      parameter: this.props.parameter,
      parameters: this.props.archetype.parameters,
      ...( _.includes(['pullMaterial', 'pullType'], key) ? {path: key} : {}),
      value: id,
      reduxActions: this.props, archetypeId: this.props.archetype.id});
  }

  render() {
    var {parameter, archetype, handleParameterChange, isExpanded, styles, appliedOptionTypeFieldsVisible, room} = this.props;

    var showDefaultPullSetting = appliedOptionTypeFieldsVisible && _.includes(['pull'], parameter.type) && parameter.instances.length > 0;
    var showDefaultPullMaterialSetting = showDefaultPullSetting && _.get(parameter, 'defaultDetails.pullType', []).length > 0;
    var showDefaultMaterialSetting = appliedOptionTypeFieldsVisible && _.includes(['material'], parameter.type);

    var materialOptions, pullOptions, pullMaterialOptions;

    if (showDefaultPullSetting) {
      pullOptions = getMaterialOptionsForArchetypeParameter({parameter, room, materialKey: 'pullType'});
    }

    if (showDefaultPullMaterialSetting) {
      pullMaterialOptions = getMaterialOptionsForArchetypeParameter({parameter, room, materialKey: 'pullMaterial'});

      if (parameter.compatibleMaterialIds) { //HINT filter compatible materials to those set manually on the parameter
        pullMaterialOptions = _.filter(pullMaterialOptions, ({value}) => {
          return _.includes(parameter.compatibleMaterialIds, value);
        });
      }
    }

    if (showDefaultMaterialSetting) {
      materialOptions = getMaterialOptionsForArchetypeParameter({parameter, room, materialKey: 'material'});
    }

    return (
      <div style={{marginBottom: K.spacing * 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
        <div
          style={{marginLeft: K.spacing, marginRight: K.spacing, textAlign: 'center', justifyContent: 'space-between', alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
          onMouseEnter={()=> this.setState({isHoveringParameterTitle: true})}
          onMouseLeave={()=> this.setState({isHoveringParameterTitle: false})}
        >
          <div
            style={{marginLeft: K.spacing * 3, marginRight: K.spacing, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: K.spacing, marginBottom: K.spacing}}
          >
            <TextInput
              label={'Title'}
              value={parameter.title}
              onChange={({value}) => handleParameterChange({title: value})}
              style={{overflow: 'visible', width: 500, backgroundColor: 'transparent', ...styles.tertiaryHeader, fontStyle: 'italic'}}
            />
          </div>
          <div style={{marginRight: K.spacing}}>
            {this.state.isHoveringParameterTitle && (
              <div style={{display: 'flex', flexDirection: 'row', gap: K.spacing}}>
                <div
                  style={{...K.defaultIconSize}}
                  onClick={() => this.props.handleUpdateExpandedParameter({parameter: isExpanded? {} : parameter})}
                >
                  <img src={upArrowIcon} style={{...K.defaultIconSize, cursor: 'pointer', ...(isExpanded ? {transform: 'rotate(180deg)'} : {transform: 'rotate(90deg)'}), opacity: 0.7}}/>
                </div>
                <div style={{ ...K.defaultIconSize, marginTop: 1}}>
                  <img style={{display: 'flex', ...K.defaultIconSize, opacity: 0.7, cursor: 'pointer'}} src={xIconBlack} onClick={() => this.handleDestroyParameter({parameter})}/>
                </div>
              </div>
            )}
          </div>
        </div>
        {isExpanded && (
          <div style={{display: 'flex', flexDirection: 'column', alignContent: 'flex-start', width: '100%'}}>
            {parameter.type === 'conditionalResources' && (
              <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start'}}>
                {
                appliedOptionTypeFieldsVisible
                  && _.includes(['conditionalResources'], parameter.type)
                && (<>
                  <div style={{marginLeft: K.spacing * 4}}>
                    <div style={{marginBottom: K.spacing, cursor: 'pointer', ...styles.secondaryHeader, opacity: 1}}>
                      Set Default Option
                    </div>
                    <div style={{marginLeft: K.spacing * 2.5}}>
                      <ConnectedPropertyField
                        key={'default-option-conditional-resource'}
                        type={'select'}
                        context={'configurator'}
                        hasDefaultTrue={false}
                        hasOptionGroups={false}
                        views={['front', 'top']}
                        placeHolder={'Set Material'}
                        title={_.get(_.find(_.get(parameter, 'options'), {value: _.get(parameter, 'defaultDetails.conditionalResources[0]')}), 'title')}
                        index={0}
                        options={_.get(parameter, 'options')}
                        value={_.find(_.get(parameter, 'options'), {value: _.get(parameter, 'defaultDetails.conditionalResources[0]')})}
                        defaultTrue={false}
                        widerOptionsDropdown={true}
                        isExpanded={this.state.defaultExpandedPropertyFieldType === 'conditionalResources'}
                        onPropertyFieldChange={({value}) => this.handleUpdateDefaultDetails({id: value, action: 'add', key: 'conditionalResources'})}
                        onClick={() => this.handleSetExpandedPropertyFieldType({value: 'conditionalResources', isDefault: true})}
                        showRadioActiveOption={false}
                        defaultThumbnailIcon={createIcon}
                        showDefaultThumbnailIcon={true}
                      />
                    </div>
                  </div>
                </>)}
                {_.map(parameter.options, (option, optionIndex) => {
                  return (
                    <div
                      onMouseEnter={() => this.setState({hoveredOptionIndex: optionIndex})}
                      onMouseLeave={() => this.setState({hoveredOptionIndex: -1})}
                    >
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                        <div style={{marginBottom: K.spacing, marginLeft: K.spacing * 3, width: 'fit-content'}}>
                          <TextInput
                            label={'Option Title'}
                            value={option.title}
                            onChange={({value}) => this.handleUpdateConditionalResourcesOption({optionIndex, props: {title: value}, optionIndex, parameter})}
                            style={{width: '100%', backgroundColor: 'transparent'}}
                          />
                        </div>
                        <div style={{marginTop: 3, position: 'relative', ...K.defaultIconSize}}>
                          {this.state.hoveredOptionIndex === optionIndex && (<img
                            style={{...K.defaultIconSize, opacity: 0.7, cursor: 'pointer'}}
                            src={xIconBlack}
                            onClick={() => this.handleDestroyConditionalResourcesOption({optionIndex, parameter})}
                          />)}
                        </div>
                      </div>
                      <></>
                      <div style={{marginLeft: K.spacing * 2.5}}>
                        <ParameterInstances
                          key={optionIndex}
                          {...{parameter, reduxActions: this.props, archetype, optionIndex}}
                          handleParameterChange={(props) => this.props.handleParameterChange({...props})}
                          activeEntitiesData={this.props.activeEntitiesData}
                          activeEntities={this.props.activeEntities}
                          containers={this.props.containers}
                          products={this.props.products}
                          defaultMaterialKeys={parameter.defaultMaterialKeys}
                          handleUpdateDefaultMaterialKeys={({materialKey, action}) => this.handleUpdateDefaultMaterialKeys({materialKey, action, parameter})}
                          handleAddInstances={() => this.handleAddInstances({optionIndex})}
                          handleDestroyInstance={({instance, type}) => this.handleDestroyInstance({instance, type, optionIndex})}
                          styles={this.props.styles}
                        />
                      </div>
                    </div>
                  )
                })}
                <div style={{marginLeft: K.spacing * 3, marginBottom: K.spacing}}>
                  <TextInput
                    label={'Add Conditional Resource Option'}
                    value={''}
                    placeholder={'Add Option'}
                    onChange={({value}) => this.handleAddConditionalResourcesOption({parameter, title: value})}
                    style={{width: '100%', backgroundColor: 'transparent'}}
                  />
                </div>
              </div>
            )}
            <div style={{marginLeft: K.spacing * 4}}>
              {(parameter.type === 'pull' && parameter.instances.length > 0) && (
                <div>
                  <div
                    style={{marginBottom: K.spacing, cursor: 'pointer', ...styles.secondaryHeader, opacity: this.state.manuallyConstrainedPulls ? 0.8 : 0.4}}
                    onClick={() => this.setState({manuallyConstrainedPulls: !this.state.manuallyConstrainedPulls})}
                  >{(this.state.manuallyConstrainedPulls || (_.get(parameter, 'compatiblePullIds') || []).length > 0)
                      ? 'Selectable Pulls'
                      :'Limit Pull Options'
                    }
                  </div>
                  {this.state.manuallyConstrainedPulls && (
                    <AppliedOptions
                      handleSetExpandedPropertyFieldType={({value}) => this.handleSetExpandedPropertyFieldType({value})}
                      expandedPropertyFieldType={this.state.expandedPropertyFieldType}
                      type={'pull'}
                      title={'Add Pull'}
                      {...{parameter, archetype, reduxActions: this.props}}
                      styles={this.props.styles}
                      compatiblePullIds={_.get(parameter, 'compatiblePullIds') || []}
                      onChange={({id, action}) => this.handleUpdateCompatibleDetailIds({id, action, key: 'compatiblePullIds'})}
                    />
                  )}
                  <div
                    style={{marginBottom: K.spacing, cursor: 'pointer', ...styles.secondaryHeader, opacity: this.state.manuallyConstrainedMaterials ? 0.8 : 0.4}}
                    onClick={() => this.setState({manuallyConstrainedMaterials: !this.state.manuallyConstrainedMaterials})}
                  >
                    {(!!this.state.manuallyConstrainedMaterials || (_.get(parameter, 'compatibleMaterialIds') || []).length > 0)
                      ? (_.get(parameter, 'type') === 'pull' ? 'Selectable Pull Materials' : 'Selectable Materials')
                      : 'Limit Pull Material Options'
                    }
                  </div>
                  {!!this.state.manuallyConstrainedMaterials && (
                    <AppliedOptions
                      handleSetExpandedPropertyFieldType={({value}) => this.handleSetExpandedPropertyFieldType({value})}
                      type={'pullMaterial'}
                      title={'Add Pull Material'}
                      expandedPropertyFieldType={this.state.expandedPropertyFieldType}
                      {...{parameter, archetype, reduxActions: this.props}}
                      styles={this.props.styles}
                      compatibleMaterialIds={_.get(parameter, 'compatibleMaterialIds') || []}
                      onChange={({id, action}) => this.handleUpdateCompatibleDetailIds({id, action, key: 'compatibleMaterialIds'})}
                    />
                  )}
                </div>
              )}
              {(
                (_.includes(['material'], parameter.type) && parameter.instances.length > 0) &&
                (_.get(parameter, 'defaultMaterialKeys', []).length > 0 || _.some(parameter.instances, instance => instance.materialKeys))
              ) && (
                <div>
                  <div
                    style={{marginBottom: K.spacing, cursor: 'pointer', ...styles.secondaryHeader, opacity: this.state.manuallyConstrainedMaterials ? 0.8 : 0.4}}
                    onClick={() => this.setState({manuallyConstrainedMaterials: !this.state.manuallyConstrainedMaterials})}
                  >
                    {(this.state.manuallyConstrainedMaterials || (_.get(parameter, 'compatibleMaterialIds') || []).length > 0)
                      ? 'Selectable Materials'
                      : 'Limit Material Options'
                    }
                  </div>
                  {!!this.state.manuallyConstrainedMaterials && (
                    <AppliedOptions
                      handleSetExpandedPropertyFieldType={({value}) => this.handleSetExpandedPropertyFieldType({value})}
                      type={'material'}
                      title={'Add Material'}
                      expandedPropertyFieldType={this.state.expandedPropertyFieldType}
                      {...{parameter, archetype, reduxActions: this.props}}
                      styles={this.props.styles}
                      compatibleMaterialIds={_.get(parameter, 'compatibleMaterialIds') || []}
                      onChange={({id, action}) => this.handleUpdateCompatibleDetailIds({id, action, key: 'compatibleMaterialIds'})}
                    />
                  )}
                </div>
              )}
              {
                appliedOptionTypeFieldsVisible &&
                _.includes(['pull'], parameter.type) &&
                parameter.instances.length > 0 &&
              (
                <div>
                  <div style={{marginBottom: K.spacing, cursor: 'pointer', ...styles.secondaryHeader, opacity: 1}}>
                    Set Default Pull Type
                  </div>
                  <div style={{marginLeft: K.spacing * 2.5}}>
                    <ConnectedPropertyField
                      key={'default-option-pullType'}
                      type={'select'}
                      context={'configurator'}
                      hasDefaultTrue={false}
                      hasOptionGroups={false}
                      views={['front', 'top']}
                      placeHolder={'Set Pull Type'}
                      title={_.get(_.find(pullOptions, {value: _.get(parameter, 'defaultDetails.pullType[0]')}), 'title')}
                      index={0}
                      options={pullOptions}
                      value={_.find(pullOptions, {value: _.get(parameter, 'defaultDetails.pullType[0]')})}
                      defaultTrue={false}
                      widerOptionsDropdown={true}
                      isExpanded={this.state.defaultExpandedPropertyFieldType === 'pullType'}
                      onPropertyFieldChange={({value}) => this.handleUpdateDefaultDetails({id: value, action: 'add', key: 'pullType'})}
                      onClick={() => this.handleSetExpandedPropertyFieldType({value: 'pullType', isDefault: true})}
                      showRadioActiveOption={false}
                      defaultThumbnailIcon={createIcon}
                      showDefaultThumbnailIcon={true}
                    />
                  </div>
                  {_.get(parameter, 'defaultDetails.pullType', []).length > 0 && (<>
                    <div style={{marginBottom: K.spacing, cursor: 'pointer', ...styles.secondaryHeader, opacity: 1}}>
                      Set Default Pull Material
                    </div>
                    <div style={{marginLeft: K.spacing * 2.5}}>
                      <ConnectedPropertyField
                        key={'default-option-pullMaterial'}
                        type={'select'}
                        context={'configurator'}
                        hasDefaultTrue={false}
                        hasOptionGroups={false}
                        views={['front', 'top']}
                        placeHolder={'Set Pull Material'}
                        title={_.get(_.find(pullMaterialOptions, {value: _.get(parameter, 'defaultDetails.pullMaterial[0]')}), 'title')}
                        index={0}
                        options={pullMaterialOptions}
                        value={_.find(pullMaterialOptions, {value: _.get(parameter, 'defaultDetails.pullMaterial[0]')})}
                        defaultTrue={false}
                        widerOptionsDropdown={true}
                        isExpanded={this.state.defaultExpandedPropertyFieldType === 'pullMaterial'}
                        onPropertyFieldChange={({value}) => this.handleUpdateDefaultDetails({id: value, action: 'add', key: 'pullMaterial'})}
                        onClick={() => this.handleSetExpandedPropertyFieldType({value: 'pullMaterial', isDefault: true})}
                        showRadioActiveOption={false}
                        defaultThumbnailIcon={createIcon}
                        showDefaultThumbnailIcon={true}
                      />
                    </div>
                  </>)}
                </div>
              )}
              {
                appliedOptionTypeFieldsVisible &&
                _.includes(['material'], parameter.type) &&
              (<>
                <div style={{marginBottom: K.spacing, cursor: 'pointer', ...styles.secondaryHeader, opacity: 1}}>
                  Set Default Material
                </div>
                <div style={{marginLeft: K.spacing * 2.5}}>
                  <ConnectedPropertyField
                    key={'default-option-material'}
                    type={'select'}
                    context={'configurator'}
                    hasDefaultTrue={false}
                    hasOptionGroups={false}
                    views={['front', 'top']}
                    placeHolder={'Set Material'}
                    title={_.get(_.find(materialOptions, {value: _.get(parameter, 'defaultDetails.material[0]')}), 'title')}
                    index={0}
                    options={materialOptions}
                    value={_.find(materialOptions, {value: _.get(parameter, 'defaultDetails.material[0]')})}
                    defaultTrue={false}
                    widerOptionsDropdown={true}
                    isExpanded={this.state.defaultExpandedPropertyFieldType === 'material'}
                    onPropertyFieldChange={({value}) => this.handleUpdateDefaultDetails({id: value, action: 'add', key: 'material'})}
                    onClick={() => this.handleSetExpandedPropertyFieldType({value: 'material', isDefault: true})}
                    showRadioActiveOption={false}
                    defaultThumbnailIcon={createIcon}
                    showDefaultThumbnailIcon={true}
                  />
                </div>
              </>)}
            </div>
            {_.includes(['material', 'pull'], parameter.type) && (<ParameterInstances
              {...{parameter, reduxActions: this.props, archetype, optionIndex: -1}}
              handleParameterChange={(props) => this.props.handleParameterChange({...props})}
              activeEntitiesData={this.props.activeEntitiesData}
              activeEntities={this.props.activeEntities}
              containers={this.props.containers}
              products={this.props.products}
              defaultMaterialKeys={parameter.defaultMaterialKeys}
              handleUpdateDefaultMaterialKeys={({materialKey, action}) => this.handleUpdateDefaultMaterialKeys({materialKey, action, parameter})}
              handleAddInstances={() => this.handleAddInstances({optionIndex: -1})}
              handleDestroyInstance={({instance, type}) => this.handleDestroyInstance({instance, type, optionIndex: -1})}
              styles={this.props.styles}
            />)}
          </div>
        )}
      </div>
    );
  }
}


export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData, archetype} = ownProps;

    var activeEntities = _.map(activeEntitiesData, (activeEntity) => {
      return _.get(state.resources, `[${pluralize(activeEntity.resourceKey)}].byId[${activeEntity.id}]`);
    });

    return {
      project: _.values(state.resources.projects.byId)[0],
      containerTypes: state.resources.containerTypes.byId,
      containers: state.resources.containers.byId,
      productTypes: state.resources.productTypes.byId,
      products: state.resources.products.byId,
      activeEntities,
      activeEntitiesData,
      archetype: _.find(state.resources.archetypes.byId, {id: archetype.id}),
      materialClasses: state.resources.materialClasses.byId,
      materials: state.resources.materials.byId,
      room: _.find(state.resources.rooms.byId, {id: archetype.roomId})
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'destroyRoom', 'destroyRooms', 'modifyRooms']),
    ..._.pick(resourceActions.archetypes, ['updateArchetype', 'destroyArchetype']),
    ..._.pick(resourceActions.products, ['updateProducts', 'modifyProducts']),
    ..._.pick(resourceActions.containers, ['updateContainers', 'modifyContainers']),
    ..._.pick(resourceActions.parts, ['updateParts']),
    ..._.pick(resourceActions.productOptions, ['modifyProductOptions']),
    ..._.pick(resourceActions.elevations, ['modifyElevations']),
  }
})(Parameter);
