import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Wall from 'project-helpers/wall';
import K from 'k';
import { singularize } from 'inflection';


const getOverlappingContainers = ({containers}) => {
  var issues = [];
  var overlappingContainerIds = [];

  containers = _.filter(containers, container => !Container.getTypeDataFor({container}).isOrnament);

  //TODO optimize this
  //should only need to check each container with any other container 1 time
  _.forEach(containers, (container) => {
    if (_.includes(overlappingContainerIds, container.id)) return;

    let siblings = Container.get('siblings', {container});

    //HINT allow countertops and barblocks to overlap
    let relevantContainers = _.reject(siblings, c2 => {
      let countertopCount = _.filter([container, c2], {type: 'countertop'}).length;
      let horizontalBarblockCount = _.filter([container, c2], {type: 'horizontalBarblock'}).length;
      let isOrnament = Container.getTypeDataFor({container: c2}).isOrnament;

      return countertopCount === 2 || (countertopCount === 1 && horizontalBarblockCount === 1) || isOrnament;
    });

    // Check with other Siblings
    let overlappingContainers = _.filter(relevantContainers, siblingContainer => {
      return Container.sharesFootprintWith({sourceContainer: container, container: siblingContainer, product: null, inclusive: false}) && Container.verticallyOverlapsWith({sourceContainer: container, container: siblingContainer});
    });

    if (overlappingContainers.length > 0) {
      overlappingContainerIds.push(container.id);
      //hint not safe to add this because these containers could also be overlapping a container that doesn't overlap the original container
      //..._.map(overlappingContainers, container => (container.id))

      issues.push({
        id: `container-${container.id}-container-${overlappingContainers[0].id}-overlapping`,
        message: `${singularize(Container.getTypeDataFor({container}).title)}/${singularize(Container.getTypeDataFor({container: overlappingContainers[0]}).title)} - overlapping`,
        resourceKey: 'container',
        resourceId: container.id,
        type: 'overlapping-resources',
        level: 1
      })
    }
  });

  return issues;
};

export default getOverlappingContainers;
