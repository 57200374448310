import _ from 'lodash';
import lib from 'lib';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Volume from 'project-helpers/volume';
import Wall from 'project-helpers/wall';
import ArchElement from 'project-helpers/arch-element';
import getProcessedDimensionSets from '../getProcessedDimensionSets';
import considerUpdatingSummarizedDimensionEdits from '../considerUpdatingSummarizedDimensionEdits';

import addRoomContainerOutsideDepthDimensions from './helpers/addRoomContainerOutsideDepthDimensions';
import addRoomContainerWidthDimensions from './helpers/addRoomContainerWidthDimensions';
import addRoomContainerDepthDimensions from './helpers/addRoomContainerDepthDimensions';
import addRoomIslandOutlineDimensions from './helpers/addRoomIslandOutlineDimensions';
import addRoomArchElementWidthDimensions from './helpers/addRoomArchElementWidthDimensions';
import addRoomArchElementContainerWidthDimensions from './helpers/addRoomArchElementContainerWidthDimensions';
import addRoomVolumeWidthDimensions from './helpers/addRoomVolumeWidthDimensions';
import addRoomVolumeDepthDimensions from './helpers/addRoomVolumeDepthDimensions';
import addRoomWallDimensions from './helpers/addRoomWallDimensions';

export default function getRoomDimensionSets({room, projectData, showWallsAndArchElements, cachedDimensionSets, considerSummarizationChange, canvasData, reduxActions}) {
  var dimensionSets = []; //[{type: 'extrudedOutside', lines: []}]

  try {
    var {products, containers, archElements, computedWalls, walls, wallSets, volumes, project} = Room.get(['containers', 'archElements', 'wallSets', 'walls', 'computedWalls', 'volumes', 'products', 'project'], {room});
    var {companyKey} = project;

    var roomContainers = containers;
    var archElementContainers = _.filter(containers, container =>  Container.getTypeDataFor({container}).isArchElement && container.position && !_.isEqual(container.position, {}));
    containers = _.reject(containers, container => container.type === 'countertop' || Container.getTypeDataFor({container}).isArchElement || Container.getTypeDataFor({container}).isOrnament || (!container.position || _.isEqual(container.position, {})));
    archElements = _.reject(archElements, archElement => !_.includes(['floor', 'hybrid'], ArchElement.get('typeData', {archElement}).classification));

    var outsideAlphas = [0, Math.PI, Math.PI * 0.5, Math.PI * 1.5]; //TODO for each walls/containers - grab any angles other than the core 4

    //TODO skip irrelevant walls under certain conditions?

    //TODO skip containers that are not dimensioned from entities
    var entities = [
      ..._.map(containers, container => ({type: 'container', key: `container-${container.id}`, container, lines: _.values(Container.getFootprintLines({container}))})),
      ..._.map(archElementContainers, archElementContainer => ({type: 'archElementContainer', key: `arch-element-container-${archElementContainer.id}`, archElementContainer, lines: _.values(Container.getFootprintLines({container: archElementContainer}))})),
      //arch elements
      ..._.map(archElements, archElement => ({type: 'archElement', key: `arch-element-${archElement.id}`, archElement, lines: _.values(ArchElement.getFootprintInRoom({archElement, wallSets, room}))})),
      //if it hits two volumes
      ..._.map(room.plan.points, (point, index) => ({
        type: 'wall', key: `wall-${point.id}`, point, lines: [{from: point, to: lib.array.next(room.plan.points, index)}]
      }))
    ];

    var allPoints = _.map([..._.flatMap(entities, entity => _.map(entity.lines, 'from')), ...room.plan.points], point => lib.object.sum(point, room.plan.position));

    var outsideDimensionSets = _.map(outsideAlphas, (alpha, index) => ({type: 'extrudeOutside', id: `outside-set-${index}`, alpha, targets: [], allPoints}));

    var normalizeLine = ({line, alpha}) => _.mapValues(line, point => lib.trig.rotate({point, byRadians: -alpha}));

    var getIsTrapped = ({line, entityType, entityId}, {filterEntities, locally, inverseComparison} = {}) => {
      var alpha = lib.trig.alpha({p1: line.from, p2: line.to});
      var normalLine = normalizeLine({line, alpha});
      var relevantEntities = _.reject(entities, {key: `${entityType}-${entityId}`});
      var isTrappedByWallCount = false;

      var getIsTrappedByEntity = ({entity}) => {
        return _.some(entity.lines, line2 => {
          var normalLine2 = normalizeLine({line: line2, alpha});
          var isGreaterThan = normalLine2.from.y >= normalLine.from.y;
          var isLessThan = normalLine2.from.y <= normalLine.from.y;

          //HINT wall dims are rotated 180, so inverse
          var isBetweenDimAndSummaryLine = ((entityType === 'wall' && entity.type === 'wall') || inverseComparison) ? isLessThan : isGreaterThan;

          return isBetweenDimAndSummaryLine && (locally ? (Math.abs(normalLine2.from.y - normalLine.from.y) < 7) : true) && lib.number.rangesOverlap({r1: _.mapValues(normalLine, 'x'), r2: _.mapValues(normalLine2, 'x'), inclusive: false});
        });
      }

      if (entityType !== 'wall') {
        var trappingWallCount = _.filter(_.filter(entities, {type: 'wall'}), entity => {
          return getIsTrappedByEntity({entity});
        }).length;

        if (trappingWallCount > 1 && !locally) isTrappedByWallCount = true;

        //HINT we ususally pull dims outside of the wall and summarize, so it isn't trapped if a wall is present
        //but when considering if its trapped locally, we consider the wall lines
        if (!locally) relevantEntities = _.reject(relevantEntities, {type: 'wall'})
      }
      else if (!locally) relevantEntities = _.filter(relevantEntities, {type: 'wall'});

      if (filterEntities) relevantEntities = _.filter(relevantEntities, filterEntities);

      return isTrappedByWallCount || _.some(relevantEntities, entity => getIsTrappedByEntity({entity}));
    };

    var deps = {outsideDimensionSets, dimensionSets, getIsTrapped, room};

    let containerGroups = Room.getContainerGroups({containers, computedWalls, walls, wallSets, room});

    var sortedContainerData = _.sortBy(_.map(containers, container => {
      var footprintLines = Container.getFootprintLines({container});
      var containerAlpha = Container.getAlpha({container});
      var isIsland = _.get(_.find(containerGroups, containerGroup => _.includes(_.map(containerGroup.containers, 'id'), container.id)), 'type') === 'island';

      return {container, roomContainers, containerAlpha, footprintLines, isIsland, widthIsTrapped: getIsTrapped({line: footprintLines.back, entityType: 'container', entityId: container.id})};
    }), ({widthIsTrapped}) => widthIsTrapped ? 0 : 1);

    //HINT containers is already filtered to exclude non-dimensioned containers
    _.forEach(sortedContainerData, containerData => {
      // if (!_.includes(['countertop'], container.type)) { //TODO filter out countertop/other non-dimensioned containers
        var containerDeps = {...deps, ...containerData};

        var {container} = containerData;

        if (!_.includes(['wall', 'floatingShelves', 'opencase', 'endPanel'], container.type)) {
          addRoomContainerWidthDimensions(containerDeps);
          addRoomContainerOutsideDepthDimensions({...containerDeps, allPoints});
        }

        //HINT includes end panel dims
        if (_.includes(['base', 'floatingBase', 'tall', 'endPanel', 'wall', 'floatingShelves', 'islandSeating', 'baseWithChase', 'tallFreestandingAppliance', 'baseFreestandingAppliance', 'wallFreestandingAppliance'], container.type)) {
          addRoomContainerDepthDimensions(containerDeps);
        }
      // }
    });

    _.forEach(_.filter(products, product => Product.getHasSink({product})), product => {
      var parentProduct = _.find(products, {id: product.productInstanceId});
      var container = _.find(containers, {id: product.containerInstanceId || parentProduct?.containerInstanceId});

      if (container && container.position && !_.isEqual(container.position, {}) && showWallsAndArchElements) {
        if (companyKey === 'hb' || !(container.type === 'vanity' && !_.includes([1215, 1219], product.productId))) {
          let positionInRoom = Product.getPositionInRoom({product});
          var size = Product.getSize({product, viewKey: 'top'});

          let vertices = [
            lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: size.width, y: size.height}, byDegrees: container.rotation})),
            lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: 0, y: size.height}, byDegrees: container.rotation})),
            positionInRoom,
            lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: size.width, y: 0}, byDegrees: container.rotation})),
          ];

          var backline = {
            from: vertices[0],
            to: vertices[1]
          };

          var centerPoint = lib.math.midpoint({line: backline});

          var intersectionPoint;

          if (product.customData.sinkDimensionWallId && _.includes(_.map(walls, 'id'), product.customData.sinkDimensionWallId)) {
            var wall = _.find(walls, {id: product.customData.sinkDimensionWallId});
            var extendedLineInRoom = lib.trig.extend({line: Wall.getLine({wall}).inRoom, by: 10000});

            var intersectionPoint = lib.math.intersectionPoint({l1: extendedLineInRoom, l2: backline});

            if (_.some(intersectionPoint, value => _.includes([Infinity, NaN], value))) intersectionPoint = undefined;
          }
          else {
            intersectionPoint = lib.waterfall(walls, [
              [_.map, wall => {
                return lib.math.intersectionPoint({l1: Wall.getLine({wall}).inRoom, l2: backline});
              }],
              [_.filter, point => _.every(point, value => !_.includes([Infinity, NaN], value))],
              [_.minBy, point => lib.trig.dist(point, centerPoint)]
            ]);
          }

          if (intersectionPoint) {
            dimensionSets.push({
              type: 'standalone',
              id: `sink-centerline-${product.id}`,
              alpha: lib.trig.alpha({p1: centerPoint, p2: intersectionPoint}),
              offset: 5,
              showLabelBorder: true,
              formatLabel: ({text}) => `${text} to sink C/L`,
              targets: [
                {position: lib.object.sum(centerPoint, room.plan.position), id: `sink-centerline-${product.id}-from`},
                {position: lib.object.sum(intersectionPoint, room.plan.position), id: `sink-centerline-${product.id}-to`}
              ]
            });
          }
        }
      }
    })

    _.forEach(volumes, volume => {
      var footprintLines = Volume.getFootprintLines({volume});
      var volumeAlpha = Volume.getAlpha({volume})
      var volumeDeps = {...deps, footprintLines, volume, volumeAlpha};

      addRoomVolumeWidthDimensions(volumeDeps);

      if (volume.dimensions.depth > 4) {
        addRoomVolumeDepthDimensions(volumeDeps);
      }
    });

    //TODO
    // addRoomIslandOutlineDimensions({...deps, containers, containerGroups, walls, showWallsAndArchElements});

    //HINT containers is already filtered to exclude non-dimensioned containers
    _.forEach(archElements, archElement => {
      var footprintLines = ArchElement.getFootprintInRoom({archElement, wallSets, room});
      //HINT rotation is the wall angle, not actual window rotation
      var rotation = ArchElement.getRotationInRoom({wallSets, archElement}) - 90;
      var archElementAlpha = lib.trig.degreesToRadians(rotation);

      var archElementDeps = {...deps, archElement, footprintLines, archElementAlpha};

      addRoomArchElementWidthDimensions(archElementDeps);
    });

    //HINT containers is already filtered to exclude non-dimensioned containers
    _.forEach(archElementContainers, archElementContainer => {
      var footprintLines = Container.getFootprintLines({container: archElementContainer});
      //HINT rotation is the wall angle, not actual window rotation
      var archElementContainerAlpha = lib.trig.degreesToRadians(archElementContainer.rotation);

      var archElementContainerDeps = {...deps, archElementContainer, footprintLines, archElementContainerAlpha};

      addRoomArchElementContainerWidthDimensions(archElementContainerDeps);
    });

    addRoomWallDimensions(deps);

    dimensionSets = [...outsideDimensionSets, ...dimensionSets];

    var activeLayerDimensionsData = {};

    if (projectData) {
      activeLayerDimensionsData = _.defaults(projectData.dimensionsData[projectData.activeDimensionsLayer], {
        disabledPositionIds: {},
        disabledLineIds: {},
        customDimensionsById: {},
        tolerancesById: {},
        shouldHoldTosById: {},
        offsetsById: {},
        swapShortDimSideById: {}
      });
    }

    var relevantCustomDimensions = _.pickBy(activeLayerDimensionsData.customDimensionsById, {contextType: 'room', contextId: room.id});

    _.forEach(relevantCustomDimensions, customDimension => {
      //HINT custom dims where the from and to are the same cause crashing
      if (!_.isEqual(customDimension.line.from, customDimension.line.to)) {
        dimensionSets.push({
          type: 'extrudeLocally',
          id: customDimension.id,
          isCustomDimension: true,
          customDimension,
          offset: -customDimension.offset,
          alpha: lib.trig.alpha({p1: customDimension.line.from, p2: customDimension.line.to}),
          targets: [
            {position: lib.object.sum(customDimension.line.from, room.plan.position), id: `custom-dimension-${customDimension.id}-from`},
            {position: lib.object.sum(customDimension.line.to, room.plan.position), id: `custom-dimension-${customDimension.id}-to`}
          ]
        });
      }
    });

    dimensionSets = getProcessedDimensionSets({dimensionSets, allPoints, activeLayerDimensionsData, canvasData});

    if (projectData && considerSummarizationChange && cachedDimensionSets) {
      considerUpdatingSummarizedDimensionEdits({projectData, considerSummarizationChange, dimensionSets, cachedDimensionSets, reduxActions});
    }
  }
  catch (error) {
    global.handleError({error, info: {componentStack: error.stack}, message: `generating room ${_.get(room, 'id')} dimensions`});
  }

  return dimensionSets;
}
