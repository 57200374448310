import React from 'react';
import K from 'k';
import _ from 'lodash';

import lib from 'lib';
import { connect, resourceActions } from 'redux/index.js';
import moment from 'moment';
import Project from 'project-helpers/project';
import Product from 'project-helpers/product';
import Room from 'project-helpers/room';
import {withErrorBoundary} from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';

import Dropdown from 'components/dropdown';

class PriceElement extends React.PureComponent {
  state = {
    showingPricePopup: false,
    pricingConstraintsPopupIsVisible: false,
  };

  async handleUpdatePricingConstraint({value}){
    this.props.updateProject({id: this.props.project.id, props: {priceElementConstraintType: value}, hitApi: false});
    await lib.api.update('projectVersion', {where: {id: this.props.project.versionId}, props: {priceElementConstraintType: value}});
  }

  render() {
    var {project, pricingRules, materialClasses, productOptionTypes, productTypes} = this.props;
    var projectProducts = Project.get('products', {project});
    var containers = Project.get('containers', {project});
    var priceElementConstraintType = _.get(project, 'priceElementConstraintType') || (project.companyKey === 'hb' ? 'product' : 'total');

    var obfuscateShipping = lib.price.getObfuscateShipping({project})

    var projectDataForPricing = {
      ...project,
      productInstances: _.map(projectProducts, product => ({
        productOptionInstances: Product.get('productOptionInstances', {product}),
        ...product
      }))
    };

    var deliveryAddress = {
      streetAddress: project.deliveryStreetAddress,
      streetAddress2: project.deliveryStreetAddress2,
      city: project.deliveryCity,
      state: project.deliveryState,
      zip: project.deliveryZip
    };

    var hasValidAddress = deliveryAddress.state && deliveryAddress.zip;
    var productOrContainerBeingDragged = _.some(projectProducts, product => product.eventType === 'transform') || _.some(containers, container => container.eventType === 'transform');

    if (this.props.showingPriceData && !productOrContainerBeingDragged && !Room.waitingForUpdateManagedResources) {
      var projectPriceData = lib.memo(({data, refs}) => {
        var {projectDataForPricing} = data;
        var {pricingRules, productTypes, materialClasses, productOptionTypes} = refs;

        return lib.price.priceDataFor('project', projectDataForPricing, {pricingRules, products: productTypes, materialClasses, productOptions: productOptionTypes})
      }, {
        data: {projectDataForPricing},
        refs: {
          pricingRules, productTypes, materialClasses, productOptionTypes
        }
      });

      var priceText;

      if (hasValidAddress) {
        priceText = projectPriceData.total.toFormat('$0,0.00');
      }
      else {
        priceText = projectPriceData.subtotals.product.toFormat('$0,0.00');
      }

      if (priceElementConstraintType === 'product') {
        priceText = projectPriceData.subtotals.product.toFormat('$0,0.00')
      }
    }

    var getDeliveryDateText = () => {
      var productionWeeksByCompanyKey = {hb: 18, vp: 18};

      var deliveryDate = moment().add(productionWeeksByCompanyKey[project.companyKey], 'weeks');

      while (deliveryDate.day() !== 5) { //HINT round to the next Friday
        deliveryDate.add(1, 'day');
      }

      return `${deliveryDate.format('MMM Do, YYYY')}`;
    };

    var companyName = project.companyKey === 'hb' ? 'Henrybuilt' : 'Space Theory';

    var initialDeposit = project.amountPaid;

    return (
      <>
        {(this.state.pricingConstraintsPopupIsVisible) && (
          <Dropdown
            options={[{value: 'product', title: 'Product'}, {value: 'total', title: 'Total'}]}
            value={priceElementConstraintType}
            onChange={(value) => this.handleUpdatePricingConstraint({value})}
            style={{left: '5vw'}}
            labelStyle={{display: 'none'}}
            showCaret={false}
            isCollapsedDefault={false}
            onCollapse={() => this.setState({pricingConstraintsPopupIsVisible: false})}
            hasFixedHeight={true}
          />
        )}
        {this.props.showingPriceData && this.state.showingPricePopup && !productOrContainerBeingDragged && !Room.waitingForUpdateManagedResources && (
          <div
            style={{position: 'absolute', top: '70px', right: '-22vw', zIndex: 1001, width: 405, fontSize: '80%', padding: K.spacing, color: 'black', backgroundColor: 'white', borderRadius: K.borderRadius, ...K.shadow}}
            onMouseEnter={() => this.setState({showingPricePopup: true})}
            onMouseLeave={() => this.setState({showingPricePopup: false})}
          >
            {projectPriceData.total.getAmount() === 0 ? (
              project.fittingsOnly ? (
                <div>Order cost will be reflected as you make changes in the Order step.
                  <br/>
                  <div>Delivery estimate is based on current lead time.</div>
                </div>
              ) : (
                <div>Project cost will be reflected as you make changes in the Layout step.
                  <div style={{textAlign: 'left', lineHeight: 1.4}}>
                    Delivery estimate is based on current lead time.<br/>
                    You will be able to choose a more specific<br/>
                    timeframe in the <b>Deposit</b> step.
                  </div>
                </div>
              )
            ) : (
              project.fittingsOnly ? (
                <div style={{opacity: 0.7}}>
                  <div style={{display: 'inline-block'}}>
                    <div style={{textAlign: 'right', marginBottom: K.margin}}>{companyName} Product:</div>
                    <div style={{textAlign: 'right', marginBottom: K.margin}}>Shipping & Delivery:</div>
                    <div style={{textAlign: 'right', marginBottom: K.margin}}>Tax ({_.round(project.taxRate * 100, 2)}%):</div>
                    {(project.discounts && project.discounts.length > 0) ? _.map(project.discounts, (discount, i) => {
                      return <div key={i} style={{textAlign: 'right', marginBottom: K.margin}}>{discount.language || <br/>}</div>
                    }) : null}
                    <div style={{textAlign: 'right', marginBottom: K.margin}}>Total:</div>
                  </div>
                  <div style={{display: 'inline-block'}}>
                    <div style={{marginBottom: K.margin}}>{projectPriceData.subtotals.product.toFormat('$0,0.00')}</div>
                    <div style={{marginBottom: K.margin}}>{hasValidAddress ? projectPriceData.subtotals.shipping.toFormat('$0,0.00') : 'TBD'}</div>
                    <div style={{marginBottom: K.margin}}>{hasValidAddress ? projectPriceData.subtotals.tax.toFormat('$0,0.00') : 'TBD'}</div>
                    <div style={{marginBottom: K.margin}}>{hasValidAddress ? projectPriceData.total.toFormat('$0,0.00') : projectPriceData.subtotals.product.toFormat('$0,0.00')}</div>
                  </div>
                  <br/>
                  <div>Delivery estimate is based on current lead time.</div>
                </div>
              ) : (
                <div>
                  <div style={{opacity: 0.7}}>
                    <div style={{display: 'inline-block'}}>
                      <div style={{textAlign: 'right', marginBottom: K.margin}}>{companyName} Product:</div>
                      {!obfuscateShipping && (<div style={{textAlign: 'right', marginBottom: K.margin}}>Shipping & Delivery:</div>)}
                      <div style={{textAlign: 'right', marginBottom: K.margin}}>Tax ({project.taxRate * 100}%):</div>
                      {(project.discounts && project.discounts.length > 0) ? _.map(project.discounts, (discount, i) => {
                        return <div key={i} style={{textAlign: 'right', marginBottom: K.margin}}>{discount.language || <br/>}</div>
                      }) : null}
                      <div style={{textAlign: 'right', marginBottom: K.margin}}>Total:</div>
                      <br/>
                      {initialDeposit ? <div style={{textAlign: 'right', marginBottom: K.margin}}>Initial Deposit:</div> : ''}
                      <div style={{textAlign: 'right', marginBottom: K.margin}}>Design Sign-Off Payment:</div>
                      <div style={{textAlign: 'right', marginBottom: K.margin}}>Final Payment (Due 10 weeks after Design Sign-Off):</div>
                    </div>
                    <div style={{display: 'inline-block', paddingLeft: '2rem'}}>
                      <div style={{marginBottom: K.margin}}>
                        {!obfuscateShipping
                          ? projectPriceData.subtotals.product.toFormat('$0,0.00')
                          : `$${((projectPriceData.subtotals.product.getAmount() + projectPriceData.subtotals.shipping.getAmount()) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
                        }
                      </div>
                      {!obfuscateShipping && (<div style={{marginBottom: K.margin}}>{hasValidAddress ? projectPriceData.subtotals.shipping.toFormat('$0,0.00') : 'TBD'}</div>)}
                      <div style={{marginBottom: K.margin}}> {hasValidAddress ? projectPriceData.subtotals.tax.toFormat('$0,0.00') : 'TBD'}</div>
                      {(project.discounts && project.discounts.length > 0) ? _.map(project.discounts, (discount, discountIndex) => {
                        return <div key={discountIndex} style={{textAlign: 'right', marginBottom: K.margin}}>-{projectPriceData.subtotals.discounts[discountIndex].toFormat('$0,0.00')}</div>
                      }) : null}
                      <div style={{marginBottom: K.margin}}>{hasValidAddress ? projectPriceData.total.toFormat('$0,0.00') : projectPriceData.subtotals.product.toFormat('$0,0.00')}</div>
                      <br/>
                      {initialDeposit ? <div style={{marginBottom: K.margin}}>{initialDeposit ? <div style={{}}>{_.find(projectPriceData.payments, {key: 'initial'}).total.toFormat('$0,0.00')}</div> : ''}</div> : ''}
                      <div style={{marginBottom: K.margin}}>{hasValidAddress ? _.find(projectPriceData.payments, {key: 'signOff'}).total.toFormat('$0,0.00') : 'TBD'}</div>
                      <div style={{marginBottom: K.margin}}>{hasValidAddress ? _.find(projectPriceData.payments, {key: 'final'}).total.toFormat('$0,0.00') : 'TBD'}</div>
                    </div>
                  </div>
                  <br/>
                  <div style={{textAlign: 'left', lineHeight: 1.4}}>
                    Delivery estimate is based on current lead time.<br/>
                    You will be able to choose a more specific<br/>
                    timeframe in the <b>Deposit</b> step.
                  </div>
                </div>
              )
            )}
          </div>
        )}
        <div
          style={{width: '10vw', height: '60px'}}
          onClick={() => {
            this.props.onPriceElementClick();
          }}
          onContextMenu={(e) => {
            if (this.props.showingPriceData) {
              e.preventDefault()
              this.setState({pricingConstraintsPopupIsVisible: true});
            }
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column', color: 'black'}} onMouseEnter={() => this.setState({showingPricePopup: true})} onMouseLeave={() => this.setState({showingPricePopup: false})}>
            {this.props.showingPriceData && (
              <>
                <div style={{fontSize: '1rem', textAlign: 'left'}}>{priceText}</div>
                {false && <div style={{fontSize: '0.75rem', opacity: 0.6, textAlign: 'left', marginTop: 9}}><div style={{marginBottom: 2}}>Earliest delivery:</div>{getDeliveryDateText()}</div>}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withErrorBoundary(connect({
  mapState: state => {
    return {
      productOptionTypes: state.resources.productOptionTypes.byId,
      project: _.values(state.resources.projects.byId)[0],
      materialClasses: state.resources.materialClasses.byId,
      pricingRules: state.resources.pricingRules.byId,
      productTypes: state.resources.productTypes.byId,
      products: state.resources.products.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['updateProject'])
  }
})(PriceElement), {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => {
    global.handleError({error, info, message: 'pricing'});
  }
});