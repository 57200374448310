import _ from 'lodash';
import CFG from 'k';
// import lib from 'lib';

var ContainerTypesScriptHelpersFor = ({companyKey}) => {
  var sct = companyKey === 'hb' ? 0.75 : 0.5; //countertop, subcountertop, panel thickness
  var lightPoints = '[{x: -0.5, y: 0}, {x: -2.5, y: 5}, {x: 2.5, y: 5}, {x: 0.5, y: 0}, {x: -0.5, y: 0}]';
  //TODO flyby fronts
  //TODO materials on backs of islands`

  var script = {};

  script.frontIndicator = `
  var frontIndicator = (props) => {
    var shouldShow = _.getIsSelected ? (_.getIsSelected() ? 1 : 0) : 0;

    return shouldShow ? [
      text({
        text: 'front',
        top: '100% + 1',
        fontSize: 3,
        left: '50%',
        opacity: _.getIsSelected ? (_.getIsSelected() ? 1 : 0) : 1,
        fontWeight: 'lighter',
        origin: {y: 'top', x: 'center'},
      }),
      path(
        {top: '100% + 7', left: '50%', opacity: _.getIsSelected ? (_.getIsSelected() ? 1 : 0) : 1, fill: 'rgb(180, 90, 250)', strokeWidth: 0, ...props},
        [{x: 0, y: 0}, {x: -1, y: -1.5}, {x: 1, y: -1.5}]
      )
    ] : [];
  };
  `;

  script.subcounter = (adjustForWrap=true) => {
    return `
    (() => {
      var left = 0;
      var netScribeDistance = 0;
      var subcounterHeight = (_.props.customData.customSubcounterHeight) ? _.props.customData.customSubcounterHeight : (_.props.customData.subcounterHeight || _.props.customData.subcounterHeight === 0) ? _.props.customData.subcounterHeight : ${sct};

      lodash.forEach(scribesData, (scribeData, sideKey) => {
        if (lodash.includes(['left', 'right'], sideKey)) {
          var subcounterDistance = scribeData.distance;

          if (scribeData.adjacentType === 'container' && scribeData.model.hasSubcounter) subcounterDistance += subcounterHeight; //scribe inset

          netScribeDistance += subcounterDistance;

          if (sideKey === 'left') {
            left -= subcounterDistance;
          }

          if (${adjustForWrap}) {
            if (wrapSizes && wrapSizes[sideKey]) {
              netScribeDistance += wrapSizes[sideKey];

              if (sideKey === 'left') left -= wrapSizes[sideKey];
            }
          }
        }
      });

      return group({shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'subcounterMaterial'})}, [
        rect({
          className: 'subcounter',
          isFillable: true,
          left,
          width: '100% + ' + netScribeDistance,
          height: subcounterHeight
        }),
        rect({
          stroke: '',
          className: 'subcounter',
          isHatchFillable: true,
          hatchKey: 'subcounterMaterial',
          left,
          width: '100% + ' + netScribeDistance,
          height: subcounterHeight
        })
      ]);
    })()
    `
  };

  script.productLabelFor = ({key, hasShopDrawing}) => {
  //inside: island back panels, kick, seating rod
  //up: top cap panels
  //down: ctop, end panels, bottom cap panels

    //offset: ctop, cap panels
    return `
      (() => {
        var children = [];

        if (_.getLabelTextFor) {
          var arg = {key: '${key}'};
          var sharedProps = {preventInvertStroke: true};

          if (typeof(index) !== 'undefined') arg.index = index;

          if (${key === 'scribe'}) arg.scribeData = scribeData;

          var labelText = _.getLabelTextFor(arg);

          if (labelText) {
            var textProps = {
              text: labelText,
              fontSize: _.defaultFontSize,
              origin: {x: 'left', y: 'bottom'},
              left: 2,
              top: '100% - 1.5',
              fontWeight: 'bold',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              ...sharedProps
            };

            var labelWidth = 3 + labelText.length * 2;

            if (${key === 'islandExtensionFrame'}) lodash.extend(textProps, {top: 8, left: '50% - 1.5'});
            if (${key === 'islandExtension'}) lodash.extend(textProps, {top: '50%', left: '20%'});
            if (${key === 'islandSeating'}) lodash.extend(textProps, {top: seatingDepth, left: 2});
            if (${key === 'chase'}) lodash.extend(textProps, {top: '100% - 3/4 - ' + exteriorDepth, left: 2});
            if (${key === 'baseWithChase'}) lodash.extend(textProps, {top: exteriorDepth, left: 2});
            if (${key === 'bottomWrapPanel'}) lodash.extend(textProps, {origin: {x: 'left', y: 'top'}, top: '100% + 2'});
            if (${key === 'topWrapPanel'}) lodash.extend(textProps, {top: -2});
            if (${key === 'islandBackPanels'} && isSculpted) {
              lodash.extend(textProps, {top: '100% - ' + lodash.get(_.props, 'customData.sculptedPanel.bottomCasingHeight', 5)});
            }

            if (${key === 'kick'}) {
              var insetKickOffset = _.getInsetKickData().left ? 3.75 : 0;

              lodash.extend(textProps, {top: '100%', left: textProps.left + insetKickOffset});
            }

            if (${key === 'countertop'}) {
              var {labelPosition, labelXOffset=0} = _.props.customData;

              if (labelPosition === 'bottom') {
                lodash.extend(textProps, {top: '100% + 1.5',left: 2.5 + labelXOffset, origin: {x: 'left', y: 'top'}});
              }
              else {
                lodash.extend(textProps, {left: 2 + labelXOffset});
              }

              if (lodash.includes(labelText, 'B/O')) lodash.extend(textProps, {fontSize: 3});
            }

            if (${key === 'topWrapPanel'}) {
              lodash.extend(textProps, {backgroundStrokeWidth: isRenderingDrawings ? 1 : 2, backgroundStroke: lodash.get(_.props.customData, 'wrap.nonStandard.top') ? 'yellow' : 'black'});

              var {capPanelLabelPosition} = _.props.customData;

              var capPanelLabelPositionHorizontal = _.props.customData.wrap.topLabelPosition || 'left';

              if (capPanelLabelPosition === 'bottom') {
                lodash.extend(textProps, {top: '100% + 1.5',left: 10.5, origin: {x: 'left', y: 'top'}});
              }

              if (capPanelLabelPositionHorizontal === 'right') {
                lodash.extend(textProps, {left: '100% - 2' , origin: {x: 'right', y: capPanelLabelPosition === 'bottom' ? 'top' : 'bottom'}});
              }
            }

            var {endPanelLabelPosition, scribeProductionIdLocations={}, vanityLabelPosition='left'} = _.props.customData;

            if (_.props.type !== 'endPanel' && ${_.includes(['leftWrapPanel', 'rightWrapPanel'], key)}) {
              endPanelLabelPosition = _.props.customData.wrap.${key === 'leftWrapPanel' ? 'leftLabelPosition' : 'rightLabelPosition'};
            }

            if (${key === 'vanity'} && vanityLabelPosition === 'right') {
              var extendedTextProps = {
                origin: {x: 'right', y: 'bottom'},
                left: '100% - 2'
              };

              lodash.extend(textProps, extendedTextProps);
            }

            if ((${key === 'leftWrapPanel'} && !endPanelLabelPosition) || endPanelLabelPosition === 'right') {
              var positionTop = _.props.position.y > 10 ? true : false;
              var kickHeightOffset = _.getInsetKickData().left ? _.props.customData.kickHeight || 0 : 0;

              var top = '100% + 6 + ' + kickHeightOffset + ' + ' + _.props.position.y;
              var origin = {x: 'left', y: 'bottom'};

              if (positionTop) {
                top = -2;
                origin = {x: 'left', y: 'bottom'};
              }

              var extendedTextProps = {top, left: '100% + 2', origin, text: lodash.replace(labelText, ', ', '\\n')};

              lodash.extend(textProps, extendedTextProps);
            }
            if ((${key === 'rightWrapPanel'} && !endPanelLabelPosition) || endPanelLabelPosition === 'left') {
              var positionTop = _.props.position.y > 10 ? true : false;
              var kickHeightOffset = _.getInsetKickData().right ? _.props.customData.kickHeight || 0 : 0;
              var top = '100% + 6 + ' + kickHeightOffset + ' + ' + _.props.position.y;
              var origin = {x: 'right', y: 'bottom'};

              if (positionTop) {
                top = -2;
                origin = {x: 'right', y: 'bottom'};
              }

              var labelWidth = lodash.max(lodash.map(lodash.split(labelText, ','), label => lodash.trim(label).length)) * 2.75;

              var extendedTextProps = {top, left: -2, origin, ...(lodash.includes(labelText, ',') ? {width: labelWidth} : {}), text: lodash.replace(labelText, ', ', '\\n')};

              lodash.extend(textProps, extendedTextProps);
            }
            if (${key === 'scribe'}) {
              var kickHeight = _.props.customData.kickHeight || 0;
              var centerPoint = visibleDistance / 2;

              if (scribeData.sideKey === 'top') {
                lodash.extend(textProps, {top: -scribeData.distance, left: 2, origin: {x: 'left', y: 'bottom'}});
              }
              else {
                var labelLocation = scribeProductionIdLocations[scribeData.sideKey] || (scribeData.sideKey === 'right' ? 'right' : 'left');

                if (labelLocation === 'right') {
                  lodash.extend(textProps, {top: '2 + ' + kickHeight, left: centerPoint + 2, origin: {x: 'left', y: 'top'}, text: lodash.replace(labelText, ', ', '\\n')});
                }
                else if (labelLocation === 'left') {
                  var labelWidth = lodash.max(lodash.map(lodash.split(labelText, ','), label => lodash.trim(label).length)) * 2.75;

                  lodash.extend(textProps, {top: '2 + ' + kickHeight, left: centerPoint - 2, origin: {x: 'right', y: 'top'}, width: labelWidth, text: lodash.replace(labelText, ', ', '\\n')});
                }
              }
            }
            if (${key === 'seatingSupportRod'}) {
              var xOffset = _.props.customData.ctopSupportLabelXOffset || 0;
              var yOffset = _.props.customData.ctopSupportLabelYOffset || 0;
              var isRenderingDrawings = (_.getIsRenderingDrawings && _.getIsRenderingDrawings());
              lodash.extend(textProps, {backgroundStroke: 'black', backgroundStrokeWidth: isRenderingDrawings ? 1 : 2, top: 8 + yOffset, left: '30% + 0.5 + ' + xOffset});
            }

            if (${hasShopDrawing} || (${key === 'leftWrapPanel'} && lodash.get(_.props.customData, 'wrap.nonStandard.left')) || (${key === 'rightWrapPanel'} && lodash.get(_.props.customData, 'wrap.nonStandard.right'))) {
              var isRenderingDrawings = (_.getIsRenderingDrawings && _.getIsRenderingDrawings());
              lodash.extend(textProps, {backgroundStrokeWidth: isRenderingDrawings ? 1 : 2, backgroundStroke: 'yellow'});
            }

            if (lodash.get(_.props.customData, 'hasShopDrawing') && ${key === 'countertop'}) {
              var isRenderingDrawings = (_.getIsRenderingDrawings && _.getIsRenderingDrawings());
              lodash.extend(textProps, {backgroundStrokeWidth: isRenderingDrawings ? 1 : 2, backgroundStroke: 'yellow'});
            }

            children.push(text(textProps));

            if (${key === 'islandExtensionFrame'}) children.push(line({x1: '50%', x2: '50%', y1: .75, y2: 3.5, ...sharedProps}));

            if (${key === 'seatingSupportRod'}) {
              var xOffset = _.props.customData.ctopSupportLabelXOffset || 0;
              var yOffset = _.props.customData.ctopSupportLabelYOffset || 0;

              var textXPosition = _.dimensions.width * .3 + .5 + xOffset;
              var textYPosition = 8 + yOffset;

              if (yOffset < -2.85 && yOffset > -6.85 && textXPosition > -2 && textXPosition < _.dimensions.width) {

              }
              else {
                var xAxisSide = textXPosition > (_.dimensions.width / 2) ? 'right' : 'left';
                var yAxisSide = textYPosition > (_.dimensions.height / 2) ? 'bottom' : 'top';

                if ((textXPosition < _.dimensions.width * .7) && textXPosition > 0) xAxisSide = 'center';

                var lineFrom = {
                  x: {right: textXPosition, left: (textXPosition + 26 * .7), center: textXPosition + 5}[xAxisSide],
                  y: {top: textYPosition, bottom: textYPosition - 4}[yAxisSide],
                };
                var lineTo = {
                  x: {right: '70%', left: '30%', center: textXPosition + 5}[xAxisSide],
                  y: yAxisSide === 'top' ? 1 : _.dimensions.height - 1
                };

                children.push(line({x1: lineFrom.x, y1: lineFrom.y, x2: lineTo.x, y2: lineTo.y, strokeWidth: 2, hasBorder: true, borderColor: 'black',...sharedProps}));
              }
            }

            if (${key === 'countertop'}) {
              if (labelPosition === 'bottom') {
                var lineAndRectX = 2 + labelXOffset;

                children.push(line({x1: lineAndRectX + (labelWidth / 2), x2: lineAndRectX + (labelWidth / 2), y1: '50%', y2: '100% + 1', strokeWidth: 2, hasBorder: true, borderColor: 'black',...sharedProps}));
                // children.push(rect({top: '100% + 1', height: 4.5, left: lineAndRectX, width: labelWidth, strokeWidth: 2,...sharedProps}));
              }
              else {
                children.push(line({x1: 4 + labelXOffset, x2: 4 + labelXOffset, y1: '0', y2: '-1', hasBorder: true, borderColor: 'black',...sharedProps}));
              }
            }

            if (${_.includes(['leftWrapPanel', 'rightWrapPanel'], key)}) {
              var positionTop = _.props.position.y > 10 ? true : false;
              var lineX2 = ${key === 'leftWrapPanel' ? 1.5 : -1.5};

              if (endPanelLabelPosition === 'left') lineX2 = -1.5;
              if (endPanelLabelPosition === 'right') lineX2 = 1.5;

              var topOrigin = positionTop ? 0 : '100%';
              var topTo = positionTop ? -3.8 : ('100% + 3.5 + ' + kickHeightOffset + ' + ' + _.props.position.y);


              children.push(line({x1: '50%', x2: '50%', y1: topOrigin, y2: topTo, ...sharedProps}));
              children.push(line({x1: '50%', x2: '50% + ' + lineX2, y1: topTo, y2: topTo, ...sharedProps}));
            }

            if (${key === 'topWrapPanel'}) {
              var capPanelLabelPositionHorizontal = _.props.customData.wrap.topLabelPosition || 'left';

              if (capPanelLabelPosition === 'bottom') {
                var lineX = capPanelLabelPositionHorizontal === 'right' ? ('100% - 2.5') : (10 + (labelWidth / 2));

                children.push(line({x1: lineX, x2: lineX, y1: '50%', y2: '100% + 1.75', shouldScaleStrokeWidth: true, strokeWidth: .25, hasBorder: true, borderColor: 'black',...sharedProps}));
              }
              else {
                var lineX = capPanelLabelPositionHorizontal === 'right' ? '100% - 2 - ' : '2 + ';
                children.push(line({x1: lineX + labelText.length * 2.25 / 2, x2: lineX + labelText.length * 2.25 / 2, y1: 0, y2: -1.5, ...sharedProps}));
              }
            }

            if (${_.includes(['bottomWrapPanel'], key)}) {
              children.push(line({x1: 2 + labelText.length * 2.25 / 2, x2: 2 + labelText.length * 2.25 / 2, y1: '100%', y2: '100% + 1.5', ...sharedProps}));
            }

            if (${key === 'scribe'}) {
              if (lodash.includes(['left', 'right'], scribeData.sideKey)) {
                var scribeSideOffset = labelLocation === 'left' ? -1.5 : 1.5;

                children.push(line({x1: centerPoint, x2: centerPoint, y1: '0', y2: '0 + 3.8 + ' + kickHeight, dashed: true, ...sharedProps}));
                children.push(line({x1: centerPoint, x2:  centerPoint + scribeSideOffset, y1: '3.8 + ' + kickHeight, y2: '3.8 + ' + kickHeight, ...sharedProps}));
              }
              else {
                children.push(line({x1: 2 + labelText.length * 2.25 / 2, x2: 2 + labelText.length * 2.25 / 2, y1: -scribeData.distance + 1, y2: -scribeData.distance, ...sharedProps}));
              }
            }
          }
        }

        return group({}, children);
      })()
    `;
  };

  script.kickFor = (sideKey) => `
  (() => {
    var scribedKicks = {};
    var mainKickLeft = 0;
    var netUnwrappedScribeDistance = 0;
    var kickHeight =  _.props.customData.kickHeight || 0;
    var insetKickData = (_.getInsetKickData) ? _.getInsetKickData() : {};
    var kickInset = (_.getKickInset) ? _.getKickInset() : 3.75;
    var scribesData = (_.getScribesData) ? _.getScribesData() : {};

    var kickGroup = ({width, left}) => {
      return group({
        width: width,
        left: left,
        shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'kickMaterial'})
      }, [
        rect({stroke: '', className: 'kick' ,isFillable: true}),
        rect({isHatchFillable: true, hatchKey: 'kickMaterial'})
      ]);
    };

    if (${sideKey === 'front'}) {
      if (insetKickData.left) {
        var leftIndent = 3.75 - wrapSizes.left;
        mainKickLeft += leftIndent;
        netUnwrappedScribeDistance -= leftIndent;
      }
      if (insetKickData.right) {
        netUnwrappedScribeDistance -= (3.75 - wrapSizes.right);
      }

      lodash.forEach(scribesData, (scribeData, sideKey) => {
        if (lodash.includes(['left', 'right'], sideKey)) {
          var kickDistance = scribeData.distance;
          if (scribeData.adjacentType === 'container' && scribeData.model.hasKick) kickDistance += 3.75 + 1/8; //kick inset + panel overhang

          if (!wrap[sideKey]) {
            netUnwrappedScribeDistance += kickDistance;

            if (sideKey === 'left') {
              mainKickLeft -= kickDistance;
            }
          }
          else {
            if (scribeData.adjacentType === 'container' && scribeData.model.hasKick) {
              scribedKicks[sideKey] = kickGroup({
                left: sideKey === 'left' ? -(kickDistance + wrapSizes[sideKey]) : '100% + ' + wrapSizes[sideKey],
                width: kickDistance
              });
            }
          }
        }
      });
    }
    else {
      if (insetKickData.right) {
        var leftIndent = kickInset - wrap.left;
        mainKickLeft += leftIndent;
        netUnwrappedScribeDistance -= leftIndent;
      }
      if (insetKickData.left) {
        netUnwrappedScribeDistance -= (kickInset - wrap.right);
      }
    }

    return group({height: kickHeight, top: '100% -' + kickHeight}, [
      kickGroup({
        width: '100% + ' + netUnwrappedScribeDistance,
        left: mainKickLeft
      }),
      ...lodash.values(scribedKicks),
      ${script.productLabelFor({key: 'kick'})}
    ])
  })()
  `;

  script.freestandingAppliance = ({text, sideKey}) => {
    return `
      var vendor = lodash.get(_, 'props.customData.vendor');
      var label = lodash.get(_, 'props.customData.label');
      var rotation = lodash.get(_, 'props.rotation');
      var isUpsideDown = ${sideKey === 'top'} && rotation === 180;
      var customText = lodash.get(_, 'props.customData.graphicLabel');
      var title = customText || (((vendor && label) ? (vendor + ' - ' + label) : (vendor ? vendor : label)) || '${text}');
      var top = ${text === 'range' && sideKey === 'top'} ?  '100% - 1.5' : '50% - 2'
      var left = '50%'
      var showingRenderings = _.activeDetailLevel === 'rendering';
      var origin = ${text === 'range' && sideKey === 'top'} ? {x: 'center', y: 'bottom'} : {x: 'center' , y: 'center' };
      var rotation = isUpsideDown ? -rotation : 0;

      if (isUpsideDown) {
        origin = {x: 'center', y: 'top'};
      }

      var indicatorShapes = [];

      ${sideKey === 'top' ? `
        ${script.frontIndicator}

        indicatorShapes.push(...frontIndicator());
      ` : ''}

      group({}, [
        rect({className: 'freestanding', isFillable: true}),
        ...(!showingRenderings ? [text({
          top, left,
          text: title,
          width: '100%',
          fontSize: 4,
          stroke: '',
          origin,
          rotation
        })] : []),
        ...indicatorShapes
      ]);
    `;
  };

  script.scribesFor = ({sideKey, containerType, hasSubcounter}) => {
    return `
      var scribes = [];
      var kickHeight = _.props.customData.kickHeight || 0;

      lodash.forEach(scribesData, (scribeData, sideKey) => {
        if (${_.includes(['left', 'right', 'top'], sideKey)}) {
          if (scribeData.type === 'corner' && ${sideKey === 'top'}) {
            scribes.push(group({width: scribeData.distance}, [
              rect({
                isFillable: true,
                width: scribeData.distance,
                height: 0.75,
                left: scribeData.sideKey === 'left' ? -scribeData.distance : _.props.dimensions.width,
                top: \`100% - 0.75 - \${scribeData.inset}\`
              }),
              rect({
                isFillable: true,
                width: 0.75, height: 4,
                left: scribeData.sideKey === 'left' ? - 0.75 : _.props.dimensions.width,
                top: \`100% - 4.75 - \${scribeData.inset}\`
              })
            ]));
          }
          else {
            if (${_.includes(['left', 'right'], sideKey)} && sideKey === 'top') {
              var scribeDepth = scribeData.soffitDepth ? _.props.dimensions.depth - scribeData.soffitDepth : 0;
              var leftSideScribeDepth = scribeDepth > 0 ?  (scribeData.soffitDepth || 0) - 0.75 : '100% - 0.75';
              var rightSideScribeDepth = scribeDepth > 0 ?  scribeDepth : 0;

              if (scribeData.type === 'recessed') {
                leftSideScribeDepth += '- 0.875';
                rightSideScribeDepth += 0.875;
              }

              scribes.push(group({}, [
                rect({
                  isFillable: true,
                  width: 0.75,
                  height: scribeData.distance - 1,
                  left: ${sideKey === 'left'} ? leftSideScribeDepth : rightSideScribeDepth,
                  top: -scribeData.distance + 1}),
                  rect({
                    isHatchFillable: true,
                    hatchKey: scribeData.sideKey + 'ScribeMaterial',
                    width: 0.75,
                    height: scribeData.distance - 1,
                    left: ${sideKey === 'left'} ? leftSideScribeDepth : rightSideScribeDepth,
                    top: -scribeData.distance + 1,
                    stroke: ''
                  }),
              ]));
            }
            else if (lodash.includes(['left', 'right'], sideKey) && ${sideKey === 'top'} && lodash.includes(['recessed', 'panel'], scribeData.type)) {
              scribes.push(group({width: scribeData.distance}, [
                rect({
                  isFillable: true,
                  width: scribeData.distance,
                  height: 0.75,
                  left: scribeData.sideKey === 'left' ? - scribeData.distance : _.props.dimensions.width,
                  top: \`100% - 0.75 - \${scribeData.inset}\`
                }),
                rect({
                  isHatchFillable: true,
                  hatchKey: scribeData.sideKey + 'ScribeMaterial',
                  width: scribeData.distance,
                  height: 0.75,
                  left: scribeData.sideKey === 'left' ? - scribeData.distance : _.props.dimensions.width,
                  top: \`100% - 0.75 - \${scribeData.inset}\`,
                  stroke: ''
                })
              ]));
            }
          }
        }
        else if (${sideKey === 'front'}) {
          var wrapSizes = _.getWrapSizes();
          var visibleDistance = (scribeData.distance || 0);
          var left = scribeData.sideKey === 'left' ? -visibleDistance : '100%';
          var breakLines = [];
          var hideBreakLines = scribeData.type === 'recessed' && ${companyKey === 'hb'} && (scribeData.sideKey !== 'top' || (scribeData.pathData && scribeData.pathData.points));

          if (scribeData.sideKey === 'top') {
            left = scribeData.xOrigin + (lodash.get(scribesData, 'left.distance') ? 0 : wrapSizes.left);
          }

          if (hideBreakLines && (scribeData.pathData && scribeData.pathData.points)) {
            scribes.push(
              group({top, left, shouldInvertStroke: _.getShouldInvertStroke({materialKey: scribeData.sideKey + 'ScribeMaterial'})}, [
                path({closed: true, strokeWidth: 1}, scribeData.pathData.points)
              ])
            );
          }

          lodash.forEach(scribeData.pathsData, (pathPoints, index) => {
            var top = 0;

            if (scribeData.sideKey !== 'top') {
              var subcounterThickness = ${hasSubcounter} ? ((_.props.customData.customSubcounterHeight) ? _.props.customData.customSubcounterHeight : (_.props.customData.subcounterHeight || _.props.customData.subcounterHeight === 0) ? _.props.customData.subcounterHeight : ${sct}) : 0;

              top = '100% -' + subcounterThickness;

              var bottomThickness = _.props.customData.wrap ? _.props.customData.wrap.bottomThickness : 0;

              top = (!_.props.customData.wrap || !_.props.customData.wrap.bottom) ? '100%' : '100% -' + bottomThickness;

              if (lodash.get(scribeData, 'model.kickExtending', false)) {
                top = '100% -' + kickHeight;
              }
            }

            var label = scribeData.type === 'recessed' ? [] : [${script.productLabelFor({key: 'scribe'})}];
            var y = -1 * (lodash.max(scribeData.lengths));

            if (!hideBreakLines) breakLines.push(line({x1: 0, y1: y, x2: scribeData.distance, y2: y}));

            if (scribeData.isTallerScribingToBaseCorner) {
              var {isTallerScribingToBaseCorner, adjacentSubcounterY, adjacentSubcounterHeight} = scribeData;

              breakLines.push(line({x1: 0, x2: scribeData.distance, y1: adjacentSubcounterY + kickHeight, y2: adjacentSubcounterY + kickHeight}));
              breakLines.push(line({x1: 0, x2: scribeData.distance, y1: adjacentSubcounterY - adjacentSubcounterHeight + kickHeight, y2: adjacentSubcounterY - adjacentSubcounterHeight + kickHeight}));
            }

            scribes.push(
              group({top, left, shouldInvertStroke: _.getShouldInvertStroke({materialKey: scribeData.sideKey + 'ScribeMaterial'})}, [
                ...label,
                path({stroke: '', strokeWidth: 0, isFillable: true, closed: true, className: 'scribe'}, pathPoints.points),
                path({
                  isHatchFillable: true, closed: true, hatchKey: scribeData.sideKey + 'ScribeMaterial', strokeWidth: (hideBreakLines && scribeData.pathData && scribeData.pathData.points) ? 0 : 1
                }, pathPoints.points),
                ...breakLines
              ])
            );

            left += pathPoints.width;
          });
        }
      })
    `;
  };

  script.forWrappedContainer = ({
    sideKey = 'front',
    isFillable = true,
    innerChildrenScript,
    hasKick = false,
    hasSubcounter = false,
    withChase = false,
    containerType
  }) => {
    var wrappedContainerScript;

    if (_.includes(['left', 'right'], sideKey)) {
      wrappedContainerScript = `
        var sideKey = '${sideKey}', hasKick = ${hasKick}, hasSubcounter = ${hasSubcounter}; var containerType = '${containerType}';
        var elevationMode = _.getFrontViewMode();
        var {wrap, unitDepth} = _.props.customData;
        var wrapSizes = _.getWrapSizes ? _.getWrapSizes() : {};

        var getShapes = ({mode, endPanelOnly}) => {
          if (_.props.type === 'endPanel') sideKey = 'left';
          var {isDashed={}} = wrap;
          var meldedContainersData = lodash.invoke(_, 'getMeldedContainersData') || {};
          var meldedContainerData = meldedContainersData['${sideKey}'];
          var isMelded = meldedContainerData !== undefined && lodash.size(meldedContainerData) > 0;
          var endPanelByOthers = lodash.get(_.props, 'customData.wrap[' + sideKey + 'ByOthers' + ']');
          var showGrainFlow = ${_.includes(['endPanel'], containerType)} && !endPanelByOthers;
          var children = [];
          var kickHeight = _.props.customData.kickHeight || 0;
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};
          var flybyData = _.getFlybyData();
          var insetKickData = _.getInsetKickData() || 3.75;
          var kickInset = _.getKickInset();
          var endPanelHeights = _.getEndPanelHeights ? _.getEndPanelHeights() : lib.object.fromKeys(['left', 'right'], () => ({visible: [_.dimensions.height]}));
          var overhang = wrap[sideKey] ? wrap.overhang : 0;
          var {boldStyle={}, boldDirection={}, isSTMetalEndPanel, leftPanelRoundedSides, rightPanelRoundedSides} = wrap;
          var stileLabels = [];
          var isBoldPanel = !endPanelByOthers && wrap[sideKey] && wrapSizes[sideKey] === 4;
          var boldIsRadiused = boldStyle[sideKey] === 'radius';
          var isSTMetalEndPanel = wrap[sideKey] && wrapSizes[sideKey] === 3 && isSTMetalEndPanel;
          var leftRadiusDepth = 0;
          var rightRadiusDepth = 0;

          var panelRoundedSides = 'none';
          var isRadiused = false;

          if (wrapSizes[sideKey] === 1.5) {
            panelRoundedSides = (sideKey === 'left' ? leftPanelRoundedSides : rightPanelRoundedSides) || 'none';
            isRadiused = panelRoundedSides !== 'none';
          }
          else if (isBoldPanel && boldIsRadiused) {
            isRadiused = true;
            panelRoundedSides = boldStyle[sideKey + 'PanelRoundedSides'] || 'both';
          }

          var showKick = (mode === 'section' || insetKickData[sideKey] || !wrap[sideKey]) && hasKick;
          var showSubcounter = (mode === 'section' || !wrap[sideKey]) && hasSubcounter;

          var height = (wrap[sideKey] && mode !== 'section') ? lodash.sum(endPanelHeights[sideKey].visible) : '100%';

          //< main path
          //represents (end panel) in elevation or (box + kick) in section
          //not closed when melded and in elevation
          //offset top when subcounter exists
          var pathPointsMap = {
            leftKick: [
              {x: 0, y: 0},
              {x: '100%', y: 0},
              {x: '100%', y: height + ' - ' + kickHeight},
              {x: _.dimensions.depth - kickInset, y: height + ' - ' + kickHeight},
              {x: _.dimensions.depth - kickInset, y: height},
              {x: 0, y: height}
            ],
            rightKick: [
              {x: '100%', y: 0},
              {x: 0, y: 0},
              {x: 0, y: height + ' - ' + kickHeight},
              {x: kickInset + (mode === 'section' ? 0 : overhang), y: height + ' - ' + kickHeight},
              {x: kickInset + (mode === 'section' ? 0 : overhang), y: height},
              {x: '100%', y: height}
            ],
            leftNoKick: [
              {x: 0, y: 0},
              {x: '100%', y: 0},
              {x: '100%', y: height},
              {x: 0, y: height}
            ],
            rightNoKick: [
              {x: '100%', y: 0},
              {x: 0, y: 0},
              {x: 0, y: height},
              {x: '100%', y: height}
            ]
          };

          var pathPoints = pathPointsMap[sideKey + (showKick ? 'Kick' : 'NoKick')];

          var left = 0;

          var sculptedChildren = [];
          var isSculpted = !showKick && (wrap.style && wrap.style[sideKey] !== 'panel');
          var wrapPanelWidths = _.getWrapPanelWidths ? _.getWrapPanelWidths() : {};
          var wrapBackExtensions = _.getWrapBackExtensions ? _.getWrapBackExtensions() : {};
          var wrapBackExtension = wrapBackExtensions[sideKey] || 0;
          var width = _.dimensions.depth;

          if (insetKickData[sideKey]) {
            width = wrapPanelWidths[sideKey];

            //HINT panel widths including overhang even when wrap is turned off
            if (!wrap[sideKey]) width -= wrap.overhang;

            left = left + (${sideKey === 'left'} ? (_.dimensions.depth - width + overhang) : (-overhang));
          }
          else if (!showKick && wrap[sideKey] && !(mode === 'section' && !${hasKick})) {
            width = wrapPanelWidths[sideKey];

            if (isMelded && !meldedContainersData.isCenterContainer) {
              var {netMeldedDepth, endPanelsAreMelded} = meldedContainerData;

              if (endPanelsAreMelded) {
                if (meldedContainerData.isPrimaryContainer) {
                  width = netMeldedDepth;
                }
                else {
                  width = 0; //hide the secondary end panel
                }
              }
              else {
                width = netMeldedDepth / 2;
              }
            }

            left = left + (${sideKey === 'left'} ? (_.dimensions.depth - width + overhang) : (-overhang));

            showGrainFlow = !endPanelByOthers && (wrap[sideKey] || ${_.includes(['endPanel'], containerType)});
          }

          if (isSculpted) {
            var wrapPanelWidths = _.getWrapPanelWidths ? _.getWrapPanelWidths() : {};

            var sculptedData = {
              size: {height: _.props.dimensions.height, width},
              panelThickness: _.props.customData.wrap.thickness,
              boardType: wrap.style[sideKey],
              left
            };

            sculptedChildren = [${script.sculptedPanelScriptFor({containerType})}];
          }

          if (isRadiused) {
            leftRadiusDepth = isBoldPanel ? 1.625 : 1.5;
            rightRadiusDepth = isBoldPanel ? 1.625 : 1.5;

            if (isBoldPanel && !lodash.includes(['both', 'left'], panelRoundedSides)) leftRadiusDepth = 0.75;
            if (isBoldPanel && !lodash.includes(['both', 'right'], panelRoundedSides)) rightRadiusDepth = 0.75;

            if (_.activeDetailLevel === 'production' && mode !== 'section') {
              stileLabels.push(
                ...((panelRoundedSides === 'right' || panelRoundedSides === 'both')
                  ? [
                    text({
                      text: 'R',
                      top: '40%',
                      left: '100% - ' + rightRadiusDepth / 2 + ' + ' + overhang,
                      fontSize: 2,
                      origin: {x: 'center', y: 'center'},
                      fill: 'red'
                    })
                  ]
                  : []
                ),
                ...((panelRoundedSides === 'left' || panelRoundedSides === 'both')
                ? [
                  text({
                    text: 'R',
                    top: '40%',
                    left: left + leftRadiusDepth / 2,
                    fontSize: 2,
                    origin: {x: 'center', y: 'center'},
                    fill: 'red'
                  })
                ]
                : []
              ),
              )
            }
          }

          if (!showKick || insetKickData[sideKey]) pathPoints = lodash.map(pathPoints, point => ({...point, x: typeof(point.x) === 'string' ? point.x.replace('100%', width) : point.x}))

          var subcounterHeight = (_.props.customData.customSubcounterHeight) ? _.props.customData.customSubcounterHeight : (_.props.customData.subcounterHeight || _.props.customData.subcounterHeight === 0) ? _.props.customData.subcounterHeight : ${sct};

          var endPanelFillByOthers = lodash.get(_.props, 'customData.wrap[' + sideKey + 'CustomFill' + ']');
          var fillRectProps = {
            stroke: '',
            isFillable: true,
            height: height + ' - ' + (insetKickData[sideKey] ? kickHeight : 0),
            ...((${withChase} && !wrap[sideKey]) ? {
              width: unitDepth,
              left: ${sideKey === 'left'} ? _.props.dimensions.depth - unitDepth : 0
            } : {width, left})
          }

          var panelMaterialKey = ${containerType === 'capPanel'} ? 'topCapPanelMaterial' : 'endPanelMaterial';

          if (_.props.type === 'vanity') {
            panelMaterialKey = 'boxMaterial';
            endPanelByOthers = false;
          }

          if (_.props.type === 'islandBackPanel') {
            panelMaterialKey = 'islandBackPanelMaterial';
            endPanelByOthers = false;
          }

          children.push(group({
            top: hasSubcounter && showSubcounter ? subcounterHeight : ('100%' + ' - ' + height),
            height: hasSubcounter && showSubcounter ? height + ' - ' + subcounterHeight : height,
            shouldInvertStroke: _.getShouldInvertStroke({materialKey: panelMaterialKey})
          },
            [
              path({isFillable: true, dashed: isDashed[sideKey], closed: !isMelded, width, left}, pathPoints),
              ...((mode !== 'section' && (!(showKick && !insetKickData[sideKey]))) ? [
                rect(fillRectProps),
                rect({dashed: isDashed[sideKey], height: '100% - ' + (insetKickData[sideKey] ? kickHeight : 0),
                  left, width,
                  ...((wrap[sideKey] || ${containerType === 'capPanel'} || _.props.type === 'vanity' || _.props.type === 'islandBackPanel') ? ((_.showColors && endPanelByOthers) ?
                    {isFillable: true, fill: endPanelFillByOthers || ''} :
                    (endPanelByOthers ? {} : {isHatchFillable: true, hatchKey: panelMaterialKey})
                  ) : {})
                })] : []),
              ...sculptedChildren
            ]
          ));
          //> path

          if (insetKickData[sideKey]) {
            children.push(line({left, x1: 0, y1: height + ' - ' + kickHeight, x2: width, y2: height + ' - ' + kickHeight}));

            if (mode !== 'section') {
              children.push(rect({
                top: height + ' - ' + kickHeight,
                left: ${sideKey === 'left'} ? 0 : kickInset,
                height: kickHeight,
                width: _.dimensions.depth - kickInset, isHatchFillable: true, hatchKey: 'kickMaterial'
              }));
            }
          }

          //< subcounter rect
          if (showSubcounter) {
            children.push(
              rect({className: 'subcounter', isFillable: true, height: subcounterHeight, width: '100% - ' +  subcounterHeight, left: ${sideKey === 'right'} ? subcounterHeight : 0}),
              ...(mode !== 'section' ? [
                rect({stroke: '', className: 'subcounter', isHatchFillable: true, hatchKey: 'subcounterMaterial', height: subcounterHeight, width: '100% - ' +  subcounterHeight, left: ${sideKey === 'right'} ? subcounterHeight : 0}),
              ] : [])
            );
          }
          //> subcounter rect

          //height breaks
          var heightsForSideKey = endPanelHeights[sideKey];

          if (mode !== 'section' && wrap[sideKey] && heightsForSideKey && heightsForSideKey.visible && heightsForSideKey.visible.length > 0) {
            var netEndPanelHeight = lodash.sum(heightsForSideKey.visible);
            var totalPanelHeights = netEndPanelHeight;
            var currentEndPanelHeight = 0;

            lodash.forEach(heightsForSideKey.visible, panelHeight => {
              currentEndPanelHeight += panelHeight;

              var y = '(100% - ' + height + ') + ' + totalPanelHeights + ' - ' + currentEndPanelHeight;

              if (!endPanelByOthers) {
                children.push(line({x1: left, y1: y, x2: '100%', y2: y}));
              }
            });
          }

          //< melded lines
          if (!endPanelByOthers) {
            var isHorizontallyOrientedEndPanel = _.props.type === 'endPanel' && lodash.get(_.props.details, 'grainDirection.id', 'vertical') === 'horizontal';
            var breakingWidth = wrapSizes[sideKey] === 4 ? 48.5 : 47;

            if (isMelded && meldedContainerData.isPrimaryContainer && !meldedContainersData.isCenterContainer) {
              //split end panels
              if (!meldedContainerData.endPanelsAreMelded && ${sideKey === 'left'}) {
                var x = _.props.dimensions.depth - meldedContainerData.netMeldedDepth / 2;

                children.push(line({x1: x, y1: 0, x2: x, y2: height}));
              }

              //back of container lines
              var x = ${sideKey === 'right'} ? '100%' : 0;

              children.push(line({x1: x, y1: 0, x2: x, y2: height, dashed: true}));
            }
            else if (_.props.type === 'capPanel' && width > breakingWidth) {
              var netCapPanelWidth = (_.getNetCapPanelWidth) ? _.getNetCapPanelWidth() : 0;
              var capPanelHeights = _.getCapPanelWidths ? _.getCapPanelWidths() : [netCapPanelWidth];
              var capPanelLongestSideMaxDim = _.getCapPanelLongestSideMaxDim ? _.getCapPanelLongestSideMaxDim() : 95;

              if (!lodash.every(capPanelHeights, h1 => h1 < breakingWidth) || width > capPanelLongestSideMaxDim) {
                children.push(line({x1: left + width / 2, y1: 0, x2: left + width / 2, y2: height}));
              }
            }
            else if (width > breakingWidth && !(isHorizontallyOrientedEndPanel && netEndPanelHeight < breakingWidth)) {
              children.push(line({x1: left + width / 2, y1: 0, x2: left + width / 2, y2: height}));
            }
          }
          //> melded lines

          if (isRadiused) {
            children.push(line({x1: left + leftRadiusDepth, y1: 0, x2: left + leftRadiusDepth, y2: height}));
            children.push(line({x1: '100% + ' + overhang + ' - ' + rightRadiusDepth, y1: 0, x2: '100% + ' + overhang + ' - ' + rightRadiusDepth, y2: height}));
          }

          //< flyby line
          if (flybyData.right && sideKey === 'right') {
            children.push(line({x1: 0.75, y1: 0, x2: 0.75, y2: '100%'}))
          }
          if (flybyData.left && sideKey === 'left') {
            children.push(line({x1: '100% -' + 0.75, y1: 0, x2: '100% -' + 0.75, y2: '100%'}))
          }
          //> flyby line

          if (!endPanelOnly) {
            ${script.scribesFor({sideKey})}
          }
          else {
            scribes = []
          }

          if (showGrainFlow) {
            children.push(...((_.getGrainScript && _.activeDetailLevel !== 'schematic') ? [eval(_.getGrainScript())] : []));
          }

          if (endPanelOnly) {
            return group({}, [
              ...children,
              ...${innerChildrenScript || '[]'}
            ]);
          }
          else {
            return group({}, [
              ...((mode !== 'section' && scribes) ? scribes : []),
              ...children,
              ...${innerChildrenScript || '[]'},
              ...((mode === 'section' && scribes) ? scribes : []),
              ...(stileLabels ? stileLabels : [])
            ]);
          }
        }

        group({}, [
          ...((elevationMode === 'section' && wrap[sideKey] && wrapSizes[sideKey] && _.props.customData.showEndPanelInSection) ? [getShapes({mode: 'elevation', endPanelOnly: true})] : []),
          getShapes({mode: elevationMode})
        ]);
      `;
    }
    else {
      wrappedContainerScript = `
        var containerType = '${containerType}';
        var {dimensions} = _.props;
        var {wrap} = _.props.customData;
        var {thickness, topThickness, bottomThickness, isDashed={}, boldStyle={}, boldDirection={}, isSTMetalEndPanel, leftPanelRoundedSides, rightPanelRoundedSides} = wrap;
        var scribesData = (_.getScribesData) ? _.getScribesData() : {};

        var kickHeight = ${sideKey !== 'top' ? '_.props.customData.kickHeight || 0' : '0'};
        var {unitDepth} = _.props.customData;
        var flybyData = _.getFlybyData ? _.getFlybyData() : {};
        var insetKickData = _.getInsetKickData ? _.getInsetKickData() : {};
        var wrapPanelWidths = _.getWrapPanelWidths ? _.getWrapPanelWidths() : {};
        if (${_.includes(['front', 'back'], sideKey)}) var endPanelHeights = _.getEndPanelHeights ? _.getEndPanelHeights() : lib.object.fromKeys(['left', 'right'], () => ({visible: [_.dimensions.height]}));

        var exteriorDepth = ${withChase ? 'dimensions.depth - unitDepth' : '0'};
        var meldedContainersData = _.getMeldedContainersData ? _.getMeldedContainersData() : {};
        var isMelded = lodash.size(meldedContainersData) > 0;

        var wrapSizes = _.getWrapSizes ? _.getWrapSizes() : {};

        var innerWidth = dimensions.width - wrapSizes.left - wrapSizes.right;
        var subcounterHeight = (_.props.customData.customSubcounterHeight) ? _.props.customData.customSubcounterHeight : (_.props.customData.subcounterHeight || _.props.customData.subcounterHeight === 0) ? _.props.customData.subcounterHeight : ${sct};
        var innerHeight = ${sideKey === 'top' ? 'dimensions.depth' : `dimensions.height - wrapSizes.top - wrapSizes.bottom - ${hasSubcounter ? 'subcounterHeight' : 0}`};

        if ((flybyData.left || flybyData.right) && ${sideKey === 'top'}) innerHeight -= 3/4;

        var capPanelStackFor = ({side}) => {
          var netCapPanelWidth = (_.getNetCapPanelWidth) ? _.getNetCapPanelWidth() : 0;
          var capPanelHeights = _.getCapPanelWidths ? _.getCapPanelWidths() : [netCapPanelWidth];
          var left = scribesData.left && !wrap.left ? -scribesData.left.distance : 0;
          var capPanelByOthers = lodash.get(_.props, 'customData.wrap[' + side + 'ByOthers' + ']');
          var capPanelFillByOthers = lodash.get(_.props, 'customData.wrap[' + side + 'CustomFill' + ']');

          if (${sideKey === 'front'} && _.capPanelPathPointsFor) {
            var panelShape = path({
              className: 'capPanel wrapPanel', left,
              ...((_.showColors && capPanelByOthers) ? {
                isFillable: !isDashed[side], fill: capPanelFillByOthers
              } : {
                isHatchFillable: !isDashed[side], hatchKey: side === 'top' ? 'topCapPanelMaterial' : 'bottomCapPanelMaterial'
              }),
              dashed: isDashed[side]
            }, _.capPanelPathPointsFor({side}));
          }

          var stackChildren = lodash.map(capPanelHeights, (width, index) => {
            return group({width}, [
              rect({stroke: '', left, isFillable: !(${sideKey === 'top'} || (${sideKey === 'front'} && isDashed[side]))}),
              (${sideKey === 'front'} && _.capPanelPathPointsFor) ? panelShape :
              rect({dashed: ${sideKey === 'top'} || isDashed[side], left, className: 'capPanel wrapPanel',
                ...((_.showColors && capPanelByOthers) ? {
                  isFillable: !(${sideKey === 'top'} || (${sideKey === 'front'} && isDashed[side])), fill: capPanelFillByOthers
                } : {
                  isHatchFillable: !(${sideKey === 'top'} || (${sideKey === 'front'} && isDashed[side])), hatchKey: side === 'top' ? 'topCapPanelMaterial' : 'bottomCapPanelMaterial'
                }),
              }),
              (side === 'bottom' ? ${script.productLabelFor({key: 'bottomWrapPanel'})} : ${script.productLabelFor({key: 'topWrapPanel'})})
            ]);
          });

          ${sideKey === 'back' ? 'stackChildren.reverse()' : ''}

          return group({width: netCapPanelWidth || '100%', top: _.dimensions.depth - wrap.overhang, height: ${sideKey === 'top' ? '"100% + " + wrap.overhang' : 'wrapSizes[side]'},
            shouldInvertStroke: _.getShouldInvertStroke({materialKey: side === 'top' ? 'topCapPanelMaterial' : 'bottomCapPanelMaterial'}),
            ...((${sideKey === 'front'} && side === 'bottom') ? {top: '100% - ' + wrapSizes[side]} : {})
          }, [
            stack({axis: 'x', top: 0, height: '100%'}, stackChildren)
          ]);
        };

        var innerStackChildren = [];

        if (wrap.top && lodash.includes(['front', 'back'], '${sideKey}')) innerStackChildren.push(capPanelStackFor({side: 'top'}));

        var innerStackShapes = [];

        if (${isFillable} && ${!_.includes(['endPanel', 'capPanel'], containerType)}) {
          var boxShapes = [];

          var subcounterHeight = ${hasSubcounter} ? ((_.props.customData.customSubcounterHeight) ? _.props.customData.customSubcounterHeight : (_.props.customData.subcounterHeight || _.props.customData.subcounterHeight === 0) ? _.props.customData.subcounterHeight : ${sct}) : 0;

          if (${sideKey === 'front'}) {
            var boxStrokeWidth = 0.5;
            var leftEndPanelDashed = wrap['left'] && (isDashed['left'] || (flybyData['left'] && ${_.includes(['front', 'back'], sideKey)}));
            var rightEndPanelDashed = wrap.right && (isDashed['right'] || (flybyData['right'] && ${_.includes(['front', 'back'], sideKey)}));

            var leftHeight = (insetKickData.left * kickHeight) || 0;
            var rightHeight = (insetKickData.right * kickHeight) || 0;
            boxShapes.push(rect({className: 'dropzone', stroke: '', isFillable: true, top: 0, height: "100% -" + kickHeight}));
            boxShapes.push(line({shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'endPanelMaterial'}), opacity: leftEndPanelDashed ? 0 : 1, strokeWidth: boxStrokeWidth, dashed: flybyData.left, x1: 0, y1: 0, x2: 0, y2: '100% -' + leftHeight}));
            boxShapes.push(line({shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'endPanelMaterial'}), opacity: rightEndPanelDashed ? 0 : 1, strokeWidth: boxStrokeWidth, dashed: flybyData.right, x1: '100%', y1: 0, x2: '100%', y2: '100% -' + rightHeight}));
            boxShapes.push(line({shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'kickMaterial'}), strokeWidth: boxStrokeWidth, dashed: flybyData.top, x1: 0, y1: 0, x2: '100%', y2: 0}));
            boxShapes.push(line({shouldInvertStroke: _.getShouldInvertStroke({materialKey: wrap.top ? 'topCapPanelMaterial' : 'topScribeMaterial'}), strokeWidth: boxStrokeWidth, dashed: flybyData.bottom, x1: 0, y1: '100% -' + kickHeight, x2: '100%', y2: '100% -' + kickHeight}));
          }
          else {
            var boxShapesTop = subcounterHeight + exteriorDepth;
            var boxShapesHeight = "100% -" + (kickHeight + subcounterHeight);

            if (${withChase}) boxShapesHeight = unitDepth;

            if (${containerType === 'capPanel'}) {
              boxShapes.push(rect({dashed: true, isFillable: false, top: boxShapesTop, height: boxShapesHeight}));
            }
            else {
              boxShapes.push(rect({isFillable: true, top: boxShapesTop, height: boxShapesHeight}));
            }
          }

          innerStackShapes.push(...boxShapes);
        }

        if (${hasKick && sideKey !== 'top'}) {
          innerStackShapes.push(${script.kickFor(sideKey)});
        }

        var subcounterShapes = undefined;

        if (${hasSubcounter && sideKey !== 'top'}) {
          subcounterShapes = [${script.subcounter(false)}];
        }

        innerStackShapes.push(...(${innerChildrenScript || '[]'}));
        innerStackChildren.push(group({height: innerHeight}, innerStackShapes));

        if (wrap.bottom && '${sideKey}' !== 'top') innerStackChildren.push(capPanelStackFor({side: 'bottom'}));

        var outerStackChildren = [];

        var endPanelProductLabels = {
          left: ${script.productLabelFor({key: 'leftWrapPanel'})},
          right: ${script.productLabelFor({key: 'rightWrapPanel'})}
        };

        var addEndPanel = (sideKey) => {
          if (wrap[sideKey]) {
            var flybySize = ${sideKey === 'top'} ? (flybyData[sideKey] ? 7/8 : 0) : 0;
            //HINT dont use default arg because we want to override 0
            var endPanelWidth = lodash.get(wrap, 'depths.' + sideKey) || wrapPanelWidths[sideKey];
            var meldedContainerData = meldedContainersData[sideKey];
            var opacity = 1, top = 0;
            var wrapBackExtensions = _.getWrapBackExtensions ? _.getWrapBackExtensions() : {};
            var wrapBackExtension = wrapBackExtensions[sideKey] || 0;
            var height = ${sideKey === 'top'} ? endPanelWidth : '100%';
            var endPanelByOthers = lodash.get(_.props, 'customData.wrap[' + sideKey + 'ByOthers' + ']');
            var endPanelFillByOthers = lodash.get(_.props, 'customData.wrap[' + sideKey + 'CustomFill' + ']');

            top = top + (dimensions.depth - endPanelWidth + wrap.overhang - flybySize);

            if ('${sideKey}' === 'top' && meldedContainersData.isCenterContainer) {
              opacity = 0;
            }
            else if ('${sideKey}' === 'top' && meldedContainerData) {
              var {netMeldedDepth, endPanelsAreMelded} = meldedContainerData;

              if (endPanelsAreMelded) {
                if (meldedContainerData.isPrimaryContainer) {
                  height = netMeldedDepth;
                  top = -(netMeldedDepth - endPanelWidth);
                }
                else {
                  opacity = 0; //hide the secondary end panel
                }
              }
              else {
                height = netMeldedDepth / 2; //TODO
                top = -1 * (netMeldedDepth / 2 - endPanelWidth)
              }
            }

            var usePath = ${sideKey === 'front'} && _.endPanelPathPointsFor;
            var endPanelBreakLines = [];
            var endPanelDimensionLines = [];

            var addEndPanelDimensionLine = (props) => {
              endPanelDimensionLines.push(line({stroke: 'red', opacity: 0, className: 'wrapPanel endPanel ' + sideKey, ...props}))
            };

            var endPanelDashed = isDashed[sideKey] || (flybyData[sideKey] && ${_.includes(['front', 'back'], sideKey)});
            var endPanelShowingFill = !(${sideKey !== 'top'} && isDashed[sideKey]);

            var sharedProps = {opacity, dashed: endPanelDashed};
            var groupProps = {};

            var endPanelShape = [];
            var hideFillElements = false;
            var isBoldPanel = !endPanelByOthers && wrapSizes[sideKey] === 4;
            var panelRoundedSides = 'none';
            var isRadiused = false;

            if (lodash.includes(['left', 'right'], sideKey)) {
              isBoldPanel = wrapSizes[sideKey] === 4;
              var boldIsRadiused = boldStyle[sideKey] === 'radius';

              var sideIsSTMetalEndPanel = !endPanelByOthers && wrapSizes[sideKey] === 3 && isSTMetalEndPanel;

              if (sideIsSTMetalEndPanel) {
                isRadiused = true;
                var meldedOnSide = meldedContainerData && meldedContainerData.endPanelsAreMelded;

                //HINT rounded only on front side, when melded, rounded on both
                panelRoundedSides = meldedOnSide ? 'both' : (sideKey === 'left' ? 'right' : 'left');
              }

              if (wrapSizes[sideKey] === 1.5) {
                panelRoundedSides = (sideKey === 'left' ? leftPanelRoundedSides : rightPanelRoundedSides) || 'none';
                isRadiused = panelRoundedSides !== 'none';
              }
              else if (isBoldPanel && boldIsRadiused) {
                isRadiused = true;
                panelRoundedSides = boldStyle[sideKey + 'PanelRoundedSides'] || 'both';
              }
            }

            if (${_.includes(['front', 'back'], sideKey)}) {
              var heightsForSideKey = endPanelHeights[sideKey];
              var netEndPanelHeight = lodash.sum(heightsForSideKey.visible);
              var height = netEndPanelHeight;
              var currentEndPanelHeight = 0;

              lodash.forEach(heightsForSideKey.visible, height => {
                currentEndPanelHeight += height;

                var y = netEndPanelHeight - currentEndPanelHeight;

                if (!endPanelByOthers) {
                  endPanelBreakLines.push(line({x1: 0, y1: y, x2: '100%', y2: y, dashed: endPanelDashed}));
                }
              });

              top = '100% - ' + (netEndPanelHeight + heightsForSideKey.insetKickHeight);

              // addEndPanelDimensionLine({sideKey, x1: 0, y1: '100%', x2: '100%', y2: '100%'});

              var epdlY = 0;

              lodash.forEach(heightsForSideKey.visible, height => {
                // addEndPanelDimensionLine({sideKey, x1: 0, y1: '100% - ' + epdlY, x2: 0, y2: '100% - ' + (epdlY + height)});

                epdlY += height;
              });

              groupProps.height = netEndPanelHeight;
            }
            else {
              var shouldBreakHeights = !endPanelByOthers && _.getShouldBreakDeepBaseEndPanels && _.getShouldBreakDeepBaseEndPanels(sideKey);
              var isHorizontallyOrientedEndPanel = _.props.type === 'endPanel' && lodash.get(_.props.details, 'grainDirection.id', 'vertical') === 'horizontal';
              var breakingDim = wrapSizes[sideKey] === 4 ? 48.5 : 47;
              var underBreakingDimTall = (_.getEndPanelHeights ? _.getEndPanelHeights() : lib.object.fromKeys(['left', 'right'], () => ({visible: [_.dimensions.height]})))[sideKey].visible < breakingDim;
              var heights = (shouldBreakHeights && height > breakingDim && !(isHorizontallyOrientedEndPanel && underBreakingDimTall)) ? [height / 2, height / 2] : [height];
              var netEndPanelHeight = height;
              var currentEndPanelHeight = 0;

              if ((sideKey === 'left' && (panelRoundedSides === 'left' || panelRoundedSides === 'both')) || (sideKey === 'right' && (panelRoundedSides === 'right' || panelRoundedSides === 'both'))) {
                heights = lodash.dropRight(heights);
              }

              lodash.forEach(heights, height => {
                currentEndPanelHeight += height;
                var y = netEndPanelHeight - currentEndPanelHeight;

                endPanelBreakLines.push(line({opacity, x1: 0, y1: y, x2: '100%', y2: y, dashed: endPanelDashed}));
              });

              sharedProps.className = 'wrapPanel endPanel ' + sideKey;
            }

            if (usePath && !isBoldPanel && !sideIsSTMetalEndPanel) {
              endPanelShape = [path({...sharedProps, top: netEndPanelHeight}, _.endPanelPathPointsFor({sideKey}))];
            }
            else {
              endPanelShape = [rect({...sharedProps, height})];

              if (!endPanelByOthers && ${sideKey === 'top'} && isRadiused) {
                var radiusDepth = isBoldPanel ? 1.625 : 1.5;
                var radiusWidth = wrapSizes[sideKey];
                var stileRoundedSides = panelRoundedSides;

                if (sideIsSTMetalEndPanel) {
                  radiusDepth = 0.75;
                }

                var stiles = [];

                var leftStileRounded = lodash.includes(['both', 'left'], stileRoundedSides);
                var rightStileRounded = lodash.includes(['both', 'right'], stileRoundedSides);
                var totalStileDepth = 0;
                var topStileDepth = 0;

                lodash.times(2, n => {
                  var isLeftStile = (sideKey === 'right') ? n === 1 : n === 0;
                  var stileOrientation = (sideKey === 'left' ? {
                    left: 'upperLeft',
                    right: 'lowerLeft'
                  } : {
                    left: 'lowerRight',
                    right: 'upperRight'
                  })[isLeftStile ? 'left' : 'right'];

                  //HINT support for standalone end panel containers where it's ambiguous which side might be rounded
                  if (${containerType === 'endPanel'} && boldDirection[sideKey] === 'right') {
                    stileOrientation = isLeftStile ? 'upperRight' : 'lowerRight';
                  }

                  var stileFacingUp = lodash.includes(stileOrientation, 'upper');

                  //hint n 0 is left stile
                  var stileRounded = isLeftStile ? leftStileRounded : rightStileRounded;
                  var stile;
                  var stileDepth = radiusDepth;

                  if (isBoldPanel && !stileRounded) stileDepth = 0.75;

                  if (stileFacingUp) topStileDepth = stileDepth;

                  if (stileRounded) {
                    var origin = {
                      upperLeft: radiusWidth,
                      lowerLeft: radiusWidth,
                      upperRight: 0,
                      lowerRight: 0
                    }[stileOrientation];
                    var endX = {
                      upperLeft: 0,
                      lowerLeft: 0,
                      upperRight: radiusWidth,
                      lowerRight: radiusWidth
                    }[stileOrientation];
                    var intermediateX = {
                      upperLeft: sideIsSTMetalEndPanel ? 0.75 : 1.25,
                      lowerLeft: sideIsSTMetalEndPanel ? 0.75 : 1.25,
                      upperRight: radiusWidth - (sideIsSTMetalEndPanel ? 0.75 : 1.25),
                      lowerRight: radiusWidth - (sideIsSTMetalEndPanel ? 0.75 : 1.25),
                    }[stileOrientation];
                    var flipped = {
                      upperLeft: true,
                      lowerLeft: false,
                      upperRight: false,
                      lowerRight: true
                    }[stileOrientation];
                    var top = {
                      upperLeft: 0,
                      lowerLeft: '100% - ' + stileDepth,
                      upperRight: 0,
                      lowerRight: '100% - ' + stileDepth,
                    }[stileOrientation];

                    stile = path({isFillable: true, top}, [
                      {x: origin, y: stileFacingUp ? stileDepth : 0},
                      {x: endX, y: stileFacingUp ? stileDepth : 0},
                      {x: endX, y: stileFacingUp ? (sideIsSTMetalEndPanel ? 0.75 : 1.25) : stileDepth - (sideIsSTMetalEndPanel ? 0.75 : 1.25)},
                      {x: intermediateX, y: stileFacingUp ? 0 : stileDepth, arc: true, quarterCircle: true,
                        flipped},
                      {x: origin, y: stileFacingUp ? 0 : stileDepth},
                      {x: origin, y: stileFacingUp ? stileDepth : 0},
                    ]);

                    if (sideIsSTMetalEndPanel) {
                      stile = path({top}, [
                        // {x: origin, y: stileFacingUp ? stileDepth : 0},
                        // {x: endX, y: stileFacingUp ? stileDepth : 0},
                        {x: endX, y: stileFacingUp ? (sideIsSTMetalEndPanel ? 0.75 : 1.25) : stileDepth - (sideIsSTMetalEndPanel ? 0.75 : 1.25)},
                        {x: intermediateX, y: stileFacingUp ? 0 : stileDepth, arc: true, quarterCircle: true,
                          flipped},
                        {x: origin, y: stileFacingUp ? 0 : stileDepth},
                        {x: origin, y: stileFacingUp ? stileDepth : 0},
                      ]);

                      stiles.push(path({isFillable: true, top, stroke: ''}, [
                        {x: origin, y: stileFacingUp ? stileDepth : 0},
                        {x: endX, y: stileFacingUp ? stileDepth : 0},
                        {x: endX, y: stileFacingUp ? (sideIsSTMetalEndPanel ? 0.75 : 1.25) : stileDepth - (sideIsSTMetalEndPanel ? 0.75 : 1.25)},
                        {x: intermediateX, y: stileFacingUp ? 0 : stileDepth, arc: true, quarterCircle: true,
                          flipped},
                        {x: origin, y: stileFacingUp ? 0 : stileDepth},
                        {x: origin, y: stileFacingUp ? stileDepth : 0},
                      ]))
                    }

                    var labelOffsetDirection = {
                      upperLeft: -1,
                      lowerLeft: -1,
                      upperRight: 1,
                      lowerRight: 1
                    }[stileOrientation];
                    var labelOffset = {
                      x: ((sideKey === 'left' ? 0 : radiusWidth) + 2) * labelOffsetDirection
                    }

                    if (stileRounded && _.activeDetailLevel === 'production') {
                      stiles.push(text({
                        text: 'R',
                        top: top,
                        left: labelOffset.x,
                        fontSize: 2,
                        rotation: 360 - _.props.rotation,
                        origin: {x: sideKey, y: 'top'},
                        fill: 'red'
                      }));
                    }
                  }
                  else {
                    if (!(isMelded)) {
                      var top = {
                        upperLeft: 0,
                        lowerLeft: '100% - ' + stileDepth,
                        upperRight: 0,
                        lowerRight: '100% - ' + stileDepth,
                      }[stileOrientation];

                      stile = rect({
                        isFillable: true, width: radiusWidth, height: stileDepth, top
                      });

                      if (sideIsSTMetalEndPanel) {
                        stile = undefined;

                        stiles.push(
                          rect({
                            isFillable: true, width: radiusWidth, height: stileDepth, top, stroke: ''
                          }),
                          line({x1: 0, x2: 0, y1: top, y2: top + stileDepth}),
                          line({x1: radiusWidth, x2: radiusWidth, y1: top, y2: top + stileDepth})
                        );
                      }

                    }
                    else {
                      stileDepth = 0;
                      totalStileDepth = 0;
                      topStileDepth = 0;
                    }
                  }

                  totalStileDepth += stileDepth;
                  if (stile) stiles.push(stile);
                });

                //HINT st metal end panels don't have visible stiles
                if (isSTMetalEndPanel) {
                  endPanelShape = [group({height: netEndPanelHeight, opacity}, [
                    rect({isFillable: true, stroke: '', top: topStileDepth - 0.01, height: '100% - ' + (totalStileDepth - 0.02), width: radiusWidth}),
                    line({y1: topStileDepth, y2: '100% - ' + (totalStileDepth - topStileDepth), x1: 0, x2: 0}),
                    line({y1: topStileDepth, y2: '100% - ' + (totalStileDepth - topStileDepth), x1: radiusWidth, x2: radiusWidth}),
                    ...stiles
                  ])];
                }
                else {
                  endPanelShape = [group({height: netEndPanelHeight, opacity}, [
                    rect({isFillable: true, top: topStileDepth, height: '100% - ' + totalStileDepth, width: radiusWidth}),
                    ...stiles
                  ])];
                }

                hideFillElements = true;
              }
              else if (!endPanelByOthers && (isBoldPanel || sideIsSTMetalEndPanel) && ${_.includes(['back', 'front'], sideKey)}) {
                endPanelShape = [
                  rect({isFillable: endPanelShowingFill, opacity, height: netEndPanelHeight - 1/4, stroke: ''}),
                  rect({opacity, height: netEndPanelHeight - 1/4,
                    ...((_.showColors && endPanelByOthers) ?
                      {isFillable: endPanelShowingFill, fill: endPanelFillByOthers || ''} :
                      (endPanelByOthers ? {} : {isHatchFillable: endPanelShowingFill, hatchKey: ${containerType === 'capPanel'} ? 'topCapPanelMaterial' : 'endPanelMaterial'})
                    )
                  }),
                  rect({isFillable: endPanelShowingFill, height: 1/4, top: '100% - 1/4', left: 1/4, width: '100% - 1/2', stroke: ''}),
                  rect({height: 1/4, top: '100% - 1/4', left: 1/4, width: '100% - 1/2',
                    ...((_.showColors && endPanelByOthers) ?
                      {isFillable: endPanelShowingFill, fill: endPanelFillByOthers || ''} :
                      (endPanelByOthers ? {} : {isHatchFillable: endPanelShowingFill, hatchKey: ${containerType === 'capPanel'} ? 'topCapPanelMaterial' : 'endPanelMaterial'})
                    )
                  }),
                ];

                hideFillElements = true;
              }
            }

            if (!endPanelByOthers && ${_.includes(['front', 'back'], sideKey)} && isRadiused) {
              var showingRadiusSideOfPanel = ${sideKey === 'front'} ? (sideKey === 'left' ? 'right' : 'left') : sideKey;
              var showingRadius = panelRoundedSides === 'both' || panelRoundedSides === showingRadiusSideOfPanel;

              if (showingRadius && _.activeDetailLevel === 'production') {
                endPanelShape.push(text({
                  text: 'R',
                  top: '40%',
                  left: ((sideKey === 'left' && ${sideKey === 'back'}) || (sideKey === 'right' && ${sideKey === 'front'})) ? '100%' : 0,
                  fontSize: 2,
                  origin: {x: 'center', y: 'center'},
                  fill: 'red'
                }));
              }
            }

            outerStackChildren.push(group({width: wrapSizes[sideKey], top, shouldInvertStroke: _.getShouldInvertStroke({materialKey: sideKey === 'top' ? 'topCapPanelMaterial' : 'endPanelMaterial'}), ...groupProps}, [
              ...(!hideFillElements ? [
                rect({isFillable: endPanelShowingFill, opacity, height: netEndPanelHeight, stroke: ''}),
                rect({opacity, stroke: '', height: netEndPanelHeight,
                ...((_.showColors && endPanelByOthers) ?
                  {isFillable: endPanelShowingFill, fill: endPanelFillByOthers || ''} :
                  (endPanelByOthers ? {} : {isHatchFillable: endPanelShowingFill, hatchKey: ${containerType === 'capPanel'} ? 'topCapPanelMaterial' : 'endPanelMaterial'})
                )
                }),
              ] : []),
              ...endPanelShape,
              ...endPanelBreakLines,
              // ...endPanelDimensionLines,
              endPanelProductLabels[sideKey]
            ]));
          }
        };

        addEndPanel('left');

        if (${!_.includes(['endPanel'], containerType)}) {
          outerStackChildren.push(stack({axis: 'y', width: innerWidth, top: ${(hasSubcounter && sideKey !== 'top') ? 'subcounterHeight' : 0}}, innerStackChildren));
          addEndPanel('right');
        }

        var chaseShapes = [];

        ${sideKey === 'top' && withChase ? `
        chaseShapes.push(line({dashed: true, x1: wrapSizes.right, y1: 0, x2: '100% - ' + (wrapSizes.left), y2: 0}));
        if (_.activeDetailLevel === 'schematic') {
          chaseShapes.push(rect({isFillable: true, height: '100%', width: '100%'}))
        }

        if ((_.getDrawingsType && _.getDrawingsType() !== 'schematic') && exteriorDepth >= 4) {
          chaseShapes.push(${script.productLabelFor({key: 'baseWithChase'})});
        }` : ''}

        var flybyShapes = [];
        if (${sideKey === 'top'}) {
          if (flybyData.top && wrap.top) {
            var left = (wrapSizes.left * (1 - flybyData.left)) || 0;
            var right = (wrapSizes.right * (1 - flybyData.right)) || 0;

            flybyShapes.push(line({x1: 0 + right, y1: '100% -' + 0.75, x2: '100% -' + left, y2: '100% -' + 0.75}));
          }

          if (flybyData.left || flybyData.right) {
            var leftOffset = rightOffset = 0;
            if (!flybyData.left) {
              var leftOffset = wrap.left;
            }
            if (!flybyData.right) {
              var rightOffset = wrap.right;
            }

            flybyShapes.push(rect({height: 0.75, width: '100% -' + (leftOffset + rightOffset), top: '100% -' + 0.75, left: leftOffset, isFillable: true}));
          }
        }

        var planCapPanelShapes = [];

        if (wrap.top && ${sideKey === 'top'}) {
          var height = (meldedContainersData.left || meldedContainersData.right) ? lodash.first(lodash.values(meldedContainersData)).netMeldedDepth : wrapPanelWidths.top;

          var netCapPanelWidth = (_.getNetCapPanelWidth) ? _.getNetCapPanelWidth() : 0;
          var capPanelHeights = _.getCapPanelWidths ? _.getCapPanelWidths() : [netCapPanelWidth];
          var left = scribesData.left && !wrap.left ? -scribesData.left.distance : 0;

          planCapPanelShapes.push(group({width: netCapPanelWidth || '100%', top: _.dimensions.depth + wrap.overhang}, [
            line({dashed: true, x1: 0, x2: netCapPanelWidth || '100%', y1: 0, y2: 0})
          ]));
        }

        ${sideKey === 'back' ? 'outerStackChildren.reverse()' : ''}

        ${script.scribesFor({sideKey, hasKick, hasSubcounter})}

        var indicatorShapes = [];

        ${sideKey === 'top' ? `
          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());
        ` : ''}

        if (${!_.includes(['endPanel', 'capPanel'], containerType)}) {
          group({}, [
            ...(scribes ? scribes : []),
            ...(subcounterShapes ? subcounterShapes : []),
            stack({axis: 'x'}, outerStackChildren),
            ...indicatorShapes,
            ...flybyShapes,
            ...chaseShapes,
            ...planCapPanelShapes
          ]);
        }
        else {
          group({}, [
            ...(scribes ? scribes : []),
            stack({axis: 'x'}, outerStackChildren),
            ...indicatorShapes,
          ]);
        }
      `;
    }

    return wrappedContainerScript;
  };

  script.forIslandBackPanel = ({sideKey, withChase, containerType = 'islandBackPanel'}) => {
    return script.forWrappedContainer({isFillable: false, sideKey, innerChildrenScript: `
      (() => {
        var backPanelProps = {top: 0, height: '100%'};

        if (isMelded && ${sideKey === 'front'} && !meldedContainersData.isCenterContainer) {
          var meldedContainerData = lodash.first(lodash.values(meldedContainersData));
          var isTall = meldedContainerData.container.type === 'tall';
          var sameHeight = meldedContainerData.container.dimensions.height === _.props.dimensions.height;

          if (isTall && sameHeight) {
            var capPanelHeight = meldedContainerData.container.wrapSizes.top;

            backPanelProps = {height: '100% - ' + capPanelHeight, top: capPanelHeight};
          }
        }

        var maxWidth = (_.getIslandBackPanelMaxPanelSize && _.getIslandBackPanelMaxPanelSize()) || 45;
        var widths = _.getBackPanelWidths();

        var isSculpted = ${sideKey === 'front'} && lodash.get(_.props, 'customData.panelStyle', 'panel') !== 'panel';
        var backPanels;

        if (!_.props.customData.panelsAreDisabled) {
          if (isSculpted) {
            var sculptedData = {
              size: {width: _.props.dimensions.width, height: _.props.dimensions.height},
              panelThickness: _.props.customData.wrap.thickness,
              boardType: _.props.customData.panelStyle,
              left: 0
            };

            backPanels = [${script.sculptedPanelScriptFor({containerType})}, ${script.productLabelFor({key: 'islandBackPanels'})}];
          }
          else {
            if (${sideKey === 'front' && containerType === 'islandSeating'} && !_.props.customData.countertopSupportDisabled) {
              backPanelProps.height = '100% - 2.5';
              backPanelProps.top = 2.5;
            }

            backPanels = lodash.map(widths, (width, index) => {
              var grain = (_.getGrainScript && ${sideKey === 'front'} && _.activeDetailLevel !== 'schematic') ? [eval(_.getGrainScript({width}))] : [];
              var textTop = (${containerType === 'islandSeating'} && !_.props.customData.countertopSupportDisabled) ? 1.5 + 2.5 : 1.5;

              var textOffset = _.props.customData.pTextOffset || 0;

              textTop += textOffset;

              return group({width, shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'islandBackPanelMaterial'})}, [
                rect({isFillable: true, stroke: '', ...backPanelProps}),
                rect({
                  className: 'islandBackPanel',
                  isHatchFillable: true,
                  hatchKey: 'islandBackPanelMaterial',
                  ...backPanelProps,
                }),
                ...grain,
                ...(${companyKey === 'hb' && sideKey === 'front'} ? [text({text: 'P', top: textTop, left: 2, fontSize: 2, origin: {x: 'left', y: 'top'}})] : []),
                ${script.productLabelFor({key: 'islandBackPanels'})}
              ]);
            });
          }
        }

        var exteriorDepth = 0, children = [];

        ${containerType === 'islandSeating' && sideKey === 'top' ? `
          exteriorDepth = _.props.customData.seatingDepth || 6;
          if(_.activeDetailLevel === 'schematic') children.push(rect({isFillable: true, width: '100%', height: '100%', opacity: 0.3}),);
        ` : ''}

        if (!_.props.customData.panelsAreDisabled) {
          ${sideKey === 'top' && withChase ? `
          children.push(line({dashed: true, x1: wrapSizes.right, y1: 0, x2: '100% - ' + (wrapSizes.left), y2: 0}));
          if ((_.getDrawingsType && _.getDrawingsType() !== 'schematic') && _.props.dimensions.depth - 3/4 - exteriorDepth > 3.5) {
            children.push(${script.productLabelFor({key: 'chase'})});
          }` : ''}
        }

        children.push(
          stack({
            axis: 'x',
            width: innerWidth
            ${sideKey === 'top' ? ', height: 3/4, top: "100% - 3/4 -" + exteriorDepth ' : ''}
          }, backPanels)
        );

        ${containerType === 'islandSeating' && sideKey === 'top' ? `
          var seatingDepth = _.props.customData.seatingDepth || 6;

          children.push(
            group({top: '100% - 1 - ' + seatingDepth, height: seatingDepth}, [
              ...(_.props.customData.countertopSupportDisabled ? [] : [rect({isFillable: false, dashed: true, height: 2.5, width: '100%', top: 1 + seatingDepth - 2 - 2.5})]),
              ...((_.getDrawingsType && _.getDrawingsType() !== 'schematic')  ? [${script.productLabelFor({key: 'islandSeating'})}] : [])
            ])
          );
        ` : ''}

        ${containerType === 'islandSeating' && sideKey === 'front' ? `
          children.push(
            ...(_.props.customData.countertopSupportDisabled ? [] : [
              group({shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'seatingSupportRodMaterial'})}, [
                rect({isFillable: true, stroke: '', height: 2.5, width: '100%', top: 0}),
                rect({isHatchFillable: true, hatchKey: 'seatingSupportRodMaterial', height: 2.5, width: '100%', top: 0})
              ])
            ]),
            ${script.productLabelFor({key: 'seatingSupportRod'})}
          );

        ` : ''}

        return children;
      })()
    `});
  };

  script.lightingFor = ({sideKey}) => {
    return `
    var lights = [];

    if (_.props.customData.hasLighting && !_.hideContainerLights) {
      if (${sideKey === 'front'}) {
        var fill = '${CFG.colors.lighting}';

        if (_.props.customData.lightingType === 'puck') {
          if (_.getLightPositions) {
            var lightPositions = _.getLightPositions();

            lightPositions.forEach(x => {
              lights.push(path({left: x, top: '100%', fill, strokeWidth: 0, isFillable: true}, ${lightPoints}));
            });
          }
        }
        else if (_.props.customData.lightingType === 'linear') {
          if (_.getLightRanges) {
            fill = '${CFG.colors.lighting}';
            var lightRanges = _.getLightRanges();

            lightRanges.forEach(lightRange => {
              var width = lightRange.x2 - lightRange.x1;

              lights.push(path({strokeWidth: 0, top: '100%', left: lightRange.x1, fill, closed: true, isFillable: true}, [{x: 2, y: 0}, {x: 0, y: 1.5}, {x: width, y: 1.5}, {x: width - 2, y: 0}]));
            });
          }
        }
      }
      else if (${sideKey === 'left'}) {
        var fill = '${CFG.colors.lighting}'; //_.theme === 'light' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 240, 170, 0.4)';

        var lightingPath = path({left: _.lightingDepth, top: '100%', strokeWidth: 0, fill}, ${lightPoints});

        if (_.props.customData.lightingType === 'linear') lightingPath = path({strokeWidth: 0, top: '100%', left: _.lightingDepth, fill}, [{x: -1, y: 0}, {x: 1, y: 0}, {x: 1, y: 1.5}, {x: -1, y: 1.5}, {x: -1, y: 0}])

        lights = [lightingPath];
      }
      else if (${sideKey === 'right'}) {
        var fill = '${CFG.colors.lighting}'; //_.theme === 'light' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 240, 170, 0.4)';

        var lightingPath = path({left: \`100% - \${_.lightingDepth}\`, top: '100%', strokeWidth: 0, fill}, ${lightPoints})

        if (_.props.customData.lightingType === 'linear') lightingPath = path({strokeWidth: 0, top: '100%', left: \`100% - \${_.lightingDepth}\`, fill}, [{x: -1, y: 0}, {x: 1, y: 0}, {x: 1, y: 1.5}, {x: -1, y: 1.5}, {x: -1, y: 0}])

        lights = [lightingPath];
      }
    }
    `;
  };

  script.sculptedPanelScriptFor = ({containerType} = {}) => {
    return `
    (() => {
      //WARNING assumes sculptedData is defined where used
      var {height, width} = sculptedData.size;
      var wrapPanelWidths = _.getWrapPanelWidths ? _.getWrapPanelWidths() : {};
      var {panelThickness, boardType, left} = sculptedData;

      var sculptedBoardData = {
        coved: {width: 3.125, xs: [0.25]},
        singleGroove: {width: 4, xs: [1.5625, 2.4375]},
        doubleGroove: {width: 4, xs: [1.083, 1.458, 2.541, 2.916]},
        chamfered: {width: 5, xs: []},
        fluted: {width: 4.5, xs: [0.875, 1.125, 2, 2.25, 3.125, 3.375, 4.25]},
      }

      var wrapPanelWidths = _.getWrapPanelWidths ? _.getWrapPanelWidths() : {};
      var {sculptedPanel, wrap} = _.props.customData || {};
      var {thickness} = wrap;
      var wrapSizes = _.getWrapSizes();
      var {topRailHeight, bottomCasingHeight, isFlutedAdjacent} = sculptedPanel || {};
      var leftWrapThickness = wrapSizes.left;
      var rightWrapThickness = wrapSizes.right;
      var sculptedWidth = width - leftWrapThickness - rightWrapThickness;
      var sculptedHeight = height - bottomCasingHeight - topRailHeight;
      var boardWidth = sculptedBoardData[boardType].width;
      var boardCount = Math.round(sculptedWidth/boardWidth);

      if (boardType === 'fluted' && !isFlutedAdjacent) {
        //HINT first board is cut off
        boardCount = Math.round(((sculptedWidth - (boardWidth - 0.875)) / boardWidth)) + 1;
      }

      var boardLinesXs = sculptedBoardData[boardType].xs;
      var boards = [];
      var children = [];

      lodash.times(boardCount, (index) => {
        var isFirstFlutedBoard = index === 0 && boardType === 'fluted'; //HINT first board gets cut off
        var lines = [];
        var width = boardWidth;

        var xs = boardLinesXs;

        if (isFirstFlutedBoard && !isFlutedAdjacent) {
          width = boardWidth - 0.875;

          xs = [.25, 1.125, 1.375, 2.25, 2.5, 3.375, 3.625];
        }

        lodash.forEach(xs, (x, i) => {
          lines.push(line({stroke: '${CFG.drawingsStrokeWidths.light.stroke}', x1: x, x2: x, y1: '100%', y2: 0}));
          lines.push(line({stroke: '${CFG.drawingsStrokeWidths.light.stroke}', x1: 0, x2: 0, y1: '100%', y2: 0}));
        });

        boards.push(group({width}, lines))
      });

      // left panel
      if (!lodash.includes(['islandBackPanel', 'islandSeating'], _.props.type)) {
        children.push(rect({width: leftWrapThickness, left}));
      }

      children.push (
        group({width: sculptedWidth}, [
          stack({axis: 'y', width: sculptedWidth, top: 0}, [
            rect({height: topRailHeight, left: 0, width: sculptedWidth}), //conditional top
            stack({axis: 'x', top: 0, height: sculptedHeight, left: 0}, boards),
            rect({height: bottomCasingHeight, left: 0, width: sculptedWidth}) //bottom
          ])
        ]),
      )

      // right panel
      if (!lodash.includes(['islandBackPanel', 'islandSeating'], _.props.type)) {
        children.push(
          rect({width: rightWrapThickness, left}) // right
        );
      }

      return group({height: height, width: sculptedWidth, left: 0, shouldInvertStroke: _.getShouldInvertStroke({materialKey: ${_.includes(['islandBackPanel', 'islandSeating'], containerType)} ? 'islandBackPanelMaterial' : 'endPanelMaterial'})}, [
        rect({isFillable: true, height: '100%', left, width: '100%'}),
        rect({isHatchFillable: true, hatchKey: ${_.includes(['islandBackPanel', 'islandSeating'], containerType)} ? 'islandBackPanelMaterial' : 'endPanelMaterial', height: '100%', left, width: '100%'}),
        stack({axis: 'x',top: 0, height: '100%', left}, children),
      ]);
    })()`;
  };
  //> scripts

  var countertopScriptFor = ({sideKey}) => {
    // var o = 0;//CFG.countertopOverhang;

    return `
      (() => {
        if (${sideKey === 'top'}) {
          var countertopMaskingData = (_.getCountertopMaskingData) ? _.getCountertopMaskingData() : {};
          var showOpaqueFill = _.getCountertopsSelectable ? _.getCountertopsSelectable() : false;

          if (countertopMaskingData.isMasked) {
            // var maskedAreaPath = maskedAreaPath = countertopMaskingData.footprintInRoom
            // lib.polygon.difference({
            //   p1: countertopMaskingData.footprintInRoom,
            //   p2: countertopMaskingData.pathInContainer
            // });

            var indicatorShapes = [];

            ${sideKey === 'top' ? `
              ${script.frontIndicator}

              indicatorShapes.push(...frontIndicator());
            ` : ''}

            countertopScript = group({}, [
              path({closed: true}, countertopMaskingData.pathInContainer),
              path({dashed: true, closed: true, zIndex: 20, ...(showOpaqueFill ? {isFillable: true, fill: 'rgba(202, 182, 217, 0.5)'} : {})}, countertopMaskingData.footprintInRoom),
              ${script.productLabelFor({key: 'countertop'})},
              ...indicatorShapes
            ])
          }
        }
        else {
          var mode = _.getFrontViewMode();

          var countertopScript = group({}, [
            rect({isFillable: true}),
            ...(mode !== 'section' ? [rect({isHatchFillable: true, hatchKey: 'countertopMaterial'})] : []),
            ${script.productLabelFor({key: 'countertop'})}
          ]);
        }

        return countertopScript
      })()
    `;
  };

  return {script, countertopScriptFor};
};

export default ContainerTypesScriptHelpersFor;
