import lib from 'lib';
import Project from 'project-helpers/project';
import Floor from 'project-helpers/floor';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';

//Probably has issues
var oldUpdateProductionIds = async ({project, reduxActions}) => {
  var scopeIndex = 1;
  var promises = [];

  var numberToLetters = number => {
    for (var ret = '', a = 1, b = 26; (number -= a) >= 0; a = b, b *= 26) {
      ret = String.fromCharCode(parseInt((number % b) / a) + 65) + ret;
    }

    return ret;
  };

  var spacialSort = ({collection, yScalar = 1}) => {
    return _.sortBy(collection, [
      item => _.get(item, 'customData.productionIdIndex', 0), //forced sort
      item => _.get(item, 'rotation', 0), //unwrap island in linear order
      item => _.get(item, 'position.x', 10000) + yScalar * _.get(item, 'position.y', 10000) //diagonally analyze position in elevation
    ]);
  };

  var updateProductProductionIdsForScope = ({scope, room}) => {
    var productIndex = 1;
    var rank = 0;

    let containers = Room.get('containers', {room});

    _.forEach(spacialSort({collection: containers}), container => {
      let products = Container.get('products', {container});

      _.forEach(spacialSort({collection: products, yScalar: -1}), product => {
        let productionId;

        if (Product.getUseProductionId({product})) {
          productionId = `${scope.productionId}${productIndex}`;
          productIndex += 1;
        }
        else {
          productionId = '';
        }

        if ((product.productionId !== productionId || product.rank !== rank) && product.id) {
          promises.push(reduxActions.updateProduct({id: product.id, props: {productionId, rank}}));
        }

        rank += 1;

        let childProducts = Product.get('childProducts', {product});

        var isSingleAppliancePanel = Product.getIsAppliance({product}) && childProducts.length <= 1;

        _.forEach(childProducts, (product, subIndex) => {
          var updates = {productionId, rank};

          if (!isSingleAppliancePanel) updates.productionId += `.${subIndex + 1}`;

          rank += 1;

          if ((product.productionId !== updates.productionId || product.rank !== updates.rank) && product.id) {
            promises.push(reduxActions.updateProduct({id: product.id, props: updates}));
          }
        });
      });
    });
  };

  let floors = Project.get('floors', {project});

  _.forEach(_.sortBy(floors, 'rank'), floor => {
    let rooms = Floor.get('rooms', {floor});

    _.forEach(_.sortBy(rooms, 'rank'), room => {
      let scopes = Room.get('scopes', {room});

      _.forEach(scopes, scope => {
        var productionId = numberToLetters(scopeIndex);

        if (scope.productionId !== productionId) promises.push(reduxActions.updateScope({id: scope.id, props: {productionId}}));

        updateProductProductionIdsForScope({scope, room});

        scopeIndex += 1;
      });
    });
  });

  await Promise.all(promises);
};

export default oldUpdateProductionIds;