import React, { useContext } from 'react';

import CanvasDataContext from 'contexts/canvas-data-context';
import Elevation from 'project-helpers/elevation';
import Room from 'project-helpers/room';
import lib from 'lib';
import K from 'k';
import PositionHelper from 'helpers/position-helper';
import _ from 'lodash';

import { CanvasText, CanvasLine, CanvasRect, CanvasPath } from 'canvas';

function CanvasElevationIndicatorObject(props) {
  const {elevation, room, canvasData, offset} = props;
  const elevations = Room.get('sortedElevations', {room});
  const rank = elevations.indexOf(elevation) + 1;
  const {lineInRoom} = elevation;
  const {from, to} = lib.trig.extend({line: lineInRoom, by: 7});
  const {scale, isStatic} = canvasData;
  const strokeWidth = 4;
  const alpha = Elevation.getAlpha({elevation});
  const indicatorOffset = elevation.indicatorOffset || 5;
  const indicatorProps = {fill: K.colors.elevationStroke, stroke: K.colors.elevationStroke, strokeWidth: 1};
  const midpoint = lib.math.midpoint({line: lineInRoom});
  const rotation = alpha + Math.PI;

  const arrowSize = 1.5;
  const arrowPoints = [{x: -arrowSize, y: 0}, {x: arrowSize / 2, y: -arrowSize}, {x: arrowSize / 2, y: arrowSize}];

  let arrowPosition = lib.object.sum(midpoint, offset, lib.trig.rotate({
    point: {x: 0, y: indicatorOffset || 5},
    byRadians: rotation
  }));

  let linePosition = {x: 0, y: 0};

  const bracketSize = 2 * scale;
  const fromInCanvas = PositionHelper.toCanvas(lib.object.sum(from, offset), canvasData);
  const toInCanvas = PositionHelper.toCanvas(lib.object.sum(to, offset), canvasData);
  const bracketPoints = [{x: bracketSize / 2, y: 0}, {x: bracketSize / 2, y: -bracketSize}, {x: 0, y: -bracketSize}, {x: 0, y: 0}];
  const linePoints = _.map(bracketPoints, transform => lib.object.sum(fromInCanvas, lib.trig.rotate({point: transform, byRadians: rotation})))
    .concat(fromInCanvas, toInCanvas)
    .concat(..._.map(bracketPoints.reverse(), transform => lib.object.sum(toInCanvas, lib.trig.rotate({point: transform, byRadians: rotation}))));

  let textPosition = lib.object.sum(arrowPosition, lib.trig.rotate({
    point: {x: 0.6, y: 5},
    byRadians: rotation
  }));

  arrowPosition = PositionHelper.toCanvas(arrowPosition, canvasData);
  textPosition = PositionHelper.toCanvas(textPosition, canvasData);

  var textOrigin = {x: 'center', y: 'center'};

  var viewDepthRectProps = {};

  if (props.showViewDepth && elevation) {
    const viewDepthInCanvas = elevation.viewDepth * canvasData.scale;
    const rotation = lib.trig.radiansToDegrees(lib.trig.alpha({p1: lineInRoom.to, p2: lineInRoom.from}));

    viewDepthRectProps = {
      width: lib.trig.dist(fromInCanvas, toInCanvas),
      height: viewDepthInCanvas,
      rotation,
      position: toInCanvas,
    };
  }

  return (<>
    {!props.hideElevationLine && (
      <CanvasLine key={`elevation-indicator-line-${elevation.id}`} {...{...linePosition, ...indicatorProps, strokeWidth: strokeWidth / 2}} points={_.flatMap(linePoints, point => [point.x, point.y])} closed/>
    )}
    <CanvasPath {...{
      ...arrowPosition, ...indicatorProps, rotation: lib.trig.radiansToDegrees(rotation + Math.PI / 2),
      points: arrowPoints,
      //HINT using data directly doesn't scale properly
      // data: 'M 0 0 L 4 -3 L 4 3 Z',
      opacity: 1,
      ...{onClick: props.onClick, onDblClick: props.onDblClick},
      listening: !isStatic,
      ...(props.showViewDepth ? {fill: '#626FD0', stroke: '#626FD0'} : {})
    }}/>
    {props.showViewDepth ? (
      <CanvasRect {...viewDepthRectProps} stroke={'#626FD0'} cornerRadius={3} fill={'transparent'} opacity={1} listening={false}/>
    ) : null}
    <CanvasText key={`elevation-indicator-text-${elevation.id}`} listening={!isStatic} {...{onClick: props.onClick, onDblClick: props.onDblClick}} {...textPosition} text={rank} fontSize={7 * scale} fontWeight={600} fill={props.showViewDepth ? '#626FD0' : K.colors.elevationStroke} origin={textOrigin}/>
  </>);
}

export default function CanvasElevationIndicatorsWithContext(props) {
  let canvasData = useContext(CanvasDataContext);

  return <CanvasElevationIndicatorObject {...props} {...{canvasData}} />;
}
