import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Product from 'project-helpers/product';
import PositionHelper from 'helpers/position-helper';
import Elevation from 'project-helpers/elevation';
import Container from 'project-helpers/container';

var Opencase = {
  removeInvalidProducts({product, reduxActions}) {
    const {childProducts} = Product.get(['childProducts'], {product});

    const invalidProductIds = _.map(_.filter(childProducts, product => !product.isValid), 'id');

    if (invalidProductIds.length > 0) reduxActions.destroyProducts({ids: invalidProductIds});
  },

  updateActiveFittings({product, reduxActions, isBatched=false}) {
    const activeFittings = Opencase.getActiveFittings({product});

    if (isBatched) {
      return {where: {id: product.id}, props: {customData: {
        ...product.customData,
        activeFittings,
      }}};
    }
    else {
      reduxActions.updateProduct({id: product.id, props: {customData: {
        ...product.customData,
        activeFittings,
      }}});
    }
  },

  getGridSize({product}) {
    const {rows, columns} = Opencase.getCellCounts({product});

    return {
      width: (columns - 1) * K.opencaseConstants.spacing.x,
      height: (rows - 1) * K.opencaseConstants.spacing.y
    };
  },

  marginFrom({product, left, bottom}) {
    const {dimensions} = product;
    const gridSize = Opencase.getGridSize({product});

    return {
      left,
      bottom,
      right: dimensions.width - (gridSize.width + left),
      top: dimensions.height - (gridSize.height + bottom)
    };
  },

  getComputedMargin({product}) {
    const {dimensions} = product;
    const gridSize = Opencase.getGridSize({product});

    const left = (dimensions.width - gridSize.width) / 2;
    const bottom = 3;

    return Opencase.marginFrom({product, left, bottom});
  },

  getMargin({product}) {
    const mergedMargin = {...Opencase.getComputedMargin({product}), ...product.customData?.margin};

    return Opencase.marginFrom({product, ...mergedMargin});
  },

  getComputedCellCounts({product}) {
    const {width, height} = product.dimensions;
    const {left, bottom} = {left: K.opencaseConstants.minMargin, bottom: K.opencaseConstants.minMargin, ...product.customData?.margin};

    const rows = Math.floor((height - bottom - K.opencaseConstants.minMargin) / K.opencaseConstants.spacing.y) + 1;
    let columns = Math.floor((width - left - K.opencaseConstants.minMargin) / K.opencaseConstants.spacing.x) + 1;

    if (rows > columns) {
      columns = lib.number.constrain(columns, {max: 6});
    }

    return {rows, columns};
  },

  getCellCounts({product}) {
    const userCellCount = product.customData?.cellCount;

    return _.mapValues(Opencase.getComputedCellCounts({product}), (computedValue, axisKey) => {
      const userValue = userCellCount && userCellCount[axisKey];

      return userValue !== undefined ? Math.min(computedValue, userValue) : computedValue;
    });
  },

  getActiveFittings({product}) {
    const activeFittings = _.flatMap(Product.get('childProducts', {product}), componentProduct => {
      return Opencase.getFittingGridPositions({product: componentProduct, absolute: true});
    });

    return activeFittings;
  },

  getActiveFittingPositionsInPanel({product}) {
    const activeFittings = Opencase.getActiveFittings({product});
    const margin = Opencase.getMargin({product});
    const {spacing} = K.opencaseConstants;

    return _.map(activeFittings, ({row, column}) => {
      return lib.object.sum({x: margin.left, y: -margin.bottom}, {x: column * spacing.x, y: row * -spacing.y});
    });
  },

  getFittingGridPositions({product, absolute = false}) {
    const dynamicProperties = Opencase.getDynamicProperties({product});

    return dynamicProperties?.holes?.map(hole => {
      let position = {row: hole.y, column: hole.x}; //y should go from top to bottom like 0, -1, -2

      if (absolute) position = lib.object.sum(position, product.customData.gridPosition);

      return position;
    }) || [];
  },

  getFittingBoundaries({product, absolute = false, includePadding = false} = {}) {
    const fittingGridPositions = Opencase.getFittingGridPositions({product, absolute});

    let min = lib.object.min(...fittingGridPositions);
    let max = lib.object.max(...fittingGridPositions);

    if (includePadding) {
      const dynamicProperties = Opencase.getDynamicProperties({product});
      const {padding} = dynamicProperties;

      min = lib.object.difference(min, {row: padding.bottom, column: padding.left});
      max = lib.object.sum(max, {row: padding.top, column: padding.right});
    }

    return {left: min.column, right: max.column, top: max.row, bottom: min.row};
  },

  constrainToGrid({product, gridPosition}) {
    const parentProduct = Product.get('parentProduct', {product});
    const cellCounts = Opencase.getCellCounts({product: parentProduct});
    const bounds = Opencase.getFittingBoundaries({product});
    const {rows, columns} = cellCounts;

    //WARNING these values are very finnicky - only modify each key-value pair individually
    const min = {row: 0 - bounds.bottom, column: 0 - bounds.left};
    const max = {row: rows - 1 + bounds.top, column: columns - 1 - bounds.right};

    return lib.object.constrain(gridPosition, {min, max});
  },

  gridPositionFor({product, position}) {
    const parentProduct = Product.get('parentProduct', {product});
    const margin = Opencase.getMargin({product: parentProduct});
    const dynamicProperties = Opencase.getDynamicProperties({product});
    const positionOffset = dynamicProperties.offset ? lib.object.multiply(dynamicProperties.offset, -1) : {x: 0, y: 0};
    const marginOffset = {x: -margin.left, y: margin.bottom};
    const halfCellOffset = {x: K.opencaseConstants.spacing.x / 2, y: -K.opencaseConstants.spacing.y / 2};
    const positionInGrid = lib.object.sum(position, positionOffset, marginOffset, halfCellOffset);

    const gridPosition = {
      column: Math.floor(positionInGrid.x / K.opencaseConstants.spacing.x),
      row: Math.floor(-positionInGrid.y / K.opencaseConstants.spacing.y)
    };

    return Opencase.constrainToGrid({product, gridPosition});
  },

  getPositionFor({product, gridPosition}) {
    const parentProduct = Product.get('parentProduct', {product});
    const margin = Opencase.getMargin({product: parentProduct});
    const dynamicProperties = Opencase.getDynamicProperties({product});
    const offset = dynamicProperties.offset ? dynamicProperties.offset : {x: 0, y: 0};

    return {
      x: margin.left + (gridPosition.column * K.opencaseConstants.spacing.x) + offset.x,
      y: -margin.bottom - (gridPosition.row * K.opencaseConstants.spacing.y) + offset.y,
      //HINT _product assumes a z in some calculations
      z: 0
    };
  },

  handleGridTransform({product, position}) {
    const gridPosition = Opencase.gridPositionFor({product, position});
    const constrainedPosition = Opencase.getPositionFor({product, gridPosition});

    return {
      gridPosition,
      position: constrainedPosition,
    };
  },

  getCustomOnMove({product}) {
    return (position) => Opencase.handleGridTransform({product, position});
  },

  getCustomDragBoundFunc({product, viewOffset, elevation, nonSpacialContainerPosition, overrideSideKey}) {
    const {dimensions} = product;
    const size = {width: dimensions.width, height: dimensions.height};
    const container = Product.get('container', {product});
    const containerPosition = nonSpacialContainerPosition ? nonSpacialContainerPosition : Elevation.getPosition2d({elevation, position3d: container.position});
    const parentProduct = Product.get('parentProduct', {product});
    const parentPosition = parentProduct.position;
    const offset = lib.object.sum(containerPosition, parentPosition, viewOffset, Container.getDropzoneInset({container, viewKey: 'front'}));

    return (positionInCanvas, canvasData) => {
      const positionInReal = PositionHelper.toReal(positionInCanvas, canvasData);
      const relativePosition = lib.object.difference(positionInReal, offset);

      const {position} = Opencase.handleGridTransform({product, position: relativePosition});

      const newPositionInReal = _.pick(lib.object.sum(position, offset, {y: -size.height}), ['x', 'y']);

      return PositionHelper.toCanvas(newPositionInReal, canvasData);
    };
  },

  getDynamicProperties({product}) {
    const {dynamicProperties} = Product.getProductData({product});

    return _.defaultsDeep(lib.json.parse(dynamicProperties), {
      padding: {top: 0, left: 0, right: 0, bottom: 0},
      holes: [{x: 0, y: 0}],
      offset: {x: 0, y: 0},
      shallow: false,
      permitShallow: {top: false, left: false, right: false, bottom: false}
    });
  },
};

export default Opencase;