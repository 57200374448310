import DetailsHelper from 'helpers/details-helper';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import { handleContainerPropertyChange } from 'properties-view-data/container-properties-view-helpers';
import { handleProductPropertyChange } from 'properties-view-data/product-properties-view-helpers';
import _ from 'lodash';

//HINT function must be async so that we can make api request to create resources, then track them
var applyArchetypeParameterSelection = async ({room, archetypeId, parameter, value, path, reduxActions, updatingSourceRoom= false, parameters, isInAdminMode=false}) => {
  //TODO validate parameter and value
  var shouldApply = true;

  if (shouldApply) {
    var hitApi = true;
    var updatedSelectedParameters = _.cloneDeep(room.selectedParameters);

    _.set(updatedSelectedParameters, `${archetypeId}.${parameter.id}${path ? `.${path}` : ''}`, value);

    var updatesMap = {
      rooms: {creations: [], deletedIds: [], updates: [], tracks: []},
      containers: {creations: [], deletedIds: [], updates: [], tracks: []},
      products: {creations: [], deletedIds: [], updates: [], tracks: []},
      productOptions: {creations: [], deletedIds: [], updates: [], tracks: []}
    };

    var conditionalResourcesUpdateResponse;

    //apply parameter based on type
    //end with array of CRUD updates to resources
    if (parameter.type === 'material' || parameter.type === 'pull') {
      var {products, containers} = Room.get(['products', 'containers'], {room});

      //HINT not sure this will work if applying something to the container and product
      //might override the updates
      //might need to do more work to combine the updates into one update
      _.forEach(parameter.instances, instance => {
        var instanceMaterialKeys = [];

        if (parameter.type === 'pull') instanceMaterialKeys = [path];
        else if (parameter.type === 'material') instanceMaterialKeys = (instance.materialKeys && instance.materialKeys.length > 0) ? instance.materialKeys : (parameter.defaultMaterialKeys || []);

        _.forEach(instanceMaterialKeys, materialKey => {
          if (instance.resourceKey === 'container') {
            var container = _.find(containers, {persistentId: instance.resourcePersistentId});

            if (container) {
              UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleContainerPropertyChange({activeEntityId: container.id, activeEntity: container, path: `details.${materialKey}.id`, value, isBatched: true}));
            }
          }
          else if (instance.resourceKey === 'product') {
            var product = _.find(products, {persistentId: instance.resourcePersistentId});

            if (product) {
              UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleProductPropertyChange({activeEntityId: product.id, activeEntity: product, path: `details.${materialKey}.id`, value, isBatched: true}));
            }
          }
        });
      });
    }
    else if (parameter.type === 'conditionalResources') {
      hitApi = false;
      var {products, containers, project} = Room.get(['products', 'containers', 'project'], {room});

      var apiBody = {
        projectId: project.id, versionId: project.versionId, roomId: room.id, archetypeId, parameterId: parameter.id, value, ...(isInAdminMode ? {updatingSourceRoom, parameter} : {})
      };

      conditionalResourcesUpdateResponse = await lib.api.request({uri: 'de-project/apply-conditional-resources-parameter', body: {...apiBody}});

      _.forEach(conditionalResourcesUpdateResponse.data, (resourceUpdateData, resourceKey) => {
        _.forEach(resourceUpdateData.deletedIds, resourceId => {
          if (resourceKey === 'containers') {
            var container = _.find(containers, {id: resourceId});

            if (container) {
              var {managedUpdatesMap, containerCacheUpdate} = Container.updateManagedResources({container, actionKey: 'destroy', reduxActions, isBatched: true});

              if (containerCacheUpdate) updatesMap.containers.updates.push({where: {id: container.id}, props: {customData: {...container.customData, cachedManagedData: containerCacheUpdate}}});

              updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);

            }
          }
          else if (resourceKey === 'products') {
            var product = _.find(products, {id: resourceId});

            if (product) {
              var {managedUpdatesMap} = Product.updateManagedResources({product, actionKey: 'destroy', reduxActions, isBatched: true});

              updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);
            }
          }
        });
      });

      updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, conditionalResourcesUpdateResponse.data);
    }

    reduxActions.updateRoom({id: room.id, props: {selectedParameters: updatedSelectedParameters}});

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions, hitApi});

    if (parameter.type === 'conditionalResources' && conditionalResourcesUpdateResponse) {
      //find room material and pull parameters that apply to the tracked resources (and the containers of newly tracked products)
      //apply the current parameter selection to those resources
      //setTimeout to wait for redux track before we can apply the parameter selection
      //so that containers can get their children

      setTimeout(() => {
        if (conditionalResourcesUpdateResponse.data && _.some(conditionalResourcesUpdateResponse.data, responseData => _.get(responseData, 'tracks.length') > 0)) {
          var trackedProducts = _.map(_.get(conditionalResourcesUpdateResponse.data, 'products.tracks'), trackedProduct => ({...trackedProduct, resourceKey: 'product'}));
          var trackedProductContainers = _.uniqBy(_.map(trackedProducts, product => Product.get('container', {product})), 'id');
          var trackedContainers = _.map([..._.get(conditionalResourcesUpdateResponse.data, 'containers.tracks', []), ...trackedProductContainers], trackedContainer => ({...trackedContainer, resourceKey: 'container'}));
          var roomParameters = isInAdminMode ? parameters : _.flatMap(_.get(room, `parameters.${room.archetypeId}`, []));
          var relevantParameters = _.filter(roomParameters, parameter => {
            return _.includes(['material', 'pull'], parameter.type);// && _.some(parameter.instances, instance => (instance.resourceKey === 'container' && _.some(trackedContainers, {persistentId: instance.resourcePersistentId})) || (instance.resourceKey === 'product' && _.some(trackedProducts, {persistentId: instance.resourcePersistentId})));
          });

          if (relevantParameters.length > 0) {
            var updatesMap = {
              rooms: {creations: [], deletedIds: [], updates: [], tracks: []},
              containers: {creations: [], deletedIds: [], updates: [], tracks: []},
              products: {creations: [], deletedIds: [], updates: [], tracks: []},
              productOptions: {creations: [], deletedIds: [], updates: [], tracks: []}
            };

            _.forEach([...trackedContainers, ...trackedProducts], trackedResource => {
              var {resourceKey} = trackedResource;
              var resourceUpdates = [];
              var resourcePropertyChangeHandler = resourceKey === 'container' ? handleContainerPropertyChange : handleProductPropertyChange;

              _.forEach(relevantParameters, parameter => {
                var resourceParameterInstance = _.find(parameter.instances, instance => instance.resourceKey === resourceKey && instance.resourcePersistentId === trackedResource.persistentId);

                if (resourceParameterInstance) {
                  if (parameter.type === 'pull') {
                    var currentlyAppliedPullTypeValue = isInAdminMode ? _.get(parameter, `defaultDetails.pullType[0]`) : _.get(room.selectedParameters, `${archetypeId}.${parameter.id}.pullType`);

                    if (currentlyAppliedPullTypeValue) {
                      UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, resourcePropertyChangeHandler({activeEntityId: trackedResource.id, activeEntity: trackedResource, path: `details.pullType.id`, value: currentlyAppliedPullTypeValue, isBatched: true}));

                      resourceUpdates.push({path: `details.pullType.id`, value: currentlyAppliedPullTypeValue});
                    }

                    var currentlyAppliedPullMaterialValue = isInAdminMode ? _.get(parameter, `defaultDetails.pullMaterial[0]`) : _.get(room.selectedParameters, `${archetypeId}.${parameter.id}.pullMaterial`);

                    if (currentlyAppliedPullMaterialValue) {
                      UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, resourcePropertyChangeHandler({activeEntityId: trackedResource.id, activeEntity: trackedResource, path: `details.pullMaterial.id`, value: currentlyAppliedPullMaterialValue, isBatched: true}));

                      resourceUpdates.push({path: `details.pullMaterial.id`, value: currentlyAppliedPullMaterialValue});
                    }
                  }
                  else if (parameter.type === 'material') {
                    var currentlyAppliedMaterialValue = isInAdminMode ? _.get(parameter, `defaultDetails.material[0]`) : _.get(room.selectedParameters, `${archetypeId}.${parameter.id}`);

                    if (currentlyAppliedMaterialValue) {
                      var instanceMaterialKeys = (resourceParameterInstance.materialKeys && resourceParameterInstance.materialKeys.length > 0) ? resourceParameterInstance.materialKeys : (parameter.defaultMaterialKeys || []);

                      _.forEach(instanceMaterialKeys, materialKey => {
                        UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, resourcePropertyChangeHandler({activeEntityId: trackedResource.id, activeEntity: trackedResource, path: `details.${materialKey}.id`, value: currentlyAppliedMaterialValue, isBatched: true}));

                        resourceUpdates.push({path: `details.${materialKey}.id`, value: currentlyAppliedMaterialValue});
                      });
                    }
                  }
                }
              });
            });

            UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions});
          }
        }

        Room.updateManagedResources({room, reduxActions});
      });
    }
    else {
      Room.updateManagedResources({room, reduxActions});
    }

  }
};

export default applyArchetypeParameterSelection;