import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import DetailsHelper from 'helpers/details-helper';
import _ from 'lodash';

const getPullMaterialIncompatibility = ({
  products, project
}) => {
  var issues = [];

  var woodMaterialIds = [
    1, 3, 4, 5, 6, 103, 135, 151, 152, 153, 154, 155, 170, 171, 172, 173, 174,
    175, 176, 177, 178, 180, 239, 279, 303, 316, 320, 321, 325, 329, 330, 337,
    346, 349, 368, 369, 370, 412, 413, 432, 433, 434, 435, 436, 445, 446, 447,
    448, 449, 450, 451, 452, 465, 480, //walnut
    8, 9, 104, 136, 143, 145, 147, 148, 149, 150, 158, 160, 161, 162, 163, 164,
    165, 166, 167, 168, 169, 179, 191, 192, 193, 194, 240, 249, 250, 251, 262,
    304, 317, 319, 332, 339, 350, 351, 352, 353, 405, 410, 431, 469, 470 //oak
  ]
  var colorMatchedEdgeMaterialIds = [
    364, 365, 366, 367, 371, 372, 373, 374, 375, 381, 382, 383, 384, 385, 386,
    387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397, 398, 411, 416, 417,
    418, 419, 420, 421, 423, 424, 425, 426, 427, 428, 430, 438, 442, 443, 458,
    459, 463, 466, 467, 474, 476, 478
  ];
  var naturalOakAndWalnutMaterialIds = [1, 12, 103, 104, 105, 135, 136, 158,166, 176, 239, 303, 304, 349, 410];
  var mutedOakAndWalnutMaterialIds = [8, 154, 165, 175, 240, 320, 350, 368, 469, 470];
  var blackOnMDFMaterialIds = [55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 322, 324, 326, 415, 440, 443, 460, 473, 475, 477];
  var aluminumOnBlackMaterialIds = [73, 241];
  var solidWhiteAcrylicMaterialIds = [323];
  var p2kSatinMaterialIds = [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 333];
  var primedForPaintMaterialIds = [72, 305, 409];
  var brassMaterialIds = [299];
  var wrappedStainlessMaterialIds = [78];
  var hplSoftTouchColorMatchedEdgeMaterialIds = [364, 365, 366, 367, 371, 372, 373, 374, 375];
  var hplColorMatchedEdgeMaterialIds = [411, 416, 417, 418, 419, 420, 421, 423, 424, 425, 426, 427, 428, 430, 438, 442, 443, 458, 459, 463, 466, 467, 474, 476, 478];

  var appliedWoodPullTypeIds = [14];
  var touchLatchPullTypeIds = [22, 33];
  var darkenedBrassPullTypeIds = [16];
  var mortisedPullTypeIds = [1, 2, 7, 8, 10, 11, 13, 14, 15, 17, 20, 37];
  var fullLengthBentPullTypeIds = [13];
  var scoopedBoxPullTypeIds = [23, 36];
  var dPullTypeIds = [1];


  _.forEach(products, product => {
    var productType = Product.get('productType', {product});

    if (productType.hasPulls) {
      var productFrontMaterialAndPullTypeData = {};

      // productFrontMaterialAndPullTypeData.default = {frontMaterialId: product.details.frontMaterial.id, pullTypeId: product.details.pullType.id};
      var ownedCompatibleDetails = Product.getOwnedCompatibleDetails({product});
      var subproductData = Product.getSubproductData({product, productType});

      var currentDetails = DetailsHelper.getDetailsFor({product});

      if (currentDetails.frontMaterial && currentDetails.pullType) {
        var frontAndPullDetailCombinations = [{pullTypeId: currentDetails.pullType.id, frontMaterialId: currentDetails.frontMaterial.id}];

        if (subproductData && subproductData.length > 0) {
          _.forEach(subproductData, (data, index) => {
            _.times(data.quantity, n => {
              frontAndPullDetailCombinations.push({pullTypeId: currentDetails[`${data.type}-${n}-pullType`].id, frontMaterialId: currentDetails[`${data.type}-${n}-frontMaterial`].id});
            });
          });
        }

        var incompatiblePair = _.find(frontAndPullDetailCombinations, ({pullTypeId, frontMaterialId}) => {
          var dbPullId = pullTypeId;

          if (project.companyKey === 'vp') {
            dbPullId = _.find([
              {hardcoded: 1, db: 27}, //   {id: 1, title: 'Radius Staple'},
              {hardcoded: 2, db: 28}, //   {id: 2, title: 'Radius Flat Tab'},
              {hardcoded: 3, db: 30}, //   {id: 3, title: 'Knurled Knob'},
              {hardcoded: 4, db: 31}, //   {id: 4, title: 'Curved Knob'},
              {hardcoded: 5, db: 32}, //   {id: 5, title: 'Notched Knob'},
              {hardcoded: 6, db: 34}, //   {id: 6, title: 'No Pulls'},
              {hardcoded: 7, db: 33}, //   {id: 7, title: 'Touch Latch'},
              {hardcoded: 8, db: 29}, //   {id: 8, title: 'Wire Radius Staple'},
              {hardcoded: 9, db: 35}, //   {id: 9, title: 'Pulls by Others'},
              {hardcoded: 10, db: 36}, //   //{id: 10, title: 'Scooped Bottom'}
            ], {hardcoded: pullTypeId}).db;
          }

          var isMortised = _.includes(mortisedPullTypeIds, dbPullId);
          var combinationIsIncompatible = false;

          if (
            _.includes(_.filter([
              ...woodMaterialIds,
              ...colorMatchedEdgeMaterialIds,
              ...blackOnMDFMaterialIds,
              ...hplSoftTouchColorMatchedEdgeMaterialIds,
              ...hplColorMatchedEdgeMaterialIds
            ], id => (!_.includes([...mutedOakAndWalnutMaterialIds, ...naturalOakAndWalnutMaterialIds], id))), frontMaterialId)
            && _.includes(appliedWoodPullTypeIds, dbPullId)
          ) {
            combinationIsIncompatible = true;
          }

          if (
            _.includes(aluminumOnBlackMaterialIds, frontMaterialId) && (_.includes(darkenedBrassPullTypeIds, dbPullId) || (isMortised && !_.includes(dPullTypeIds, dbPullId)))
          ) {
            combinationIsIncompatible = true;
          }

          if (
            _.includes(solidWhiteAcrylicMaterialIds, frontMaterialId) && isMortised
          ) {
            combinationIsIncompatible = true;
          }

          if (
            _.includes([...p2kSatinMaterialIds, ...primedForPaintMaterialIds], frontMaterialId) && (isMortised && !_.includes([fullLengthBentPullTypeIds], dbPullId))
          ) {
            combinationIsIncompatible = true;
          }

          //TODO applied brass fronts
          // if (_.includes(brassMaterialIds, frontMaterialId) && !_.includes([...fullLengthBentPullTypeIds, ...touchLatchPullTypeIds, ...scoopedBoxPullTypeIds], dbPullId)) {
          //   combinationIsIncompatible = true
          // }

          if (_.includes(wrappedStainlessMaterialIds, frontMaterialId) && (isMortised || _.includes(darkenedBrassPullTypeIds, dbPullId))) {
            combinationIsIncompatible = true;
          }

          return combinationIsIncompatible;
        });

        if (incompatiblePair) {
          issues.push({
            id: `product-${product.id}-pull-front-incompatibility-${incompatiblePair.pullTypeId}-${incompatiblePair.frontMaterialId}`,
            message: `${product.productionId || productType.title} - pull/front compatibility`,
            resourceKey: 'product',
            resourceId: product.id,
            type: 'pull-type-front-material-incompatibility',
            level: 1
          });
        }
      }
    }
  });

  return issues;
};

export default getPullMaterialIncompatibility;
