import React from 'react';
import K from 'k';
import _ from 'lodash';

import lib from 'lib';
import { connect } from 'redux/index.js';
import moment from 'moment';
import Project from 'project-helpers/project';
import Product from 'project-helpers/product';
import Room from 'project-helpers/room';
import {withErrorBoundary} from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';

import Dropdown from 'components/dropdown';

class IssuesElement extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.issuesDataLength > 0) {
      return (
        <div
          style={{
            marginRight: K.spacing * 2, display: 'flex', zIndex: 4, width: 32, height: 32,
            alignItems: 'center', borderRadius: 32, backgroundColor: '#FFCC67', cursor: 'pointer', color: 'black',
            justifyContent: 'center'
          }}
          onClick={() => this.props.handleIssuesButtonPress({value: !this.props.showIssues})}
        >
          {this.props.issuesDataLength}
        </div>
      );
    }
    else {
      return (<></>)
    }
  }
}

export default withErrorBoundary(connect({
  mapState: state => {
    var issuesDataLength = 0;

    if (_.has(state.issuesData, 'issues')) {
      _.forEach(state.issuesData.issues, (roomIssues, key) => {
        issuesDataLength = issuesDataLength + _.filter(roomIssues, issue => !issue.isResolved && issue.level === 1).length;
      })
    }

    return {
      issuesDataLength
    };
  }
})(IssuesElement));