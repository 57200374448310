import _ from 'lodash';
import lib from 'lib';
import getDependencies from 'helpers/get-dependencies';
import K from 'k';
import BKey from 'helpers/b-key';
import Color from 'color';

import Wall from 'project-helpers/wall';
import Elevation from 'project-helpers/elevation';
import Room from 'project-helpers/room';
import Product from 'project-helpers/product';
import Project from 'project-helpers/project';
import Volume from 'project-helpers/volume';
import Scope from 'project-helpers/scope';

import updateProductionIds from 'helpers/update-production-ids-helper';
import Container3dNodesHelper from './container-3d-nodes-for';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import DetailsHelper from 'helpers/details-helper';
import CountertopHelper from './countertop-helper';
import ScribesHelper from './scribes-helper';
import ContainerLightingHelper from './container-lighting-helper';
import ContainerTypeData from './container-types-helpers/container-types';
import ContainerUpdateManagedResourcesHelper from './update-managed-resources-helper';
import ArchElement from 'project-helpers/arch-element';
import memo from 'helpers/memo';
import HatchHelper from 'helpers/hatch-helper';
import getOrnamentTypes from './container-types-helpers/ornaments/ornaments';

var Container = {
  ...CountertopHelper,
  ...ScribesHelper,
  ...ContainerLightingHelper,
  ...ContainerUpdateManagedResourcesHelper,
  get(dependencyKeys, {container, state}) {
    return getDependencies({dependencyKeys, state}, ({state, useDependency}) => {
      if (useDependency('room') || useDependency('scopes') || useDependency('siblings') || useDependency('wallSets') || useDependency('sinkProducts') || useDependency('computedWalls') || useDependency('walls') || useDependency('volumes')) {
        var scope = state.resources.scopes.byId[container.scopeId];
        var room = state.resources.rooms.byId[scope.roomId];
        var scopes = state.resources.scopes.byFieldKeyIndex.roomId[room.id];
      }

      if (useDependency('wallSets') || useDependency('computedWalls')) {
        var wallSets = Room.get('wallSets', {room, state});
      }

      if (useDependency('sinkProducts')) {
        var sinkProducts = _.filter(Room.get('products', {room}), product => {
          return Product.getHasSink({product}) && Container.containsFootprintOf({container, product});
        });
      }

      if (useDependency('siblings') || useDependency('containerType')) {
        var project = state.resources.projects.byId[container.projectId];

        var initializedTypes = {};

        if (project) {
          var {companyKey, isEmployee} = project;

          initializedTypes = Container.getInitializedTypes({companyKey, isEmployee});
        }
      }

      var combinedResourcesByScopeIds = (resources, scopeIds) => {
        var resourcesByScopeIds = _.map(scopeIds, scopeId => resources[scopeId]);
        var combinedByScopeIds = {};

        _.forEach(resourcesByScopeIds, resources => {
          _.forEach(resources, resource => combinedByScopeIds[resource.id] = resource);
        });

        return combinedByScopeIds;
      };

      return {
        //HINT something is causing child products to sometimes have a container id
        //those products are handled by their parents, not the container
        products: () => _.filter(state.resources.products.byFieldKeyIndex.containerInstanceId[container.id], product => {
          return !product.productInstanceId;
        }),
        // products: () => state.resources.products.byFieldKeyIndex.containerInstanceId[container.id],
        allProducts: () => _.uniqBy([..._.values(state.resources.products.byFieldKeyIndex.containerInstanceId[container.id]), ..._.flatMap(state.resources.products.byFieldKeyIndex.containerInstanceId[container.id], product => {
          return _.values(Product.get('childProducts', {product}));
        })], 'id'),
        room: () => room,
        scopes: () => scopes,
        scope: () => state.resources.scopes.byId[container.scopeId],
        project: () => state.resources.projects.byId[container.projectId],
        companyKey: () => state.resources.projects.byId[container.projectId] && state.resources.projects.byId[container.projectId].companyKey,
        isEmployee: () => state.resources.projects.byId[container.projectId] && state.resources.projects.byId[container.projectId].isEmployee,
        siblings: () => _.filter(combinedResourcesByScopeIds(state.resources.containers.byFieldKeyIndex.scopeId, _.map(scopes, 'id')), c => {
          return c.id !== container.id && !initializedTypes[c.type].isOrnament && c.position && !_.isEqual(c.position, {});
        }),
        sinkProducts: () => sinkProducts,
        wallSets: () => wallSets,
        walls: () => Room.get('walls', {room}),
        volumes: () => Room.get('volumes', {room}),
        dbContainerType: () => _.find(state.resources.containerTypes.byId, {key: container.type}),
        containerType: () => initializedTypes[container.type],
        computedWalls: () => Room.get('computedWalls', {room}),
        unmanagedProductInstances: () => _.filter(state.resources.products.byFieldKeyIndex.containerInstanceId[container.id], product => {
          return _.get(product, 'managedData.managedKey') === undefined && product.position;
        }),
        managedProductInstances: () => _.filter(state.resources.products.byFieldKeyIndex.containerInstanceId[container.id], product => {
          return _.get(product, 'managedData.managedKey') !== undefined;
        }),
        dependencies: () => state.resources
      };
    });
  },

  getTypeDataFor: memo(({container}) => {
    const {companyKey, isEmployee} = Container.get(['companyKey', 'isEmployee'], {container});

    return Container.getInitializedTypes({companyKey, isEmployee})[container.type];
  }),

  getInitializedTypes({isEmployee, companyKey}) {
    if (!K.initializedTypes || (K.isEmployee !== isEmployee || K.companyKey !== companyKey)) {
      K.initializedTypes = ContainerTypeData.initializeTypes({types: ContainerTypeData.TypesFor({companyKey, isEmployee: true}), companyKey});
      K.isEmployee = isEmployee;
      K.companyKey = companyKey;
    }

    return K.initializedTypes;
  },

  getTypesFor: memo(({viewKey, companyKey, isEmployee}) => {
    var hybridContainers = ContainerTypeData.HybridContainerTypes;

    var wallDependentContainers = ContainerTypeData.WallDependentContainerTypes;

    return lib.waterfall(Container.getInitializedTypes({companyKey, isEmployee}), [
      [_.filter, typeData => _.includes(typeData.companies, companyKey)],
      [_.filter, typeData => {
        var shouldShow = true;

        if (_.includes(['endPanel', 'capPanel', 'daylightIsland', 'vanity', 'custom', 'generic'], typeData.type) && !isEmployee) {
          shouldShow = false;
        }

        return shouldShow;
      }],
      [_.map, typeData => {
        // var isWallDependentContainer = _.includes(wallDependentContainers, typeData.type);
        // var isHybridContainer = _.includes(hybridContainers, typeData.type);
        // var isDisabled = viewKey === 'top' ? isWallDependentContainer : (!isWallDependentContainer && !isHybridContainer);
        var isDisabled = false;

        // if (!typeData.isOrnament && viewKey === 'both') {
        //   isDisabled = false; //isWallDependentContainer || (!isWallDependentContainer && !isHybridContainer);
        // }

        if (typeData.isOrnament) {
          isDisabled = _.isEmpty(typeData.scripts[viewKey === 'top' ? 'top' : 'front']);
        }

        return {...typeData, isDisabled};
      }],
      [_.sortBy, 'isDisabled']
    ]);
  }),

  getScript: memo(({container, elevation, isNonSpacial, nonSpacialSideKey}) => {
    var sideKey = 'top';

    if (elevation || (isNonSpacial && nonSpacialSideKey !== 'top')) {
      sideKey = isNonSpacial ? nonSpacialSideKey : Container.getSideKey({container, elevation, viewKey: 'front'});
    }

    var script = Container.getTypeDataFor({container}).scripts[sideKey];

    return script;
  }),

  get3dNodesFor: memo(({container, isArchetype, activeFillMode, isContextElement}) => {
    return Container3dNodesHelper.get3dNodesFor({container, isArchetype, activeFillMode, isContextElement});
  }),

  updateProjectCalculatedData({container, room, reduxActions}) {
    if (!room) room = Container.get('room', {container});

    Room.updateManagedResources({room, reduxActions});
  },

  async create({props, reduxActions}) {
    var {room, project} = Container.get(['room', 'project'], {container: props});
    var details = _.clone(DetailsHelper.getCleanedOwnedDetailsFor({container: props}));
    var parentDetails = DetailsHelper.getDetailsFor({room});

    //HINT value was being set to a default when it shouldn't
    details = _.omit(details, ['leftScribeMaterial', 'rightScribeMaterial', 'topScribeMaterial']);

    _.forEach(parentDetails, (value, key) => {
      var detail = details[key];

      //HINT if value is liner and liner isn't an option use end panel material
      if (detail && _.includes(detail.key, 'Material') && _.get(value, 'id') === 71) {
        if (!_.find(detail.options, {id: 71})) {
          var newValue = _.get(parentDetails, 'endPanelMaterial.id');

          if (newValue) value = newValue;
        }
      }

      if (_.includes(key, 'frontMaterial') && !!value.id) {
        // use frontMaterial of the room
        details[key] = value;
      }

      if (detail && !_.includes(['leftScribeMaterial', 'rightScribeMaterial', 'topScribeMaterial'], key) && (!value.isMixed || project.materialPreset)) {
        details[key] = _.pick(value, ['id', 'settings']);
      }
    });

    props = Container.constrainProps({container: {...props, details}});

    let container = await lib.api.create('containerInstance', {props});

    reduxActions.trackContainers({containers: [container]});

    //HINT update container managed data and countertops
    Container.updateProjectCalculatedData({container, reduxActions});

    return container;
  },

  //HINT cachedContainer is the container since last db update
  //HINT used for undo, careful, transforms are tracked in redux (but shouldn't be considered for undo)
  update({id, container = {}, cachedContainer = {}, props, reduxActions, oldContainer, pushToUndoQueue, isPropertiesViewUpdate = false, isBatched = false, isUndo = false}) {
    if (!id) id = container.id || cachedContainer.id;
    if (pushToUndoQueue && cachedContainer) pushToUndoQueue({type: 'container', eventKey: 'transformEnd', instance: cachedContainer});

    if (props.eventType !== 'transform') props.eventType = undefined;

    props.dimensions = Container.constrainProps({container: {...cachedContainer, ...container, ...props}}).dimensions;

    if (props.rotation) props.rotation = lib.trig.normalize({degrees: props.rotation || container.rotation});

    let updatedContainer = {...cachedContainer, ...container, ...props};

    //HINT when a ctop is moved or resized
    if (container.type === 'countertop' && !isUndo) {
      //HINT sense if dimensions or position changed
      //HINT ignore height because we can continue automating when height is changed
      if (!_.isEmpty(cachedContainer) && (!_.isEqual(_.pick(updatedContainer.dimensions, ['width', 'depth']), _.pick(cachedContainer.dimensions, ['width', 'depth'])) || !_.isEqual(updatedContainer.position, cachedContainer.position))) {
        props = {
          ...props,
          customData: {
            ...container.customData,
            ...props.customData,
            inManualMode: 1
          }
        };

        updatedContainer = {
          ...updatedContainer,
          customData: {
            ...updatedContainer.customData,
            inManualMode: 1
          }
        }
      }
    }

    var scopeId = Container.getScopeId({container: updatedContainer});

    updatedContainer.scopeId = scopeId;
    props.scopeId = scopeId;

    let updatesMap = {
      containers: {creations: [], updates: [{where: {id}, props}], deletedIds: []},
      productOptions: {creations: [], updates: [], deletedIds: []},
      products: {creations: [], updates: [], deletedIds: []},
    };

    //HINT update product positions (when scaling) and update product and product option scopeIds
    if (!isPropertiesViewUpdate) updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, Container.getDependentProductUpdates({container: updatedContainer, oldContainer, actionKey: isUndo ? 'transform' : 'transformEnd'}));

    if (!isBatched) {
      //HINT this is debounced so its ok to call it before redux is updated
      //Important that this is above redux update so that countertops remain hidden until we finish recalculating them
      // if (props.eventType !== 'transform') Container.updateProjectCalculatedData({container: updatedContainer, reduxActions});
      Container.updateProjectCalculatedData({container: updatedContainer, reduxActions});

      UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions});
    }
    else {
      return updatesMap;
    }
  },

  //HINT scopeId is included to update product scopeIds when container is moved
  //HINT managed products are handled in the updateManagedProducts function
  getDependentProductUpdates({container, oldContainer, actionKey}) {
    let updatesMap = {
      productOptions: {creations: [], updates: [], deletedIds: []},
      products: {creations: [], updates: [], deletedIds: []},
    };

    if (container.type === 'horizontalBarblock') {
      const products = Container.get('products', {container});

      if (_.values(products).length && !(actionKey === 'transform' && _.isEqual(container.dimensions, oldContainer.dimensions))) {
        const barblockFrameProducts = _.filter(products, product => Product.getIsHorizontalBarblock({product}));

        _.forEach(barblockFrameProducts, barblockFrameProduct => {
          const updatedProductsProps = {
            dimensions: container.dimensions,
            customData: {
              ...barblockFrameProduct.customData,
              countertopThickness: ((container.dimensions.height || 6.125) - 4.625 - 0.75 - 0.25),
            },
            scopeId: container.scopeId
          };

          updatesMap.products.updates.push({where: {id: barblockFrameProduct.id}, props: updatedProductsProps});
        });
      }
    }
    else {
      if (actionKey === 'transform') {
        if (oldContainer.rotation === container.rotation && !_.isEqual(_.pick(container.dimensions, ['width', 'height']), _.pick(oldContainer.dimensions, ['width', 'height']))) {
          let products = Container.get('unmanagedProductInstances', {container});
          // Reset the position of the product inside the container
          let positionDifference = lib.object.difference(container.position, oldContainer.position);

          positionDifference = {
            y: positionDifference.y,
            x: lib.trig.rotate({point: {x: positionDifference.x, y: positionDifference.z}, byDegrees: container.rotation}).x
          };

          if (_.includes([0, 180], container.rotation)) positionDifference.x *= -1;

          if (_.values(products).length > 0 && !_.every(positionDifference, value => value === 0)) {
            updatesMap.products.updates.push(..._.map(products, product => {
              return {where: {id: product.id}, props: {position: lib.object.sum(positionDifference, product.position)}};
            }));
          }
        }
      }
      else if (actionKey === 'transformEnd') {
        var products = Container.get('allProducts', {container});

        if (products.length > 0) {
          updatesMap.products.updates.push(..._.map(products, product => {
            var updatedProps = {position: product.position, scopeId: container.scopeId};

            if (_.includes([1355, 901, 691, 762, 1516], product.productId)) {
              var subcounterHeight = Container.getSubcounterHeight({container});
              var shouldAddSubcounterHeight = subcounterHeight === 0.5;

              updatedProps.productionDimensions = {...(product.productionDimensions || {}), height: product.dimensions.height + (shouldAddSubcounterHeight ? subcounterHeight : 0)};
            }

            return {where: {id: product.id}, props: updatedProps};
          }));

          updatesMap.productOptions.updates.push(..._.flatMap(products, product => {
            var productOptionInstances = Product.get('productOptionInstances', {product});

            return _.map(productOptionInstances, productOption => {
              return {where: {id: productOption.id}, props: {scopeId: container.scopeId}};
            });
          }));
        }
      }
    }

    return updatesMap;
  },

  destroy({container, reduxActions, pushToUndoQueue, isBatched = false}) {
    let {unmanagedProductInstances: products, dependencies} = Container.get(['unmanagedProductInstances', 'dependencies'], {container});
    let productOptions = [];

    _.forEach(products, product => {
      let {childProducts, productOptionInstances} = Product.get(['childProducts', 'productOptionInstances'], {product});
      childProducts = _.values(childProducts);

      if (productOptionInstances && productOptionInstances.length > 0) {
        productOptions.push(...productOptionInstances);
      }

      if (childProducts && childProducts.length > 0) {
        _.forEach(childProducts, childProduct => {
          let productOptionInstances = Product.get('productOptionInstances', {product: childProduct});

          if (productOptionInstances && productOptionInstances.length > 0) {
            productOptions.push(...productOptionInstances);
          }
        });

        products.push(...childProducts);
      }
    });

    let objectsByType = {
      products,
      productOptions,
      containers: [container]
    };

    if (pushToUndoQueue) {
      pushToUndoQueue({type: 'container', eventKey: 'destroy', instance: container, data: {objectsByType}});
    }

    let updatesMap = {
      productOptions: {creations: [], updates: [], deletedIds: _.map(productOptions, 'id')},
      products: {creations: [], updates: [], deletedIds: _.map(products, 'id')},
      containers: {creations: [], updates: [], deletedIds: [container.id]},
    };

    if (container.type === 'countertop') {
      if (!container.customData.inManualMode) {
        var coveringContainerIds = _.get(container.customData, 'containerIds');

        if (coveringContainerIds) {
          //For now we're maintaining the automated status, but I considered setting the countertop to manual when it is deleted
          //to more cleanly handle undo, want to avoid situations where an automated countertop is deleted, then undone, but it no longer exists or should exist
          //if in the future we want to return to that, uncomment out these lines
          // updatesMap.containers.updates.push({where: {id: container.id}, props: {customData: {...container.customData, inManualMode: 1}}})
          // _.set(container, 'customData.inManualMode', 1);

          //HINT turn off automated countertops for the containers covered by the deleted automated countertop
          _.forEach(coveringContainerIds, coveringContainerId => {
            var coveredContainer = _.get(dependencies.containers, `byId.${coveringContainerId}`);

            if (coveredContainer) {
              updatesMap.containers.updates.push({where: {id: coveredContainer.id}, props: {customData: {...coveredContainer.customData, managedCountertopsEnabled: 0}}})
            }

          });
        }
      }
    }

    var {managedUpdatesMap, containerCacheUpdate} = Container.updateManagedResources({container, actionKey: 'destroy', reduxActions, isBatched: true});

    if (containerCacheUpdate) updatesMap.containers.updates.push({where: {id: container.id}, props: {customData: {...container.customData, cachedManagedData: containerCacheUpdate}}});

    updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);

    if (!isBatched) {
      UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions});

      Container.updateProjectCalculatedData({container, reduxActions});
    }
    else {
      return {updatesMap, objectsByType};
    }
  },

  constrainProps({container}) {
    const containerType = Container.getTypeDataFor({container});
    const constraints = containerType.constraintsFor({container});
    const constrainer = new lib.DimensionConstrainer({constraints});

    if (!(container.type === 'countertop' && container.customData.isByOthers) && !container.customData.hasNonStandardDimensions) {
      container.dimensions = constrainer.constrain({dimensions: container.dimensions});
    }

    return container;
  },

  getSupportsCountertop({container}) {
    return _.includes(K.supportedCountertopContainerTypes, container.type);
  },

  getSubcounterHeight({container}) {
    var defaultSubcounterHeight = container ? (Container.get('companyKey', {container}) === 'hb' ? 0.75 : 0.5) : 0;

    return Container.getHasSubcounter({container}) ? (_.get(container, 'customData.customSubcounterHeight') || _.get(container, 'customData.subcounterHeight', defaultSubcounterHeight)) : 0;
  },

  getDistance: memo(({sourceContainer, wall, computedWall, wallSet, room, container, volume, line}) => {
    var fromFootprint = Container.getFootprintInRoom({container: sourceContainer});

    var distances = [];
    var f2, f1 = fromFootprint;

    if (computedWall) wallSet = computedWall.wallSet;

    if (volume) {
      f2 = Volume.getFootprintInRoom({volume});

      _.forEach(f1, p1 => {
        _.forEach(f2, p2 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toPoint: p2}));
        });
      });
    }
    else if (container) {
      f2 = Container.getFootprintInRoom({container});

      _.forEach(f1, p1 => {
        _.forEach(f2, p2 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toPoint: p2}));
        });
      });
    }
    else if (line) {
      _.forEach(f1, point => {
        distances.push(lib.math.trig.distance({fromPoint: point, toLine: line}));
      });
    }
    else {
      if (!room) room = Container.get('room', {container: sourceContainer});
      var walls = computedWall ? computedWall.walls : [wall];
      var wallLinesInRoom = _.map(walls, wall => Wall.getLine({wall})); //wallSet, room

      _.forEach(wallLinesInRoom, line => {
        _.forEach(f1, p1 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toLine: line}));
        });
      });
    }

    return _.min(distances);
  }, {getRelevantMemoData: ({sourceContainer, wall, computedWall, wallSet, room, container, volume, line}) => {
    return {
      ...(sourceContainer ? {sourceContainerId: sourceContainer.id, sourceContainerPosition: sourceContainer.position, sourceContainerDimensions: sourceContainer.dimensions, sourceContainerRotation: sourceContainer.rotation} : {}),
      ...(container ? {containerId: container.id, containerPosition: container.position, containerDimensions: container.dimensions, containerRotation: container.rotation} : {}),
      ...(volume ? {volumePosition: volume.position, volumeDimensions: volume.dimensions, volumeRotation: volume.rotation} : {}),
      ...(line ? {line} : {}),
      ...(wall ? {wall, plan: room ? room.plan : undefined} : {}),
      ...(computedWall ? {computedWall: {lineInRoom: computedWall.lineInRoom, walls: computedWall.walls, alpha: computedWall.alpha, isPerpendicularJog: computedWall.isPerpendicularJog}, plan: room ? room.plan : undefined} : {}),
    };
  }}),

  getContainersSeparatedByWall: memo(({sourceContainer, sourceContainerFootprint, container, wallsData, project}) => {
    var containersSeparatedByWall = false;

    var pointCombinations = [];
    var f1 = Container.getFootprintInRoom({container});
    var f2 = sourceContainerFootprint || Container.getFootprintInRoom({container: sourceContainer});

    _.forEach(f1, p1 => {
      _.forEach(f2, p2 => {
        pointCombinations.push({from: p1, to: p2});
      });
    });

    var minLine = _.minBy(pointCombinations, ({from, to}) => lib.math.trig.distance({fromPoint: from, toPoint: to}));
    var wallsInTheWay = [];
    var extendedMinLine = lib.trig.extend({line: minLine, by: 0.001});

    _.forEach(wallsData, wallData => {
      //hint extend to have containers directly against a wall intersect;
      if (lib.math.linesIntersect({l1: extendedMinLine, l2: wallData.slightlyExtendedLine})) wallsInTheWay.push(wallData);
    });

    if (wallsInTheWay.length >= 2) {
      var i = 0;

      while (i < wallsInTheWay.length) {
        var wall = wallsInTheWay[i];

        var parallelWall = _.find(wallsInTheWay, (w2, w2Index) => {
          if (w2Index <= i) return false;

          return !lib.math.linesIntersect({l1: wall.extendedLine, l2: w2.extendedLine});
        });

        if (parallelWall) {
          var minIntersectedParallelWallWidth = _.min([wall.width, parallelWall.width]);

          if (minIntersectedParallelWallWidth >= (_.get(project, 'id') === 5572 ? 36 : 48)) {
            containersSeparatedByWall = true;
            i = wallsInTheWay.length;
          }
        }

        i++;
      }
    }

    return containersSeparatedByWall;
  }),
  // }, {getRelevantMemoData: ({sourceContainer, sourceContainerFootprint, container, wallsData, project}) => {
  //   return {
  //     ...(sourceContainer ? {position: sourceContainer.position, dimensions: sourceContainer.dimensions, rotation: sourceContainer.rotation} : {}),
  //     ...(container ? {position: container.position, dimensions: container.dimensions, rotation: container.rotation} : {}),
  //     ...(sourceContainerFootprint ? {sourceContainerFootprint} : {}),
  //     ...(wallsData ? {wallsData} : {}),
  //     ...(project ? {projectId: project.id} : {}),
  //   }
  // }}),

  getAlpha({container}) {
    return lib.trig.degreesToRadians(container.rotation);
  },

  getVolume: memo(({container, volumes}) => {
    if (!volumes) volumes = _.values(Container.get('volumes', {container}));

    return _
      .chain(volumes)
      .map(volume => ({volume, distance: Container.getDistance({sourceContainer: container, volume})}))
      .filter(({distance}) => distance < K.nearbyWallThreshold)
      .minBy('distance')
      .get('volume')
      .value();
  }),

  getComputedWall: memo(({container, computedWalls}) => {
    return _
      .chain(computedWalls)
      .filter(computedWall => lib.trig.anglesAreEqual({a1: Container.getAlpha({container}), a2: computedWall.alpha}))
      .map(computedWall => ({computedWall, distance: Container.getDistance({sourceContainer: container, computedWall})}))
      .filter(({distance}) => distance < K.nearbyWallThreshold)
      .minBy('distance')
      .get('computedWall')
      .value();
  }),

  getWall: memo(({container}) => {
    var computedWalls = Container.get('computedWalls', {container});

    var computedWall = Container.getComputedWall({container, computedWalls});

    return computedWall && _.minBy(computedWall.walls, wall => Container.getDistance({sourceContainer: container, wall}));
  }),
  // }, {getRelevantMemoData: ({container}) => {
  //   //TODO should consider wall data
  //   return {
  //     id: container.id,
  //     position: container.position,
  //     dimensions: container.dimensions,
  //     rotation: container.rotation
  //   }
  // }}),

  //TODO tbd need to migrate to volumes
  //should use volumes to represent soffits and remove this
  getSoffit({container}) {
    let soffit;
    const wall = Container.getWall({container});

    if (wall) soffit = _.find(Wall.get('archElements', {wall}), {type: 'soffit'});

    return soffit;
  },

  getSoffitVolume: memo(({container, volumes}) => {
    if (!volumes) volumes = _.values(Container.get('volumes', {container}));

    return _.find(volumes, volume => {
      var isAboveContainer = Volume.getYRange({volume}).from >= Container.getYRange({container}).to;
      var sharesFootprint = Container.sharesFootprintWith({sourceContainer: container, volume, inclusive: false});

      return isAboveContainer && sharesFootprint;
    });
  }),

  getZIndex: memo(({container, elevation, viewKey, showOrnamentTopIndicators, countertopsAreSelectable}) => {
    var position = viewKey === 'top' ? Container.getPositionInRoom({container}) : Container.getPositionInElevation({container, elevation});
    var {dimensions} = container;
    var zIndex = position.z;

    // if (Container.getTypeDataFor({container}).isOrnament) zIndex += 10;

    if (viewKey === 'top') {
      zIndex += dimensions.height;

      var ornamentTypes = getOrnamentTypes();

      if (showOrnamentTopIndicators && _.has(ornamentTypes, `${container.type}`)) zIndex += 1000;
      if (countertopsAreSelectable && container.type === 'countertop') zIndex += 100;
    }
    else if (viewKey === 'front') {
      var sideKey = Container.getSideKey({container, elevation, viewKey});

      var containerElevationTheta = Container.getElevationTheta({container, elevation});

      if (false) {//(containerElevationTheta % 90 !== 0) {
        //TODO
      }
      else {
        if (_.includes(['front'], sideKey)) {
          var rotatedDepth = _.find(lib.trig.rotate({point: {x: dimensions.depth, y: 0}, byDegrees: Container.getElevationTheta({container, elevation})}), point => point !== 0);

          zIndex += rotatedDepth;

          //HINT bandaid to get unit numbers appearing over freestanding appliances
          if (_.includes(['tallFreestandingAppliance', 'wallFreestandingAppliance', 'baseFreestandingAppliance'], container.type)) {
            zIndex -= 6;
          }

          if (container.type === 'endPanel' && _.get(container, 'customData.wrap.isDashed.left')) {
            zIndex += 10;
          }
          else if (container.type === 'capPanel' && _.get(container, 'customData.wrap.isDashed.top')) {
            zIndex += 10;
          }
        }
        else if (sideKey === 'right') {
          zIndex += dimensions.width;
        }

        if (sideKey === 'front') {
          zIndex += 0.001;
        }

        if (sideKey === 'back' && _.includes(['horizontalBarblock', 'rearFacingBarblock'], container.type)) {
          //HINT move in front of frontfacing ctops
          zIndex += 0.002;
        }
      }
    }

    return zIndex;
  }),

  getPositionInRoom({container}) {
    return {x: container.position.x, y: container.position.z, z: container.position.y}; //TODO rotate
  },

  getPositionInElevation: memo(({container, elevation, isNonSpacial, nonSpacialSideKey, overridePosition}) => {
    const sideKey = isNonSpacial ? nonSpacialSideKey : Container.getSideKey({container, viewKey: 'front', elevation});

    var sideKeyOffset = {
      front: 0,
      left: container.type === 'countertop' ? 0 : -lib.trig.rotate({point: {x: container.dimensions.depth, y: 0}, byDegrees: 90}).x,
      right: lib.trig.rotate({point: {x: container.dimensions.depth, y: 0}, byDegrees: 270}).y,
      back: lib.trig.rotate({point: {x: container.dimensions.width, y: 0}, byDegrees: 180}).x
    }[sideKey];

    return lib.object.sum(overridePosition || Elevation.getPosition2d({elevation, position3d: container.position}), {y: -container.dimensions.height, x: sideKeyOffset});
  }),

  getSnapToWall: memo(({container, viewKey}) => {
    var typeData = Container.getTypeDataFor({container});
    var {snapToWall} = typeData;
    var companyKey = Container.get('companyKey', {container});

    var relevantContainerKeys = {
      hb: ['wallPanel', 'wall', 'opencase', 'pivotDoor', 'cornerCounterTransition', 'wallFreestandingAppliance'],
      vp: ['wallPanel', 'wall', 'wallUnitLiner', 'opencase', 'wallFreestandingAppliance']
    }[companyKey];

    // HINT for hybrid containers and containers that can be moved off the wall after being dropped
    // snap to wall should be true for the initial drop, then false after it's created
    if ((_.includes(ContainerTypeData.HybridContainerTypes, container.type) || typeData.isOrnament) && viewKey === 'front' && !container.id) {
      snapToWall = true;
    }

    if (_.includes(relevantContainerKeys, container.type) && container.id) {
      snapToWall = false;
    }

    return snapToWall;
  }),

  position3dTransformFor({container, position2d, position3d, viewKey, room, elevation, forceSnapToWall = false}) {
    if (!room) room = Container.get('room', {container});
    var newTransformProps = {};

    if (viewKey === 'top') {
      position3d = {x: position2d.x, y: position3d.y, z: position2d.y};

      // if (Container.getSnapToWall({container, viewKey}) || forceSnapToWall) {
      //   var nearestWall = _.minBy(_.values(Room.get('walls', {room})), wall => lib.trig.distance({fromPoint: position2d, toLine: Wall.getLine({wall}).inRoom}));

      //   var positionOnWall = lib.trig.nearestPoint({point: position2d, onLine: Wall.getLine({wall: nearestWall}).inRoom});
      //   var newRotation = lib.trig.radiansToDegrees(lib.trig.normalize({radians: Wall.getAlpha({wall: nearestWall})}));

      //   newTransformProps.rotation = newRotation;

      //   position3d.x = positionOnWall.x;
      //   position3d.z = positionOnWall.y;
      // }
    }
    else {
      //snap to nearest wall
      //TODO should snap to volume if present
      if (forceSnapToWall) { //Container.getSnapToWall({container, viewKey})
        var wallDataFor = ({x}) => {
          var wall = Elevation.getWallFor({elevation, x});
          var wallX = Elevation.getWallX({elevation, wall});

          return {wall, xRelativeToWall: x - wallX};
        };

        var {wall, xRelativeToWall} = wallDataFor({x: position2d.x});

        if (wall) {
          var wallPositionXZInWall = lib.trig.rotate({point: {x: xRelativeToWall, y: 0}, byDegrees: Elevation.getRotation({elevation})});
          var wallPositionXZ = lib.object.sum(Wall.getLine({wall}).inRoom.from, wallPositionXZInWall);

          position3d = {x: wallPositionXZ.x, y: -position2d.y, z: wallPositionXZ.y};
        }
      }
      else {
        //convert the existing position3d to a position on the wall, so it is effectively 2d
        var elevationXYOrigin = elevation.lineInRoom.from;
        var elevationXZOrigin = {x: elevationXYOrigin.x, z: elevationXYOrigin.y};

        var offsetXZPositionInElevation = lib.object.difference(position3d, elevationXZOrigin);
        var rotatedXZPositionInElevation = lib.math.trig.rotate({point: {x: offsetXZPositionInElevation.x, y: offsetXZPositionInElevation.z}, byDegrees: -Elevation.getRotation({elevation})});

        var newXZPosition = {x: position2d.x, y: rotatedXZPositionInElevation.y};

        //convert the position back to 3d
        var rotatedXZPositionInRoom = lib.math.trig.rotate({point: newXZPosition, byDegrees: Elevation.getRotation({elevation})});
        position3d = {x: rotatedXZPositionInRoom.x, y: -position2d.y, z: rotatedXZPositionInRoom.y};
        position3d = lib.object.sum(position3d, elevationXZOrigin);
      }
    }

    newTransformProps.position3d = position3d;

    return newTransformProps;
  },

  getFootprintFor({position, dimensions, origin, rotation, includeCenterPoints = false}) {
    var {width, depth} = dimensions, {x, z} = position;

    return _.map([
      {x: x, y: z},
      {x: x, y: z + depth},
      {x: x + width, y: z + depth},
      {x: x + width, y: z},
      ...(includeCenterPoints ? [
        {x: x + width / 2, y: z},
        {x: x + width / 2, y: z + depth},
      ] : [])
    ], point => lib.trig.rotate({point, aroundOrigin: origin, byDegrees: rotation}));
  },

  getFootprintInRoom({container, includeCenterPoints}) {
    var origin = Container.getPositionInRoom({container}), {dimensions, rotation} = container;

    return Container.getFootprintFor({position: {x: origin.x, z: origin.y}, dimensions, origin, rotation, includeCenterPoints});
  },

  getFootprintLines({container, includeCenterline}) {
    var center;
    var footprintInRoom = Container.getFootprintInRoom({container});

    if (includeCenterline) {
      center = {
        from: lib.trig.translate({point: footprintInRoom[0], by: container.dimensions.width / 2, alpha: Container.getAlpha({container})}),
        to: lib.trig.translate({point: footprintInRoom[1], by: container.dimensions.width / 2, alpha: Container.getAlpha({container})}),
      };
    }

    return {
      left: {from: footprintInRoom[0], to: footprintInRoom[1]},
      front: {from: footprintInRoom[1], to: footprintInRoom[2]},
      right: {from: footprintInRoom[2], to: footprintInRoom[3]},
      back: {from: footprintInRoom[3], to: footprintInRoom[0]},
      ...(includeCenterline ? {center} : {})
    };
  },

  getComputedFootprintLines({container}) {
    var footprintLines = Container.getFootprintLines({container});

    return _.mapValues(footprintLines, line => ({
      normal: line,
      extended: lib.trig.extend({line}),
      fromExtended: lib.trig.extend({line, rangeKey: 'from'}),
      toExtended: lib.trig.extend({line, rangeKey: 'to'})
    }));
  },

  sharesFootprintWith({sourceContainer, container, product, volume, inclusive = true}) {
    var f1 = Container.getFootprintInRoom({container: sourceContainer});
    var f2;
    if (product) f2 = Product.getFootprintInRoom({product});
    if (container) f2 = Container.getFootprintInRoom({container});
    if (volume) f2 = Volume.getFootprintInRoom({volume});

    return lib.math.polygon.polygonsOverlap(f1, f2, {inclusive});
  },

  getSideKey: memo(({viewKey, container, elevation}) => {
    var sideKey = 'top';

    if (viewKey === 'front') {
      var theta = lib.round(Container.getElevationTheta({container, elevation}), {toNearest: 1});

      sideKey = 'back';
      var sideCandidates = [
        {theta: 0, sideKey: 'front'},
        {theta: 90, sideKey: 'left'},
        {theta: 180, sideKey: 'back'},
        {theta: 270, sideKey: 'right'},
        {theta: 360, sideKey: 'front'},
      ];
      sideCandidates.forEach(candidate => {
        if (candidate.theta === theta) {
          sideKey = candidate.sideKey;
        }
      });
    }

    return sideKey;
  }, {name: 'containerGetSideKey'}),

  //< dropzone
  getDropzoneSize({container, viewKey}) {
    return Container.getTypeDataFor({container}).getDropzoneSize({container, viewKey});
  },

  getDropzoneInset: memo(({container, viewKey}) => {
    return Container.getTypeDataFor({container}).getDropzoneInset({container, viewKey});
  }, {name: 'containerGetDropzoneInset'}),
  //> dropzone

  containsFootprintOf({container, product}) {
    var f1 = Container.getChildrenFootprintInRoom({container}), f2 = Product.getFootprintInRoom({product});

    return _.every(f2, point => lib.math.polygon.pointInsidePolygon({point, polygon: f1}));
  },

  footprintIsInlineWith({sourceContainer, container}) {
    return _.some(Container.getComputedFootprintLines({container: sourceContainer}).extended, c1Line => {
      return _.some(Container.getFootprintInRoom({container}), c2Point => {
        return lib.trig.distance({fromPoint: c2Point, toLine: c1Line}) < Number.EPSILON;
      });
    });
  },

  getAbsoluteFrontLine({container, room}) {
    if (!room) room = Container.get('room', {container});

    return _.mapValues(Container.getFootprintLines({container}).front, point => lib.object.sum(room.plan.position, point));
  },

  getIsBottomInFootprint({container, roomContainers}) {
    var siblings;

    if (roomContainers) {
      siblings = _.filter(roomContainers, c1 => c1.id !== container.id);
    }
    else {
      siblings = Container.get('siblings', {container});
    }

    var overlappingSiblings = [container, ..._.filter(siblings, container1 => {
      return Container.sharesFootprintWith({sourceContainer: container, container: container1});
    })];

    var isBottom = _.minBy(overlappingSiblings, 'position.y').id === container.id;

    return isBottom;
  },

  // absolute positions of each corner of children zone in plan
  getChildrenFootprintInRoom({container}) {
    var origin = Container.getPositionInRoom({container});
    var dropzoneInset = Container.getDropzoneInset({container});
    var dimensions = Container.getDropzoneSize({container});
    var position = lib.object.sum({x: origin.x, z: origin.y}, dropzoneInset);

    return Container.getFootprintFor({position, dimensions, origin, rotation: container.rotation});
  },
  //> footprint

  //< wallprint
  getWallprint({container, elevation, minWallX}) {
    return Container.getWallprintInElevation({container, elevation, minWallX});
  },

  //TODO if elevation id undefined try to find an elevation where the container is front facing
  getWallprintInElevation({container, elevation, minWallX}) {
    let wallPrintInElevation = [];

    if (elevation) {
      const sideKey = Container.getSideKey({container, elevation, viewKey: 'front'});

      var width = container.dimensions[K.sideSizeMap[sideKey || 'front'].width];
      var height = container.dimensions[K.sideSizeMap[sideKey || 'front'].height];
      let positionInElevation = Container.getPositionInElevation({container, elevation});
      let {x, y} = positionInElevation;
      y = -y;

      if (!(minWallX || minWallX === 0)) minWallX = Elevation.getMinWallX({elevation});

      //HINT position in elevation origin top;
      y -= height;

      //HINT want position in wall, not overall elevation
      x -= minWallX;

      wallPrintInElevation = [
        {x: x, y: y},
        {x: x, y: y + height},
        {x: x + width, y: y + height},
        {x: x + width, y: y}
      ];
    }
    //HINT not sure why we're getting wallprint when elevation isn't defined, but it's coming from getScribeData
    //HINT keeping old logic
    else {
      const sideKey = 'front';

      // var {width, height} = container;
      var width = container.dimensions[K.sideSizeMap[sideKey || 'front'].width];
      var height = container.dimensions[K.sideSizeMap[sideKey || 'front'].height];
      var {x, y, z} = container.position;
      var wallPosition = {x: 0, y: 0};

      var wall = undefined;

      if (wall) {
        wallPosition = Wall.getLine({wall}).from;
      }

      var floorPositions = {
        container: {x, y: z},
        wall: wallPosition
      };

      const rotation = container.rotation;

      floorPositions = _.mapValues(floorPositions, (position) => {
        return lib.math.trig.rotate({position, byDegrees: -rotation});
      });

      var normalizedContainerFloorPosition = lib.object.difference(floorPositions.container, floorPositions.wall);

      x = K.round(normalizedContainerFloorPosition.x);

      wallPrintInElevation = [
        {x: x, y: y},
        {x: x, y: y + height},
        {x: x + width, y: y + height},
        {x: x + width, y: y}
      ];
    }

    return wallPrintInElevation;
  },

  sharesWallprintWith({sourceContainer, container, inclusive}) {
    return lib.math.polygon.polygonsOverlap(Container.getWallprint({container: sourceContainer}), Container.getWallprint({container}), {inclusive});
  },

  containsWallprintOf({container, product}) {
    var w1 = Container.getWallprint({container}), w2 = Product.getWallprint({product}); //TODO parent y offset

    return _.every(w2, point => lib.math.polygon.pointInsidePolygon({point, polygon: w1}));
  },
  //> wallprint

  getElevationTheta({elevation, container}) {
    return lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), container.rotation]});
  },
  //> rotation

  //< misc
  contains({container, product}) {
    var isWallContainer = _.includes(['opencase', 'wall-panel', 'floatingShelves', 'backsplash'], container.type);

    return (isWallContainer || Container.containsFootprintOf({container, product})) && Container.containsWallprintOf({container, product});
  },

  verticallyOverlapsWith({sourceContainer, container, volume}) {
    var a = Container.getYRange({container: sourceContainer}), b = container ? Container.getYRange({container}) : Volume.getYRange({volume});

    return (a.from < b.to && a.to > b.from) || (a.to === b.to && a.from === b.from);
  },

  distanceTo({sourceContainer, ...args}) {
    return Container.calcDistanceTo({fromFootprint: Container.getFootprintInRoom({container: sourceContainer}), ...args});
  },

  calcDistanceTo({fromFootprint, computedWall, wall, container, volume, element, line}) {
    var distances = [];
    var f2, f1 = fromFootprint;

    if (element) {
      if (element.type === 'container') container = element.model;
      else if (element.type === 'volume') volume = element.model;
      else if (element.type === 'wall') wall = element.model;
    }

    if (container) {
      f2 = Container.getFootprintInRoom({container});

      _.forEach(f1, p1 => {
        _.forEach(f2, p2 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toPoint: p2}));
        });
      });
    }
    else if (volume) {
      f2 = Volume.getFootprintInRoom({volume});

      _.forEach(f1, p1 => {
        _.forEach(f2, p2 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toPoint: p2}));
        });
      });
    }
    else if (line) {
      _.forEach(f1, point => {
        distances.push(lib.math.trig.distance({fromPoint: point, toLine: line}));
      });
    }
    else {
      var lines = computedWall ? _.map(computedWall.walls, wall => Wall.getLine({wall}).inRoom) : [Wall.getLine({wall}).inRoom];

      _.forEach(lines, line => {
        _.forEach(f1, p1 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toLine: line}));
        });
      });
    }

    return _.min(distances);
  },

  getChildrenPositionInRoom({container}) {
    var {x, y} = Container.getPositionInRoom({container});
    var dropzoneInset = Container.getDropzoneInset({container});
    var {rotation} = container;
    var position = lib.object.sum({x: x, z: y}, dropzoneInset);

    return lib.trig.rotate({point: {x: position.x, y: position.z}, aroundOrigin: {x, y}, byDegrees: rotation});
  },

  getYPositionRange({container}) {
    var {position, dimensions} = container;

    return {from: position.y, to: position.y + dimensions.height};
  },

  getYRange({container}) {
    return Container.getYPositionRange({container});
  },

  getXRange({container}) {
    var {position, dimensions} = container;

    return {from: position.x, to: position.x + dimensions.width};
  },

  isShowingFrontFor({container, elevation, room, anyCountertopSide = false}) {
    if (container.type === 'countertop') {
      if (!room) room = Container.get('room', {container});

      return anyCountertopSide ? elevation.roomId === room.id : false;
    }
    else {
      return lib.round(lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), container.rotation]}), {toNearest: 5}) === 0;
    }
  },

  //TODO
  // childBoundingRect({container}) {
  //   var size = Container.getDropzoneSize({container});
  //   var origin = this.canvasObject.containerChildren.position.absolute;
  //   var originRight = this.canvasObject.props.origin.x === 'right';
  //   var position = lib.object.sum(origin, {x: originRight ? -size.width : 0, y: -size.height}); //make sure this is top-left

  //   return {...position, ...size};
  // },

  //TODO bring over wrap sizes
  innerWidth({container}) {
    return container.type === 'endPanel' ? container.dimensions.width : container.dimensions.width - Container.getWrapSizes({container}).x;
  },
  //> misc

  //<Countertop stuff
  getEditablePropsStructure({container}) {
    if (container.type === 'countertop') {
      var {isByOthers} = container.customData;

      var structure = {
        dimensions: {type: 'object'},
        customData: {
          isByOthers: {type: 'integer', options: [0, 1]}
        }
      };

      if (!isByOthers) {
        if (container.dimensions.height === 0.5) {
          structure.customData.sinks = {type: 'object', children: {}};

          var sinks = Container.getSinks({container});
          var companyKey = Container.get('companyKey', {container});

          _.forEach(sinks, ({id, product}) => {
            if (!(companyKey === 'vp' && Product.get('container', {product}).type === 'vanity')) {
              var sinkProps = container.customData.sinks[id];
              var includeCutout = _.get(sinkProps, 'includeCutout', 0);

              var sinkStructure = {
                dependencies: {product},
                includeCutout: {type: 'integer', options: [0, 1]},
                defaultValue: {includeCutout: 0, includeDrainfield: 'none', drainfieldIsFlat: false, width: 24, depth: 18}
              };

              if (includeCutout) {
                var footprints = {cutout: Product.getFootprintInRoom({product, sinkProps}), countertop: Container.getFootprintInRoom({container})};

                var drainfieldIsCompatible = ({sideKey}) => {
                  var productPoint = sideKey === 'left' ? footprints.cutout[1] : footprints.cutout[2];
                  var minDistanceToEdge = K.sinkDrainfieldWidth + K.sinkMargins.front;

                  var distanceToEdge = _.min(_.map(footprints.countertop, countertopPoint => {
                    return lib.math.trig.distance({fromPoint: productPoint, toPoint: countertopPoint});
                  }));

                  var sideIsAwayFromEdge = distanceToEdge >= minDistanceToEdge; //footprints

                  var sideIsAwayFromInterrupters = !_.some(Product.get('siblings', {product}), sibling => {
                    var intersectsCountertop = Product.getIntersectsCountertop({product: sibling});
                    var siblingX = sibling.position.x, productX = product.position.x;
                    var isOnRelevantSide = sideKey === 'left' ? (siblingX < productX) : (productX < siblingX);

                    return intersectsCountertop && isOnRelevantSide;
                  });

                  return sideIsAwayFromEdge && sideIsAwayFromInterrupters;
                };

                sinkStructure.includeDrainfield = {type: 'string', options: ['none']};

                var drainfieldOptions = sinkStructure.includeDrainfield.options;
                var compatibleSides = lib.object.fromKeys(['left', 'right'], sideKey => drainfieldIsCompatible({sideKey}));

                if (compatibleSides.left) drainfieldOptions.push('left');
                if (compatibleSides.right) drainfieldOptions.push('right');
                if (compatibleSides.left && compatibleSides.right) drainfieldOptions.push('both');

                sinkStructure.width = {type: 'integer'};
                sinkStructure.height = {type: 'integer'};
              }

              structure.customData.sinks.children[id] = sinkStructure;
            }
          });
        }
      }

      return structure;
    }
  },

  getSinks({container}) {
    return _.map(Container.get('sinkProducts', {container}), product => ({id: product.id, product}));
  },

  getHasKick({container}) {
    return _.includes(['tall', 'base', 'baseWithChase', 'baseWithChase', 'kick'], container.type);
  },

  getSupportsScribe({container}) {
    return _.includes(['tall', 'floatingBase', 'base', 'baseWithChase', 'wall', 'wallUnitLiner', 'cornerCounterTransition', 'vanity', 'endPanel', 'capPanel', 'assembly'], container.type);
  },

  getSupportsTopScribe({container}) {
    return _.includes(['wall', 'wallUnitLiner', 'tall', 'custom', 'generic', 'capPanel', 'cornerCounterTransition'], container.type);
  },

  getSupportsLighting({container}) {
    return _.includes(['wall', 'floatingShelves'], container.type);
  },

  getHasWrap({container}) {
    var {wrap} = container.customData;

    return !_.every(['bottom', 'top', 'right', 'left'], sideKey => !wrap[sideKey]);
  },

  getInsetKickData({container}) {
    return _.get(container, 'customData.insetKick', {});
  },

  getKickInset({container}) {
    var kickInset = Container.getKickHeight({container}) >= 3.625 ? 3.75 : 0.875;

    if (container.customData.flushKick) kickInset = 0;

    return kickInset;
  },

  // getKickData({container, elevation}) {
  //   var mainKickLeft = 0;
  //   var netUnwrappedScribeDistance = 0;
  //   var kickHeight =  container.customData.kickHeight || 0;
  //   var scribesData = Container.getScribesData({container, elevation})

  //   const insetKickData = Container.getInsetKickData({container});
  //   const {wrap} = container.customData;
  //   const wrapSizes = Container.getWrapSizes({container});

  //   if (insetKickData.left) {
  //     var leftIndent = 3.75 - wrap.left;
  //     mainKickLeft += leftIndent;
  //     netUnwrappedScribeDistance -= leftIndent;
  //   }
  //   if (insetKickData.right) {
  //     netUnwrappedScribeDistance -= (3.75 - wrap.right);
  //   }

  //   _.forEach(scribesData, (scribeData, sideKey) => {
  //     if (_.includes(['left', 'right'], sideKey)) {
  //       var kickDistance = scribeData.distance;

  //       if (scribeData.adjacentType === 'container' && scribeData.model.hasKick) kickDistance += 3.75; //kick inset

  //       if (!wrap[sideKey]) {
  //         netUnwrappedScribeDistance += kickDistance;

  //         if (sideKey === 'left') {
  //           mainKickLeft -= kickDistance;
  //         }
  //       }
  //     }
  //   });

  //   return {width: container.dimensions.width + netUnwrappedScribeDistance - wrapSizes.left - wrapSizes.right, height: kickHeight, left: wrapSizes.left + mainKickLeft};
  // },
  // considerConstrainingEditableProps() {
  //   if (!this.editablePropsHaveBeenConstrained) {
  //     this.constrainEditableProps();

  //     this.editablePropsHaveBeenConstrained = true;
  //   }
  // },

  // considerUpdatingRelatedViews() {
  //   if (this.props.viewKey === 'top') {
  //     if (this.constrainEditableProps()) {
  //       this.trigger('editablePropStructuralChange');

  //       _.forEach(this.parent.unmanagedProductInstances, product => product.canvasObject.layout());
  //     }
  //   }
  // },
  // constrainEditableProps() {
  //   var structure = this.editablePropsStructure;
  //   var cachedEditableProps = _.cloneDeep(this.editableProps);

  //   var sync = ({object, structure}) => {
  //     _.forEach(object, (value, key) => {
  //       if (!_.includes(_.keys(structure), key)) delete object[key];
  //     });

  //     _.forEach(structure, ({defaultValue}, key) => {
  //       if (key !== 'dependencies') object[key] = object[key] || defaultValue;
  //     });
  //   };

  //   if (structure.customData.sinks) {
  //     sync({object: this.editableProps.customData.sinks, structure: structure.customData.sinks.children});

  //     _.forEach(structure.customData.sinks.children, (sinkStructure, id) => {
  //       var sinkProps = this.editableProps.customData.sinks[id];

  //       if (sinkProps.includeCutout) {
  //         if (!_.includes(sinkStructure.includeDrainfield.options, sinkProps.includeDrainfield)) {
  //           //HINT if client had drainfield on both sides, try to keep at least one, otherwise turn drainfields off
  //           if (sinkProps.includeDrainfield === 'both') {
  //             sinkProps.includeDrainfield = _.last(sinkStructure.includeDrainfield.options);
  //           }
  //           else {
  //             sinkProps.includeDrainfield = 'none';
  //           }
  //         }

  //         var {product} = sinkStructure.dependencies;

  //         sinkProps.width = lib.number.constrain(sinkProps.width, {
  //           min: 12, max: product.dimensions.width - this.K.sinkMargins.sides * 2
  //         });
  //         sinkProps.depth = lib.number.constrain(sinkProps.depth, {
  //           min: 12, max: product.dimensions.depth - this.K.sinkMargins.front - this.K.sinkMargins.back
  //         });
  //       }
  //       else {
  //         sinkProps.includeDrainfield = 'none';
  //       }
  //     });
  //   }

  //   var constrained = !_.isEqual(this.editableProps, cachedEditableProps);

  //   if (constrained) {
  //     this.onChange();
  //   }

  //   return constrained;
  // },

  // onPropertiesViewChange(...args) {
  //   if (this.editableProps.customData.isByOthers) {
  //     this.editableProps.customData.sinks = {};

  //     //HINT remove previously selected type of countertop when made by st
  //     delete this.editableProps.customData.productId;
  //   }
  //   else if (this.editableProps.customData.productId) {
  //     this.editableProps.dimensions.height = this.K.ids.countertop.productIdHeightMap[this.editableProps.customData.productId];
  //   }

  //   this.constrainEditableProps();

  //   this.canvasObject.set({dimensions: this.editableProps.dimensions});

  //   if (this.props.viewKey === 'top') this.layoutSinkProducts();

  //   this.trigger('editablePropStructuralChange');

  //   super.onPropertiesViewChange(...args);
  // },

  //Countertop stuff/>

  //TODO calculate based on intersections with architecture
  getPanelOversizeHeight({container}) {
    return container.position.y === 0 ? 1 : 0;
  },

  getEndPanelHeights({container, scribesData, companyKey, dependencies}) {
    if (!companyKey || !dependencies) {
      var resources = Container.get(['companyKey', 'dependencies'], {container});
      companyKey = resources.companyKey;
      dependencies = resources.dependencies;
    }

    var adjacentContainersData = Container.getAdjacentContainersData({container});

    if (!scribesData) scribesData = Container.getScribesData({container});
    var topScribeData = scribesData.top;
    var topScribeDistance = topScribeData ? topScribeData.distance - 1 : 0;
    var shouldCaptureTopScribe = !_.get(container, 'customData.wrap.top', 0);
    var endPanelMaterialId = _.get(container, 'details.endPanelMaterial.id');

    return lib.object.fromKeys(['left', 'right'], sideKey => {
      var rangeKey = sideKey === 'left' ? 'from' : 'to';
      var oppositeRangeKey = sideKey === 'left' ? 'to' : 'from';
      var kickHeight = Container.getKickHeight({container});
      var endPanelKickHeight = _.get(container, `customData.insetKick.${sideKey}`) ? 0 : kickHeight;
      var insetKickHeight = kickHeight - endPanelKickHeight;

      var oversizeHeight = Container.getPanelOversizeHeight({container});

      var endPanelCustomBreaksString = _.get(container, `customData.customWrapBreaks.${sideKey}`);
      var heights = {};
      var netHeight;
      var wrapSizes = Container.getWrapSizes({container});

      //HINT bold panels don't get oversized
      if (wrapSizes[sideKey] === 4) oversizeHeight = 0;

      if (endPanelCustomBreaksString) {
        heights.visible = [];

        netHeight = container.dimensions.height + oversizeHeight - insetKickHeight + (shouldCaptureTopScribe ? topScribeDistance : 0);
        var endPanelBreakPoints = [..._.map(_.split(endPanelCustomBreaksString, ','), width => parseFloat(width)), netHeight];

        var currentHeight = 0;

        _.map(endPanelBreakPoints, breakPoint => {
          var height = breakPoint - currentHeight;

          heights.visible.push(height);

          currentHeight = breakPoint;
        });

        heights.visible[heights.visible.length - 1] -= oversizeHeight;
        heights.actual = heights.visible;
        heights.insetKickHeight = insetKickHeight;
      }
      else {
        var sideWrapSize = Container.getWrapSizes({container})[sideKey];
        var material = _.find(_.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials), {id: endPanelMaterialId});
        var isVeneer = _.includes(K[companyKey].materialIds.endPanelVeneer, endPanelMaterialId) || _.get(material, 'materialTypeId') === 1;
        var maxEndPanelHeight = (sideWrapSize >= 0.75 && isVeneer) ? 119 : 95;
        var panelStyle = _.get(container, `customData.wrap.style.${sideKey}`, 'panel');
        var isSculpted = panelStyle !== 'panel';
        var isSTMetalEndPanel = companyKey === 'vp' && wrapSizes[sideKey] === 3 && _.get(container, `customData.wrap.isSTMetalEndPanel`);

        if (isSTMetalEndPanel) {
          maxEndPanelHeight = 47;
        }

        if (companyKey === 'hb') {
          maxEndPanelHeight = 95;

          if (isSculpted) {
            maxEndPanelHeight = 108;
          }
          else if (wrapSizes[sideKey] === 4) {
            maxEndPanelHeight = 48.5;
          }
          else if (wrapSizes[sideKey] === 0.25) {
            maxEndPanelHeight = 47;
          }
          else if (wrapSizes[sideKey] === 0.375) {
            maxEndPanelHeight = 119;
          }
          else if (wrapSizes[sideKey] === 0.5) {
            //TODO different rules for island end panels
            maxEndPanelHeight = 119;
          }
          else if (_.includes([0.75, 1.5], sideWrapSize) && isVeneer) {
            maxEndPanelHeight = 119;
          }
        }

        var subcounterHeight = Container.getSubcounterHeight({container});

        //hint use subcounter height of base units next to container
        if (!Container.getHasSubcounter({container})) {
          var borderingContainer = _.get(adjacentContainersData, `${rangeKey}[0].container`);
          var oppositeBorderingContainer = _.get(adjacentContainersData, `${oppositeRangeKey}[0].container`);
          var adjacentSubcounterHeight = Container.getSubcounterHeight({container: borderingContainer});

          if (!adjacentSubcounterHeight) adjacentSubcounterHeight = Container.getSubcounterHeight({container: oppositeBorderingContainer});

          subcounterHeight = adjacentSubcounterHeight || subcounterHeight || 0;
        }

        netHeight = container.dimensions.height + oversizeHeight - insetKickHeight + (shouldCaptureTopScribe ? topScribeDistance : 0);
        var baseUnitHeight = endPanelKickHeight + 30 + subcounterHeight; //base height + sct height

        heights = {actual: netHeight > maxEndPanelHeight ? [baseUnitHeight + oversizeHeight, netHeight - baseUnitHeight - oversizeHeight] : [netHeight]};

        heights.visible = [...heights.actual];
        heights.visible[0] -= oversizeHeight;
        heights.insetKickHeight = insetKickHeight;
      }

      return heights;
    });
  },

  getKickHeight({container}) {
    return _.get(container, 'customData.kickHeight', 0);
  },

  getHasSubcounter({container = {}}) {
    return _.includes(['base', 'baseWithChase', 'floatingBase', 'baseWithChase', 'vanity'], container.type) && !(container.type === 'vanity' && _.get(container, 'customData.hasExpressedBox'));
  },

  getHasLighting({container}) {
    return _.get(container, 'customData.hasLighting');
  },

  getHasCleat({container}) {
    var noWallSpacing = _.get(container, 'customData.noWallSpacing', 0) || _.get(container, 'customData.wallSpacing') === 'none';

    return _.includes(['tall', 'base', 'baseWithChase'], container.type) && !noWallSpacing && (Container.getWall({container}) !== undefined || Container.getVolume({container}) !== undefined);
  },

  getSupportsGrainDirection({container}) {
    return _.includes(['islandBackPanel', 'islandSeating', 'endPanel'], container.type);
  },

  getInnerWidth({container}) {
    return container.type === 'endPanel' ? container.dimensions.width : container.dimensions.width - Container.getWrapSizes({container}).x;
  },

  getWrapSizes({container}) {
    var wrap = _.defaults(_.get(container, 'customData.wrap'), {
      left: 0, right: 0, bottom: 0, top: 0,
      thickness: 0.5, topThickness: 0.5, bottomThickness: 0.5, overhang: 1 / 8
    });

    var getIsByOthers = (sideKey) => {
      return _.get(container, 'details.endPanelMaterial.id') === 331 || _.get(container.customData, `wrap.${sideKey}ByOthers`, 0) === 1;
    };

    var sizes = {
      left: wrap.left * (getIsByOthers('left') ? _.get(container, 'customData.wrap.leftCustomThickness', wrap.thickness) : wrap.thickness),
      right: wrap.right * (getIsByOthers('right') ? _.get(container, 'customData.wrap.rightCustomThickness', wrap.thickness) : wrap.thickness),
      bottom: wrap.bottom * (getIsByOthers('bottom') ? _.get(container, 'customData.wrap.bottomCustomThickness', wrap.bottomThickness) : wrap.bottomThickness),
      top: wrap.top * (getIsByOthers('top') ? _.get(container, 'customData.wrap.topCustomThickness', wrap.topThickness) : wrap.topThickness)
    };

    if (_.includes(['endPanel', 'capPanel'], container.type)) {
      sizes = {...sizes, x: 0, y: 0};
    }
    else {
      sizes.x = sizes.left + sizes.right;
      sizes.y = sizes.top + sizes.bottom;
    }

    return sizes;
  },

  getWrapPanelWidths({container}) {
    var wrapPanelOverhang = Container.getWrapPanelOverhang({container});
    var wrapBackExtensions = Container.getWrapBackExtensions({container});

    return _.mapValues(wrapPanelOverhang, (overhang, sideKey) => {
      var customWrapPanelWidth = _.get(container, `customData.wrap.depths.${sideKey}`);

      return (customWrapPanelWidth ? customWrapPanelWidth : (container.dimensions.depth + overhang)) + wrapBackExtensions[sideKey];
    });
  },

  getWrapPanelOverhang({container}) {
    var flybyData = Container.getFlybyData({container});

    return _.mapValues(flybyData, flyby => flyby ? -0.75 : _.get(container, 'customData.wrap.overhang'));
  },

  getWrapBackExtensions({container}) {
    var wrapBackExtensions = {};

    _.forEach(['top', 'bottom', 'left', 'right'], sideKey => {
      wrapBackExtensions[sideKey] = _.get(container, `customData.wrap.backExtension.${sideKey}`, 0);
    });

    return wrapBackExtensions;
  },

  getSculptedPanelLocation({container}) {
    var location = '';

    if (_.includes(['islandSeating', 'islandBackPanel'], container.type)) location = 'back';
    else if (_.includes(['tall', 'base', 'baseWithChase', 'floatingBase'], container.type)) location = 'end';

    return location;
  },

  getFlybyData({container}) {
    var flyby = _.get(container, 'customData.flyby', {});

    return {
      left: flyby.end,
      right: flyby.end,
      top: flyby.cap,
      bottom: flyby.cap
    };
  },

  getNetCapPanelWidth({container}) {
    var inlineContainersData = Container.getInlineContainersData({container});
    var scribesData = Container.getScribesData({container});
    var wrapSizes = Container.getWrapSizes({container});
    var capPanelWidth = container.dimensions.width - wrapSizes.x;

    _.forEach(['left', 'right'], sideKey => {
      var rangeKey = sideKey === 'left' ? 'from' : 'to';

      if (!wrapSizes[sideKey] && !_.get(scribesData, `${sideKey}.isTallerScribingToBaseCorner`)) capPanelWidth += _.get(scribesData, `${sideKey}.distance`, 0);

      _.forEach(inlineContainersData[rangeKey], ({c1 = container}) => {
        if (c1.type === 'cornerCounterTransition' && Container.distanceTo({sourceContainer: container, container: c1}) === 0) {
          capPanelWidth += c1.dimensions.width + _.get(Container.getScribesData({container: c1}), `${sideKey}.distance`, 0);
        }
      });
    });

    return capPanelWidth;
  },

  getCapPanelLongestSideMaxDim({container}) {
    var {companyKey, dependencies} = Container.get(['products', 'companyKey', 'dependencies'], {container});
    var wrapSizes = Container.getWrapSizes({container});

    var capPanelMaterialId = _.get(container, 'details.topCapPanelMaterial.id');

    var material = _.find(_.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials), {id: capPanelMaterialId});
    var isVeneer = _.includes(K[companyKey].materialIds.endPanelVeneer, capPanelMaterialId) || _.get(material, 'materialTypeId') === 1;
    var maxCapPanelHeight = (wrapSizes['top'] >= 0.75 && isVeneer) ? 119 : 95;

    if (companyKey === 'hb') {
      maxCapPanelHeight = 95;

      if (wrapSizes['top'] === 0.25) {
        var meldedContainersData = Container.getMeldedContainersData({container});

        var panelDepth = (meldedContainersData.left || meldedContainersData.right) ? _.first(_.values(meldedContainersData)).netMeldedDepth : Container.getWrapPanelWidths({container})['top'];

        maxCapPanelHeight = panelDepth > 6 ? 47 : 95;
      }
      else if (wrapSizes['top'] === 0.375) {
        maxCapPanelHeight = 119;
      }
      else if (wrapSizes['top'] === 0.5) {
        //TODO different rules for island end panels
        maxCapPanelHeight = 119;
      }
      else if (_.includes([0.75, 1.5], wrapSizes['top']) && isVeneer) {
        maxCapPanelHeight = 119;
      }
    }

    return maxCapPanelHeight;
  },

  getCapPanelWidths({container}) {
    var {products, companyKey, dependencies} = Container.get(['products', 'companyKey', 'dependencies'], {container});
    var capPanelWidths = [];
    var capPanelHeightsString = _.get(container, 'customData.wrap.capPanelHeights');

    if (capPanelHeightsString) {
      capPanelWidths = _.map(_.split(capPanelHeightsString, ','), width => parseFloat(width));
    }
    else {
      var wrapSizes = Container.getWrapSizes({container});
      var netCapPanelWidth = Container.getNetCapPanelWidth({container});
      capPanelWidths = [netCapPanelWidth];

      var capPanelMaterialId = _.get(container, 'details.topCapPanelMaterial.id');

      var material = _.find(_.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials), {id: capPanelMaterialId});
      var isVeneer = _.includes(K[companyKey].materialIds.endPanelVeneer, capPanelMaterialId) || _.get(material, 'materialTypeId') === 1;
      var maxCapPanelHeight = Container.getCapPanelLongestSideMaxDim({container});

      if (netCapPanelWidth > maxCapPanelHeight) {
        var scribesData = Container.getScribesData({container});
        var estimatedNumberOfPanels = _.ceil(netCapPanelWidth / maxCapPanelHeight);

        var productBreaks = lib.waterfall(_.filter(products, product => _.isEmpty(_.get(product, 'managedData'))), [
          [_.filter, product => product.position.y === 0],
          [_.map, ({position, dimensions}) => {
            return [{...position}, {...position, x: position.x + dimensions.width}];
          }],
          [_.flatten],
          [_.map, 'x'],
          [_.uniq],
          [_.sortBy]
        ]);

        if (!wrapSizes.left && scribesData.left) {
          productBreaks = _.map(productBreaks, x => x + scribesData.left.distance + wrapSizes.left);
        }

        while (estimatedNumberOfPanels < 5) {
          var splitPoints = [0, netCapPanelWidth];

          for (var i = 1; i < estimatedNumberOfPanels; i++) {
            var idealSplitPoint = lib.number.round((netCapPanelWidth / estimatedNumberOfPanels) * i, {toNearest: 1 / 16});
            var nearestProductBreakPoint = lib.array.closest(productBreaks, idealSplitPoint);

            splitPoints.push(!isNaN(nearestProductBreakPoint) ? nearestProductBreakPoint : idealSplitPoint);
          }

          splitPoints = _.sortBy(splitPoints);

          var potentialWidths = [];

          _.forEach(splitPoints, (point, index) => {
            if (splitPoints[index + 1]) {
              potentialWidths.push(splitPoints[index + 1] - point);
            }
          });

          if (_.every(potentialWidths, width => width <= maxCapPanelHeight)) {
            capPanelWidths = potentialWidths;
            break;
          }
          else {
            estimatedNumberOfPanels++;
          }
        }
      }
    }

    return capPanelWidths;
  },

  getIslandBackPanelMaxPanelSize({container, companyKey}) {
    if (!companyKey) companyKey = Container.get('companyKey', {container});
    var grainDirection = _.get(container.details, 'grainDirection.id', 'vertical');
    var maxWidth = grainDirection === 'horizontal' ? 95 : 47;

    if (companyKey === 'hb') {
      var woodFronts = [191, 194, 1, 158, 166, 176, 103, 104, 105, 239, 1, 3, 4, 5, 6,
        135, 151, 152, 153, 154, 155, 170, 171, 172, 173, 174,
        175, 176, 177, 178, 180, 279, 303, 8, 9, 136, 143, 144, 145, 146, 147, 148, 149, 150, 158,
        160, 161, 162, 163, 164, 165, 166, 167, 168, 179, 191, 192, 193, 194, 240, 249, 250, 251,
        262, 304, 316, 317, 358];
      var backPanelMaterialId = _.get(container.details, 'islandBackPanelMaterial.id', 1);
      var isVeneer = _.includes(woodFronts, backPanelMaterialId);
      maxWidth = isVeneer ? (grainDirection === 'vertical' ? 47 : 119) : 95;

      var panelStyle = _.get(container, 'customData.panelStyle', 'panel');

      if (panelStyle !== 'panel') {
        maxWidth = 119;
      }
    }

    return maxWidth;
  },

  //Melded end panels are when two containers are back to back and their end panel behavior needs to be shared/aware of both containers
  //- melded refers to endpanels that are combined into one end panel managed by one container
  //- split refers to endpanels that are together, but too long to be melded
  //- split panels are managed by their individual containers
  //conditions to consider/test:
  //1. base, base with chase behind seating, base w/ chase next to seating - there are 3 containers being melded here, and the base w/ chase next to seating is to show that container shouldn't be confused with the base with chase container behind seating (3 meld)
  //2. base, seating, base next to seating
  //3. tall units
  //4. base, seating on other side trapped on both sides by base
  getMeldedContainersData({container, roomContainers, siblings}) {
    var c1 = container;
    var meldedContainersData = {};
    var getIsSameHeight = c2 => _.get(c1, 'dimensions.height', 0) === _.get(c2, 'dimensions.height', 1);

    //HINT it doesn't make sense for end panel containers to meld, the 'melding' would be manually specified by the designer
    if (container.type === 'endPanel') return {};

    if (roomContainers) {
      siblings = _.filter(roomContainers, c2 => c2.id !== container.id);
    }
    else if (siblings) {
      roomContainers = [...siblings, container];
    }
    else {
      siblings = Container.get('siblings', {container});
      roomContainers = [...siblings, container];
    }

    //check if a container is trapped on all 4 sides or not in a 4+ container island, in which case it would be disqualified
    var getIsCenterContainer = (container) => {
      var siblingFootprintLines = _.flatMap(_.filter(roomContainers, c2 => c2.id !== container.id && getIsSameHeight(c2) && !_.includes(['islandExtension', 'hbIslandExtension', 'sculptedPanel'], c2.type) && Container.getDistance({sourceContainer: container, container: c2}) < 2), sibling => _.values(_.pick(Container.getFootprintLines({container: sibling}), ['front', 'back'])));
      var {front, back, left, right} = Container.getFootprintLines({container});

      //at least two points are on the other containers front/back line
      return _.every([front, back, left, right], (l1) => {
        return !!_.find(siblingFootprintLines, l2 => {
          var somePointIsOnALine = _.some([[l1.from, l2], [l1.to, l2], [l2.from, l1], [l2.to, l1]], ([point, line]) => lib.math.pointIsOnLine({point, line}) && !_.isEqual(point, line.from) && !_.isEqual(point, line.to));
          var allPointsAreSame = _.every([[l1.from, l2], [l1.to, l2]], ([point, line]) => _.isEqual(point, line.from) || _.isEqual(point, line.to));

          return allPointsAreSame || somePointIsOnALine;
        });
      });
    };

    var isCenterContainer = getIsCenterContainer(c1);

    //make sure it's not the middle container in a 3-meld - i.e. seating + base storage behind seating + base on other side - make sure not base storage behind seating
    if (!isCenterContainer) {
      _.forEach(['left', 'right'], sideKey => {
        var inverseSideKey = sideKey === 'left' ? 'right' : 'left';
        var netMeldedDepth = Container.getWrapPanelWidths({container: c1})[sideKey];

        var candidateSiblingsData = _.chain(siblings)
          .filter(getIsSameHeight)
          .filter(c2 => c2.type !== 'endPanel')
          .map(c2 => {
            var isCenterContainer = getIsCenterContainer(c2);

            return {
              container: c2,
              anglesAreOpposite: lib.trig.anglesAreEqual({a1: Container.getAlpha({container: c1}), a2: Container.getAlpha({container: c2}) + Math.PI, mode: 'radians'}),
              anglesAreSame: lib.trig.anglesAreEqual({a1: Container.getAlpha({container: c1}), a2: Container.getAlpha({container: c2}), mode: 'radians'}) && isCenterContainer,
              isCenterContainer
            };
          })
          .filter(data => data.anglesAreOpposite || data.anglesAreSame) //make sure perpendicular or same angle
          .value();

        var meldedSiblingsData = [];

        //HINT using recursion as an easy approach to chaining when there 3 total containers
        var trackNearbySibling = (container) => {
          //check if container is touching on a corner that's relevant to end panel melding
          var nearbySiblingData = _.find(candidateSiblingsData, data => {
            var footprint1 = Container.getFootprintInRoom({container});
            var footprint2 = Container.getFootprintInRoom({container: data.container});
            var orientation = lib.trig.anglesAreEqual({a1: Container.getAlpha({container}), a2: Container.getAlpha({container: data.container}), mode: 'radians'}) ? 'equal' : 'opposite';

            //top left: 0, bottom left: 1, bottom right: 2, top right: 3 - clockwise footprint starting at top left (back left) of container
            var sideAndOrientationToFootprintIndexMap = {
              left: {
                equal: [0, 1], //my top left should be equal to your bottom left
                opposite: [0, 3]
              },
              right: {
                equal: [3, 2], //my top right should be equal to your bottom right
                opposite: [3, 0]
              }
            };

            var footprintIndices = sideAndOrientationToFootprintIndexMap[sideKey][orientation];

            if (data.anglesAreOpposite && orientation === 'equal') footprintIndices.reverse(); //if the original container is opposite to current one, but current one is equal to next one

            return lib.math.trig.distance({fromPoint: footprint1[footprintIndices[0]], toPoint: footprint2[footprintIndices[1]]}) === 0;
          });

          if (nearbySiblingData) {
            _.pull(candidateSiblingsData, nearbySiblingData);

            meldedSiblingsData.push(nearbySiblingData);

            trackNearbySibling(nearbySiblingData.container);
          }
        };

        trackNearbySibling(c1);

        if (meldedSiblingsData.length) {
          //needs an opposite container to be considered melded
          var oppositeContainer = _.get(_.find(meldedSiblingsData, data => data.anglesAreOpposite && !data.isCenterContainer), 'container');

          if (oppositeContainer && Container.getWrapSizes({container: oppositeContainer})[inverseSideKey] === Container.getWrapSizes({container})[sideKey]) {
            var otherContainersWrapPanelWidth = _.sum(_.map(meldedSiblingsData, data => {
              return !data.isCenterContainer ? Container.getWrapPanelWidths({container: data.container})[inverseSideKey] : data.container.dimensions.depth;
            }));

            netMeldedDepth += otherContainersWrapPanelWidth;

            var breakingDim = Container.getWrapSizes({container})[sideKey] === 4 ? 48.5 : 47;
            var endPanelsAreMelded = netMeldedDepth <= breakingDim;

            meldedContainersData[sideKey] = {
              otherContainers: _.map(meldedSiblingsData, 'container'),
              isPrimaryContainer: oppositeContainer.id > c1.id,
              container: oppositeContainer,
              netMeldedDepth,
              endPanelsAreMelded,
              otherContainersWrapPanelWidth
            };
          }
        }
      });
    }

    return isCenterContainer ? {isCenterContainer: true} : meldedContainersData;
  },

  getAdjacentElementsData({container}) {
    return _.mapValues(Container.getUnfilteredAdjacentElementsData({container}), elementsData => elementsData.length ? [_.minBy(elementsData, 'distance')] : []);
  },

  getUnfilteredAdjacentElementsData({container, siblings, volumes, walls}) {
    var adjacentElementsData = {from: [], to: []};
    if (!siblings || !volumes || !walls) {
      var resources = Container.get(['siblings', 'volumes', 'walls'], {container});

      siblings = resources.siblings;
      volumes = resources.volumes;
      walls = resources.walls;
    }

    _.forEach(Container.getAdjacentContainersData({container, siblings}), (containersData, rangeKey) => {
      _.forEach(containersData, data => {
        adjacentElementsData[rangeKey].push({type: 'container', model: data.container, ...data});
      });
    });

    _.forEach(Container.getAdjacentVolumesData({container, volumes}), (volumesData, rangeKey) => {
      if (volumesData) {
        adjacentElementsData[rangeKey].push({type: 'volume', model: volumesData.volume, ...volumesData});
      }
    });

    _.forEach(Container.getAdjacentWallsData({container, walls}), (data, rangeKey) => {
      if (data /* && !adjacentElementsData[rangeKey].length*/) {
        adjacentElementsData[rangeKey].push({type: 'wall', model: data.wall, ...data});
      }
    });

    return _.mapValues(adjacentElementsData, elementsData => elementsData.length ? _.sortBy(elementsData, 'distance') : []); //TODO pick one based on distance rather than an array
  },

  getAdjacentVolumesData({container, volumes}) {
    if (!volumes) volumes = Container.get('volumes', {container});
    var adjacentVolumesData = {};

    var volumes = _.filter(_.values(volumes), volume => Container.verticallyOverlapsWith({sourceContainer: container, volume}));
    var computedFootprintLines = Container.getComputedFootprintLines({container});

    _.forEach(['from', 'to'], rangeKey => { //create {from, to} object
      // var rangeBKey = new BKey(rangeKey);
      //HINT avoid volumes that are flush with the container on the other side
      var containerLine = lib.trig.extend({line: computedFootprintLines.front[`${rangeKey}Extended`], by: -0.001, rangeKey: rangeKey === 'from' ? 'to' : 'from'}); //extend containers front line to see what it intersects with

      var volumeData = lib.waterfall(volumes, [
        [_.map, volume => ({volume})],
        [_.filter, ({volume}) => {
          return _.some(Volume.getFootprintLines({volume}), volumeLine => {
            return lib.math.linesIntersectInclusive({l1: containerLine, l2: volumeLine});
          });
        }],
        [_.map, data => {
          var intersectionPoint, relevantFootprintLine;
          var distance = 100000000;

          _.forEach(Volume.getFootprintLines({volume: data.volume}), volumeLine => {
            var lineIntersectionPoint = lib.math.intersectionPoint({l1: containerLine, l2: volumeLine}); //see where they run into each other

            if (lineIntersectionPoint) {
              var lineDistance = lib.trig.distance({fromPoint: lineIntersectionPoint, toLine: computedFootprintLines.front.normal}); //how far away are they?

              if (lineDistance < distance) {
                intersectionPoint = lineIntersectionPoint;
                distance = lineDistance;
                relevantFootprintLine = volumeLine;
              }
            }
          });

          return {...data, intersectionPoint, distance, relevantFootprintLine};
        }],
        [_.filter, ({distance}) => distance < K.nearbyWallThreshold], //check that they're nearby
        [_.minBy, 'distance']
      ]);

      if (volumeData) {
        adjacentVolumesData[rangeKey] = volumeData;
      }
    });

    return adjacentVolumesData;
  },

  getAdjacentContainersData({container, siblings}) {
    if (!siblings) siblings = Container.get('siblings', {container});
    var adjacentContainersData = {from: [], to: []};

    _.forEach(Container.getInlineContainersData({container, siblings}), (containersData, rangeKey) => {
      _.forEach(containersData, data => {
        adjacentContainersData[rangeKey].push({orientation: 'inline', ...data});
      });
    });

    _.forEach(Container.getCornerContainersData({container, siblings, shouldFilter: false}), (containersData, rangeKey) => {
      _.forEach(containersData, data => {
        adjacentContainersData[rangeKey].push({orientation: 'corner', ...data});
      });
    });

    return adjacentContainersData;
  },

  //Aggregate adjacent inline containers and walls
  getInlineElementsData({container}) {
    var {siblings, volumes, walls} = Container.get(['siblings', 'volumes', 'walls'], {container});
    var inlineElementsData = {from: [], to: []};

    _.forEach(Container.getInlineContainersData({container, siblings}), (containersData, rangeKey) => {
      _.forEach(containersData, data => {
        inlineElementsData[rangeKey].push({type: 'container', model: data.container, ...data});
      });
    });

    _.forEach(Container.getAdjacentVolumesData({container, volumes}), (volumesData, rangeKey) => {
      if (volumesData) {
        inlineElementsData[rangeKey].push({type: 'volume', model: volumesData.volume, ...volumesData});
      }
    });

    _.forEach(Container.getAdjacentWallsData({container, walls}), (data, rangeKey) => {
      if (data) {
        inlineElementsData[rangeKey].push({type: 'wall', model: data.wall, ...data});
      }
    });

    return inlineElementsData;
  },

  //containers that are in plane with the current container and are close
  getInlineContainersData({container, siblings}) {
    var containers = siblings;
    if (!siblings) containers = Container.get('siblings', {container});
    var {rotation} = container;
    var computedFootprintLines = Container.getComputedFootprintLines({container});
    var footprintInRoom = Container.getFootprintInRoom({container});

    var inlineContainersData = lib.object.fromKeys(['from', 'to'], rangeKey => {
      var containerLine = computedFootprintLines.front[`${rangeKey}Extended`];
      var containerCornerPosition = footprintInRoom[rangeKey === 'from' ? 1 : 2];
      var oppositeSideKey = rangeKey === 'from' ? 'right' : 'left';

      var inlineContainerData = lib.waterfall(containers, [
        [_.filter, {rotation}], //filter to containers with same angle
        [_.reject, ({type}) => _.includes(['cornerCounterTransition', 'countertop', 'capPanel'], type)], //filter out irrelevant containers
        [_.filter, c2 => Container.verticallyOverlapsWith({sourceContainer: container, container: c2})], //check that their y ranges overlap
        [_.map, c2 => ({container: c2, line: Container.getComputedFootprintLines({container: c2})[oppositeSideKey].extended})], //cache dependency
        [_.filter, data => lib.math.linesIntersect({l1: containerLine, l2: data.line})], //check if front line runs into other container's extended side line (see previous line)
        [_.map, data => { //calculate more dependencies
          var intersectionPoint = lib.math.intersectionPoint({l1: containerLine, l2: data.line});
          var distance = lib.trig.distance({fromPoint: containerCornerPosition, toLine: Container.getComputedFootprintLines({container: data.container})[oppositeSideKey].normal}); //HINT distance is relative to actual container line

          return {...data, intersectionPoint, distance};
        }],
        [_.filter, ({distance}) => distance < 1] //only consider when super close by
      ]);

      return inlineContainerData;
    });

    return inlineContainersData;
  },

  getCornerContainersData({container, siblings, shouldFilter = true}) {
    if (!siblings) siblings = Container.get('siblings', {container});
    var c1Lines = Container.getComputedFootprintLines({container});
    var inlineContainersData = Container.getInlineContainersData({container, siblings});

    //Don't consider containers that aren't really relevant
    var candidateContainers = _.filter(siblings, c2 => {
      return !_.includes(['baseFreestandingAppliance', 'countertop', 'capPanel'], c2.type) && Container.verticallyOverlapsWith({sourceContainer: container, container: c2});
    });

    //Calculate and cache lines data
    var candidateContainersData = _.map(candidateContainers, c2 => {
      return {sibling: c2, lines: {a: c1Lines, b: Container.getComputedFootprintLines({container: c2})}};
    });

    //Create a {from, to} object
    var cornerContainersData = lib.object.fromKeys(['from', 'to'], rangeKey => {
      var data = [];

      //HINT if there's something inline, ignore possible corner containers
      if (!inlineContainersData[rangeKey].length || !shouldFilter) {
        //WARNING this logic is tricky/picky and has a lot of implicit rules/assumptions
        _.forEach([
          {condition: 'childPocket', l1Path: 'a.front.normal', l2Path: `b.front.${rangeKey === 'from' ? 'to' : 'from'}Extended`}, //current container is tucked into corner
          {condition: 'parentPocket', l1Path: `a.front.${rangeKey}Extended`, l2Path: 'b.front.normal'}, //other container is tucked into corner behind this container
          {condition: 'back', l1Path: `a.front.${rangeKey}Extended`, l2Path: 'b.back.normal'}, //container is perpendicular to back of another container
          {condition: 'open', l1Path: `a.front.${rangeKey}Extended`, l2Path: 'b.front.extended'} //double corner scribe situation, where neither container is tucked behind another - the corner is just empty/open
        ], ({condition, l1Path, l2Path}) => {
          _.forEach(candidateContainersData, ({sibling, lines}) => {
            if (!(shouldFilter && !_.isEqual(data, []))) {
              var l1 = _.get(lines, l1Path);
              var l2 = _.get(lines, l2Path);

              //WARNING tricky island convex corner situation - allow flush intersection
              if (condition === 'back' && sibling.type !== 'opencase') l2 = lib.trig.extend({line: l2, by: 0.125});

              var linesIntersect = lib.math.linesIntersect({l1, l2});

              //HINT sense condition when containers intersect at a T (linesIntersect returns false in these cases)
              if (condition === 'parentPocket' || condition === 'childPocket') {
                linesIntersect = linesIntersect || (
                  lib.trig.isOnLine({point: lib.math.intersectionPoint({l1, l2}), line: l1}) &&
                  lib.trig.isOnLine({point: lib.math.intersectionPoint({l1, l2}), line: l2})
                );
              }

              //WARNING tricky - don't want to allow the larger open threshold if it's intersecting with normal (effectively large parent pocket)
              //HINT also want to correct for times where the lines are extending through the container (this shouldn't count)
              if (condition === 'open' && (
                lib.math.linesIntersect({l1: lines.a.front.extended, l2: lines.b.front.normal}) ||
                lib.math.linesIntersect({l1: lines.b.front.extended, l2: lines.a.front.normal}) ||
                lib.trig.isOnLine({point: lib.math.intersectionPoint({l1, l2}), line: lines.b.front.normal}) ||
                lib.trig.isOnLine({point: lib.math.intersectionPoint({l1, l2}), line: lines.a.front.normal})
              )) linesIntersect = false;

              if (sibling.type === 'opencase' && condition === 'open') {
                linesIntersect = lib.math.linesIntersect({l1: lines.a.front.extended, l2: lines.b.front.normal});
              }

              if (linesIntersect) {
                var maxDistance = _.includes(['open'], condition) ? 30 : 8;
                var minDistance = 0.125;
                var intersectionPoint = lib.math.intersectionPoint({l1, l2});

                //HINT used to filter out containers that are too close/far away
                var distances = _.map(lines, (containerLines, key) => {
                  var clSideKey = (key === 'b' && condition === 'back') ? 'back' : 'front';

                  return K.round(lib.trig.distance({fromPoint: intersectionPoint, toLine: containerLines[clSideKey].normal}));
                });

                var filterDistance = _.max(distances);
                var distance = distances[0];

                if (filterDistance >= minDistance && filterDistance <= maxDistance) {
                  data.push({container: sibling, lines, condition, distance, distances, filterDistance, intersectionPoint});
                }
              }
            }
          });
        });
      }

      return data;
    });

    return cornerContainersData;
  },

  getAdjacentWallsData({container, walls}) {
    var adjacentWallsData = {};
    if (!walls) walls = Container.get('walls', {container});
    var computedFootprintLines = Container.getComputedFootprintLines({container});

    _.forEach(['from', 'to'], rangeKey => { //create {from, to} object
      // var rangeBKey = new BKey(rangeKey);
      var containerLine = computedFootprintLines.front[`${rangeKey}Extended`]; //extend containers front line to see what it intersects with

      //HINT use midpoint for non-extended point
      //otherwise if container is flush against wall on left
      //it prevents a scribe on the right
      containerLine = {
        ...containerLine,
        [rangeKey === 'from' ? 'to' : 'from']: lib.math.midpoint({line: computedFootprintLines.front.normal})
      };

      var wallData = lib.waterfall(walls, [
        [_.map, wall => ({wall})],
        [_.filter, ({wall}) => {
          return lib.math.linesIntersectInclusive({l1: containerLine, l2: Wall.getLine({wall})}) && !wall.isPseudoWall;

          //HINT also ok
          // return lib.math.linesIntersect({l1: containerLine, l2: lib.trig.extend({line: wall.line, by: 0.001})}) && !wall.isPseudoWall;
        }], //filter out dashed pseudo walls
        [_.map, data => {
          var intersectionPoint = lib.math.intersectionPoint({l1: containerLine, l2: Wall.getLine({wall: data.wall})}); //see where they run into each other
          var distance = lib.trig.distance({fromPoint: intersectionPoint, toLine: computedFootprintLines.front.normal}); //how far away are they?

          return {...data, intersectionPoint, distance};
        }],
        [_.filter, ({distance}) => distance < K.nearbyWallThreshold], //check that they're nearby
        [_.minBy, 'distance']
      ]);

      if (wallData) {
        adjacentWallsData[rangeKey] = wallData;
      }
    });

    return adjacentWallsData;
  },

  //TODO handle volumes
  getArchPoints({container, elevation}) {
    var archPoints = [];
    var wall = Container.getWall({container});
    var soffit = Container.getSoffit({container});
    var soffitVolume = Container.getSoffitVolume({container});

    if (wall) {
      const isSection = elevation ? Elevation.getIsSection({elevation}) : Container.getIsSection({container, elevation});

      var archPoints = isSection ? [...wall.outline.points] : (elevation ? Elevation.getOutline({elevation}) : [...wall.outline.points]);
      var offset = elevation ? Elevation.getMinWallX({elevation}) : _.min(_.map(archPoints, point => point.x));

      archPoints = _.map(archPoints, point => {
        return {x: point.x - offset, y: point.y};
      });

      if (soffitVolume) {
        soffitOverlapsRelevantContainerDepth = container.dimensions.depth - soffitVolume.dimensions.depth <= 2;

        //< merge soffts with wall
        if (soffitOverlapsRelevantContainerDepth) {
          var soffitPoints = [];

          var insertIndex = 2; //TODO

          var soffitPoints = _.map(Volume.getWallprintInElevation({volume: soffitVolume, elevation}), point => ({...point, y: -point.y}));
          //HINT order correctly for insertion into arch points
          soffitPoints = [
            soffitPoints[1],
            soffitPoints[0],
            soffitPoints[3],
            soffitPoints[2]
          ];
          archPoints = _.orderBy(archPoints, ['x', 'y']);

          var tempY0Point = archPoints[0];
          var tempLargeYPoint = archPoints[1];

          archPoints[0] = tempLargeYPoint;
          archPoints[1] = tempY0Point;

          _.forEach(_.reverse(soffitPoints), soffitPoint => archPoints.splice(insertIndex, 0, soffitPoint));
          //> merge soffts with wall
        }
      }
      else if (soffit) {
        var soffitOverlapsRelevantContainerDepth = container.dimensions.depth - _.get(soffit, 'customData.dimensions.depth', 0) <= 2;

        //< merge soffts with wall
        if (soffitOverlapsRelevantContainerDepth) {
          var soffitPoints = [];

          var {x, y} = soffit.position;
          var {width, height} = soffit.customData.dimensions;
          var insertIndex = 2; //TODO

          if (elevation) {
            x += Wall.getXOffsetInElevation({wall, elevation}) - Elevation.getMinWallX({elevation});
          }

          var soffitPoints = [
            {x: x, y: -(y + height)},
            {x: x, y: -y},
            {x: x + width, y: -y},
            {x: x + width, y: -(y + height)}
          ];

          archPoints = _.orderBy(archPoints, ['x', 'y']);

          var tempY0Point = archPoints[0];
          var tempLargeYPoint = archPoints[1];

          archPoints[0] = tempLargeYPoint;
          archPoints[1] = tempY0Point;

          _.forEach(_.reverse(soffitPoints), soffitPoint => archPoints.splice(insertIndex, 0, soffitPoint));
          //> merge soffts with wall
        }
      }

      archPoints = _.filter(archPoints, p1 => !_.some(archPoints, p2 => p1 !== p2 && _.isEqual(p1, p2)));
    }

    return archPoints;
  },

  getOwnedCompatibleDetails({container}) {
    var {companyKey, isEmployee, dependencies, products, project} = Container.get(['companyKey', 'isEmployee', 'dependencies', 'products', 'project'], {container});
    var {customData} = container;
    var wrap = customData.wrap || {};
    var containerType = container.type;
    var islandBackPanelHasDetail = _.includes(['islandSeating', 'islandBackPanel', 'islandBackPanelWithChase'], containerType);
    var hasKick = Container.getHasKick({container}) && Container.getKickHeight({container}) !== 0;
    var details = [];

    var materialClassIds = {
      wrap0_25: companyKey === 'hb' ? 8 : 88,
      wrap0_375: companyKey === 'hb' ? 20 : 56,
      wrap0_5: companyKey === 'hb' ? 109 : 87,
      wrap0_75: companyKey === 'hb' ? 128 : 84,
      wrap1_5: companyKey === 'hb' ? 133 : 140,
      wrap1_625: companyKey === 'hb' ? 92 : 89,
      wrap2_125: companyKey === 'hb' ? 92 : 89,
      wrap2_25: companyKey === 'hb' ? 92 : 89,
      wrap3: companyKey === 'hb' ? 92 : 89,
      wrap4: companyKey === 'hb' ? 55 : 89,
      islandBackPanel: companyKey === 'hb' ? 2 : 91,
      seatingSupportRod: companyKey === 'hb' ? 8 : 90,
      islandExtensionFrameMaterial: companyKey === 'hb' ? 8 : 68, //ST Powdercoated Parts materialClassId
      ctop0_5: companyKey === 'hb' ? 31 : 85,
      ctop1_5: companyKey === 'hb' ? 24 : 86,
      kick: companyKey === 'hb' ? (_.get(container, 'customData.flushKick') ? 2 : 123) : (_.get(container, 'customData.flushKick') ? 79 : 83),
      scribe: companyKey === 'hb' ? 124 : 82, // TODO we need to figure it out later
      box: companyKey === 'hb' ? 5 : 84,
      subcounter: companyKey === 'hb' ? 146 : 147,
      ocSolidCorner: 86,
      backstop: 78
    };

    //HINT st 3" metal end panel
    if (companyKey === 'vp' && wrap.thickness === 3 && _.get(container, 'customData.wrap.isSTMetalEndPanel')) {
      var product = _.find(dependencies.productTypes.byId, {id: 1702});

      materialClassIds.wrap3 = _.get(product, 'materialAssociations.countertop.materialClassId', 68);
    }

    var wrapThicknessToMaterialClassId = {
      0.25: materialClassIds.wrap0_25,
      0.375: materialClassIds.wrap0_375,
      0.5: materialClassIds.wrap0_5,
      0.75: materialClassIds.wrap0_75,
      1.5: materialClassIds.wrap1_5,
      1.625: materialClassIds.wrap1_625,
      2.125: materialClassIds.wrap2_125,
      2.25: materialClassIds.wrap2_25,
      3: materialClassIds.wrap3,
      4: materialClassIds.wrap4
    };

    var countertopMaterialClassId = {0.5: materialClassIds.ctop0_5, 1.5: materialClassIds.ctop1_5}[container.dimensions.height];

    if (companyKey === 'hb') {
      var product = _.find(dependencies.productTypes.byId, {id: customData.productId || K[companyKey].ids.countertop.defaultProductId});

      countertopMaterialClassId = _.get(product, 'materialAssociations.countertop.materialClassId', 46);
    }

    var scribesData = Container.getScribesData({container});

    var allDetails = [
      {key: 'endPanelMaterial', hasDetail: wrap.left || wrap.right, materialClassId: wrapThicknessToMaterialClassId[wrap.thickness || 0.5]},
      {key: 'topCapPanelMaterial', hasDetail: wrap.top, materialClassId: wrapThicknessToMaterialClassId[wrap.topThickness || 0.5]},
      {key: 'bottomCapPanelMaterial', hasDetail: wrap.bottom, materialClassId: wrapThicknessToMaterialClassId[wrap.bottomThickness || 0.5]},
      {key: 'islandBackPanelMaterial', hasDetail: islandBackPanelHasDetail, materialClassId: materialClassIds.islandBackPanel},
      {key: 'seatingSupportRodMaterial', hasDetail: _.includes(['islandSeating', 'countertopSupport'], containerType), materialClassId: materialClassIds.seatingSupportRod},
      {key: 'frameMaterial', hasDetail: _.includes(['islandExtension', 'daylightIsland'], containerType), materialClassId: materialClassIds.islandExtensionFrameMaterial},
      {key: 'countertopMaterial', hasDetail: containerType === 'countertop' && !container.customData.isByOthers, materialClassId: countertopMaterialClassId},
      {key: 'kickMaterial', hasDetail: hasKick, materialClassId: materialClassIds.kick},
      {key: 'scribeMaterial', hasDetail: _.size(scribesData), materialClassId: materialClassIds.scribe},
      {key: 'boxMaterial', hasDetail: containerType === 'vanity', materialClassId: materialClassIds.box},
      {key: 'interiorBoxMaterial', hasDetail: containerType === 'vanity' && container.customData.hasExpressedBox, materialClassId: materialClassIds.box},
      {key: 'drawerMaterial', hasDetail: containerType === 'vanity' && container.customData.hasWoodDrawers, materialClassId: materialClassIds.box},
      {key: 'subcounterMaterial', hasDetail: Container.getHasSubcounter({container}) && !(companyKey === 'st' && _.includes([0.25, 0.5], Container.getSubcounterHeight({container}))), materialClassId: materialClassIds.subcounter},
      {key: 'leftScribeMaterial', hasDetail: _.size(scribesData) && scribesData.left, materialClassId: materialClassIds.scribe},
      {key: 'rightScribeMaterial', hasDetail: _.size(scribesData) && scribesData.right, materialClassId: materialClassIds.scribe},
      {key: 'topScribeMaterial', hasDetail: _.size(scribesData) && scribesData.top, materialClassId: materialClassIds.scribe},
      {key: 'ocSolidCornerMaterial', hasDetail: containerType === 'ocSolidCorner', materialClassId: materialClassIds.ocSolidCorner},
      {key: 'backstopMaterial', hasDetail: companyKey === 'hb' && containerType === 'backsplash', materialClassId: materialClassIds.backstop},
    ];

    var materialOptionInfoFor = ({materialClassId, materialKey}) => {
      let optionGroups = [];
      var options = _.filter(_.map(lib.materials.materialsFor({materialClassId, materialClasses: _.values(dependencies.materialClasses.byId)
      }), material => {
        return _.pick(material, ['id', 'title', 'isEmployeeOnly', 'employeeOnlyMaterialClassIds', 'materialTypeId', 'companyKey', 'isDiscontinued']);
      }), material => companyKey === 'hb' ? material.companyKey === companyKey : true);

      if (!isEmployee) {
        var options = _.filter(options, ({isEmployeeOnly, employeeOnlyMaterialClassIds}) => !isEmployeeOnly && !_.includes(employeeOnlyMaterialClassIds, `.${materialClassId}.`));
      }

      var isPostDiscontinuation = project.versionId > 23503 && !_.includes([7653, 9184], project.id) && !container.customData.allowDiscontinuedMaterials;

      options = _.map(options, option => {
        var isCurrentlySelected = _.get(container, `details.${materialKey}.id`) === option.id;

        var shouldHide = isPostDiscontinuation && !isCurrentlySelected && option.isDiscontinued;
        var invalid = option.isDiscontinued;

        return {...option, shouldHide, invalid};
      });

      //HINT the europly materials are only supposed to be applied to these products
      //HOWEVER there are old projects where we still need to allow them to be chosen
      var semiDiscontinuedEuroplyMaterialIds = [252, 256, 258, 259, 376, 377, 378, 379, 380];
      var compatibleEuroplyProductIds = [162, 163, 164, 165, 166, 167, 1379, 406, 1451, 1523, 470, 435, 451, 1090, 1058, 1070, 1370, 680, 978, 1563, 678, 1717];

      if (_.some(products, product => !_.includes(compatibleEuroplyProductIds, product.productId))) {
        options = _.map(options, option => {
          if (_.includes(semiDiscontinuedEuroplyMaterialIds, option.id)) {
            option.invalid = true;
          }

          return option;
        });
      }

      //HINT now grouping by material type, so - color matched edge isn't needed, but production still needs it
      options = _.map(options, option => {
        return {
          ...option,
          title: _.replace(_.replace(option.title, ' - color matched edge', ''), ' - Color Matched Edge', ''),
          thumbnail: `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/material_swatches/${option.id}.jpg`
        };
      });

      if (options.length > 0) {
        if (materialKey === 'countertopMaterial') {
          optionGroups = [{
            title: 'Countertop',
            options: options
          }];
        }
        else {
          optionGroups = _.map(_.groupBy(options, 'materialTypeId'), (options, materialTypeId) => {
            return {title: _.get(dependencies.materialTypes.byId, `${materialTypeId}.title`, materialTypeId), options};
          });
        }
      }

      return {optionGroups, options};
    };

    _.forEach(allDetails, ({key, hasDetail, materialClassId}) => {
      if (hasDetail) {
        let {options, optionGroups} = materialOptionInfoFor({materialClassId, materialKey: key});
        //HINT need to have a flattened list of options for detail updates
        details.push({
          key: `${key}`, type: 'material',
          title: _.startCase(key),
          optionGroups,
          options
        });
      }
    });

    if (Container.getSupportsGrainDirection({container}) && companyKey === 'hb') {
      var grainDirectionOptions = [
        {id: 'vertical', title: 'Vertical'},
        {id: 'horizontal', title: 'Horizontal'},
        {id: 'hidden', title: 'Hidden'}
      ]

      // if (container.dimensions.width > 47 && container.dimensions.height <= 47) {
      //   grainDirectionOptions = _.filter(grainDirectionOptions, ({id}) => id !== 'vertical');
      // }

      // if (container.dimensions.height > 47 && container.dimensions.width <= 47) {
      //   grainDirectionOptions = _.filter(grainDirectionOptions, ({id}) => id !== 'horizontal');
      // }

      details.push({
        key: 'grainDirection',
        type: 'grainDirection',
        title: 'Grain Direction',
        options: grainDirectionOptions,
        userLenses: ['design', 'engineering']
      });

      details.push({
        key: 'grainContinuity',
        type: 'grainContinuity',
        title: 'Continuous Grain Direction',
        options: [
          {id: 'none', title: 'None'},
          {id: 'from', title: 'From'},
          {id: 'to', title: 'To'},
          {id: 'both', title: 'Both'}
        ],
        userLenses: ['design', 'engineering']
      });
    }

    return details;
  },

  getIsManagedCountertop({container}) {
    return container.type === 'countertop' && !_.get(container, 'customData.inManualMode') && _.get(container, 'customData.managingContainerId');
  },

  getManagedCountertopContainers({container, room}) {
    if (!room) room = Container.get('room', {container});
    const countertops = _.filter(Room.get('containers', {room}), {type: 'countertop'});

    return _.filter(countertops, countertop => _.get(countertop, 'customData.managingContainerId') === container.id);
  },

  //countertops overlapping with container in room
  getCountertops({container, room, siblings}) {
    if (!Container.getSupportsCountertop({container})) {
      return [];
    }
    else {
      if (!room) room = Container.get('room', {container});
      if (!siblings) siblings = Room.get('containers', {room});

      const countertops = _.filter(siblings, {type: 'countertop'});

      return _.filter(countertops, countertop => {
        return (!container.position || _.isEqual(container.position, {})) ? _.includes(container.customData.containerIds, container.id) : Container.sharesFootprintWith({sourceContainer: container, container: countertop});
      });
    }
  },

  getCountertopDataFor({container: sourceContainer, containerGroup, room}) {
    if (containerGroup && containerGroup.containers) {
      var relevantContainers = _.filter(containerGroup.containers, c1 => _.includes(K.supportedCountertopContainerTypes, c1.type));
      var showWallsAndArchElements = global.visibilityLayers.wallsAndArchElements;
      var countertopGroupsData = [];
      var footprints = [];

      var oppositeSideKeysOf = ({sideKey}) => {
        return _.includes(['right', 'left'], sideKey) ? ['front', 'back'] : ['right', 'left'];
      };

      var unionMany = (footprints) => {
        var polygon = [];

        _.forEach(footprints, footprint => {
          polygon = polygon.length > 0 ? lib.polygon.union({p1: footprint, p2: polygon}) : footprint;
        });

        return polygon;
      };

      var footprintFrom = ({sideKey, footprintLines}) => {
        var footprintInRoom = [];

        //WARNING order matters, specifically for footprintLinesFor
        if (_.includes(['front', 'back'], sideKey)) {
          footprintInRoom = [footprintLines.back.from, footprintLines.front.to, footprintLines.front.from, footprintLines.back.to];
        }
        else {
          footprintInRoom = [footprintLines.right.to, footprintLines.right.from, footprintLines.left.to, footprintLines.left.from];
        }

        return footprintInRoom;
      };

      //WARNING order matters
      var footprintLinesFor = (footprintInRoom, rotation) => {
        return {
          left: {from: footprintInRoom[3], to: footprintInRoom[2]},
          front: {from: footprintInRoom[2], to: footprintInRoom[1]},
          right: {from: footprintInRoom[1], to: footprintInRoom[0]},
          back: {from: footprintInRoom[0], to: footprintInRoom[3]}
        };
      };

      var extendFootprintLinesToPoint = ({point, footprintLines, sideKey, footprintInRoom}) => {
        if (!isNaN(point.x) && !isNaN(point.y) && !lib.polygon.pointInsidePolygon({point, polygon: footprintInRoom})) {
          var fromDistance = lib.trig.distance({fromPoint: footprintLines[sideKey].from, toPoint: point});
          var toDistance = lib.trig.distance({fromPoint: footprintLines[sideKey].to, toPoint: point});

          if (fromDistance < toDistance) footprintLines[sideKey].from = point;
          if (toDistance < fromDistance) footprintLines[sideKey].to = point;
        }

        return footprintLines;
      };

      var countertopGroupsDataFor = ({relevantContainers}) => {
        relevantContainers = [...relevantContainers];

        var countertopGroupsData = [];

        //< merge nearby footprints
        _.forEach(relevantContainers, container => {
          var container = relevantContainers[0];

          if (container) {
            var mergedPolygon, containersToMerge = [];
            var footprintLines = Container.getFootprintLines({container});
            var footprintInRoom = Container.getFootprintInRoom({container});

            var otherContainers = _.reject(relevantContainers, c1 => c1.id === container.id);
            var containerLinesMap = _.map(otherContainers, c1 => {
              return {footprintLines: _.omit(Container.getFootprintLines({container: c1}), 'front'), container: c1};
            });

            //identify containers that share one vertex
            //and have the other vertex on a line that shares that vertex
            _.forEach(Container.getFootprintLines({container}), line => {
              containersToMerge.push(..._.filter(containerLinesMap, containerData => {
                var yRanges = _.map([container, containerData.container], container => Container.getYRange({container}));
                var ysEqual = yRanges[0].to === yRanges[1].to;

                var hasLineThatOverlaps = _.some(containerData.footprintLines, c2line => {
                  var fromOverlapsAndToIsOnLine = _.some(c2line, c2vertex => _.isEqual(line.from, c2vertex)) && lib.trig.isOnLine({line: c2line, point: line.to});
                  var toOverlapsAndFromIsOnLine = _.some(c2line, c2vertex => _.isEqual(line.to, c2vertex)) && lib.trig.isOnLine({line: c2line, point: line.from});

                  return fromOverlapsAndToIsOnLine || toOverlapsAndFromIsOnLine;
                });

                return ysEqual && hasLineThatOverlaps;
              }));
            });

            if (containersToMerge.length > 0) {
              mergedPolygon = unionMany([Container.getFootprintInRoom({container}), ..._.map(containersToMerge, containerData => Container.getFootprintInRoom({container: containerData.container}))]);

              //if the combined footprint is a rectangle
              if (mergedPolygon.length === 4) {
                //identify potential primary container that
                //has corner condition with a container not in the group
                var containerWithCornerData = _.find([container, ..._.map(containersToMerge, 'container')], c1 => {
                  var flatCornerContainersData = _.flatten(_.values(Container.getCornerContainersData({container: c1})));

                  return _.some(flatCornerContainersData, cornerContainerData => {
                    return !_.includes([container.id, ..._.map(containersToMerge, 'container.id')], cornerContainerData.container.id);
                  });
                });

                footprintInRoom = mergedPolygon;
                footprintLines = footprintLinesFor(mergedPolygon);

                _.remove(relevantContainers, c1 => _.includes(_.map(containersToMerge, 'container.id'), c1.id));
              }
            }

            var primaryContainer = containerWithCornerData || container;

            var countertopGroupData = {
              footprintInRoom,
              footprintLines,
              rotation: primaryContainer.rotation,
              primaryContainer,
              containers: [container, ..._.map(containersToMerge, 'container')],
            };

            //remove containers in group from potential containers
            _.remove(relevantContainers, c1 => c1.id === container.id);

            countertopGroupsData.push(countertopGroupData);
          }
        });
        //>

        return countertopGroupsData;
      };

      if (relevantContainers.length > 2) {
        var normalCountertopGroupsData = countertopGroupsDataFor({relevantContainers});
        var reversedCountertopGroupsData = countertopGroupsDataFor({relevantContainers: _.reverse(relevantContainers)});

        countertopGroupsData = normalCountertopGroupsData.length <= reversedCountertopGroupsData.length ? normalCountertopGroupsData : reversedCountertopGroupsData;
      }
      else {
        countertopGroupsData = countertopGroupsDataFor({relevantContainers});
      }

      if (!room) room = Container.get('room', {container: sourceContainer});
      var {walls, volumes} = showWallsAndArchElements ? Room.get(['walls', 'volumes'], {room}) : Room.get(['volumes'], {room});

      _.forEach(countertopGroupsData, ({footprintInRoom, containers, footprintLines, rotation, primaryContainer}) => {
        var container = primaryContainer;
        var inlineContainersData = Container.getInlineContainersData({container});
        var cornerContainersData = Container.getCornerContainersData({container});
        var potentialExtensionLinesData = [..._.map(_.filter(walls, wall => !wall.isPseudoWall), wall => {
          return {line: Wall.getLine({wall}), alpha: Wall.getAlpha({wall})};
        }), ..._.flatMap(_.filter(volumes, volume => Container.verticallyOverlapsWith({sourceContainer: container, volume})), volume => {
          return _.map(Volume.getFootprintLines({volume}), footprintLine => {
            return {line: footprintLine, alpha: lib.math.trig.alpha({p1: footprintLine.from, p2: footprintLine.to})};
          });
        })];

        var overhang = {x: 0, y: 0};

        if (container.type === 'daylightIsland') {
          overhang = {x: 0.125, y: 0.125};
        }
        else {
          var updatedSideKeys = [];

          _.forEach(cornerContainersData, adjacenciesData => {
            _.forEach(adjacenciesData, cornerContainerData => {
              var cornerContainer = cornerContainerData.container;

              //filter out containers already being represented in the group
              if (cornerContainerData.condition && !_.includes(_.map(containers, 'id'), cornerContainer.id)) {
                var containerIsPrimary = container.id < cornerContainer.id;
                var extendToFrontOfCornerContainer;

                if (cornerContainerData.condition === 'open') {
                  extendToFrontOfCornerContainer = containerIsPrimary;
                }
                else if (cornerContainerData.condition === 'parentPocket') {
                  extendToFrontOfCornerContainer = true;
                }
                else if (cornerContainerData.condition === 'childPocket') {
                  extendToFrontOfCornerContainer = false;
                }

                var containerGroupFrontAlpha = lib.trig.alpha({p1: footprintLines.front.from, p2: footprintLines.front.to});
                var perpendicularSideKeys = lib.trig.anglesAreParallel({a1: Container.getAlpha({container}), a2: containerGroupFrontAlpha}) ? ['front', 'back'] : ['left', 'right'];

                //extend the perpendicular lines to intersect with the corner containers front or back line
                // if extending to back line, check if there is a nearby wall to the back line
                _.forEach(perpendicularSideKeys, perpendicularSideKey => {
                  var line = footprintLines[perpendicularSideKey];

                  var cornerContainerLine = Container.getFootprintLines({container: cornerContainer}).front;

                  if (!extendToFrontOfCornerContainer) {
                    var nearbyExtensionLineData = _.minBy(_.filter(potentialExtensionLinesData, extensionLineData => {
                      var isParallel = lib.trig.anglesAreParallel({a1: extensionLineData.alpha, a2: Container.getAlpha({container: cornerContainer})});
                      var isNearby = lib.trig.distance({fromPoint: lib.math.midpoint({line: Container.getFootprintLines({container: cornerContainer}).back}), toLine: extensionLineData.line}) < K.nearbyElementForCountertopThreshold;

                      return isParallel && isNearby;
                    }), extensionLineData => lib.trig.distance({fromPoint: lib.math.midpoint({line: Container.getFootprintLines({container: cornerContainer}).back}), toLine: extensionLineData.line}));

                    cornerContainerLine = nearbyExtensionLineData ? nearbyExtensionLineData.line : Container.getFootprintLines({container: cornerContainer}).back;
                  }

                  var extendedPoint = lib.math.intersectionPoint({l1: cornerContainerLine, l2: line});

                  //warning, usually not safe to assume from vs to, but safe in this case
                  //because we only care about the section of the line not overlapping the container
                  var potentialExtensionLine = {from: line.to, to: extendedPoint};

                  //hint prevent extending through a wall
                  if (showWallsAndArchElements) {
                    _.forEach(potentialExtensionLinesData, extensionLinesData => {
                      var wallLine = extensionLinesData.line;

                      if (lib.math.linesIntersect({l1: lib.trig.extend({line: potentialExtensionLine, by: 0.001}), l2: lib.trig.extend({line: wallLine, by: 0.001})})) {
                        extendedPoint = _.mapValues(lib.math.intersectionPoint({l1: lib.trig.extend({line: wallLine, by: 0.001}), l2: lib.trig.extend({line: potentialExtensionLine, by: 0.001})}), value => lib.number.round(value, {toNearest: 0.001}));
                      }
                    });
                  }

                  if (!_.some(footprintLines, line => lib.math.pointIsOnLine({point: extendedPoint, line}) || _.isEqual(extendedPoint, line.from) || _.isEqual(extendedPoint, line.to))) {
                    //< HINT identify sidekey that is being updated, so we know not to extend to wall in logic below
                    var updatedSideKeyCandidates = _.includes(['left', 'right'], perpendicularSideKey) ? ['front', 'back'] : ['left', 'right'];

                    var updatedSideKey = _.minBy(updatedSideKeyCandidates, sideKeyCandidate => {
                      return lib.trig.distance({toLine: footprintLines[sideKeyCandidate], fromPoint: extendedPoint});
                    });

                    updatedSideKeys.push(updatedSideKey);
                    ///>

                    footprintLines = extendFootprintLinesToPoint({point: extendedPoint, footprintInRoom, footprintLines, sideKey: perpendicularSideKey});
                  }
                });

                footprintInRoom = footprintFrom({sideKey: perpendicularSideKeys[0], footprintLines});
                footprintLines = footprintLinesFor(footprintInRoom);
              }
            });
          });

          _.forEach(footprintLines, (line, sideKey) => {
            //Hint if we already stretched to a nearby container, don't stretch to wall
            if (!_.includes(updatedSideKeys, sideKey)) {
              var alpha = lib.trig.alpha({p1: line.from, p2: line.to});

              var nearbyExtensionLineData = _.minBy(_.filter(potentialExtensionLinesData, extensionLineData => {
                var isParallel = lib.trig.anglesAreParallel({a1: extensionLineData.alpha, a2: alpha});
                var isNearby = lib.trig.distance({fromPoint: lib.math.midpoint({line}), toLine: extensionLineData.line}) < K.nearbyElementForCountertopThreshold;

                return isParallel && isNearby;
              }), extensionLineData => lib.trig.distance({fromPoint: lib.math.midpoint({line}), toLine: extensionLineData.line}));

              //filter out inline containers that are between ctr and wall
              // if ((sideKey === 'left' && inlineContainersData.from.length) || (sideKey === 'right' && inlineContainersData.to.length)) nearbyExtensionLineData = undefined;

              if (nearbyExtensionLineData) {
                var perpendicularSideKeys = oppositeSideKeysOf({sideKey});

                _.forEach(perpendicularSideKeys, perpendicularSideKey => {
                  //HINT don't update if already updated by corner container loop
                  var line = footprintLines[perpendicularSideKey];
                  var extendedPoint = lib.math.intersectionPoint({l1: nearbyExtensionLineData.line, l2: line});

                  footprintLines = extendFootprintLinesToPoint({point: extendedPoint, footprintInRoom, footprintLines, sideKey: perpendicularSideKey});
                });

                footprintInRoom = footprintFrom({sideKey: perpendicularSideKeys[0], footprintLines});
                footprintLines = footprintLinesFor(footprintInRoom, rotation);
              }
            }
          });
        }

        var countertopY = Container.getYRange({container}).to;

        footprints.push({footprintInRoom, footprintLines, countertopY, overhang, rotation, containers});
      });

      //TODO generalize for non-90-degree rotations
      return _.map(footprints, ({footprintInRoom, footprintLines, countertopY, overhang, rotation, containers}) => {
        var rotation = lib.trig.normalize({degrees: lib.trig.radiansToDegrees(lib.trig.alpha({p1: footprintLines.front.from, p2: footprintLines.front.to}))});

        return {
          containers,
          dimensions: {
            width: lib.number.round(lib.trig.distance({fromPoint: footprintLines.front.from, toPoint: footprintLines.front.to}), {toNearest: 1 / 16}) + (2 * overhang.x),
            depth: lib.number.round(lib.trig.distance({fromPoint: footprintLines.left.from, toPoint: footprintLines.left.to}), {toNearest: 1 / 16}) + (2 * overhang.y),
            height: 0.5
          },
          rotation,
          position: {
            x: footprintLines.back.to.x - lib.trig.rotate({point: {x: overhang.x, y: overhang.y}, byDegrees: rotation}).x,
            z: footprintLines.back.to.y - lib.trig.rotate({point: {x: overhang.x, y: overhang.y}, byDegrees: rotation}).y,
            y: countertopY
          }
        };
      });
    }
  },

  getSnapToLines({container, elevation, room, viewKey}) {
    // const snapToWall = Container.getSnapToWall({container, viewKey});
    const {snapToFloor} = Container.getTypeDataFor({container});
    const snapToLines = [];

    if (!container.customData.preventSnapToFloor && snapToFloor && viewKey === 'front') {
      const walls = Elevation.get('walls', {elevation});

      _.forEach(walls, wall => {
        snapToLines.push(...Wall.getFloorLines({wall, elevation}));
      });

      if (!snapToLines.length) {
        snapToLines.push({from: {x: -1000, y: 0}, to: {x: 1000, y: 0}});
      }
    }

    return snapToLines;
  },

  getFill({container, elevation, activeFillMode, activeDetailLevel, for3d}) {
    var fill = '';

    if (_.includes(['tallFreestandingAppliance', 'baseFreestandingAppliance', 'wallFreestandingAppliance'], container.type)) {
      fill = K.applianceAppearancePropsByTheme().fill.light;
    }
    else {//if (!_.includes(['floatingShelves', 'wallPanel', 'backsplash', 'wall', 'pivotDoor', 'hbIslandExtension'], container.type)) {
      fill = K.containerAppearancePropsByTheme().fill.light;
    }

    if (container.type !== 'countertop' && elevation && _.includes(['left', 'right'], Container.getSideKey({container, elevation, viewKey: 'front'})) && lib.math.linesIntersect({l1: elevation.lineInRoom, l2: Container.getFootprintLines({container}).front})) {
      fill = K.sectionContainerAppearancePropsByTheme().fill.light;
    }

    if (elevation && container.type === 'countertop' && _.some(Container.getFootprintLines({container}), line => lib.math.linesIntersect({l1: elevation.lineInRoom, l2: line}))) {
      fill = K.sectionContainerAppearancePropsByTheme().fill.light;

    }

    if (activeFillMode === 'unitType') {
      var unitTypeColor = K.designPlanningCompleteColorMap[container.type] || K.designPlanningCompleteColorMap.base;

      if (_.includes(['tallFreestandingAppliance', 'baseFreestandingAppliance', 'wallFreestandingAppliance'], container.type)) {
        unitTypeColor = K.designPlanningCompleteColorMap.freestandingAppliance;
      }

      fill = `#${unitTypeColor}`;
    }

    if (activeFillMode === 'grayscale') {
      var defaultFill = '#d7d7d7';

      if (container.type !== 'countertop' && (container.position.y > 50 || container.dimensions.height > 50)) {
        defaultFill = '#ababab';
      }

      if (!for3d && container.type !== 'countertop' && container.position.y > 50) {
        defaultFill = Color('#919191').alpha(0.7).hexa()
      }

      fill = _.get(container, 'customData.value', defaultFill);
    }

    if (Container.getTypeDataFor({container}).isOrnament) {
      fill = _.get(container, 'customData.ornamentFill');
    }

    return fill;
  },

  getHasComplexAutofillLogic({container}) {
    return _.includes(['pivotDoor', 'valet', 'assembly', 'daylightIsland', 'hbIslandExtension'], container.type);
  },

  getIsSection({container, elevation}) {
    var isSection = false;
    const viewKey = elevation ? 'front' : 'top';
    const sideKey = Container.getSideKey({viewKey, container, elevation});

    if (_.includes(['left', 'right'], sideKey) && elevation) {
      const footprintLines = Container.getFootprintLines({container});
      var isIntersectedByElevationLine = _.some(footprintLines, l1 => lib.math.linesIntersect({l1, l2: elevation.lineInRoom}));

      //Elevation.getIsSection({elevation}) ||
      if (isIntersectedByElevationLine) {
        isSection = true;
      }
    }

    return isSection;
  },
  getIsInvalid({container, room, issuesData}) {
    var isInvalid = false;

    //HINT while adding
    if (!container.id) return false;

    if (issuesData && issuesData.issues) {
      if (issuesData && issuesData.issues) {
        isInvalid = _.some(_.get(issuesData.issues, `[${room.id}]`), issue => {
          var {resourceKey, resourceId, level, isResolved} = issue;

          return !isResolved && resourceKey === 'container' && resourceId === container.id && level === 1;
        });
      }
    }

    return isInvalid;
  },
  getBackPanelWidths({container, companyKey}) {
    var panelWidths;

    if (container.customData.backPanelWidths) {
      panelWidths = _.map(_.split(container.customData.backPanelWidths, ','), width => _.toNumber(width));
    }
    else {
      var innerWidth = Container.getInnerWidth({container});
      var maxWidth = Container.getIslandBackPanelMaxPanelSize({container, companyKey});
      var panelCount = _.ceil(innerWidth / maxWidth);
      var panelWidth = lib.number.round(innerWidth / panelCount, {toNearest: 1 / 16});

      //WARNING total needs to add up to innerWidth, despite rounding, so last width is relative
      var panelWidths = _.times(panelCount, index => {
        var isMiddle = index === Math.floor(panelCount / 2);

        return isMiddle ? (innerWidth - panelWidth * (panelCount - 1)) : panelWidth;
      });
    }

    return panelWidths;
  },
  getDefaultY({container}) {
    var defaultY = 0;

    var companyKey = Container.get('companyKey', {container});

    if (_.includes(['floatingShelves', 'wall', 'wallUnitLiner', 'capPanel', 'opencase', 'wallFreestandingAppliance', 'wallPanel'], container.type)) defaultY = 68;
    if (_.includes(['countertop', 'horizontalBarblock', 'rearFacingBarblock', 'backsplash'], container.type)) defaultY = 35.25;
    if (_.includes(['valet'], container.type)) defaultY = 34;
    if (container.type === 'vanity') defaultY = companyKey === 'hb' ? (19 + 5 / 16) : 10;
    if (_.includes(['countertopSupport'], container.type)) defaultY = 32.75;

    return defaultY;
  },

  getHasProjection({container, elevation}) {
    var hasProjection = false;
    var hasLighting = Container.getHasLighting({container});
    var sideKey = Container.getSideKey({container, viewKey: 'front', elevation});

    if (sideKey === 'front' && elevation && Container.isShowingFrontFor({container, elevation})) {
      hasProjection = (hasLighting && container.customData.lightingType !== 'linear') || container.type === 'opencase';

      if (container.type === 'opencase') {
        hasProjection = _.some(Container.get('products', {container}), product => Product.getHasComponents({product}));
      }
    }

    return hasProjection;
  },

  getProjectionData({container, elevation}) {
    var hasLighting = Container.getHasLighting({container});
    var sideKey = Container.getSideKey({container, viewKey: 'front', elevation});
    var projectionY = 0;

    var hasProjection = Container.getHasProjection({container, elevation});

    if (hasProjection) {
      var margin = 40;

      if (hasLighting) {
        projectionY = -margin - Elevation.getMaxHeight({elevation}) - container.dimensions.height;
      }
      else if (container.type === 'opencase') {
        margin = 35;

        var containerBoundaries = Elevation.getContainerBoundaryPositions({
          elevation,
          filter: ({containers}) => {
            return _.filter(containers, c2 => {
              return c2.type === 'opencase' && Container.getHasProjection({container: c2, elevation});
            });
          }
        });
        //HINT use highest opencase panel as 0
        //margin + distance from highest opencase
        projectionY = margin + containerBoundaries.max.y - container.position.y - container.dimensions.height;
      }
    }

    return {hasProjection, projectionY};
  },
  getHatchFillData: ({container, viewKey, includeAll, activeFillMode, activeDetailLevel}) => {
    var fills = {};
    var showColors = _.includes(['materialColors'], activeFillMode);
    var showHatches = _.includes(['materialHatches', 'materialColors'], activeFillMode);
    var shouldInvertStrokeByMaterialKey = {};

    if (viewKey === 'front' && showHatches) {
      var {project, dependencies} = Container.get(['project', 'dependencies'], {container});
      var companyKey = project.companyKey;
      var potentialMaterialKeys = [
        'islandBackPanelMaterial', 'endPanelMaterial', 'kickMaterial', 'subcounterMaterial', 'seatingSupportRodMaterial', 'ocSolidCornerMaterial', 'backstopMaterial', 'frameMaterial',
        'topCapPanelMaterial', 'bottomCapPanelMaterial', 'countertopMaterial', 'boxMaterial', ...(includeAll ? [..._.keys(container.details)] : []), ..._.map(['left', 'right', 'top'], sideKey => `${sideKey}ScribeMaterial`)
      ];
      var currentDetails = DetailsHelper.getDetailsFor({container});
      var flattenedMaterials = _.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials);

      _.forEach(_.uniq(potentialMaterialKeys), detailKey => {
        if (detailKey === 'countertopMaterial' && (container.customData.isByOthers || container.customData.isByOthers === undefined)) {
          fills[detailKey] = showColors ? container.customData.fillColor || '' : '';

          if (showColors) {
            shouldInvertStrokeByMaterialKey[detailKey] = container.customData.fillColor === 'black';
          }
        }
        //HINT ST half inch subcounter takes front material
        else if (companyKey === 'vp' && detailKey === 'subcounterMaterial' && _.includes([0.25, 0.5], Container.getSubcounterHeight({container}))) {
          //TODO handle situations where you can't see/modify front material
          var detailValue = _.get(currentDetails['frontMaterial'], 'id');
          var material = _.find(flattenedMaterials, {id: detailValue});

          fills[detailKey] = !showColors ? HatchHelper.forHatch({key: Project.getHatchKeyFor({project, detailValue})}) : _.get(material, 'color');

          if (showColors) {
            shouldInvertStrokeByMaterialKey[detailKey] = _.get(material, 'isDark');
          }
        }
        else {
          var detailValue = _.get(currentDetails, `[${detailKey}].id`);

          if (_.includes(detailKey, 'ScribeMaterial')) {
            detailValue = _.get(container, `details[${detailKey}].id`);

            if (!detailValue) detailValue = _.get(currentDetails, 'scribeMaterial.id');
          }

          if (detailValue) {
            var material = _.find(flattenedMaterials, {id: detailValue});

            fills[detailKey] = !showColors ? HatchHelper.forHatch({key: Project.getHatchKeyFor({project, detailValue})}) : _.get(material, 'color');

            if (showColors) {
              shouldInvertStrokeByMaterialKey[detailKey] = _.get(material, 'isDark');
            }
          }
        }

        if (fills[detailKey] === '') delete fills[detailKey];

        if (fills[detailKey] && showColors && activeDetailLevel === 'rendering' && container.customData.renderingOverrideFill) {
          fills[detailKey] = container.customData.renderingOverrideFill;
        }
      });
    }

    return {hatchFills: fills, shouldInvertStrokeByMaterialKey};
  },
  getScopeId({container, scopes}) {
    if (!scopes) scopes = Container.get('scopes', {container});

    scopes = _.orderBy(scopes, 'id');

    if (!container.position || _.isEmpty(container.position)) {
      scopeId = container.scopeId;
    }
    else {
      var footprint = Container.getFootprintInRoom({container});

      var scopeId = _.get(_.find(scopes, scope => {
        var isInScope = false;

        if (scope.plan && scope.plan.points && scope.plan.points.length > 0 && scope.plan.position) {
          var scopeFootprint = _.map(scope.plan.points, point => lib.object.sum(point, scope.plan.position));

          if (lib.polygon.polygonsOverlap(scopeFootprint, footprint)) isInScope = true;
        }

        return isInScope;
      }), 'id');
    }

    if (!scopeId) scopeId = _.get(_.orderBy(scopes, 'id'), '0.id');

    return scopeId;
  },
  getUpdatedPropsForTransformerProps: ({container, viewKey, elevation, room, viewOffset, transformerProps, overridePosition, isNonSpacial, nonSpacialSideKey, roundToMinPrecision = false}) => {
    const sideKey = isNonSpacial ? nonSpacialSideKey : Container.getSideKey({container, elevation, viewKey});

    transformerProps.size = _.mapKeys(transformerProps.size, (size, sizeKey) => (K.sideSizeMap[sideKey][sizeKey]));
    var updatedDimensions = _.mapValues({...container.dimensions, ...transformerProps.size}, value => lib.round(value, {toNearest: K.minPrecision}));

    var updatedPosition = transformerProps.position;
    var normalizeOffsets = viewOffset;

    //HINT renormalize
    if (viewKey === 'front') {
      var sideKeyOffset = {
        front: 0,
        left: 0,
        right: -updatedDimensions.depth,
        back: -updatedDimensions.width
      }[sideKey];

      normalizeOffsets = lib.object.sum(normalizeOffsets, {y: -updatedDimensions.height, x: sideKeyOffset});
    }
    else {
      normalizeOffsets = lib.object.sum(normalizeOffsets, room.plan.position);
    }

    updatedPosition = lib.object.difference(updatedPosition, normalizeOffsets);

    if (roundToMinPrecision) {
      updatedPosition = _.mapValues(updatedPosition, value => lib.round(value, {toNearest: K.minPrecision}));
    }

    if (isNonSpacial) {
      var updatedProps = {
        dimensions: updatedDimensions,
        customData: container.customData
      };
    }
    else {
    // attemptSpacialUpdate() {
      var position3dProps = Container.position3dTransformFor({
        container, viewKey, elevation,
        position2d: updatedPosition,
        position3d: container.position
      });

      if (!container.customData.preventSnapToFloor && Container.getTypeDataFor({container}).snapToFloor && viewKey === 'front') {
        var y = 0;
        var walls = Elevation.get('walls', {elevation});

        if (_.some(walls, wall => _.some(_.map(Wall.getFloorLines({wall, elevation}), 'y'), y => y !== 0))) {
          var xRange = {from: _.minBy(Container.getWallprint({container, elevation}), 'x').x, to: _.maxBy(Container.getWallprint({container, elevation}), 'x').x};
          var wall = _.find(walls, wall => {
            return _.some(xRange, sideKey => {
              return Elevation.getWallX({wall, elevation}) <= sideKey && sideKey <= (Elevation.getWallX({elevation, wall}) + Elevation.getVisibleWallWidth({elevation, wall}));
            });
          });

          if (wall) var {y} = Wall.getNearestFloorData({xRange, elevation, wall});
        }

        position3dProps.position3d.y = y;
      }

      var updatedProps = {
        position: position3dProps.position3d,
        dimensions: updatedDimensions,
        customData: container.customData
      };
    }

    _.unset(updatedProps.dimensions, undefined);

    if (viewKey === 'top') updatedProps.rotation = position3dProps.rotation || transformerProps.rotation; //lib.round(, {toNearest: 1});

    if (container.type === 'endPanel') {
      _.set(updatedProps, 'customData.wrap.thickness', updatedProps.dimensions.width);
    }
    if (container.type === 'capPanel') {
      _.set(updatedProps, 'customData.wrap.topThickness', updatedProps.dimensions.height);
    }
    if (container.type === 'kick') {
      _.set(updatedProps, 'customData.kickHeight', updatedProps.dimensions.height);
    }

    return updatedProps;
  },

  getScopeGridPosition({container, i, containers, containersLength, scope}) {
    if (!((i || i === 0) && (containersLength || containersLength === 0)) && !scope) scope = Container.get('scope', {container});

    if (!i || !containersLength || !containers) {
      var {containers} = Scope.get(['containers'], {scope});

      containersLength = _.size(containers);
      i = _.findIndex(_.values(containers), {id: container.id});
    }

    var productRowHeight = 15;
    var productOptionHeight = 7.5;

    var rowContainersHeights = _.map(containers, (_c) => {
      var {products} = Container.get(['products'], {container: _c});
      var productIdsAndEnabledOptions = {};

      _.forEach(products, _p => productIdsAndEnabledOptions[_p.id] = Product.getEnabledOptionsLiteMode({product: _p}));

      var pOptionsHeightsByRowTotals = _.map(productIdsAndEnabledOptions, (pdata) => ((pdata.length) * productOptionHeight));
      var pRowHeightsTotal = _.sum(_.map(pOptionsHeightsByRowTotals, (value) => productRowHeight + value));

      return _c.dimensions.height + pRowHeightsTotal;
    });

    var cellHeight = _.max([120, ..._.map(containers, 'dimensions.height')]) + 10;
    var cellWidth = _.max([..._.map(containers, 'dimensions.width')]) + 10;
    var columns = 4;
    var rows = _.ceil(containersLength / columns);

    var column = i % 4;
    var row = _.floor(i / columns);

    if (row !== 0 ) cellHeight = _.max([120, ...rowContainersHeights]);

    var x = _.sum(_.map(_.filter(_.values(containers), (container, _i) => _.floor(_i / columns) === row && (_i % 4) < column), (container) => _.max([20, container.dimensions.width]) / 2 + 15)); //(column * cellWidth) - (cellWidth * columns / 2);
    var y = (row * cellHeight);

    var containerCenterOffset = {x, y: (cellHeight / 2)};

    return lib.object.sum({x, y}, containerCenterOffset);
  },

  getCanvasSettings({container}) {
    var canvasSettings = [];
    var {containerType, project} = Container.get(['containerType', 'project'], {container});

    if (containerType.type === 'countertop') {
      canvasSettings.push({type: 'toggle', key: 'customData.isByOthers', options: [{value: 0, title: project.companyKey === 'vp' ? 'ST' : 'HB'}, {value: 1, title: 'B/O'}], value: _.get(container, 'customData.isByOthers'), position: {x: container.dimensions.width / 2, y: -(container.dimensions.height + 1)}, shape: 'rect'});
    }
    else if (_.size(containerType.wrappableSides)) {
      var wrapSizes = Container.getWrapSizes({container});

      _.forEach(containerType.wrappableSides, sideKey => {
        var position = {
          left: {x: 0, y: container.dimensions.height + 1},
          right: {x: container.dimensions.width - wrapSizes.right, y: container.dimensions.height + 1},
          bottom: {x: container.dimensions.width / 2, y: container.dimensions.height - (wrapSizes.top / 2)},
          top: {x: container.dimensions.width / 2, y: (wrapSizes.bottom / 2)},
        }[sideKey];

        // canvasSettings.push({type: 'toggle', key: `customData.wrap.${sideKey}`, shape: 'rect', value: _.get(container, `customData.wrap.${sideKey}`), position: sideKey === 'left' ? {x: ((wrapSizes.left - 1.5) / 2), y: container.dimensions.height / 2} : {x: container.dimensions.width - ((wrapSizes.right + 1.5) / 2), y: container.dimensions.height / 2}});
        canvasSettings.push({type: 'toggle', key: `customData.wrap.${sideKey}`, value: _.get(container, `customData.wrap.${sideKey}`), position, shape: 'rect'});

      });
    }

    return canvasSettings;
  }
};

export default Container;
