import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import K from 'k';
import lib from 'lib';

import checkIconBlack from 'assets/check-icon-black.png';
import Thumbnail from './thumbnail';
import Slideshow from 'components/slideshow';

function ConfiguratorOptionSelector({project, archetype, activePropertyData, appliedOption = {}, activePropertyRoomId, onBackClick, onApplyClick, isConfirming, hasConfirmed, handleBuyButtonClick, pulls}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [activeSlideshowOption, setActiveSlideshowOption] = useState(null);
  const [mediaByType, setMediaByType] = useState({mediaByMaterial: {}, mediaByPull: {}});
  const [hoveringOptionValue, setHoveringOptionValue] = useState(null);
  var [isApplying, setIsApplying] = useState(false);

  var style = {
    header: {
      column: {flex: 1, display: 'flex', flexDirection: 'column'}
    },
    fonts: {
      label: {fontWeight: 500, fontSize: '1em', letterSpacing: '0.1em'}
    },
  };

  var {options, title: propertyTitle, archetypeParameterGroup, path} = activePropertyData;
  var {value: appliedOptionId} = appliedOption;

  var handleOnClick = (option) => () => {
    setSelectedOption(option);
    if (!option.tbd) handleOnApply({selectedOption: option});
    setActiveSlideshowOption(option);
  };
  var handleSelectPhotosClick = (option) => () => setActiveSlideshowOption(option);

  var handleOnApply = ({selectedOption}) => onApplyClick({roomId: activePropertyRoomId, id: activePropertyData.id, value: selectedOption.value, path, activePropertyData, appliedOption: selectedOption});

  useEffect(async () => {
    var mediaByTypeData = {mediaByMaterials: {}, mediaByPull: {}};

    if (activePropertyData.path === 'pullType') {
      mediaByTypeData.mediaByPulls = _.mapValues(pulls, (pull, pullIndex) => {
        return _.map(pull.media, medium => ({...medium, mediumId: medium.id, id: pullIndex}));
      });
    }
    else {
      if (options && options.length > 0) {
        var materialOptionIds = _.map(options, _o => {
          mediaByTypeData.mediaByMaterials[_o.value] = [];

          return _o.value;
        });

        var media = await lib.api.get('media', {where: {materialId: materialOptionIds, companyKey: 'hb', isPublic: 1}, order: ['rank']});

        _.forEach(media, (medium, mediumIndex) => {
          _.forEach(materialOptionIds, (id, index) => {
            if (_.get(medium, `associations.materials.id_${id}`)) {
              mediaByTypeData.mediaByMaterials[id].push({...medium, mediumId: medium.id, id: mediumIndex});
            }
          });
        });
      }
    }

    setMediaByType(mediaByTypeData);

  }, []);

  var mediaToRender = _.get(mediaByType, `${activePropertyData.path === 'pullType' ? 'mediaByPulls' : 'mediaByMaterials'}.${activeSlideshowOption ? activeSlideshowOption.value : appliedOptionId}`, []);

  mediaToRender = _.filter(mediaToRender, {showInConfigurator: 1});

  var visuallySelectedOption = activeSlideshowOption || appliedOption;
  var hasChangedValue = appliedOption.value !== visuallySelectedOption?.value;

  return (
    <div style={{width: '100%', position: 'fixed', zIndex: 100, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', backgroundColor: 'rgba(0, 0, 0, 0.5)'}} className='configurator-option-selector-popup-wrapper' onClick={event => {
      if (event.target.classList.contains('configurator-option-selector-popup-wrapper')) {
        onBackClick();
      }
    }}>
      <div style={{backgroundColor: 'white', display: 'flex', flexDirection: 'column', height: '100%', width: 500, justifyContent: 'space-between'}}>
        <div style={{display: 'flex', flexDirection: 'row', padding: '1rem 2rem', alignItems: 'center'}}>
          <div style={{fontSize: '0.7rem', letterSpacing: '0.05em', textTransform: 'uppercase', marginRight: '1rem', flex: 1}}>{propertyTitle}</div>
          <div onClick={onBackClick} style={{display: 'flex', backgroundColor: '#f5f5f5', marginRight: '0.5rem', cursor: 'pointer', padding: '0.5rem 1rem', ...K.fonts.label, fontSize: '0.7rem', opacity: 1}}>
            {hasChangedValue ? 'Cancel' : 'Back'}
          </div>
          {hasChangedValue && (
            <div style={{backgroundColor: '#000', color: 'white', padding: '0.5rem 1rem', cursor: 'pointer', ...K.fonts.label, fontSize: '0.7rem', opacity: 1}} onClick={() => {
              if (!isApplying) {
                setIsApplying(true);
                setTimeout(() => {
                  handleOnClick(visuallySelectedOption)();
                }, 50);
              }
            }}>
              {isApplying ? 'Applying...' : 'Apply'}
            </div>
          )}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, overflowY: 'auto', borderTop: 'rgba(0, 0, 0, 0.05) 1px solid', borderBottom: 'rgba(0, 0, 0, 0.05) 1px solid'}}>
          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0}}>
            <div style={{overflow: 'auto', flex: 1, padding: '1rem', paddingRight: '2rem'}}>
              {_.map(options, o => {
                var isActive = visuallySelectedOption.value === o.value;

                return (
                  <div key={`option-${o.value}`} style={{display: 'flex', borderRadius: 100, marginRight: K.margin, marginBottom: K.margin, alignItems: 'center'}}
                    onMouseEnter={() => setHoveringOptionValue(o.value)}
                    onMouseLeave={() => setHoveringOptionValue(null)}
                  >
                    <div style={{display: 'flex', flex: 1, columnGap: K.spacing * 2, alignItems: 'center', cursor: 'pointer'}} onClick={handleSelectPhotosClick(o)}>
                      <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 19}}>
                        <div style={{...K.fonts.label, fontSize: '0.8em', textTransform: 'uppercase', letterSpacing: '0.05em', opacity: isActive ? 1 : 0.6}}>
                          {o.title + (isActive ? ' (Selected)' : '')}
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 42, width: 42, minWidth: 42, borderRadius: 25, border: isActive ? '1px solid black' : K.grayBorder, overflow: 'hidden', position: 'relative'}}>
                        <Thumbnail key={`option-thunmbnail-${o.value}`} src={o.thumbnail} style={{height: 42, width: 42, minWidth: 42, backgroundColor: '#fff'}}/>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Slideshow key={visuallySelectedOption.value} media={mediaToRender} imageStyle={{width: 500, height: 300}} closeDisabled/>
      </div>
    </div>
  );
}

export default ConfiguratorOptionSelector;
