import React, {useState, memo } from 'react';

import K from 'k';
import _ from 'lodash';

import {withErrorBoundary} from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';

import InfoIcon from '../../../../assets/info-icon.png';
import Container from 'project-helpers/container';
import Thumbnail from './thumbnail'

var AddableItem = function AddableItem({option, optionIndex, addingGroup, toggleInfoPopupShowing, createDragItem, setDragData, viewMode, bothIsShowingElevations, handleAddMenuMouseUp, companyKey, showThumbnails, headerDivStyles, isProjectMenuNodeContainer, onClick, getIncludesActiveEntity}) {
  var [isHovering, setIsHovering] = useState(false);
  var [isHoveringInfoIcon, setIsHoveringInfoIcon] = useState(false);
  var styles = {
    infoButton: {
      width: 14, height: 14
    }
  };

  var thumbnailScript;
  var isOrnament = _.get(option, 'isOrnament', false);
  var isSpeccedContainer = _.get(option, 'isSpeccedContainer', false);
  var isDisabled = _.get(option, 'isDisabled', false);
  var usesTopScriptForPreviewOrnaments = ['scaleFigure7', 'scaleFigure8', 'scaleFigure9'];
  var addableFromTopOrnaments = [...usesTopScriptForPreviewOrnaments, 'propCouch1', 'propCouch2'];

  if (isOrnament) {
    thumbnailScript = option.scripts.front;

    if (_.includes(usesTopScriptForPreviewOrnaments, option.type)) {
      thumbnailScript = option.scripts.top;
    }

    // HINT team reported wanting to be able to add any ornament from the top view
    if (_.includes(['top', 'both'], viewMode) && !bothIsShowingElevations && !_.includes(addableFromTopOrnaments, option.type)) {
      // isDisabled = true;
    }
  }

  return (<>
    <div
      onMouseEnter={() => setIsHovering(isProjectMenuNodeContainer ? false : true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        ...(isHovering ? {opacity: 0.7} : {opacity: 1}),
        display: 'flex', flexDirection: 'row',
        ...(!showThumbnails
          ? {paddingBottom: K.spacing * .75, width: '90%'}
          : { width: '30%', alignItems: 'flex-start'}
        ),
        ...(!isDisabled || isProjectMenuNodeContainer ? {cursor: 'pointer'} : {cursor: 'default', opacity: 0.3}),
      }}
    >
      <div
        style={{
          display: 'flex',
          ...(showThumbnails ? {flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto'} : {}),
          ...(!showThumbnails ? {
            marginLeft: K.spacing + 14,
            flexDirection: 'row'} : {}),

        }}

        {...((isProjectMenuNodeContainer ? {
          onClick: () => onClick()
        } : (viewMode === 'lite' ? {
          onClick: (event) => {
            event.preventDefault();
            if (!isDisabled && !event.target.closest('.info-icon')) {
              var dragItem = createDragItem(option);

              handleAddMenuMouseUp(dragItem);
            }
          }
        } : {
          onMouseDown: (event) => {
            event.preventDefault();
            if (!isDisabled) {
              setDragData({isDragging: true, dragItem: createDragItem(option), lastMouseEvent: event});
            }
          }
        })))}
      >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          {showThumbnails && (
            <Thumbnail {...{item: option, addingGroup, thumbnail: option.thumbnail, viewMode, optionIndex, bothIsShowingElevations, isOrnament, isSpeccedContainer, isDisabled, thumbnailScript, companyKey}}/>
          )}
          <div
            style={{
              ...headerDivStyles,
              ...(showThumbnails === true ? {textAlign: 'center'} : {textAlign: 'left'}),
              ...(isProjectMenuNodeContainer && option && getIncludesActiveEntity({resourceKey: option.type, id: option.draggableId}) ? {fontWeight: 'bold'} : {})
            }}
          >
            {_.replace(option.title, 'Atlantic Fields - ', '')}
          </div>
        </div>
      </div>
      {!isProjectMenuNodeContainer && (
        <div
          style={{
            ...styles.infoButton,
            ...(showThumbnails ? {position: 'relative', top: 0, left: -15} : {}),
            ...(!showThumbnails ? {display: 'flex', marginLeft: K.spacing, marginTop: 1} : {})
          }}>
          {_.includes(['product', 'container'], addingGroup) && isHovering && (
            <div
              onMouseEnter={() => setIsHoveringInfoIcon(true)}
              onMouseLeave={() => setIsHoveringInfoIcon(false)}
              style={{
                cursor: 'pointer',
                opacity: isHoveringInfoIcon ? 0.9 : 0.5,
                ...(showThumbnails ? {position: 'absolute'} : {})
              }}
              onClick={() => toggleInfoPopupShowing({entity: addingGroup === 'container' ? Container.get('dbContainerType', {container: option}) : option, entityResourceKey: addingGroup})}
              // data-tip='tooltip' data-for={`${activeEntityId}-tooltip`}
            >
              <img src={InfoIcon} style={{objectFit: 'contain', ...styles.infoButton}}/>
            </div>
          )}
        </div>
      )}
    </div>
  </>);
};

export default withErrorBoundary(AddableItem, {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Menu Element'})
});