import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Shelfbank from 'project-helpers/product-helpers/shelfbank';
import DaylightIslandComponent from 'project-helpers/product-helpers/daylight-island-component';
import K from 'k';
import _ from 'lodash';

const getOverlappingComponents = ({
  products
}) => {
  var issues = [];

  products = _.filter(products, product => !Product.getIsManaged({product}) || _.get(product, 'managedData.managedKey') === 'autofilledStorage');

  _.forEach(products, (product) => {
    var isInvalid = false;
    var message = '';
    var resourceId = product.id;
    var issueId = '';

    const {siblings, companyKey, parentProduct} = Product.get(['siblings', 'companyKey', 'parentProduct'], {product});
    const type = Product.getType({product});


    if (_.includes(Product.getProductIdsThatIgnoreOverlap({companyKey: companyKey}), product.productId) || _.get(product, 'customData.isDashed')) {
      isInvalid = false;
    }
    else if (type === 'barblockComponent') {
      var isOverlappingWithComponents, isOverlappingWithFixedShelf = false;

      // isOvelappingWithComponents = _.some(this.parent.products, product => {
      //   var isOverlapping = product !== this ? this.sharesWallprintWith({product}) : false;

      //   return isOverlapping;
      // });

      // if (this.resourceProps.productId === 1142 && this.sharesWallprintWith()) {
      //   isOverlappingWithFixedShelf = true;
      // }

      isInvalid = isOverlappingWithComponents;// || isOverlappingWithFixedShelf;

      _.forEach({left: product.position.x, right: product.position.x + product.dimensions.width}, (productX, side) => {
        var correctlySpacedSibling = _.find(siblings, sibling => {
          var siblingX = side === 'left' ? sibling.position.x + sibling.dimensions.width : sibling.position.x;

          return side === 'left' ? ((siblingX + 3 / 4) === productX) : ((siblingX - 3 / 4) === productX);
        });

        if (!(_.includes([0, parentProduct.dimensions.width - 1.5], productX) || correctlySpacedSibling)) {
          isInvalid = true;
        }
      });

      issueId = 'incorrect-spacing';

      if (isInvalid) message = `${product.productionId || Product.get('productType', {product}).title} - overlapping barblock component`
    }
    else if (_.includes(['woodShelf', 'divider', 'steelShelf'], type)) {
      var isOverlappingWithComponents = false;
      var isFarEnoughFromComponent = true;

      var siblingWithConflict = _.find(siblings, siblingProduct => {
        const siblingType = Product.getType({product: siblingProduct});
        var isShelf = _.includes(['woodShelf', 'steelShelf'], type);
        var isProductShelf = _.includes(['woodShelf', 'steelShelf'], siblingType);

        if (siblingType !== 'glassPanel' && Shelfbank.sharesWallprintWith({product, siblingProduct})) isOverlappingWithComponents = true;
        if (isShelf && isProductShelf && !Shelfbank.isFarEnoughFrom({product, siblingProduct})) isFarEnoughFromComponent = false;

        return isOverlappingWithComponents || !isFarEnoughFromComponent;
      });

      if (siblingWithConflict) {
        isInvalid = true;
        issueId = `product-${product.id}-overlapping-product-${siblingWithConflict.id}`;
        message = `${product.productionId || Product.get('productType', {product}).title} - overlapping shelf or divider`
      }

    }
    else if (type === 'daylightIslandProduct') {
      var isOverlappingWithSibling = _.some(_.filter(siblings, sibling => !Product.getIsManaged({product: sibling}) || _.get(product, 'managedData.managedKey') === 'autofilledStorage'), siblingProduct => {
        return DaylightIslandComponent.sharesWallprintWith({product, siblingProduct});
      });

      isInvalid = !_.includes([0, 25, 25 * 2, 25 * 3, 25 * 4], product.position.x) || isOverlappingWithSibling;

      if (isInvalid) {
        message = `${product.productionId || Product.get('productType', {product}).title} - overlapping daylight island product`;
        issueId = `product-${product.id}-daylight-island-configuration`;
      }
    }
    else if (type === 'opencaseComponent') {
      var productGridPositions = Product.getFittingGridPositions({product, absolute: true});

      var overlappingSibling = _.find(siblings, siblingProduct => {
        var siblingGridPositions = Product.getFittingGridPositions({product: siblingProduct, absolute: true});

        return _.some(productGridPositions, gridPosition => _.some(siblingGridPositions, siblingGridPosition => _.isEqual(gridPosition, siblingGridPosition)));
      });

      isInvalid = overlappingSibling;

      if (isInvalid) {
        message = `${product.productionId || Product.get('productType', {product}).title}/${overlappingSibling.productionId || Product.get('productType', {product: overlappingSibling}).title} - overlapping opencase component`
        issueId = `product-${product.id}-overlapping-product-${overlappingSibling.id}`;
      }
    }
    else if (!_.includes(Product.getProductIdsThatIgnoreOverlap({companyKey}), product.productId) && !_.get(product, 'customData.isDashed')) {
      function checkOverlap(productPrintInView, siblingPrintInView) {
        try {
          var isOverlapping = lib.math.polygon.polygonsOverlap(productPrintInView, siblingPrintInView, {inclusive: false});

          // If neither of the above, rectangles overlap
          return isOverlapping;
        }
        catch (e) {
          return false;
        }
      }

      var productPositionInView = product.position;

      var dimensions = _.clone(product.dimensions);

      if (_.includes([...K.hb.ids.verticalHiddenPanels, ...K.vp.ids.verticalHiddenPanels, ...K.hb.ids.horizontalHiddenPanels, ...K.vp.ids.horizontalHiddenPanels], product.productId)) {
        var swapDimKey = _.includes([...K.hb.ids.verticalHiddenPanels, ...K.vp.ids.verticalHiddenPanels], product.productId) ? 'width' : 'height';
        dimensions = {...product.dimensions, [swapDimKey]: product.dimensions.depth, depth: product.dimensions[swapDimKey]};
      }

      var productPrintInView = [
        productPositionInView,
        lib.object.sum(productPositionInView, {x: dimensions.width}),
        lib.object.sum(productPositionInView, {x: dimensions.width, y: -dimensions.height}),
        lib.object.sum(productPositionInView, {y: -dimensions.height})
      ];

      var filteredSiblings = _.filter(siblings, product => {
        return _.includes([undefined, 'autofilledStorage'], _.get(product, 'managedData.managedKey')) && !_.includes(Product.getProductIdsThatIgnoreOverlap({companyKey}), product.productId) && !_.get(product, 'customData.isDashed');
      });

      var overlappingSibling = _.find(filteredSiblings, siblingProduct => {
        var isOverlappingThisSibling = false;
        var siblingDimensions = _.clone(siblingProduct.dimensions);

        if (_.includes([...K.hb.ids.verticalHiddenPanels, ...K.vp.ids.verticalHiddenPanels, ...K.hb.ids.horizontalHiddenPanels, ...K.vp.ids.horizontalHiddenPanels], siblingProduct.productId)) {
          var swapDimKey = _.includes([...K.hb.ids.verticalHiddenPanels, ...K.vp.ids.verticalHiddenPanels], siblingProduct.productId) ? 'width' : 'height';
          siblingDimensions = {...siblingProduct.dimensions, [swapDimKey]: siblingProduct.dimensions.depth, depth: siblingProduct.dimensions[swapDimKey]};
        }

        var siblingPositionInView = siblingProduct.position;
        var siblingPrintInView = [
          siblingPositionInView,
          lib.object.sum(siblingPositionInView, {x: siblingDimensions.width}),
          lib.object.sum(siblingPositionInView, {x: siblingDimensions.width, y: -siblingDimensions.height}),
          lib.object.sum(siblingPositionInView, {y: -siblingDimensions.height})
        ];

        if (checkOverlap(productPrintInView, siblingPrintInView)) {
          isOverlappingThisSibling = true;
        }

        return isOverlappingThisSibling;
      });

      if (overlappingSibling) {
        isInvalid = true;
        issueId = `product-${product.id}-overlapping-product-${overlappingSibling.id}`;
        message = `${product.productionId || Product.get('productType', {product}).title}/${overlappingSibling.productionId || Product.get('productType', {product: overlappingSibling}).title} - overlapping`;
      }
    }

    if (isInvalid) {
      issues.push({
        id: issueId,
        message,
        resourceKey: 'product',
        resourceId,
        type: 'overlapping-resources',
        level: 1
      })
    }
  });

  return issues;
};

export default getOverlappingComponents;
