import React, { useState } from 'react';
import _ from 'lodash';
import K from 'k';
import { connect } from 'redux/index.js';
import { resourceActions } from 'redux/index.js';

import Popup from 'components/popup';

const FinalizePopup = ({onClose, project, updateProject}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  var styles = {
    button: {
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      ...K.fonts.label,
      padding: K.spacing,
      opacity: 0.9,
      cursor: 'pointer'
    }
  };

  var handleConfirmClick = async () => {
    var projectId = project.id;
    var {versionId} = project;

    try {
      setIsConfirming(true);
      var apiResponse = await lib.api.request({uri: 'de-project/handle-configurator-draft-submission', body: {projectId, versionId}});

      this.handleBuyPopupOnClose();

      setIsConfirming(false);
      setHasConfirmed(true);

      if (_.get(apiResponse, 'data.locked')) {
        await updateProject({id: projectId, props: {locked: 1}, hitApi: false});
      }
    }
    catch (err) {
      setIsConfirming(false);

      onClose();
    }
  };

  return <Popup onClose={onClose}>
    <div style={{backgroundColor: 'white'}}>
      <p>Are you sure? Clicking "confirm" notifies our team and locks your selections, you won't be able to make edits in the future.</p>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <button onClick={onClose} style={{...styles.button, backgroundColor: '#f5f5f5', color: 'black'}}>Cancel</button>
        <button disabled={isConfirming} onClick={handleConfirmClick} style={{...styles.button, marginLeft: 15, opacity: isConfirming ? 0.5 : 0.9}}>Confirm</button>
      </div>
    </div>
  </Popup>;
};

export default connect({
  mapState: state => {
    return state;
  },
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['updateProject']),
  }
})(FinalizePopup);